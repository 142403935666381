import React, { useState, useEffect } from "react";
import { getBaseUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import ImageSvgs from "../ImageSvgs";
import { Spinner } from "react-bootstrap";

const ToolSupport = (props) => {
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const param = `${getBaseUrl()}${props.source["source-url"]}`;
    async function getResponse() {
      const res = await getData(param);

      const array = res?.data?.tools;
      const max = array
        ? Math.max(...array.map?.((obj) => obj.contacts.length))
        : 0;
      const contactsArray = (array || []).map((obj) => {
        const emptyContact = () => ({ contactPerson: null, email: null });
        const contacts = [
          ...obj.contacts,
          ...Array.from({ length: max - obj.contacts.length }, emptyContact),
        ];
        return { ...obj, contacts };
      });
      res.data.tools = contactsArray;

      setTableData(res.data);
    }
    getResponse();

    // eslint-disable-next-line
  }, []);
  if (!tableData)
    return (
      <div className="text-center">
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
      </div>
    );
  return (
    <div className="org-table d-flex flex-column">
      <table className="w-100 table table-bordered table-scroll">
        <thead>
          <tr>
            {tableData.schema.map((thead, key) => {
              return <th key={key}>{thead}</th>;
            })}
          </tr>
        </thead>
        <tbody className="tools-table-body ">
          {tableData.tools.map((row, key) => {
            return (
              <tr key={key}>
                <td>{row.toolName}</td>
                {row.contacts.map((e, key) =>
                  e.contactPerson ? (
                    <td key={key}>
                      <span
                        className="img m-2 copy-icon"
                        onClick={() => navigator.clipboard.writeText(e.email)}
                      >
                        <ImageSvgs name="copy" />
                      </span>
                      <a className="me-2" href={`mailto:${e.email}`}>
                        <ImageSvgs name="mail" />
                      </a>
                      {e.contactPerson}
                    </td>
                  ) : (
                    <td key={key}></td>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ToolSupport;
