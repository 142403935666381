import { useState, useEffect } from "react";
import "../../src/assets/scss/common.scss";
import "../components/ManageAdminPage/ManageTools.scss";
import ToolsTable from "../components/ManageAdminPage/ToolsTable";
import { getData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { useSearchParams } from "react-router-dom";
import ImageSvgs from "../components/ImageSvgs";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const ManageTools = () => {
  const [toolData, setToolData] = useState(null);
  const [getRes, setGetRes] = useState(false);
  const [searchParams] = useSearchParams();
  const [isAdmin, setIsAdmin] = useState(null);
  const [toolLevel] = useState(
    searchParams ? searchParams.get("page") : "tool"
  );
  const navigate = useNavigate();

  useEffect(() => {
    const param = `${getHostUrl()}tools?include=apps,trainings,experts,communities,tool_info&use_pagination=false`;

    async function getResponse() {
      const adRes = await getData(
        `${getHostUrl()}user/check/${
          JSON.parse(sessionStorage.getItem("userData")).id
        }`
      );
      await setIsAdmin(adRes.data);
      if (adRes.data) {
        try {
          const res = await getData(param);

          if (res) {
            setGetRes(true);
            setToolData(res.data);
          }
          // setIsAdmin(true);
        } catch (err) {
          if (err) {
            setGetRes(false);
            if (err.response.status === 401) {
              setGetRes(true);
              // setIsAdmin(false);
            }
          }
        }
      }
    }
    getResponse();
  }, []);

  return (
    <div className="main-page-margin">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <>
              {getRes ? (
                <div className="main-manage">
                  <div>
                    {
                      <ToolsTable
                        data={toolData}
                        tool={toolLevel && toolLevel}
                      />
                    }
                  </div>
                </div>
              ) : (
                <div style={{ maxWidth: "100%", height: "100vh" }}>
                  <Loader />
                </div>
              )}
            </>
          ) : (
            <div
              className="non-admin d-flex justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="my-auto">
                <span className="mx-auto d-flex justify-content-center">
                  <ImageSvgs name="non_admin" />
                </span>
                <h1 className="text-danger d-flex justify-content-center">
                  Unauthorized user!
                </h1>
                <h4 className="mx-auto d-flex justify-content-center">
                  You do not have admin access rights.
                </h4>
                <button
                  className="d-flex mx-auto"
                  id="common-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ManageTools;
