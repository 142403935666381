import React from "react";
import ImageSvgs from "../ImageSvgs";
import ConnectionsComponent from "../CommonComponents/ConnectionsComponent";
import { Carousel, Stack } from "react-bootstrap";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import { chunk } from "lodash";
import validator from "validator";

const InfoComp = ({ appdata, included }) => {
  const appInfo =
    appdata?.relationships["app-info"].data.length > 0
      ? appdata?.relationships["app-info"].data.map((elem) => {
          return included?.filter(
            (info) =>
              info.type === "app-additional-info" &&
              info.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  const tempCommCons =
    appdata?.relationships.communities.data.length > 0
      ? appdata?.relationships.communities.data.map((elem) => {
          return included?.filter(
            (comm) =>
              comm.type === "community" &&
              comm.id.toString() === elem.id.toString()
          )[0].attributes["communities-master"].data;
        })
      : [];

  const commCons =
    tempCommCons.length > 0
      ? chunk(
          tempCommCons.sort((a, b) =>
            a.attributes["community-name"].localeCompare(
              b.attributes["community-name"]
            )
          ),
          5
        )
      : [];

  const tempTrainCons =
    appdata?.relationships.trainings.data.length > 0
      ? appdata?.relationships.trainings.data.map((elem) => {
          return included?.filter(
            (train) =>
              train.type === "training" &&
              train.id.toString() === elem.id.toString()
          )[0].attributes["trainings-master"].data;
        })
      : [];

  const trainCons =
    tempTrainCons.length > 0
      ? chunk(
          tempTrainCons.sort((a, b) =>
            a.attributes["training-name"].localeCompare(
              b.attributes["training-name"]
            )
          ),
          5
        )
      : [];

  const tempProCons =
    appdata?.relationships.process.data.length > 0
      ? appdata?.relationships.process.data.map((elem) => {
          return included?.filter(
            (process) =>
              process.type === "process" &&
              process.id.toString() === elem.id.toString()
          )[0].attributes["process-master"].data;
        })
      : [];

  const proCons =
    tempProCons.length > 0
      ? chunk(
          tempProCons.sort((a, b) =>
            a.attributes["title"].localeCompare(b.attributes["title"])
          ),
          5
        )
      : [];

  const tempToolCons =
    appdata?.attributes["app-nodes"].data.length > 0
      ? appdata?.attributes["app-nodes"].data.map((elem) => {
          return included?.filter(
            (tool) =>
              tool.type === "tool" &&
              tool.id.toString() === elem.attributes["tool-id"].toString()
          )[0];
        })
      : [];

  const toolCons =
    tempToolCons.length > 0
      ? chunk(
          tempToolCons.sort((a, b) =>
            a.attributes["tool-name"].localeCompare(b.attributes["tool-name"])
          ),
          5
        )
      : [];

  return (
    <div className="d-flex flex-column">
      <div className="links-section mt-4">
        <div className="app-access">
          <span className="d-flex mb-2">
            {appdata.attributes["access-link"] === "" ||
            appdata.attributes["access-link"] === null ? (
              <b className="d-flex">
                Link to application access:
                <b className="text-muted ms-2 mb-0">N/A</b>
              </b>
            ) : (
              <b>Link to application access:</b>
            )}
            <span className="d-flex ms-2">
              {appdata.attributes["access-link"] !== "" ||
              appdata.attributes["access-link"] !== null ? (
                <>
                  {!validator.isURL(appdata.attributes["access-link"]) ? (
                    <p
                      className="preview-link mb-1"
                      style={{ color: "#8a00e5" }}
                    >
                      {appdata.attributes["access-link"]}
                    </p>
                  ) : (
                    <>
                      <span
                        onClick={() => {
                          // setId(data.id);
                          navigator.clipboard.writeText(
                            appdata.attributes["access-link"]
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <ImageSvgs name="expertCopy" />
                      </span>

                      <p className="preview-link mb-1 ms-2">
                        <a
                          href={appdata.attributes["access-link"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {appdata.attributes["access-link"]}
                        </a>
                      </p>
                    </>
                  )}
                </>
              ) : null}
            </span>
          </span>
          <div className="d-flex flex-row">
            {appInfo.length > 0 ? (
              <b style={{ marginRight: "40px" }}>Further Information:</b>
            ) : (
              <b style={{ marginRight: "40px" }}>
                Further Information: <b className="text-muted">N/A</b>
              </b>
            )}
            <div className="d-flex flex-column">
              {appInfo.length > 0 ? (
                <>
                  {appInfo.map((elem) => {
                    return (
                      <span className="d-flex">
                        <span
                          className="ms-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setId(data.id);
                            navigator.clipboard.writeText(
                              elem.attributes["info-URL"]
                            );
                          }}
                        >
                          <ImageSvgs name="expertCopy" />
                        </span>
                        <p className="preview-link mb-1 ms-2">
                          <a
                            href={elem.attributes["info-URL"]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {elem.attributes["info-title"]}
                          </a>
                        </p>
                      </span>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column mt-3">
        <h6>
          <b>Connections in FIN GS :-</b>
        </h6>
        <div className="p-3 page-connections-section">
          <div className="mt-2">
            {commCons.length > 0 ? (
              <h6 className="fw-bold ms-4 d-flex">Communities:</h6>
            ) : (
              <h6 className="fw-bold ms-4 d-flex">
                Communities:<p className="mb-0 ms-2 text-muted">N/A</p>
              </h6>
            )}
            <div className="d-flex flex-row justify-content-center">
              {commCons?.length > 0 ? (
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                  wrap={false}
                  indicators={false}
                >
                  {commCons?.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100 align-items-center"
                        gap={2}
                      >
                        {record.map((elem, j) => {
                          if (j < record.length - 1) {
                            return (
                              <>
                                <ConnectionsComponent data={elem} key={j} />
                                <div className="divider"></div>
                              </>
                            );
                          } else {
                            return <ConnectionsComponent data={elem} key={j} />;
                          }
                        })}
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            {trainCons.length > 0 ? (
              <h6 className="fw-bold ms-4 d-flex">Trainings:</h6>
            ) : (
              <h6 className="fw-bold ms-4 d-flex">
                Trainings:<p className="mb-0 ms-2 text-muted">N/A</p>
              </h6>
            )}
            <div className="d-flex flex-row justify-content-center">
              {trainCons?.length > 0 ? (
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                  wrap={false}
                  indicators={false}
                >
                  {trainCons?.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100  align-items-center"
                        gap={2}
                      >
                        {record.map((elem, j) => {
                          if (j < record.length - 1) {
                            return (
                              <>
                                <ConnectionsComponent data={elem} key={j} />
                                <div className="divider"></div>
                              </>
                            );
                          } else {
                            return <ConnectionsComponent data={elem} key={j} />;
                          }
                        })}
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            {proCons.length > 0 ? (
              <h6 className="fw-bold ms-4 d-flex">Processes:</h6>
            ) : (
              <h6 className="fw-bold ms-4 d-flex">
                Processes:<p className="mb-0 ms-2 text-muted">N/A</p>
              </h6>
            )}
            <div className="d-flex flex-row justify-content-center">
              {proCons?.length > 0 ? (
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                  wrap={false}
                  indicators={false}
                >
                  {proCons?.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100  align-items-center"
                        gap={2}
                      >
                        {record.map((elem, j) => {
                          if (j < record.length - 1) {
                            return (
                              <>
                                <ConnectionsComponent data={elem} key={j} />
                                <div className="divider"></div>
                              </>
                            );
                          } else {
                            return <ConnectionsComponent data={elem} key={j} />;
                          }
                        })}
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            {toolCons.length > 0 ? (
              <h6 className="fw-bold ms-4 d-flex">Tools:</h6>
            ) : (
              <h6 className="fw-bold ms-4 d-flex">
                Tools:<p className="mb-0 ms-2 text-muted">N/A</p>
              </h6>
            )}
            <div className="d-flex flex-row justify-content-center">
              {toolCons?.length > 0 ? (
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                  wrap={false}
                  indicators={false}
                >
                  {toolCons?.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100  align-items-center"
                        gap={2}
                      >
                        {record.map((elem, j) => {
                          if (j < record.length - 1) {
                            return (
                              <>
                                <ConnectionsComponent data={elem} key={j} />
                                <div className="divider"></div>
                              </>
                            );
                          } else {
                            return <ConnectionsComponent data={elem} key={j} />;
                          }
                        })}
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoComp;
