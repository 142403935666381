import React from "react";
import { useNavigate } from "react-router-dom";
import ImageSvgs from "../components/ImageSvgs";
import EmployeeRelations from "../components/CommonComponents/EmployeeRelations";

const EmpRelationPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <button
          className="fw-bold bg-transparent border-0 mb-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ImageSvgs name="goBack" /> Back
        </button>
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">User Connection in FIN GS</h2>
          </div>
        </div>

        <div className="page-content h-100">
          <EmployeeRelations />
        </div>
      </div>
    </div>
  );
};

export default EmpRelationPage;
