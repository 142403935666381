import { FiFolder } from "react-icons/fi";
import { HiLink } from "react-icons/hi";
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDown,
} from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine, RiAdminLine } from "react-icons/ri";
import { IoSettingsSharp } from "react-icons/io5";
import { BiImageAdd, BiSearchAlt } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { MdDragHandle } from "react-icons/md";

export const nextArrow = (
  <span>
    <IoIosArrowForward />
  </span>
);

export const prevArrow = (
  <span>
    <IoIosArrowBack />
  </span>
);

export const folderIcon = <FiFolder />;

export const linkIcon = <HiLink />;

export const cardArrow = (
  <span>
    <IoIosArrowForward />
  </span>
);

export const threeDots = (
  <span>
    <BsThreeDotsVertical />{" "}
  </span>
);

export const editIcon = (
  <span>
    <TbEdit />
  </span>
);

export const deleteIcon = (
  <span>
    <RiDeleteBinLine />
  </span>
);

export const downArrow = (
  <span>
    <IoIosArrowDown size={"1.5em"} />
  </span>
);

export const settingsIcon = (
  <span>
    <IoSettingsSharp color="#641E8C" />
  </span>
);

export const imageIcon = (
  <span>
    <BiImageAdd />
  </span>
);

export const profileIcon = (
  <span>
    <CgProfile />
  </span>
);

export const searchIcon = (
  <span>
    <BiSearchAlt />
  </span>
);

export const tickIcon = (
  <span>
    <TiTick />
  </span>
);

export const crossIcon = (
  <span>
    <ImCross />
  </span>
);

export const dragIcon = (
  <span>
    <MdDragHandle />
  </span>
);

export const adminIcon = (
  <span>
    <RiAdminLine />
  </span>
);
