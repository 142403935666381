import React from "react";
import { useEffect, useState, useRef } from "react";
import { chunk } from "lodash";
import ImageSvgs from "./ImageSvgs";
import noData from "../assets/images/noData.svg";
import { linkIcon, folderIcon } from "../utils/iconUtils";
import { getBaseUrl } from "../config/environment";
import MyVerticallyCenteredModal from "./CommonComponents/ReadMore";
import CarouselConnections from "../components/CommonComponents/CarouselConnections";
import CarouselExpertComponent from "./CommonComponents/CarouselExpertComponent";

const TrainingsUserScreen = ({ source }) => {
  const trainingData = source.data;
  const included = source.included;
  const [flag, setFlag] = useState();
  const [list, setList] = useState(trainingData);
  const [activeTraining, setActiveTraining] = useState();
  const [trainerData, setTrainerData] = useState([]);
  const [connections, setConnections] = useState();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const widthRef = useRef(null);

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      let id = url.searchParams.get("id");
      if (id === "0") {
        setTraining(trainingData[0].id);
      } else {
        setTraining(id);
      }
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    })();
    // eslint-disable-next-line
  }, [activeTraining]);

  const setTraining = async (el) => {
    setActiveTraining();
    setTrainerData([]);
    let tempList = [];
    let data = trainingData?.filter(
      (elem) => elem.id.toString() === el.toString()
    )[0];

    data.relationships.trainers.data.map((elem) => {
      let newElem = included?.filter(
        (trainer) =>
          trainer.type === "trainers" &&
          trainer.id.toString() === elem.id.toString()
      )[0].attributes.employee.data;
      tempList.push(newElem);
      return null;
    });

    setTrainerData(
      chunk(
        tempList.sort((a, b) =>
          a?.attributes["preferred-name"].localeCompare(
            b?.attributes["preferred-name"]
          )
        ),
        4
      )
    );

    setActiveTraining(data);
    setFlag(typeof data.attributes["training-link"].link);
    addQueryParam("id", data.id);

    let appCons =
      data?.relationships.apps.data.length > 0
        ? data?.relationships.apps.data.map((elem, index) => {
            let appData = included?.filter(
              (app) =>
                app.type === "application" &&
                app.id.toString() === elem.id.toString()
            )[0];
            return appData.attributes.apps.data;
          })
        : [];

    let toolCons =
      data?.relationships.tools.data.length > 0
        ? data?.relationships.tools.data.map((elem, index) => {
            let toolData = included?.filter(
              (tool) =>
                tool.type === "tools" &&
                tool.id.toString() === elem.id.toString()
            )[0];
            return toolData.attributes["tools-master"].data;
          })
        : [];

    let proCons =
      data?.relationships.process.data.length > 0
        ? data?.relationships.process.data.map((elem, index) => {
            let proData = included?.filter(
              (process) =>
                process.type === "process" &&
                process.id.toString() === elem.id.toString()
            )[0];
            return proData.attributes["process-master"].data;
          })
        : [];

    setConnections({
      apps: chunk(
        appCons.sort((a, b) =>
          a.attributes["app-title"].localeCompare(b.attributes["app-title"])
        ),
        5
      ),
      tools: chunk(
        toolCons.sort((a, b) =>
          a.attributes["tool-name"].localeCompare(b.attributes["tool-name"])
        ),
        5
      ),
      process: chunk(
        proCons.sort((a, b) =>
          a.attributes["title"].localeCompare(b.attributes["title"])
        ),
        5
      ),
    });

    return null;
  };

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };
  const handleSearchFilter = (name) => {
    const newList = trainingData?.filter((item) =>
      item.attributes["training-name"]
        .toLowerCase()
        .includes(name.toLowerCase())
    );
    setList(newList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="d-flex flex-row">
      <div className="leftSide">
        <h5 className="appHead">Trainings list:-</h5>
        <form className="search-app" onSubmit={(e) => handleSubmit(e)}>
          <div className="app-primary-input-field" style={{ width: "16rem" }}>
            <input
              type="search"
              placeholder="Search Training..."
              onChange={(e) => handleSearchFilter(e.target.value)}
            />
          </div>
        </form>
        <div className="appList" style={{ marginTop: "10px" }}>
          {list
            ?.sort((a, b) =>
              a.attributes["training-name"].localeCompare(
                b.attributes["training-name"]
              )
            )
            .map((el) => {
              return (
                <>
                  <div
                    className={`${
                      activeTraining?.id.toString() === el.id.toString()
                        ? "border-bottom appTag act"
                        : "border-bottom appTag"
                    }`}
                    key={el.id}
                    onClick={async () => {
                      setTraining(el.id);
                      // eslint-disable-next-line
                      let data = await setActiveTraining(el);
                    }}
                  >
                    <span className="capitalize" style={{ fontSize: "15px" }}>
                      {typeof el.attributes["training-link"].link ===
                      "object" ? (
                        <span className="me-2">{folderIcon}</span>
                      ) : (
                        <span className="me-2">{linkIcon}</span>
                      )}
                      {el.attributes["training-name"]}
                    </span>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      {activeTraining ? (
        <div className="rightSide d-flex flex-column">
          <div className="titDiv">
            <div className="innerDiv border-bottom pb-2">
              <div className="details d-flex flex-column ">
                <div className="d-flex">
                  {activeTraining?.attributes["training-image"] ? (
                    <div>
                      <img
                        className="training-image"
                        src={`${getBaseUrl()}${
                          activeTraining?.attributes["training-image"]
                        }`}
                        alt="app"
                      />
                    </div>
                  ) : (
                    <span>
                      <ImageSvgs
                        className="app-image"
                        name="training_default"
                      />
                    </span>
                  )}
                  <div className="d-flex flex-column">
                    <p className="titLabel">
                      {flag === "object" ? (
                        <span className="titName capitalize fw-bold">
                          {folderIcon}{" "}
                          {activeTraining?.attributes["training-name"]}
                        </span>
                      ) : (
                        <span className="titName capitalize">
                          {linkIcon}
                          {activeTraining?.attributes["training-name"]}
                        </span>
                      )}
                    </p>
                    <div>
                      <p className="limited-space" ref={widthRef}>
                        <b>Description: </b>

                        <div style={{ whiteSpace: "pre-line" }}>
                          {activeTraining?.attributes[
                            "training-description"
                          ]?.replace(/\\n/g, "\n")}
                        </div>
                      </p>
                      <p
                        className="ms-3 mb-0 read-more-btn"
                        style={{ cursor: "pointer", color: "#8a00e5" }}
                        onClick={() => {
                          setShowInfoModal(true);
                        }}
                      >
                        {isOverflowing ? "Read more" : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="experts-section mt-3 ms-2 pb-2 border-bottom">
              <div className="border-bottom pb-3">
                <h6 className="ms-2 fw-bold">
                  Trainers for {activeTraining?.attributes["training-name"]} are
                  :-
                </h6>

                <div className="d-flex flex-row justify-content-between">
                  {trainerData?.length > 0 ? (
                    <CarouselExpertComponent data={trainerData} />
                  ) : (
                    <div className="ms-2 mb-3 d-flex flex-row border justify-content-center w-100 expert-no-data">
                      <img src={noData} alt="No data available" />
                      <h5 className="my-auto fw-bold">No data available</h5>
                    </div>
                  )}
                </div>
              </div>
              {flag === "object" ? (
                <div className="mt-3">
                  <h6 className="fw-bold">
                    Links related to{" "}
                    {activeTraining?.attributes["training-name"]} are :-
                  </h6>
                  <div className="d-flex flex-column">
                    {chunk(
                      activeTraining?.attributes["training-link"].link,
                      3
                    ).map((record) => {
                      return (
                        <div className="d-flex mb-2">
                          {record.map((elem) => {
                            return (
                              <div
                                style={{ width: "33%" }}
                                className="d-flex me-2"
                              >
                                <span
                                  className="me-1 h5"
                                  style={{ color: "#8A00E5" }}
                                >
                                  {linkIcon}
                                </span>
                                <a
                                  href={elem.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="trainings-link fw-bold my-auto"
                                >
                                  {elem.name}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="mt-3">
                  <span>
                    <h6 className="fw-bold">
                      Links related to{" "}
                      {activeTraining?.attributes["training-name"]} are :-
                    </h6>
                  </span>
                  <span style={{ width: "75%" }} className="d-flex">
                    {/* <ImageSvgs name="app_icon" />{" "} */}
                    <span className="me-1 h5" style={{ color: "#8A00E5" }}>
                      {linkIcon}
                    </span>
                    <a
                      href={activeTraining?.attributes["training-link"].link}
                      target="_blank"
                      rel="noreferrer"
                      className="trainings-link fw-bold my-auto"
                    >
                      {activeTraining?.attributes["training-link"].link}
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="section">
            <div className="innerSec">
              <div className="d-flex flex-column mt-3">
                <h6>
                  <b>Connections in FIN GS :-</b>
                </h6>
                <div className="p-3 page-connections-section">
                  <div className="mt-2">
                    {connections?.apps.length > 0 ? (
                      <h6 className="fw-bold ms-4 d-flex">Applications</h6>
                    ) : (
                      <h6 className="fw-bold ms-4 d-flex">
                        Applications:<p className="mb-0 ms-2 text-muted">N/A</p>
                      </h6>
                    )}

                    <div className="d-flex flex-row justify-content-center">
                      {connections?.apps.length > 0 ? (
                        <CarouselConnections data={connections?.apps} />
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4">
                    {connections?.tools.length > 0 ? (
                      <h6 className="fw-bold ms-4 d-flex">Tools</h6>
                    ) : (
                      <h6 className="fw-bold ms-4 d-flex">
                        Tools:<p className="mb-0 ms-2 text-muted">N/A</p>
                      </h6>
                    )}
                    <div className="d-flex flex-row justify-content-center">
                      {connections?.tools.length > 0 ? (
                        <CarouselConnections data={connections?.tools} />
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4">
                    {connections?.process.length > 0 ? (
                      <h6 className="fw-bold ms-4 d-flex">Processes</h6>
                    ) : (
                      <h6 className="fw-bold ms-4 d-flex">
                        Processes:<p className="mb-0 ms-2 text-muted">N/A</p>
                      </h6>
                    )}
                    <div className="d-flex flex-row justify-content-center">
                      {connections?.process.length > 0 ? (
                        <CarouselConnections data={connections?.process} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showInfoModal ? (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          data={activeTraining && activeTraining}
        />
      ) : null}
    </div>
  );
};

export default TrainingsUserScreen;
