import React from "react";
import CommunityUserPage from "./CommunityUserPage";
import Offcanvas from "react-bootstrap/Offcanvas";
export default function CommunityDetailsPage({
  comId,
  hide,
  show,
  handleClose,
  setLevel,
}) {
  const hidePage = () => {
    setLevel("");
    hide();
  };
  return (
    <div>
      <Offcanvas
        show={show}
        onHide={hidePage}
        placement="end"
        sm={12}
        lg={12}
        style={{ width: "80%" }}
      >
        <Offcanvas.Body>
          <CommunityUserPage comId={comId} hide={hide} setLevel={setLevel} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
