import React from "react";
import { useState, useRef, useEffect } from "react";
import { chunk } from "lodash";
import ImageSvgs from "../ImageSvgs";
import noData from "../../assets/images/noData.svg";
import { getBaseUrl } from "../../config/environment";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import CarouselExpertComponent from "../CommonComponents/CarouselExpertComponent";
import "./style.scss";

export default function Details({ data, included }) {
  const [copy, setCopy] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const widthRef = useRef(null);

  let tempData =
    data?.relationships.owners.data.length > 0
      ? data?.relationships.owners.data.map((elem) => {
          return included?.filter(
            // eslint-disable-next-line
            (owners) =>
              owners.type === "owners" &&
              owners.id.toString() === elem.id.toString()
          )[0].attributes.employee.data;
        })
      : [];

  let ownerData = chunk(
    tempData
      .sort((a, b) =>
        a.attributes["preferred-name"].localeCompare(
          b.attributes["preferred-name"]
        )
      )
      .sort(
        (a, b) =>
          Number(
            included?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners.attributes["app-id"].toString() === data.id.toString() &&
                owners.attributes["employee-id"].toString() === b.id.toString()
            )[0].attributes["is-owners"]
          ) -
          Number(
            included?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners.attributes["app-id"].toString() === data.id.toString() &&
                owners.attributes["employee-id"].toString() === a.id.toString()
            )[0].attributes["is-owners"]
          )
      ),
    4
  );

  useEffect(() => {
    (async () => {
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="details d-flex flex-column">
      <div className="d-flex">
        {data?.attributes["app-image"] ? (
          <>
            <img
              className="app-image"
              src={`${getBaseUrl()}${data?.attributes["app-image"]}`}
              alt="app"
            />
          </>
        ) : (
          <span>
            <ImageSvgs name="app_icon" />
          </span>
        )}
        <div className="d-flex flex-column">
          <p className="titLabel">
            <a
              href={data?.attributes["app-link"]}
              target="_blank"
              rel="noreferrer"
              className="titName capitalize"
            >
              {data?.attributes["app-title"]}
            </a>
            <button
              className="ms-2 bg-transparent border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={!copy ? "Copy application link" : "Copied"}
              disabled={copy}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setCopy(true);
                setTimeout(() => {
                  setCopy(false);
                }, 1500);
              }}
            >
              {!copy ? (
                <>
                  <ImageSvgs name="expertCopy" />
                </>
              ) : (
                <>
                  <ImageSvgs name="tickMark" />
                </>
              )}
            </button>
          </p>
          <div>
            <p className="limited-space" ref={widthRef}>
              <b>Description: </b>

              <div style={{ whiteSpace: "pre-line" }}>
                {data?.attributes["app-description"]?.replace(/\\n/g, "\n")}
              </div>
            </p>
            <p
              className="ms-3 mb-0 read-more-btn"
              style={{ cursor: "pointer", color: "#8a00e5" }}
              onClick={() => {
                setShowInfoModal(true);
              }}
            >
              {isOverflowing ? "Read more" : null}
            </p>
          </div>
        </div>
      </div>
      <div className="experts-section mt-3 ms-2">
        <h6 className="ms-2 fw-bold">
          Owners & Deputies for {data?.attributes["app-title"]} are :-
        </h6>

        <div className="d-flex flex-row justify-content-between w-100">
          {ownerData?.length > 0 ? (
            <CarouselExpertComponent data={ownerData} includedData={included} />
          ) : (
            <div className="ms-2 mb-3 d-flex flex-row border justify-content-center w-100 expert-no-data">
              <img src={noData} alt="No data available" />
              <h5 className="my-auto fw-bold">No data available</h5>
            </div>
          )}
        </div>
      </div>
      {showInfoModal ? (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          data={data}
        />
      ) : null}
    </div>
  );
}
