import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import "../../assets/scss/common.scss";
import { TopicsDeleteModal } from "../../utils/topicsUtils";
import Avatar from "react-avatar";
import { UserModal } from "../../utils/topicsUtils";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomTooltip from "../CommonComponents/CustomTooltip";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function TopicTable(props) {
  const editData = props.setEditTopicData;
  const pageLevel = props.setPageLevel;
  const queryParam = props.addQueryParam;
  const [topicDeleteData, setTopicDeleteData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const updateData = props.setFlag;
  const initialData = props.topicData && props.topicData;
  const [topicData, setTopicData] = useState(
    props.topicData && props.topicData
  );
  const updateTopicData = props.setViewTopicData;
  const [owners, setOwners] = useState();
  const [orgs, setOrgs] = useState();
  const [usersData, setUsersData] = useState();
  const [usersModal, setShowUserModal] = useState(false);
  const [res, setRes] = useState(false);
  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const param = `${getHostUrl()}topics?include=orgs,owners&use_pagination=false`;
    const topicHeads = {};
    const topicOrgs = {};

    (async () => {
      const res = await getData(param);

      setRes(true);
      res?.data.included &&
        res?.data.included?.forEach((emp) => {
          if (emp.type === "owners") {
            let topic_id = emp.attributes["topics-owners-id"];
            if (topicHeads[topic_id]) {
              topicHeads[topic_id].push(emp);
            } else {
              topicHeads[topic_id] = [emp];
            }
          }
        });
      setTopicData(res?.data?.data);
      // eslint-disable-next-line
      res.data.included &&
        res.data.included?.forEach((org) => {
          if (org.type === "organization") {
            let topic_id = org.attributes["topics-owners-id"];
            if (topicOrgs[topic_id]) {
              topicOrgs[topic_id].push(org);
            } else {
              topicOrgs[topic_id] = [org];
            }
          }
        });
    })();
    setOwners(topicHeads);
    setOrgs(topicOrgs);
    setRes(false);
    // eslint-disable-next-line
  }, [deleteFlag]);

  useEffect(() => {
    if (deleteFlag) {
      setNameQuery("");
      setDescQuery("");
      setOrgQuery([]);
      setTopicData(initialData);
      setDeleteFlag(false);
    }
  }, [deleteFlag, initialData]);

  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(initialData);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-name"].toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });
      setTopicData(tempList);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter(
          (item) =>
            item.attributes["topics-name"].toLowerCase().includes(query) &&
            item.attributes["topics-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-description"]
          .toLowerCase()
          .includes(descQuery.toLowerCase())
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });
      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-name"].toLowerCase().includes(query)
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });

      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      query.length.toString() > "0" &&
      descQuery?.length.toString() > "0" &&
      orgQuery.length.toString() > "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialData?.filter((item) =>
          item.attributes["topics-name"].toLowerCase().includes(query)
        ),
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setTopicData(finalList);
    }
  };

  const descSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });
      setTopicData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      orgQuery.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter(
          (item) =>
            item.attributes["topics-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["topics-description"]
              .toLowerCase()
              .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-description"]
          .toLowerCase()
          .includes(query.toLowerCase())
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });

      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });

      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      query?.length.toString() > "0" &&
      orgQuery.length.toString() > "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === orgQuery[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialData?.filter((item) =>
          item.attributes["topics-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setTopicData(finalList);
    }
  };

  const orgSearch = (value) => {
    if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setTopicData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === value[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });
      setTopicData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setTopicData(
        initialData?.filter(
          (item) =>
            item.attributes["topics-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["topics-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-description"]
          .toLowerCase()
          .includes(descQuery.toLowerCase())
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === value[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });

      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = initialData?.filter((item) =>
        item.attributes["topics-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );
      let tempList2 = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === value[0].id
              )
              .length.toString() > "0"
          ) {
            tempList2.push(elem);
          }
        }
        return false;
      });

      setTopicData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["topics-name"] ===
                elem2.attributes["topics-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      descQuery?.length.toString() > "0" &&
      value.length.toString() > "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (orgs[elem.id]) {
          if (
            orgs[elem.id]
              ?.filter(
                (org) =>
                  org.type === "organization" &&
                  org.attributes["orgs-id"] === value[0].id
              )
              .length.toString() > "0"
          ) {
            tempList.push(elem);
          }
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialData?.filter((item) =>
          item.attributes["topics-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        initialData?.filter((item) =>
          item.attributes["topics-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setTopicData(finalList);
    }
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgOptions(res.data.data);
  };

  const handleNameChange = (e) => {
    const query = e.target.value;
    setNameQuery(query);
    nameSearch(query);
  };

  const handleOrgChange = (value) => {
    setOrgQuery(value);
    orgSearch(value);
  };

  const handleDescChange = (e) => {
    setDescQuery(e.target.value);
    descSearch(e.target.value.toLowerCase());
  };

  return (
    <div>
      <div className="table-responsive">
        <Table className="main-table" striped>
          <thead className="user-table-head">
            <tr>
              <th>Topic Name </th>
              <th>Topic Description</th>
              <th>Topic Owners</th>
              <th>Department</th>
              <th>Action</th>
            </tr>
            <tr style={{ height: "3.5rem" }}>
              <th>
                {" "}
                <div className="app-primary-input-field mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by name..."
                    onChange={handleNameChange}
                    value={nameQuery}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by description..."
                    value={descQuery}
                    onChange={handleDescChange}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
              <th>
                <div
                  className="app-primary-dropdown mb-2"
                  style={{ position: "absolute", width: "19%" }}>
                  <Typeahead
                    multiple={false}
                    labelKey="name"
                    filterBy={["name"]}
                    // highlightOnlyResult={true}
                    clearButton={true}
                    placeholder="Search by department..."
                    id="typeAhead"
                    onChange={handleOrgChange}
                    onInputChange={(text) => {
                      handleOrgSearch(text);
                    }}
                    options={
                      orgOptions &&
                      orgOptions.map((item) => ({
                        type: "organization",
                        name: item.attributes["org-title"],
                        id: item.id,
                      }))
                    }
                    selected={deleteFlag ? [] : orgQuery}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
            </tr>
          </thead>
          {res ? (
            <tbody>
              {topicData && topicData.length > 0 ? (
                <>
                  {topicData &&
                    topicData
                      ?.sort((a, b) =>
                        a.attributes["topics-name"].localeCompare(
                          b.attributes["topics-name"]
                        )
                      )
                      .map((topic, key) => (
                        <tr key={topic.id}>
                          <td
                            onClick={() => {
                              updateTopicData(topic);
                              pageLevel(`topic_id${topic.id}`);
                            }}>
                            <span className="app-table-name">
                              {topic.attributes["topics-name"]}{" "}
                            </span>
                          </td>

                          <td>{topic.attributes["topics-description"]}</td>
                          <td>
                            {" "}
                            <>
                              <span>
                                {owners && owners[topic.id] ? (
                                  <>
                                    {owners[topic.id]?.length <= 3 ? (
                                      <>
                                        {owners[topic.id]?.map(
                                          (users, length) => {
                                            return (
                                              <span
                                                className="px-1 cursor-pointer"
                                                onClick={() => {
                                                  navigate(
                                                    `/employee_relation?id=${users?.attributes["employee-id"]}`
                                                  );
                                                }}>
                                                {users?.attributes?.employee
                                                  ?.data?.attributes[
                                                  "profile-picture"
                                                ] ? (
                                                  <CustomTooltip
                                                    name={
                                                      users?.attributes
                                                        ?.employee?.data
                                                        ?.attributes[
                                                        "preferred-name"
                                                      ]
                                                    }
                                                    orgCode={
                                                      users?.attributes
                                                        ?.employee?.data
                                                        ?.attributes[
                                                        "employee-org-master"
                                                      ].data.attributes[
                                                        "org-title"
                                                      ]
                                                    }>
                                                    <img
                                                      className="my-auto"
                                                      src={`${getBaseUrl()}${
                                                        users?.attributes
                                                          ?.employee?.data
                                                          ?.attributes[
                                                          "profile-picture"
                                                        ]
                                                      }`}
                                                      style={{
                                                        height: "2rem",
                                                        width: "2rem",
                                                        border:
                                                          "1.5px solid black",
                                                        borderRadius: "7rem",
                                                        objectFit: "contain",
                                                      }}
                                                      alt="User Img"
                                                    />
                                                  </CustomTooltip>
                                                ) : (
                                                  <CustomTooltip
                                                    name={
                                                      users?.attributes
                                                        ?.employee?.data
                                                        ?.attributes[
                                                        "preferred-name"
                                                      ]
                                                    }
                                                    orgCode={
                                                      users?.attributes
                                                        ?.employee?.data
                                                        ?.attributes[
                                                        "employee-org-master"
                                                      ].data.attributes[
                                                        "org-title"
                                                      ]
                                                    }>
                                                    <Avatar
                                                      name={
                                                        users?.attributes
                                                          ?.employee?.data
                                                          ?.attributes[
                                                          "preferred-name"
                                                        ]
                                                      }
                                                      size="32"
                                                      round={true}
                                                    />
                                                  </CustomTooltip>
                                                )}
                                              </span>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {owners[topic.id]?.map(
                                          // eslint-disable-next-line
                                          (expert, index) => {
                                            if (index <= 2) {
                                              return (
                                                <span
                                                  className="px-1 cursor-pointer"
                                                  onClick={() => {
                                                    navigate(
                                                      `/employee_relation?id=${expert?.attributes["employee-id"]}`
                                                    );
                                                  }}>
                                                  {expert?.attributes?.employee
                                                    ?.data?.attributes[
                                                    "profile-picture"
                                                  ] ? (
                                                    <img
                                                      className="my-auto"
                                                      src={`${getBaseUrl()}${
                                                        expert?.attributes
                                                          ?.employee?.data
                                                          ?.attributes[
                                                          "profile-picture"
                                                        ]
                                                      }`}
                                                      style={{
                                                        height: "2rem",
                                                        width: "2rem",
                                                        border:
                                                          "1.5px solid black",
                                                        borderRadius: "7rem",
                                                        objectFit: "contain",
                                                      }}
                                                      alt="User Img"
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      name={
                                                        expert?.attributes
                                                          ?.employee?.data
                                                          ?.attributes[
                                                          "preferred-name"
                                                        ]
                                                      }
                                                      size="32"
                                                      round={true}
                                                    />
                                                  )}
                                                </span>
                                              );
                                            }
                                          }
                                        )}
                                        <button
                                          className="more-user-btn"
                                          onClick={() => {
                                            setUsersData(owners[topic.id]);
                                            setShowUserModal(true);
                                          }}>
                                          + {owners[topic.id]?.length - 3} more
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </>
                          </td>

                          <td>
                            {orgs && orgs[topic.id] ? (
                              <span>
                                {
                                  // eslint-disable-next-line
                                  orgs[topic.id]?.map((orgName, index) => {
                                    if (orgName.type === "organization") {
                                      return (
                                        <span key={`demo_snap_${index}`}>
                                          {(index ? ", " : "") +
                                            orgName?.attributes["orgs-master"]
                                              .data?.attributes["org-title"]}
                                        </span>
                                      );
                                    }
                                  })
                                }
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            <span className="d-flex flex-row">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  pageLevel("editTopic");
                                  queryParam("page", "editTopic");
                                  editData(topic);
                                }}>
                                <ImageSvgs name="edit" />
                              </span>
                              <span
                                className="px-4 cursor-pointer"
                                onClick={() => {
                                  setTopicDeleteData(topic);
                                  setShowModal(true);
                                }}>
                                <ImageSvgs name="delete" />
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}
                </>
              ) : (
                <tr className="w-100">
                  <td
                    className="w-100 mt-2"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#e9ecef ",
                    }}>
                    <p className="fw-bold mt-1 mb-0">
                      No matching records found.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <div style={{ maxWidth: "100%", height: "25rem" }}>
              <Loader />
            </div>
          )}
        </Table>
      </div>
      {usersModal === true ? (
        <UserModal
          title={"Topic Owners"}
          heading={"List of Topic Owners"}
          listdata={usersData}
          show={usersModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : (
        " "
      )}
      {showModal === true ? (
        <TopicsDeleteModal
          topicdata={topicDeleteData}
          show={showModal}
          onHide={() => setShowModal(false)}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          updatedata={updateData}
          setTableLoad={props.setTableLoad}
        />
      ) : (
        " "
      )}
    </div>
  );
}
