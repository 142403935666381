import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsOrganization from "highcharts/modules/organization";
import HighchartsReact from "highcharts-react-official";
import ReactResizeDetector from "react-resize-detector";
import "./style.scss";

if (typeof Highcharts === "object") {
  HighchartsSankey(Highcharts);
  HighchartsOrganization(Highcharts);
}

const OrgChart = (props) => {
  const chartRef = useRef();

  const setEventData = (event) => {
    props.setTabData(event.point.id, event.point.name, event.point.level);
  };

  const onResizeChart = (width) => {
    setChartOptions((prevOptions) => ({
      chart: {
        width: Math.floor(width),
      },
    }));
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      inverted: true,
      width: 1200,
      height: props.chartHeight || 360,
    },

    title: {
      text: "",
      align: "left",
    },

    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var nodeName = point.toNode.name,
            nodeId = point.toNode.id,
            nodeDesc =
              nodeName === nodeId ? nodeName : nodeName + ", " + nodeId,
            parentDesc = point.fromNode.id;
          return (
            point.index + ". " + nodeDesc + ", reports to " + parentDesc + "."
          );
        },
      },
    },

    series: [
      {
        type: "organization",
        name: "Org-Map",
        keys: ["from", "to"],
        borderWidth: 0.5,
        borderColor: "#000",
        linkLineWidth: 2,
        borderRadius: 1,
        linkColor: "#000",
        data: props.dataSet,
        nodes: props.nodesSet,
        colorByPoint: false,
        color: "white",
        dataLabels: {
          color: "#2e2e2e",
          nodeFormatter: function () {
            // Call the default renderer
            var html =
              Highcharts.defaultOptions.plotOptions.organization.dataLabels.nodeFormatter.call(
                this
              );

            // Do some modification
            html = html.replace('<h4 style="', '<h4 style="font-style: bold;');
            return html;
          },
        },
        nodeWidth: 130,
        nodeHeight: 110,
        cursor: "pointer",
        allowPointSelect: true,
        states: {
          hover: {
            borderColor: "#7437a2",
          },
        },

        point: {
          events: {
            click: function (event) {
              setEventData(event);
            },
          },
        },
      },
    ],
    tooltip: {
      outside: false,
      enabled: false,
    },
    exporting: {
      allowHTML: true,
      sourceWidth: 900,
      sourceHeight: 600,
    },
  });

  useEffect(() => {
    let objIndex = props.nodesSet.findIndex((obj) => obj.id === props.selID);
    props.nodesSet.map((a) => (a.color = "white"));
    if (objIndex > -1) {
      props.nodesSet[objIndex].color = "#e0d2e8";
    }

    setChartOptions((prevOptions) => ({
      series: [
        {
          data: [...props.dataSet],
          nodes: [...props.nodesSet],
        },
      ],
      chart: {
        height: props.chartHeight,
      },
    }));
    // eslint-disable-next-line
  }, [props.dataSet, props.nodesSet, props.orgName, props.chartHeight]);

  return (
    <div id="container">
      <div className="titleDiv">
        <h3 className="title">{props.orgName}</h3>
      </div>
      <div className="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate={true}
          ref={chartRef}
        />
      </div>
      <ReactResizeDetector handleWidth handleHeight onResize={onResizeChart} />
    </div>
  );
};

export default OrgChart;
