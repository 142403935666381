import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { MultipleSelectDropdown } from "../../CommonComponents/MultipleSelect";
import { Typeahead } from "react-bootstrap-typeahead";
import ImageSvgs from "../../ImageSvgs";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProcessFilterDropdown.scss";

const ProcessFilterDropdown = ({
  groupList,
  groupQuery,
  handleGroupSearch,
  orgList,
  orgQuery,
  handleOrgSearch,
  handleCancel,
  handleApply,
  dropdownShow,
  setDropdownShow,
  setOrgQuery,
  activeBtn,
  handleChange,
  selectedValue,
}) => {
  const [selected, setSelected] = useState([]);
  const [selectedGrp, setSelectedGrp] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const options = [
    { name: "No", id: 1 },
    { name: "Yes", id: 2 },
  ];

  useEffect(() => {
    if (dropdownShow) {
      const params = new URLSearchParams(location.search);
      const selectedProcessFilter = params.get("selectedProcessFilter");
      const parsedFilter = selectedProcessFilter
        ? JSON.parse(decodeURIComponent(selectedProcessFilter))
        : {};

      const updateSelected = (query, setSelectedFn) => {
        if (parsedFilter && parsedFilter[query]?.length > 0) {
          setSelectedFn(parsedFilter[query][0] ? [parsedFilter[query][0]] : []);
        } else {
          setSelectedFn([]);
        }
      };

      updateSelected("orgQuery", setSelected);
      updateSelected("groupQuery", setSelectedGrp);
    }
  }, [dropdownShow, location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedProcessFilter = params.get("selectedProcessFilter");
    const parsedFilter = selectedProcessFilter
      ? JSON.parse(decodeURIComponent(selectedProcessFilter))
      : {};

    if (parsedFilter.orgQuery) {
      setSelected(parsedFilter.orgQuery);
    }
  }, [location.search]);

  const handleApplyFilters = () => {
    if (selected?.length === 0 && orgQuery?.length > 0) {
      setIsSelected(true);
      return;
    } else {
      setIsSelected(false);
      const filter = {
        orgQuery: selected.length > 0 ? selected : [],

        groupQuery: groupQuery?.length > 0 ? groupQuery : selectedGrp,
      };
      const params = new URLSearchParams(location.search);
      params.set(
        "selectedProcessFilter",
        encodeURIComponent(JSON.stringify(filter))
      );

      navigate({ search: params.toString() });
      handleApply(filter);
    }
  };

  return (
    <Dropdown
      show={dropdownShow}
      onToggle={(isOpen) => {
        setDropdownShow(isOpen);
      }}
      onSelect={() => {
        setDropdownShow(false);
      }}
      className="custom-dropdown-main"
    >
      <Dropdown.Toggle
        onClick={() => {
          setDropdownShow(!dropdownShow);
        }}
        id="dropdown-basic"
        className="dropdown-btn pe-0"
      >
        <ImageSvgs name="filterLogo" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 custom-dropdown">
        <div className="dropdown-title">Advanced Filters</div>
        <div className="pt-2 pb-2">
          <div className="pb-1 pt-1">Groups</div>
          <div className="app-primary-dropdown">
            <MultipleSelectDropdown
              multi={false}
              color="#8A00E5"
              placeholder="Search by Groups"
              className="dropdown-select mb-2"
              options={
                groupList?.length > 0 &&
                [...groupList]
                  .sort((a, b) => {
                    const titleA = a.attributes["title"].toLowerCase();
                    const titleB = b.attributes["title"].toLowerCase();
                    return titleA.localeCompare(titleB);
                  })
                  ?.map((grp) => ({
                    type: "process-groups",
                    name: grp.attributes["title"],
                    id: grp.id.toString(),
                  }))
              }
              onChange={(selected) => {
                setSelectedGrp(selected);
                handleGroupSearch(selected);
              }}
              values={groupQuery?.length > 0 ? groupQuery : selectedGrp}
            />
          </div>
        </div>

        <div className="pt-2 pb-2">
          <div className="pb-1 pt-1">Org Code</div>
          <div className="app-primary-dropdown">
            <Typeahead
              labelKey="name"
              filterBy={["name"]}
              placeholder="Search by OrgCode"
              id="typeAhead"
              selected={selected}
              clearButton={true}
              highlightOnlyResult={true}
              onChange={(selected) => {
                setSelected(selected);
                setIsSelected(false);
                if (selected?.length > 0) {
                  setOrgQuery(selected);
                }
              }}
              onInputChange={(text) => {
                setOrgQuery(text);
                handleOrgSearch(text);
              }}
              options={
                selected.length > 0
                  ? selected.map((item) =>
                      item
                        ? {
                            type: "organization",
                            name: item.name,
                            id: item.id,
                          }
                        : {}
                    )
                  : orgQuery?.length < 4
                  ? []
                  : orgList &&
                    orgList.map((item) => ({
                      type: "organization",
                      name: item.attributes["org-title"],
                      id: item.id,
                    }))
              }
            />
          </div>
        </div>

        {activeBtn === "mapView" && (
          <div className="pt-2 pb-2">
            <div className="pb-1 pt-1">Include Parent and Child Nodes</div>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={false}
                color="#8A00E5"
                className="dropdown-select mb-2"
                options={options}
                onChange={handleChange}
                values={selectedValue}
              />
            </div>
          </div>
        )}

        {isSelected && (
          <span className="text-danger">
            Note: Please choose a value from the Org code list.
          </span>
        )}

        <div className="d-flex justify-content-end font-weight-bold pt-4">
          <Button
            className="app-secondary-btn ms-3 my-auto"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button className="app-primary-btn ms-3" onClick={handleApplyFilters}>
            Apply
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProcessFilterDropdown;
