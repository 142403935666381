import React from "react";
import { useEffect, useState } from "react";
import SplineCanvas from "../components/LandscapeCanvas/SplineCanvas";
import { getBaseUrl } from "../config/environment";
import { getData } from "../services/apiService";

function FinGSLandscape() {
  const [landscapeData, setLandscapeData] = useState(null);
  useEffect(() => {
    const param = `${getBaseUrl()}/configurations/finLandscape.json`;
    async function getResponse() {
      // eslint-disable-next-line
      const res = await getData(param);
      setLandscapeData(res.data);
    }
    getResponse();
    // eslint-disable-next-line
  }, []);
  if (landscapeData) {
    return (
      <div style={{ position: "relative" }}>
        <SplineCanvas data={landscapeData} />
      </div>
    );
  }
}

export default FinGSLandscape;
