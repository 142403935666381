import React from "react";
import { useState, useEffect } from "react";
import StocksGraph from "./StocksGraph";
import InfoComp from "./InfoComp";
import { Carousel } from "react-bootstrap";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";

const MainStocksComp = ({ fullSize }) => {
  const [currentOrg, setCurrentOrg] = useState("2");
  const [mainData, setMainData] = useState();
  const [orgData, setOrgData] = useState();

  useEffect(() => {
    (async () => {
      const finalData = [];

      const agRes = await getData(
        `${getBaseUrl()}/files/Siemens_AG/siemens_ag.json`
      );
      finalData.push({ id: "1", data: agRes.data });

      const seRes = await getData(
        `${getBaseUrl()}/files/Siemens_Energy/siemens_energy.json`
      );
      finalData.push({ id: "2", data: seRes.data });

      const heRes = await getData(
        `${getBaseUrl()}/files/Siemens_Healthineers/siemens_healthineers.json`
      );
      finalData.push({ id: "3", data: heRes.data });

      await setMainData(finalData);
      await setOrgData(finalData?.filter((elem) => elem.id === currentOrg)[0]);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      await setOrgData(mainData?.filter((elem) => elem.id === currentOrg)[0]);
    })();
    // eslint-disable-next-line
  }, [currentOrg]);

  return (
    <div>
      {mainData && orgData ? (
        <div
          className="d-flex flex-column mt-3 mb-3"
          style={{ marginLeft: "1rem", marginRight: "1rem" }}
        >
          <div className="d-flex">
            <p className="fw-bold fs-4 mb-2" style={{ color: "#1E1E1E" }}>
              Your Stocks
            </p>
          </div>
          {fullSize ? (
            <div
              className="d-flex"
              style={{ height: "24rem", marginTop: "2rem" }}
            >
              <div
                className="w-50 border mx-1"
                style={{ backgroundColor: "white" }}
              >
                <InfoComp setCurrentOrg={setCurrentOrg} orgData={orgData} />
              </div>
              <div className="w-50 border mx-1">
                <StocksGraph orgData={orgData} />
              </div>
            </div>
          ) : (
            <div
              id="stocks-carousel"
              className="banner-carousel w-100 border"
              style={{ height: "24rem", marginTop: "2rem" }}
            >
              <Carousel controls={false} wrap={false} interval={null}>
                <Carousel.Item>
                  <InfoComp setCurrentOrg={setCurrentOrg} orgData={orgData} />
                </Carousel.Item>
                <Carousel.Item>
                  <StocksGraph orgData={orgData} />
                </Carousel.Item>
              </Carousel>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MainStocksComp;
