import React from "react";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../CommonComponents/CustomizableHomePage.scss";

export default function StockPlaceholder(props) {
  const deleteCard = props.deleteCard;
  const setWidth = props.setStockFeed;
  const activeBtn = props.stockFeed;
  const handleHalfWidth = props.handleHalfWidth;
  const handleFullWidth = props.handleFullWidth;
  const widthData = props.data;
  return (
    <div className="d-flex flex-row stocks-placeholder-div ">
      <div
        className="d-flex flex-column"
        style={{ width: "-webkit-fill-available" }}
      >
        <div className="placeholder-title pt-2">{props.title}</div>

        <Container className="pt-2 component-container">
          <Row className="component-layout">
            <Col xs={6} md={6} xl={6} className="me-1">
              <div className="first-div mb-3 "></div>
              <div className="middle-div"></div>
            </Col>

            <Row>
              <Col xs={6} md={6} xl={6} className="me-1">
                <div className="single-div "></div>
              </Col>
            </Row>
          </Row>
        </Container>
        <div className="d-flex flex-row">
          {activeBtn === true || widthData?.w === 1 ? (
            <Button
              className="common-save-btn"
              onClick={() => {
                setWidth(false);
                handleFullWidth();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              L
            </Button>
          ) : (
            <Button
              className="common-save-btn"
              onClick={() => {
                setWidth(true);
                handleHalfWidth();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              S
            </Button>
          )}
        </div>
      </div>
      <div className="delete-div d-flex">
        <Button
          className="widget-delete-btn"
          onClick={() => deleteCard()}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ImageSvgs name="widgets_delete" />
        </Button>
      </div>
    </div>
  );
}
