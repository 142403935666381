import React, { useEffect } from "react";
import ImageSvgs from "../ImageSvgs";
import "./style.scss";

export default function OrgTable({ data, refProp, pointClicked }) {
  useEffect(() => {
    if (refProp && refProp.current && pointClicked) {
      refProp.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // eslint-disable-next-line
  }, [data, refProp]);

  return (
    <div className="org-table" ref={refProp}>
      <table className="w-100 table table-striped table-bordered table-scroll">
        <thead className="org-table-head">
          <tr>
            <th style={{ width: "20%" }}>Topic</th>
            <th style={{ width: "35%" }}>Description</th>
            <th style={{ width: "30%" }}>People in Charge</th>
            <th style={{ width: "20%" }}>Relevant Applications</th>
          </tr>
        </thead>
        <tbody className="org-table-body">
          {data ? (
            data?.map((item, idx) => (
              <tr key={idx}>
                <td style={{ width: "19%" }}>{item?.topic}</td>
                <td style={{ width: "33%" }}>{item?.desc}</td>
                <td className="peopleCol" style={{ width: "30%" }}>
                  {item?.people?.map((el, i) => (
                    <React.Fragment key={i}>
                      <span
                        className="img m-2 copy-icon"
                        onClick={() => navigator.clipboard.writeText(el.email)}
                      >
                        <ImageSvgs name="copy" />
                      </span>
                      <a className="me-2" href={`mailto:${el.email}`}>
                        <ImageSvgs name="mail" />
                      </a>
                      {el.name}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td style={{ width: "18%" }}>
                  {item?.app?.map((el, i) => (
                    <React.Fragment key={i}>
                      <span>{el.name}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="w-100" style={{ textAlign: "center" }}>
                Please click on the Dept to see relevant information
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
