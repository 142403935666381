import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../components/CommunitiesAdminPages/CommunitiesAdminScreen.scss";
import "../../src/assets/scss/common.scss";
import { deleteData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { postData } from "../services/apiService";
import { patchData } from "../services/apiService";
import { getBase64 } from "./favoriteUtils";
import { now } from "lodash";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const addCommunity = async (props) => {
  const communityData = props["data"];
  const communityImg = props["data"]["attributes"]["community_image"];
  let base64 = null;
  let sendImageUrl = null;
  const hash = require("object-hash");

  if (communityImg != null) {
    const base64Img = await getBase64(communityImg);
    base64 = base64Img.split(",")?.pop();
    const imageHash = hash({ file: communityImg, timestamp: now() });
    const extension = communityImg.type?.split("/")?.pop();
    sendImageUrl = `/images/communities/${imageHash}.${extension}`;
  }

  const finalCommunityPayload = {
    data: {
      type: "community",
      attributes: {
        image: base64,
        community_desc:
          communityData["attributes"]["community_desc"] != null
            ? communityData["attributes"]["community_desc"]
            : null,
        community_type: 1,
        community_link:
          communityData["attributes"]["community_link"] != null
            ? communityData["attributes"]["community_link"]
            : null,
        community_image: sendImageUrl,
        community_name: communityData["attributes"]["community_name"]
          ? communityData["attributes"]["community_name"]
          : null,
        tags: communityData?.attributes?.tags,
      },
      relationships: {
        heads: { data: communityData?.relationships.heads },
        members: {
          data: communityData?.relationships.members,
        },
        apps: { data: communityData?.relationships.apps },
        communities_process: {
          data: communityData?.relationships.communities_process,
        },
        tools: { data: communityData?.relationships.tools },
        "channel-info": {
          data: communityData?.relationships?.communityChannel,
        },
      },
    },
  };

  const url = `${getHostUrl()}community`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };

  try {
    // eslint-disable-next-line
    const communityRes = await postData(url, finalCommunityPayload, headers);
    if (communityRes.status === 200) {
      props.updateData(true);
      props.pageLevel("communities");
      props.setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addYammerCommunity = async (props) => {
  const yammerCommunityPayLoad = {
    link: props.link.toString(),
  };

  const url = `${getHostUrl()}community/verify`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    yammertoken: `${sessionStorage.getItem("accessToken")}`,
  };
  try {
    // eslint-disable-next-line
    const communityRes = await postData(url, yammerCommunityPayLoad, headers);
    if (communityRes.status === 200) {
      props.setRes(true);
      props.setYammerCommunityData(communityRes.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addYammerCom = async (props) => {
  const yammerCommunityPayLoad = props;
  const url = `${getHostUrl()}community?yammer=true`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    yammertoken: `${sessionStorage.getItem("accessToken")}`,
  };

  try {
    // eslint-disable-next-line
    const communityRes = await postData(url, yammerCommunityPayLoad, headers);
    if (communityRes.status === 200) {
      props.updateData(true);
      props.pageLevel("communities");
      props.setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateYammerCom = async (props) => {
  const updateCommunityId = props?.id;
  const yammerCommunityPayLoad = props?.payloadData;

  const url = `${getHostUrl()}community/${updateCommunityId}?yammer=true`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };
  try {
    // eslint-disable-next-line
    const communityRes = await patchData(url, yammerCommunityPayLoad, headers);
    if (communityRes.status === 200) {
      props.updateData(true);
      props.pageLevel("communities");
      props.setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

export const DeleteCommunityModal = ({ setUpdateData, ...props }) => {
  const deleteTool = async () => {
    const deleteToolId = props.deleteModalData["id"];
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    // eslint-disable-next-line
    const res = await deleteData(
      `${getHostUrl()}community/${deleteToolId}`,
      headers
    );
    if (res.status === 204) {
      props.onHide();
      setUpdateData(true);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Community
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="pt-1 text-capitalize">
          <strong>
            Community Name :{" "}
            {props.deleteModalData["attributes"]["community-name"]}{" "}
          </strong>
          <br></br>
        </span>
        <span className="pt-1">
          <span className="d-flex flex-column pt-2">
            <span className="pb-2">
              Do you want to delete this Community for the Finance Center.
            </span>
            <span>*This does not affect the communities on Viva Engage</span>
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        {
          <Button id="common-favbutton" onClick={() => deleteTool()}>
            Delete
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export const UpdateCommunityData = async (props) => {
  const updateToolId = props["id"];
  const updateData = props["payload"]["data"];
  const updateImage = props["payload"]["data"]["attributes"]["community_image"];
  let base64 = null;
  let sendImageUrl = null;
  const hash = require("object-hash");

  if (updateImage !== null && typeof updateImage === "string") {
    // eslint-disable-next-line
    sendImageUrl = updateImage;
  } else if (updateImage !== null && typeof updateImage === "object") {
    const base64Img = await getBase64(updateImage);
    base64 = base64Img.split(",")?.pop();
    const imageHash = hash({ file: updateImage, timestamp: now() });
    const extension = updateImage.type?.split("/")?.pop();
    sendImageUrl = `/images/communities/${imageHash}.${extension}`;
  } else {
    base64 = null;
    sendImageUrl = null;
  }

  const finalCommunityUpdatePayload = {
    data: {
      type: "community",
      attributes: {
        image: base64,
        community_desc:
          updateData["attributes"]["community_desc"] &&
          updateData["attributes"]["community_desc"],
        community_type: 1,
        community_link:
          updateData["attributes"]["community_link"] != null
            ? updateData["attributes"]["community_link"]
            : null,
        community_image: updateImage ? sendImageUrl : null,
        community_name: updateData["attributes"]["community_name"]
          ? updateData["attributes"]["community_name"]
          : null,
        tags: updateData?.attributes?.tags,
      },
      relationships: {
        heads: { data: updateData?.relationships.heads },
        members: { data: updateData?.relationships.members },
        apps: { data: updateData?.relationships.apps },
        communities_process: {
          data: updateData?.relationships.communities_process,
        },
        tools: { data: updateData?.relationships.tools },
        "channel-info": {
          data: updateData?.relationships?.communityChannel,
        },
      },
    },
  };

  const url = `${getHostUrl()}community/${updateToolId}`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };
  try {
    // eslint-disable-next-line
    const communityRes = await patchData(
      url,
      finalCommunityUpdatePayload,
      headers
    );
    if (communityRes.status === 200) {
      props.updateData(true);
      props.pageLevel("communities");
      props.setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};
