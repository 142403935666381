import React from "react";
import { useState, useEffect } from "react";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import TrainingsTable from "../../components/TrainingAdminComponents/TrainingsTable";
import { AddTraining, EditTraining } from "../../utils/trainingUtils";
import ImageSvgs from "../../components/ImageSvgs";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader/Loader";
import { Button } from "react-bootstrap";

const TrainingsAdmin = () => {
  const url = new URL(window.location.href);
  const navigate = useNavigate();
  const [included, setIncluded] = useState();
  const [data, setData] = useState();
  const [tableData, setTableData] = useState();
  const [level, setLevel] = useState(url.searchParams.get("page"));
  const [isDeleted, setIsDeleted] = useState(false);
  // eslint-disable-next-line
  const [query, setQuery] = useState("");
  const [appData, setAppData] = useState();
  const [toolData, setToolData] = useState();
  const [processData, setProcessData] = useState();
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    (async () => {
      await setData();
      await setIncluded();
      const adRes = await getData(
        `${getHostUrl()}user/check/${
          JSON.parse(sessionStorage.getItem("userData")).id
        }`
      );
      await setIsAdmin(adRes.data);
      if (adRes.data) {
        const res = await getData(
          `${getHostUrl()}training?include=apps,tools,process,trainers&use_pagination=false`
        );

        await setData(res.data.data);
        await setTableData(res.data.data);
        await setIncluded(res.data.included);
        await setIsDeleted(false);
        const apps = await getData(
          `${getHostUrl()}application?use_pagination=false`
        );
        await setAppData(apps.data.data);
        const tools = await getData(
          `${getHostUrl()}tools?use_pagination=false`
        );
        await setToolData(tools.data.data);
        const process = await getData(
          `${getHostUrl()}process?use_pagination=false`
        );
        await setProcessData(process.data.data);
      }
    })();
  }, [level, isDeleted]);

  useEffect(() => {
    addQueryParam("page", level);
  }, [level]);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const keys = ["training-name"];
  // eslint-disable-next-line
  const Search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item.attributes[key].toLowerCase().includes(query))
    );
  };

  return (
    <div className="main-manage">
      {isAdmin !== null ? (
        <>
          {isAdmin && isAdmin ? (
            <>
              <button
                className="fw-bold border-0 bg-transparent"
                onClick={() => {
                  if (level === "table") {
                    navigate("/manage_users");
                  } else {
                    setLevel("table");
                    sessionStorage.removeItem("trainingData");
                  }
                }}
              >
                <ImageSvgs name="goBack" /> Back
              </button>

              <div>
                <>
                  {data ? (
                    <>
                      {level === "table" ? (
                        <>
                          <div className="sub-bar d-flex justify-content-between">
                            <p className="main-title">
                              Trainings (
                              {Search(tableData ? tableData : null)?.length})
                            </p>
                            <div className="search-div">
                              <Button
                                className="app-secondary-btn"
                                onClick={() => {
                                  setLevel("addTraining");
                                }}
                              >
                                Add New Training
                              </Button>
                            </div>
                          </div>
                          <div className="user-table">
                            <TrainingsTable
                              data={Search(tableData ? tableData : null)}
                              included={included ? included : null}
                              setLevel={setLevel}
                              setIsDeleted={setIsDeleted}
                            />
                          </div>
                        </>
                      ) : level === "addTraining" ? (
                        <AddTraining
                          trainingList={tableData && tableData}
                          apps={appData && appData}
                          tools={toolData && toolData}
                          process={processData && processData}
                          flag="addTraining"
                          setLevel={setLevel}
                        />
                      ) : level === "string" || level === "object" ? (
                        <EditTraining
                          trainingList={tableData && tableData}
                          apps={appData && appData}
                          tools={toolData && toolData}
                          process={processData && processData}
                          flag={level}
                          included={included}
                          setLevel={setLevel}
                        />
                      ) : null}
                    </>
                  ) : (
                    <div style={{ maxWidth: "100%", height: "100vh" }}>
                      <Loader />
                    </div>
                  )}
                </>
              </div>
            </>
          ) : (
            <div className="non-admin">
              <ImageSvgs name="non_admin" />
              <h1 className="text-danger">Unauthorized user!</h1>
              <h4>You do not have admin access rights.</h4>
              <button
                id="common-button"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Home
              </button>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TrainingsAdmin;
