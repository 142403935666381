import "../../assets/scss/common.scss";
import { chunk } from "lodash";
import { useState, useEffect, useRef } from "react";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import CarouselExpertComponent from "../CommonComponents/CarouselExpertComponent";

export default function ViewTopic(props) {
  const data = props?.viewTopicData;
  const includedData = props?.topicIncludedData;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const widthRef = useRef(null);

  useEffect(() => {
    (async () => {
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    })();

    // eslint-disable-next-line
  }, [data]);

  let ownerData =
    data?.relationships?.owners?.data?.length > 0
      ? data?.relationships?.owners?.data.map((item) => {
          return includedData?.filter(
            (value) =>
              value.type === "owners" &&
              value.id.toString() === item.id.toString()
          )[0]?.attributes?.employee?.data;
        })
      : [];

  let tempOwnerData = chunk(
    ownerData
      .sort((a, b) =>
        a.attributes["preferred-name"].localeCompare(
          b.attributes["preferred-name"]
        )
      )
      .sort(
        (a, b) =>
          Number(
            includedData?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners?.attributes["employee-id"].toString() === b.id.toString()
            )[0].attributes["is-deputy"]
          ) -
          Number(
            includedData?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners.attributes["employee-id"].toString() === a.id.toString()
            )[0].attributes["is-deputy"]
          )
      ),
    4
  );

  return (
    <div className="pb-4">
      <div>
        <div className="d-flex justify-content-between pt-2 ">
          <p className="main-title">{props.title}</p>
        </div>
        <div>
          <span className="d-flex flex-row">
            <p
              className="font-weight-bold"
              style={{ paddingTop: "2px", fontSize: "18px" }}
            >
              <strong> {data?.attributes["topics-name"]} </strong>
            </p>
          </span>

          <span>
            <p className="limited-space  mx-0" ref={widthRef}>
              <b>Description: </b>
              <div style={{ whiteSpace: "pre-line" }}>
                {data.attributes["topics-description"]?.replace(/\\n/g, "\n")}
              </div>
            </p>
            <p
              className="mx-0 mb-0 read-more-btn"
              style={{ cursor: "pointer", color: "#8a00e5" }}
              onClick={() => {
                setShowInfoModal(true);
              }}
            >
              {isOverflowing ? "Read more" : null}
            </p>
          </span>
        </div>
        <div className="pt-2">
          <p>
            <strong>
              {" "}
              Owners & Deputies of {data?.attributes["topics-name"]} are :-{" "}
            </strong>
          </p>
        </div>
        <div className="experts-section mt-3">
          <div className="d-flex flex-row justify-content-between">
            {tempOwnerData?.length > 0 ? (
              <CarouselExpertComponent
                data={tempOwnerData}
                includedData={includedData && includedData}
              />
            ) : null}
          </div>
        </div>
      </div>
      {showInfoModal ? (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          data={data}
        />
      ) : null}
    </div>
  );
}
