import React from "react";
import ProcessRender from "./ProcessRender";
import ProcessNetworkGraph from "./ProcessNetworkGraph";
import ResponsivePagination from "react-responsive-pagination";
import "./ProcessViewContent.scss";

function ProcessViewContent({
  activeView,
  processDetails,
  activePro,
  getOrgTitles,
  handleProcessData,
  navigate,
  included,
  groupList,
  currentPage,
  handleEmployeeModalClick,
  handleOrgModalClick,
  totalPage,
  onPageChange,
  groupQuery,
  orgQuery,
  allLoading,
  showConnection,
  setDeleteFlag,
}) {
  return (
    <>
      <div className="process-view-content">
        {activeView === "cardView" && (
          <ProcessRender
            processDetails={processDetails}
            activePro={activePro}
            getOrgTitles={getOrgTitles}
            handleProcessData={handleProcessData}
            navigate={navigate}
            activeBtn={activeView}
            handleEmployeeModalClick={handleEmployeeModalClick}
            handleOrgModalClick={handleOrgModalClick}
            included={included}
            groupQuery={groupQuery}
            orgQuery={orgQuery}
            allLoading={allLoading}
            setDeleteFlag={setDeleteFlag}
          />
        )}

        {activeView === "mapView" && (
          <div className="map-view-content">
            <ProcessNetworkGraph
              data={processDetails}
              included={included}
              groupList={groupList}
              view={activeView}
              groupQuery={groupQuery}
              orgQuery={orgQuery}
              selectedValue={showConnection}
              setDeleteFlag={setDeleteFlag}
            />
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-end">
        {activeView === "cardView" ? (
          <ResponsivePagination
            current={currentPage}
            total={totalPage}
            onPageChange={onPageChange}
          />
        ) : null}
      </div>
    </>
  );
}

export default ProcessViewContent;
