import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResponsivePagination from "react-responsive-pagination";
import { getData } from "../../services/apiService";
import Avatar from "react-avatar";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Loader from "../Loader/Loader";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import { debounce } from "lodash";

export const EmployeeModal = (props) => {
  let role = props.role;
  const initialList = props.getMembers;

  // eslint-disable-next-line
  const [selectedOptions, setSelectedOptions] = useState(props.getMembers);
  const [currentPage, setCurrentPage] = useState(1);
  const [empQuery, setEmpQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState("");
  const [employeeData, setEmployeeData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [organData, setOrganData] = useState();
  const [totalPages, setTotalPages] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [finalOrgQuery, setFinalOrgQuery] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(
    selectedOptions.map((employee) => employee.attributes["employee-id"])
  );
  const [selectedCheck, setSelectedCheck] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCurrentPage, setSelectedCurrentPage] = useState(1);
  let selectedTotalPage = Math.ceil(selectedUsers?.length / itemsPerPage);

  useEffect(() => {
    const updatedUsers = selectedOptions?.map((item) => {
      let tempData = role?.toLowerCase().includes("owners")
        ? {
            type: item.type,
            name: item.name,
            attributes: {
              "preferred-name": item.attributes["preferred-name"],
              "email-id": item.attributes["email-id"],
              "employee-id": item.attributes["employee-id"],
              "is-owners": item.attributes["is-owners"],
              "profile-picture": item.attributes["profile-picture"],
              "employee-org-master": item.attributes["employee-org-master"],
            },
            owner_flag: item.owner_flag ? item.owner_flag : false,
            id: item.id,
          }
        : role?.toLowerCase().includes("deputy")
        ? {
            type: item.type,
            name: item.name,
            attributes: {
              "preferred-name": item.attributes["preferred-name"],
              "email-id": item.attributes["email-id"],
              "employee-id": item.attributes["employee-id"],
              "is-deputy": item.attributes["is-deputy"],
              "profile-picture": item.attributes["profile-picture"],
              "employee-org-master": item.attributes["employee-org-master"],
            },
            owner_flag: item.owner_flag ? item.owner_flag : false,
            id: item.id,
          }
        : role?.toLowerCase().includes("head")
        ? {
            type: item.type,
            name: item.name,
            attributes: {
              "preferred-name": item.attributes["preferred-name"],
              "email-id": item.attributes["email-id"],
              "employee-id": item.attributes["employee-id"],
              "is-head": item.attributes["is-head"],
              "profile-picture": item.attributes["profile-picture"],
              "employee-org-master": item.attributes["employee-org-master"],
            },
            owner_flag: item.owner_flag ? item.owner_flag : false,
            id: item.id,
          }
        : {
            type: item.type,
            name: item.name,
            attributes: {
              "preferred-name": item.attributes["preferred-name"],
              "email-id": item.attributes["email-id"],
              "employee-id": item.attributes["employee-id"],
              "profile-picture": item.attributes["profile-picture"],
              "employee-org-master": item.attributes["employee-org-master"],
            },
            owner_flag: item.owner_flag ? item.owner_flag : false,
            id: item.id,
          };
      return tempData;
    });
    setSelectedUsers([...selectedUsers, ...updatedUsers]);
    // eslint-disable-next-line
  }, []);

  const employeeValues =
    employeeData &&
    employeeData?.data.map((emp) => ({
      id: emp.id.toString(),
      label: emp.attributes["preferred-name"],
      email: emp.attributes["email-id"],
      image: emp.attributes["profile-picture"],
      "employee-org-master": emp.attributes["employee-org-master"],
      owner_flag: selectedOptions?.filter(
        (elem) => elem.attributes["employee-id"] === emp.id
      )[0]
        ? selectedOptions?.filter(
            (elem) => elem.attributes["employee-id"] === emp.id
          )[0].owner_flag
        : false,
    }));

  const orgValues = organData?.data.map((org) => ({
    type: org.type,
    id: org.id,
    name: org.attributes["org-title"],
  }));

  async function getResponse() {
    setShowLoader(true);
    let employeeData;
    // if (selectedCheck === false) {
    if (finalOrgQuery.length === 0) {
      if (empQuery.length === 0) {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&page=${currentPage}&per_page=${itemsPerPage}`;
      } else {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&search=${empQuery}&page=${currentPage}&per_page=${itemsPerPage}`;
      }
    } else {
      let id = finalOrgQuery[0]?.id;
      if (empQuery === "") {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&page=${currentPage}&per_page=${itemsPerPage}&org_id=${id}`;
      } else {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&search=${empQuery}&page=${currentPage}&per_page=${itemsPerPage}&org_id=${id}`;
      }
    }
    // }
    const empData = await getData(employeeData);
    setShowLoader(false);
    let pageCount = empData.data.meta["page-count"];
    let perPage = empData.data.meta["per-page"];
    try {
      if (empData && empQuery.length > 0) {
        await setEmployeeData(empData.data);
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      } else {
        await setEmployeeData(empData.data);
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      }
    } catch (error) {
      if (error) {
        setEmployeeData([]);
      }
    }
  }

  async function restoreResponse() {
    let employeeData;
    if (selectedCheck === false) {
      employeeData = `${getHostUrl()}employee/search?&page=${currentPage}&per_page=${itemsPerPage}`;
      const empData = await getData(employeeData);
      setEmployeeData(empData?.data);
    }
  }

  useEffect(() => {
    restoreResponse();
    // eslint-disable-next-line
  }, [selectedCheck]);

  async function getOrgResponse() {
    const orgData = `${getHostUrl()}organization/search?search=${orgQuery}&per_page=${itemsPerPage}&search=${empQuery}`;
    const org = await getData(orgData);

    try {
      if (orgData) {
        await setOrganData(org.data);
      }
    } catch (error) {
      if (error) {
        setOrganData([]);
      }
    }
  }

  async function getOrgDataResponse() {
    const orgData = `${getHostUrl()}organization/search?search=${orgQuery}&per_page=30`;
    const org = await getData(orgData);

    try {
      if (orgData) {
        await setOrganData(org.data);
      }
    } catch (error) {
      if (error) {
        setOrganData([]);
      }
    }
  }

  useEffect(() => {
    getOrgDataResponse();
    // eslint-disable-next-line
  }, [orgQuery]);

  async function getOrgEmployeeResponse(value) {
    const empDataOnOrg = `${getHostUrl()}employee/search?org_id=${
      value[0].id
    }&per_page=20&org_data=true`;
    const empData = await getData(empDataOnOrg);

    try {
      if (empData && value.length > 0) {
        await setEmployeeData(empData.data);
        let pageCount = empData.data.meta["page-count"];
        let perPage = empData.data.meta["per-page"];
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      }
    } catch (error) {
      if (error) {
        setEmployeeData([]);
      }
    } 
  }

  useEffect(() => {
    getOrgResponse();
    // eslint-disable-next-line
  }, [orgQuery]);

  useEffect(() => {
    setCurrentPage(currentPage);
    setTotalPages(totalPages);
    // eslint-disable-next-line
  }, [empQuery, finalOrgQuery, currentPage]);

  const handleCheckboxChange = (item) => {
    const isSelected = selectedEmployeeIds.includes(item?.id);
    if (isSelected) {
      const updatedUsers = selectedUsers.filter(
        (user) => user.attributes?.["employee-id"] !== item.id
      );

      setSelectedEmployeeIds(
        selectedEmployeeIds.filter((elem) => elem !== item.id)
      );
      setSelectedUsers(updatedUsers);
    } else {
      const userObject = role.toLowerCase().includes("owner")
        ? {
            type: props.type,
            name: item.label,
            attributes: {
              "preferred-name": item.label,
              "email-id": item.email,
              "employee-id": item.id,
              "is-owners": props.is_owners,
              "profile-picture": item.image,
              "employee-org-master": item["employee-org-master"],
            },
            owner_flag: item?.owner_flag ? item.owner_flag : false,
            id: initialList?.filter(
              (emp) =>
                emp.attributes["employee-id"].toString() === item.id.toString()
            )[0]
              ? initialList?.filter(
                  (emp) =>
                    emp.attributes["employee-id"].toString() ===
                    item.id.toString()
                )[0].id
              : null,
          }
        : role.toLowerCase().includes("deputy")
        ? {
            type: props.type,
            name: item.label,
            attributes: {
              "preferred-name": item.label,
              "email-id": item.email,
              "employee-id": item.id,
              "is-deputy": props.is_owners,
              "profile-picture": item.image,
              "employee-org-master": item["employee-org-master"],
            },
            owner_flag: item?.owner_flag ? item.owner_flag : false,
            id: initialList?.filter(
              (emp) =>
                emp.attributes["employee-id"].toString() === item.id.toString()
            )[0]
              ? initialList?.filter(
                  (emp) =>
                    emp.attributes["employee-id"].toString() ===
                    item.id.toString()
                )[0].id
              : null,
          }
        : role.toLowerCase().includes("head")
        ? {
            type: props.type,
            name: item.label,
            attributes: {
              "preferred-name": item.label,
              "email-id": item.email,
              "employee-id": item.id,
              "is-head": props.is_owners,
              "profile-picture": item.image,
              "employee-org-master": item["employee-org-master"],
            },
            owner_flag:
              props.is_owners === true || item?.owner_flag === true
                ? true
                : false,
            id: initialList?.filter(
              (emp) =>
                emp.attributes["employee-id"].toString() === item.id.toString()
            )[0]
              ? initialList?.filter(
                  (emp) =>
                    emp.attributes["employee-id"].toString() ===
                    item.id.toString()
                )[0].id
              : null,
          }
        : {
            type: props.type,
            name: item.label,
            attributes: {
              "preferred-name": item.label,
              "email-id": item.email,
              "employee-id": item.id,
              "profile-picture": item.image,
              "employee-org-master": item["employee-org-master"],
            },
            owner_flag: item?.owner_flag ? item.owner_flag : false,
            id: initialList?.filter(
              (emp) =>
                emp.attributes["employee-id"].toString() === item.id.toString()
            )[0]
              ? initialList?.filter(
                  (emp) =>
                    emp.attributes["employee-id"].toString() ===
                    item.id.toString()
                )[0].id
              : null,
          };

      setSelectedUsers([...selectedUsers, userObject]);
      setSelectedEmployeeIds([...selectedEmployeeIds, item.id]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSelectedPageData = (newPage) => {
    setSelectedCurrentPage(newPage);
  };

  const debouncedGetResponse = debounce(getResponse, 500);
  useEffect(() => {
    debouncedGetResponse();
    return debouncedGetResponse.cancel;
    // eslint-disable-next-line
  }, [empQuery, finalOrgQuery, currentPage]);

  const handleInputChange = (e) => {
    const targetValue = e.target.value;
    if (targetValue) {
      setCurrentPage(1);
      setEmpQuery(targetValue);
    } else {
      setEmpQuery("");
      setEmployeeData(employeeData);
    }
  };

  const handleOrgChange = (value) => {
    let newValue = value;
    if (value.length) {
      setEmpQuery("");
      setFinalOrgQuery(newValue);
      getOrgEmployeeResponse(newValue);
    } else {
      setFinalOrgQuery(newValue);
    }
  };

  const handleOrgDataChange = (e) => {
    const newInput = e;
    setOrgQuery(newInput.toUpperCase());
    // handleOrgQueryChange(newInput);
  };

  const paginateData = (pageNumber, pageSize, data) => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
    // return data;
  };

  const handleSelectedValue = () => {
    setSelectedCheck((prevSelectedCheck) => !prevSelectedCheck);
  };

  useEffect(() => {
    if (selectedCheck === true) {
      const selectedPaginatedItems = selectedUsers?.map((value) => ({
        attributes: {
          "preferred-name": value.name,
          "email-id": value.attributes["email-id"],
          "profile-picture": value.attributes["profile-picture"],
          "is-selected": true,
        },
        id: value.attributes?.["employee-id"],
      }));

      setEmployeeData({
        data: paginateData(selectedCurrentPage, 20, selectedPaginatedItems),
      });
    } else {
      setEmployeeData(employeeData);
    }
    // eslint-disable-next-line
  }, [selectedCheck]);

  const paginatedItems = employeeValues;

  const renderCheckboxes = (items) => {
    if (items) {
      items = items?.map((i) => {
        if (selectedEmployeeIds.length > 0) {
          i.isSelected = selectedEmployeeIds.includes(i.id);
        } else {
          i.isSelected = false;
        }
        return i;
      });

      return (
        <Container className="px-0 pe-0">
          <Row className=" users-row">
            {items &&
              items.map((item) => {
                return (
                  <Col xs="3" xl="3" className="mb-3" key={item.id}>
                    <div className="col-12 check-div">
                      <input
                        type="checkbox"
                        className="float-end custom-checkbox-align"
                        value={selectedOptions}
                        checked={
                          item.isSelected === true ||
                          props.getExisting?.some(
                            (e) =>
                              e.attributes["employee-id"].toString() ===
                              item.id.toString()
                          )
                            ? true
                            : false
                        }
                        disabled={
                          props.getExisting?.some(
                            (e) =>
                              e.attributes["employee-id"].toString() ===
                              item.id.toString()
                          ) ||
                          (props.is_owners === false &&
                            item.owner_flag === true)
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCheckboxChange(item);
                        }}
                      />
                    </div>
                    <div key={item.id} className="p-1 custom-card-user">
                      <span className="d-flex flex-row">
                        <span className="px-1 d-flex flex-row">
                          {item.image ? (
                            <img
                              className="my-auto"
                              src={`${getBaseUrl()}${item.image}`}
                              style={{
                                height: "3.6rem",
                                width: "3.6rem",
                                border: "1.5px solid black",
                                borderRadius: "7rem",
                                objectFit: "contain",
                              }}
                              alt="User Img"
                            />
                          ) : (
                            <span className="my-auto">
                              <Avatar
                                name={item.label}
                                size="55"
                                className="me-1"
                                round={true}
                              />
                            </span>
                          )}
                        </span>
                        <div className="px-0 d-flex flex-column">
                          <p className="pr-2 pt-2 custom-name mb-0 me-3">
                            {item.label}
                          </p>
                          <div className="d-flex justify-content-start pt-2 align-items-center custom-email">
                            <OverlayTrigger
                              placement="bottom"
                              bsPrefix="tooltip-style"
                              overlay={<Tooltip>{item.email}</Tooltip>}
                            >
                              <span className="overflow-ellipses">
                                {" "}
                                {item.email}
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      );
    } else {
      return <h1> No Match Found! </h1>;
    }
  };

  useEffect(() => {
    const selectedPaginatedValues = selectedUsers?.map((value) => ({
      attributes: {
        "preferred-name": value.name,
        "email-id": value.attributes["email-id"],
        "profile-picture": value.attributes["profile-picture"],
        "is-selected": true,
      },
      id: value.attributes?.["employee-id"],
    }));
    const currentPageData = paginateData(
      selectedCurrentPage,
      20,
      selectedPaginatedValues
    );
    setEmployeeData({ data: currentPageData });
    // eslint-disable-next-line
  }, [selectedCurrentPage]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="community-employee-modal my-auto"
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-modal-body">
          <>
            <div className="d-flex pb-3 ">
              <div className="app-primary-input-field d-flex w-100">
                <div className="w-75">
                  <input
                    className="w-100"
                    type="search"
                    id={"message"}
                    value={empQuery}
                    placeholder="Search by Name or Email"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="app-primary-dropdown"
                  style={{ width: "16.5rem" }}
                >
                  <Typeahead
                    className="ms-3"
                    labelKey="name"
                    placeholder="Search by Org Code"
                    id="typeAhead"
                    clearButton
                    onChange={handleOrgChange}
                    disabled={selectedCheck}
                    emptyLabel={
                      <div>
                        {orgQuery?.length < 1
                          ? "Keep typing..."
                          : orgQuery?.length >= 1 && showLoader
                          ? "Loading..."
                          : orgQuery?.length >= 1
                          ? "No matches found!"
                          : "Loading..."}
                      </div>
                    }
                    onInputChange={handleOrgDataChange}
                    options={orgValues && orgValues}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row">
              {selectedUsers?.length > 0 ? (
                <div className="d-flex flex-row px-3">
                  <input
                    type="checkbox"
                    className=" custom-checkbox"
                    onChange={() => {
                      handleSelectedValue();
                    }}
                    checked={selectedCheck}
                  />
                  <div className="selected-members px-2">
                    Show {selectedUsers?.length} Selected Members
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {!showLoader ? (
              <div className="pb-3 employee-users pt-3">
                {renderCheckboxes(paginatedItems)}
              </div>
            ) : (
              <div style={{ maxWidth: "100%", height: "30rem" }}>
                <Loader />
              </div>
            )}
            <div className="d-flex justify-content-between">
              {selectedCheck === false ? (
                <div className="w-100 mt-3">
                  <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                    extraClassName="pagination-component"
                  />
                </div>
              ) : (
                <ResponsivePagination
                  current={selectedCurrentPage}
                  total={selectedTotalPage}
                  onPageChange={handleSelectedPageData}
                  extraClassName="pagination-component mt-3"
                />
              )}

              <Button
                onClick={() => {
                  props.setMember(selectedUsers);
                  props.handleClose();
                }}
                className="app-primary-btn col-2 my-auto"
              >
                {props.buttonTitle}
              </Button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
