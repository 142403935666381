export const userFavorites = {
  "user-favorites": {
    type: "user-favorite",
    fields: {
      id: "string",
      "favorite-name": "string",
      "favorite-redirect-url": "string",
      "created-at": "date",
      "user-id": "string",
      "updated-at": "date",
      description: "string",
      "created-by": "string",
      "is-folder": "boolean",
      "updated-by": "string",
      "favorite-image": "string",
      "parent-id": "string",
      "is-external": "boolean",
    },
    relationships: {
      favourites: {
        type: "user-favorite",
      },
    },
  },
};
