import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ImageSvgs from "../ImageSvgs";
import Avatar from "react-avatar";
import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { getBaseUrl } from "../../config/environment";
import { Modal } from "react-bootstrap";

export const UserModal = (props) => {
  const [copy, setCopy] = useState(false);
  const [copyMail, setCopyMail] = useState(false);
  const [id, setId] = useState(null);
  const listData = props.usersData;
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="user-modal-title"
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}
        <ListGroup>
          {listData.map((value) => {
            let imageUrl = value.attributes["profile-picture"];
            return (
              <ListGroupItem className="user-modal-user-list">
                <span className="d-flex justify-content-between">
                  <span>
                    <span>
                      {imageUrl ? (
                        <img
                          className="my-auto"
                          src={`${getBaseUrl()}${imageUrl}`}
                          style={{
                            height: "2rem",
                            width: "2rem",
                            border: "1.5px solid black",
                            borderRadius: "7rem",
                            objectFit: "contain",
                          }}
                          alt="User Img"
                        />
                      ) : (
                        <Avatar
                          name={value.attributes["preferred-name"]}
                          size="32"
                          round={true}
                        />
                      )}
                    </span>
                    <span className="px-2 user-name">
                      {" "}
                      {value.attributes["preferred-name"]}{" "}
                    </span>
                  </span>
                  <span className="d-flex justify-content-between">
                    <span>
                      <button
                        className=" bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copy ? "Copy email" : "Copied"}
                        disabled={copy}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes["email-id"]
                          );
                          setCopy(true);
                          setId(value.id);
                          setTimeout(() => {
                            setCopy(false);
                            setId(null);
                          }, 1500);
                        }}
                      >
                        {id !== value.id ? (
                          <>
                            <ImageSvgs name="expertCopy" />
                          </>
                        ) : (
                          <>
                            <ImageSvgs name="tickMark" />
                          </>
                        )}
                      </button>
                    </span>
                    <span>
                      <button
                        className="ms-2 bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copyMail ? "Mail to" : "Mail to:"}
                        disabled={copyMail}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes["email-id"]
                          );
                          setCopyMail(true);
                        }}
                      >
                        {value.id && (
                          <>
                            <a href={`mailto:${value.attributes["email-id"]}`}>
                              <ImageSvgs name="expertMail" />
                            </a>
                          </>
                        )}
                      </button>
                    </span>

                    <span>
                      <button
                        className="bg-transparent border-0"
                        onClick={() => {
                          navigate(`/employee_relation?id=${value.id}`);
                        }}
                      >
                        <ImageSvgs name="relationIcon" />
                      </button>
                    </span>
                  </span>
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
