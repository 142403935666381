import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { MultipleSelectDropdown } from "../components/CommonComponents/MultipleSelect";
import { deleteData, patchData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { ImageUpload } from "../components/AdminComponents/ImageUpload";
import { Modal, ButtonGroup } from "react-bootstrap";
import { now } from "lodash";
import { linkIcon, folderIcon } from "./iconUtils";
import { postData } from "../services/apiService";
import { EmployeeModal } from "../components/CommonComponents/EmployeeModal";
import "react-bootstrap-typeahead/css/Typeahead.css";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      console.log("error");
      reject(error);
    };
  });
};

export const AddTraining = ({
  trainingList,
  users,
  apps,
  tools,
  process,
  setLevel,
}) => {
  const [type, setType] = useState("link");

  const AddTrainingLink = ({ trainingList, users, apps, tools, process }) => {
    const hash = require("object-hash");
    const imageData = useRef(null);
    const sendImageUrl = useRef(null);
    const base64Img = useRef(null);
    const [nameErr, setNameErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [trainerData, setTrainerData] = useState([]);
    const [fieldName, setFieldValue] = useState({
      training_name: "",
      training_link: null,
      training_description: "",
      training_image: null,
      image: null,
      training_type: null,
      trainer_employee_id: null,
      parent_training_id: null,
    });

    const [link, setLink] = useState();

    const [toolCons, setToolCons] = useState([]);

    const [appCons, setAppCons] = useState([]);

    const [processCons, setProcessCons] = useState([]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
      if (isDeleted) {
        imageData.current = null;
        setIsDeleted(false);
      }
    }, [isDeleted]);

    const findFormErrors = () => {
      const training_name = fieldName.training_name;
      const training_description = fieldName.training_description;
      const training_link = link;
      const trainer_list = trainerData;

      const newErrors = {};

      if (!training_name || training_name === "")
        newErrors.training_name = "Required*";
      if (!training_description || training_description === "")
        newErrors.training_description = "Required*";
      if (training_description.length > 999)
        newErrors.training_description = "Description too long";
      if (!training_link || training_link === null)
        newErrors.training_link = "Required*";
      if (!trainer_list || trainer_list.length === 0)
        newErrors.trainer_list = "Required*";

      return newErrors;
    };

    const handleChange = (e) => {
      setFieldValue({
        ...fieldName,
        [e.target.name]: e.target.value,
      });
    };

    const handleAppChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "application",
          attributes: {
            name: item.name,
            "app-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });

      setAppCons(finalValue);
    };

    const handleToolsChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "tools",
          attributes: {
            name: item.name,
            "tool-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });
      setToolCons(finalValue);
    };

    const handleProcessChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "process",
          attributes: {
            name: item.name,
            "process-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });

      setProcessCons(finalValue);
    };

    const getTrainingName = (name) => {
      trainingList?.map((element) => {
        if (
          element?.attributes["training-name"]?.toLowerCase() ===
          name?.trim()?.toLowerCase()
        ) {
          setNameErr(true);
        }
        return null;
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = findFormErrors();

      if (imageData.current) {
        const base64 = await getBase64(imageData.current);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageData.current, timestamp: now() });
        const extension = imageData.current.type?.split("/")?.pop();
        sendImageUrl.current = `/images/trainings/${imageHash}.${extension}`;
      } else {
        base64Img.current = null;
        sendImageUrl.current = null;
      }

      if (Object.keys(newErrors).length > 0 || nameErr === true) {
        setErrors(newErrors);
      } else {
        let tempVal = {
          data: {
            type: "training",
            attributes: {
              "training-description": fieldName.training_description,
              "training-image": sendImageUrl.current,
              image: base64Img.current,
              "training-link": {
                link:
                  link.includes("https:") || link.includes("http:")
                    ? link
                    : `https://${link}`,
              },
              "training-name": fieldName.training_name,
              "training-type": null,
              "parent-training-id": null,
            },
            relationships: {
              trainers: { data: trainerData },

              tools: {
                data: toolCons,
              },

              apps: {
                data: appCons,
              },

              process: {
                data: processCons,
              },
            },
          },
        };

        const url = `${getHostUrl()}training`;
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        };
        try {
          setLoading(true);
          // eslint-disable-next-line
          // await setFinalValue(tempVal);
          const res = await postData(url, tempVal, headers);
          await setLoading(false);
          setLevel("table");
          if (res.status === 200) {
          }
        } catch (error) {
          console.error(error);
        }
      }

      return false;
    };

    return (
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Name</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Training Name"
                value={fieldName.training_name}
                name="training_name"
                onChange={(e) => {
                  // eslint-disable-next-line
                  setNameErr(false);
                  getTrainingName(e.target.value);
                  handleChange(e);
                }}
                isInvalid={errors.training_name ? true : false}
              />
            </div>
            {errors.training_name ? (
              <p className="m-0 text-danger">Required*</p>
            ) : nameErr === true ? (
              <p className="m-0 text-danger">Name already exists</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Link</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Training Link"
                onChange={(e) => {
                  setLink(e.target.value);
                }}
                isInvalid={!!errors.training_link}
              />
            </div>
            {errors.training_link ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Description</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                as="textarea"
                rows={7}
                placeholder="Enter Training Description"
                value={fieldName.training_description}
                name="training_description"
                onChange={(e) =>
                  // eslint-disable-next-line
                  handleChange(e)
                }
                isInvalid={!!errors.training_description}
              />
            </div>
            {errors.training_description ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              {errors.training_description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Training Image</Form.Label>
            <ImageUpload
              editImage={null}
              className="main-container"
              setIsDeleted={setIsDeleted}
              isAppImage={true}
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label className="font-weight-bold d-flex justify-content-between">
              <p className="my-auto">Select Trainers</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                disabled
                type="input"
                placeholder="Select..."
                className="text-field"
                // value="Value"
                value={
                  trainerData?.length === 0
                    ? ""
                    : trainerData?.length > 0 && trainerData?.length <= 3
                    ? `${trainerData && trainerData?.map((item) => item.name)}`
                    : trainerData?.length > 3
                    ? `${
                        trainerData &&
                        trainerData?.slice(0, 3)?.map((item) => item.name)
                      } + ${trainerData?.length - 3} more...`
                    : null
                }
                // value={processOwner}
              />
            </div>
            {errors.trainer_list ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} className="mt-2" controlId="formGridPassword">
            <Form.Label>Applications Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Applications..."
                options={
                  apps &&
                  apps
                    ?.sort((a, b) =>
                      a.attributes["app-title"].localeCompare(
                        b.attributes["app-title"]
                      )
                    )
                    .map((item) => ({
                      name: item.attributes["app-title"],
                      id: item.id,
                    }))
                }
                onChange={handleAppChange}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Tools Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Tools..."
                options={
                  tools &&
                  tools
                    ?.sort((a, b) =>
                      a.attributes["tool-title"].localeCompare(
                        b.attributes["tool-title"]
                      )
                    )
                    .map((item) => ({
                      name: item.attributes["tool-title"],
                      id: item.id,
                    }))
                }
                onChange={handleToolsChange}
                className="dropdown-select"
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Processes Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Processes..."
                options={
                  process &&
                  process
                    ?.sort((a, b) =>
                      a.attributes["title"].localeCompare(b.attributes["title"])
                    )
                    .map((item) => ({
                      name: item.attributes["title"],
                      id: item.id,
                    }))
                }
                onChange={handleProcessChange}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>

        <div className="w-100 d-flex justify-content-end">
          <Button
            className="app-secondary-btn me-3"
            variant="primary"
            type="submit"
            onClick={async (e) => {
              setLevel("table");
            }}
          >
            Cancel
          </Button>
          <Button
            className="app-primary-btn"
            variant="primary"
            type="submit"
            onClick={async (e) => {
              await handleSubmit(e);
            }}
          >
            {loading ? "Adding New Training..." : "Add New Training"}
          </Button>
        </div>

        {showModal === true ? (
          <EmployeeModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            getMembers={trainerData}
            title="Select Trainers"
            setMember={setTrainerData}
            type="trainers"
            buttonTitle={"Add Trainers"}
            is_owners={true}
            role="no-role"
          />
        ) : null}
      </Form>
    );
  };

  const AddTrainingFolder = ({ trainingList, users, apps, tools, process }) => {
    const hash = require("object-hash");
    const imageData = useRef(null);
    const sendImageUrl = useRef(null);
    const base64Img = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [trainerData, setTrainerData] = useState([]);
    const [fieldName, setFieldValue] = useState({
      training_name: "",
      training_link: null,
      training_description: "",
      training_image: null,
      image: null,
      training_type: null,
      trainer_employee_id: null,
      parent_training_id: null,
    });

    const [toolCons, setToolCons] = useState([]);
    const [appCons, setAppCons] = useState([]);
    const [processCons, setProcessCons] = useState([]);
    const [errors, setErrors] = useState({});
    const [folderLinks, setFolderLinks] = useState([{ name: "", link: "" }]);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
      if (isDeleted) {
        imageData.current = null;
        setIsDeleted(false);
      }
    }, [isDeleted]);

    const findFormErrors = () => {
      const training_name = fieldName.training_name;
      const training_description = fieldName.training_description;
      const training_link = fieldName.training_link;
      const trainer_list = trainerData;

      const newErrors = {};

      if (!training_name || training_name === "")
        newErrors.training_name = "Required*";
      if (!training_description || training_description === "")
        newErrors.training_description = "Required*";
      if (training_description.length > 999)
        newErrors.training_description = "Description too long";
      if (!training_link || training_link === null)
        newErrors.training_link = "Required*";
      if (!trainer_list || trainer_list.length === 0)
        newErrors.trainer_list = "Required*";

      return newErrors;
    };

    const handleChange = (e) => {
      setFieldValue({
        ...fieldName,
        [e.target.name]: e.target.value,
      });
    };

    const handleAppChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "application",
          attributes: {
            name: item.name,
            "app-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });

      setAppCons(finalValue);
    };

    const handleToolsChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "tools",
          attributes: {
            name: item.name,
            "tool-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });

      setToolCons(finalValue);
    };

    const handleProcessChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "process",
          attributes: {
            name: item.name,
            "process-id": item.id.toString(),
          },
          id: null,
        };
        finalValue.push(newElem);
        return null;
      });

      setProcessCons(finalValue);
    };

    const getTrainingName = (name) => {
      trainingList?.map((element) => {
        if (
          element?.attributes["training-name"]?.toLowerCase() ===
          name?.trim()?.toLowerCase()
        ) {
          setNameErr(true);
        }
        return null;
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = findFormErrors();

      if (imageData.current) {
        const base64 = await getBase64(imageData.current);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageData.current, timestamp: now() });
        const extension = imageData.current.type?.split("/")?.pop();
        sendImageUrl.current = `/images/trainings/${imageHash}.${extension}`;
      } else {
        base64Img.current = null;
        sendImageUrl.current = null;
      }

      if (Object.keys(newErrors).length > 0 || nameErr === true) {
        setErrors(newErrors);
      } else {
        let tempVal = {
          data: {
            type: "training",
            attributes: {
              "training-description": fieldName.training_description,
              "training-image": sendImageUrl.current,
              image: base64Img.current,
              "training-link": { link: folderLinks },
              "training-name": fieldName.training_name,
              "training-type": null,
              "parent-training-id": null,
            },
            relationships: {
              trainers: { data: trainerData },

              tools: {
                data: toolCons,
              },

              apps: {
                data: appCons,
              },

              process: {
                data: processCons,
              },
            },
          },
        };

        const url = `${getHostUrl()}training`;
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        };
        try {
          setLoading(true);
          // eslint-disable-next-line
          const res = await postData(url, tempVal, headers);
          await setLoading(false);
          setLevel("table");
          if (res.status === 200) {
          }
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    };

    const FolderLinksModal = (props) => {
      const [formValues, setFormValues] = useState(folderLinks);

      let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      };

      let addFormFields = () => {
        setFormValues([...formValues, { name: "", link: "" }]);
      };

      let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
      };

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Links to Playlist
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="trainings-links-modal">
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Training Name</p>
                          {index === 0 ? null : (
                            <p
                              className="app-tertiary-btn"
                              onClick={() => {
                                removeFormFields(index);
                              }}
                            >
                              Remove
                            </p>
                          )}
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          className="form-text-field"
                          placeholder="Enter Training Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                          isInvalid={errors.training_name ? true : false}
                        />
                      </div>
                      {errors.training_name ? (
                        <p className="m-0 text-danger">Required*</p>
                      ) : null}
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Training Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          className="form-text-field"
                          placeholder="Enter Training Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      {errors.training_link ? (
                        <p className="m-0 text-danger">Required*</p>
                      ) : null}
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <div>
                <Button
                  className="app-primary-btn me-2"
                  onClick={() => {
                    addFormFields();
                  }}
                >
                  Add
                </Button>
              </div>
              <div>
                <Button
                  className="app-secondary-btn me-2"
                  onClick={props.onHide}
                >
                  Close
                </Button>
                <Button
                  className="app-primary-btn"
                  onClick={async () => {
                    let linksList = formValues?.map((elem) => {
                      return {
                        name: elem.name,
                        link:
                          elem.link.includes("https:") ||
                          elem.link.includes("http:")
                            ? elem.link
                            : `https://${elem.link}`,
                      };
                    });
                    await setFolderLinks(linksList);
                    setFieldValue({
                      ...fieldName,
                      training_link: { link: linksList },
                    });
                    props.onHide();
                  }}
                >
                  Add Links
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Training Name</Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  className="form-text-field"
                  placeholder="Enter Training Name"
                  value={fieldName.training_name}
                  name="training_name"
                  onChange={(e) => {
                    // eslint-disable-next-line
                    setNameErr(false);
                    getTrainingName(e.target.value);
                    handleChange(e);
                  }}
                  isInvalid={errors.training_name ? true : false}
                />
              </div>
              {errors.training_name ? (
                <p className="m-0 text-danger">Required*</p>
              ) : nameErr === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className="w-100">
                <span className="w-100 d-flex justify-content-between">
                  <p className="mb-0">Playlist Links</p>
                  <p
                    className="app-tertiary-btn"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add Links
                  </p>
                </span>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  disabled={true}
                  placeholder="Enter Playlist Links"
                  value={folderLinks && folderLinks.map((link) => link.name)}
                  isInvalid={!!errors.training_link}
                />
              </div>
              {errors.training_link ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Training Description</Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  className="form-text-field"
                  as="textarea"
                  rows={7}
                  placeholder="Enter Training Description"
                  value={fieldName.training_description}
                  name="training_description"
                  onChange={(e) =>
                    // eslint-disable-next-line
                    handleChange(e)
                  }
                  isInvalid={!!errors.training_description}
                />
              </div>
              {errors.training_description ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                {errors.training_description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Upload Training Image</Form.Label>
              <ImageUpload
                editImage={null}
                className="main-container"
                setIsDeleted={setIsDeleted}
                isAppImage={true}
                onDrop={(files) => {
                  imageData.current = files[0];
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label className="font-weight-bold d-flex justify-content-between">
                <p className="my-auto">Select Trainers</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder="Select..."
                  className="text-field"
                  // value="Value"
                  value={
                    trainerData?.length === 0
                      ? ""
                      : trainerData?.length > 0 && trainerData?.length <= 3
                      ? `${
                          trainerData && trainerData?.map((item) => item.name)
                        }`
                      : trainerData?.length > 3
                      ? `${
                          trainerData &&
                          trainerData?.slice(0, 3)?.map((item) => item.name)
                        } + ${trainerData?.length - 3} more...`
                      : null
                  }
                  // value={processOwner}
                />
              </div>
              {errors.trainer_list ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} className="mt-2" controlId="formGridPassword">
              <Form.Label>Applications Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Applications..."
                  options={
                    apps &&
                    apps
                      ?.sort((a, b) =>
                        a.attributes["app-title"].localeCompare(
                          b.attributes["app-title"]
                        )
                      )
                      .map((item) => ({
                        name: item.attributes["app-title"],
                        id: item.id,
                      }))
                  }
                  onChange={handleAppChange}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Tools Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Tools..."
                  options={
                    tools &&
                    tools
                      ?.sort((a, b) =>
                        a.attributes["tool-title"].localeCompare(
                          b.attributes["tool-title"]
                        )
                      )
                      .map((item) => ({
                        name: item.attributes["tool-title"],
                        id: item.id,
                      }))
                  }
                  onChange={handleToolsChange}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Processes Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Processes..."
                  options={
                    process &&
                    process
                      ?.sort((a, b) =>
                        a.attributes["title"].localeCompare(
                          b.attributes["title"]
                        )
                      )
                      .map((item) => ({
                        name: item.attributes["title"],
                        id: item.id,
                      }))
                  }
                  onChange={handleProcessChange}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>
          </Row>

          <div className="w-100 d-flex justify-content-end">
            <Button
              className="app-secondary-btn me-3"
              variant="primary"
              type="submit"
              onClick={async (e) => {
                setLevel("table");
              }}
            >
              Cancel
            </Button>
            <Button
              className="app-primary-btn"
              variant="primary"
              type="submit"
              onClick={async (e) => {
                await handleSubmit(e);
              }}
            >
              {loading ? "Adding New Training..." : "Add New Training"}
            </Button>
          </div>
        </Form>
        {showModal === true ? (
          <EmployeeModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            getMembers={trainerData}
            title="Select Trainers"
            setMember={setTrainerData}
            type="trainers"
            buttonTitle={"Add Trainers"}
            is_owners={true}
            role="no-role"
          />
        ) : null}
        {modalShow ? (
          <FolderLinksModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="add-app-form">
      <span className="w-100 d-flex justify-content-between">
        <h4 className="fw-bold mt-2">Add New Training</h4>
      </span>

      <span className="fw-bold fs-6 d-flex">
        <p className="my-auto">Select training type:</p>
        <span>
          <ButtonGroup className="me-2 ms-3" aria-label="First group">
            <Button
              className={
                type === "link"
                  ? "fav-row-btn activeBtn me-2"
                  : "fav-row-btn me-2"
              }
              onClick={() => {
                setType("link");
              }}
            >
              {linkIcon} Link
            </Button>
            <Button
              className={
                type === "folder"
                  ? "fav-row-btn activeBtn mx-2"
                  : "fav-row-btn mx-2"
              }
              onClick={() => {
                setType("folder");
              }}
            >
              <span className="mb-1">{folderIcon} </span>
              Playlist
            </Button>
          </ButtonGroup>
        </span>
      </span>

      <div className="mt-2">
        {type === "link" ? (
          <AddTrainingLink
            trainingList={trainingList}
            users={users}
            apps={apps}
            tools={tools}
            process={process}
          />
        ) : (
          <AddTrainingFolder
            trainingList={trainingList}
            users={users}
            apps={apps}
            tools={tools}
            process={process}
          />
        )}
      </div>
    </div>
  );
};

export const EditTraining = ({
  trainingList,
  users,
  apps,
  tools,
  process,
  flag,
  included,
  setLevel,
}) => {
  const trainingData = JSON.parse(sessionStorage.getItem("trainingData"));

  const EditTrainingLink = ({ trainingList, users, apps, tools, process }) => {
    const hash = require("object-hash");
    const imageData = useRef(null);
    const sendImageUrl = useRef(null);
    const base64Img = useRef(null);
    const [errors, setErrors] = useState({});
    const [isDeleted, setIsDeleted] = useState(false);
    const tempImg = useRef(trainingData?.attributes["training-image"]);
    const [showModal, setShowModal] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fieldName, setFieldValue] = useState({
      training_name: trainingData?.attributes["training-name"],
      training_link: trainingData?.attributes["training-link"].link,
      training_description: trainingData?.attributes["training-description"],
      training_image: trainingData?.attributes["training-image"],
      training_type: null,
      parent_training_id: null,
    });

    const [link, setLink] = useState(
      trainingData?.attributes["training-link"].link
    );

    const tempTrainers =
      trainingData?.relationships.trainers.data.length > 0
        ? trainingData?.relationships.trainers.data
            .filter(
              (elem) =>
                included?.filter(
                  (trainer) =>
                    trainer.type === "trainers" &&
                    trainer.id.toString() === elem.id.toString()
                )[0]
            )
            .map((elem) => {
              let ownerData = included?.filter(
                (trainer) =>
                  trainer.type === "trainers" &&
                  trainer.id.toString() === elem.id.toString()
              )[0];
              return {
                type: "trainers",
                name: `${ownerData?.attributes.employee.data.attributes["preferred-name"]}`,
                attributes: {
                  "employee-id": ownerData?.attributes["employee-id"],
                  "email-id":
                    ownerData?.attributes.employee.data.attributes["email-id"],
                  "profile-picture":
                    ownerData?.attributes.employee.data.attributes[
                      "profile-picture"
                    ],
                },
                id: ownerData?.id,
              };
            })
        : [];

    const [trainerData, setTrainerData] = useState(tempTrainers);

    const tempTool =
      trainingData?.relationships.tools.data.length > 0
        ? trainingData?.relationships.tools.data.map((elem, index) => {
            let toolData = included?.filter(
              (tool) =>
                tool.type === "tools" &&
                tool.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "tools",
              name: toolData.attributes["tools-master"].data.attributes[
                "tool-title"
              ],
              attributes: {
                "tool-id": toolData.attributes["tool-id"].toString(),
              },
              id: toolData.id,
            };
          })
        : [];
    const toolOptions = tools?.map((item, el) =>
      tempTool?.filter(
        (tool) =>
          tool.type === "tools" &&
          tool.attributes["tool-id"].toString() === item.id.toString()
      )[0]
        ? tempTool?.filter(
            (tool) =>
              tool.type === "tools" &&
              tool.attributes["tool-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "tools",
            name: item.attributes["tool-title"],
            attributes: { "tool-id": item.id.toString() },
            id: null,
          }
    );
    const [toolCons, setToolCons] = useState(tempTool);

    const tempApp =
      trainingData?.relationships.apps.data.length > 0
        ? trainingData?.relationships.apps.data.map((elem, index) => {
            let appData = included?.filter(
              (app) =>
                app.type === "application" &&
                app.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "application",
              name: appData.attributes.apps.data.attributes["app-title"],
              attributes: {
                "app-id": appData.attributes["app-id"].toString(),
              },
              id: appData.id,
            };
          })
        : [];

    const appOptions = apps?.map((item) =>
      tempApp?.filter(
        (app) =>
          app.type === "application" &&
          app.attributes["app-id"].toString() === item.id.toString()
      )[0]
        ? tempApp?.filter(
            (app) =>
              app.type === "application" &&
              app.attributes["app-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "application",
            name: item.attributes["app-title"],
            attributes: {
              "app-id": item.id.toString(),
            },
            id: null,
          }
    );
    const [appCons, setAppCons] = useState(tempApp);

    const tempProcess =
      trainingData?.relationships.process.data.length > 0
        ? trainingData?.relationships.process.data.map((elem, index) => {
            let proData = included?.filter(
              (process) =>
                process.type === "process" &&
                process.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "process",
              name: proData.attributes["process-master"].data.attributes[
                "title"
              ],
              attributes: {
                "process-id": proData.attributes["process-id"].toString(),
              },
              id: proData.id,
            };
          })
        : [];
    const processOptions = process?.map((item, el) =>
      tempProcess?.filter(
        (process) =>
          process.type === "process" &&
          process.attributes["process-id"].toString() === item.id.toString()
      )[0]
        ? tempProcess?.filter(
            (process) =>
              process.type === "process" &&
              process.attributes["process-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "process",
            name: item.attributes["title"],
            attributes: { "process-id": item.id.toString() },
            id: null,
          }
    );
    const [processCons, setProcessCons] = useState(tempProcess);

    useEffect(() => {
      if (isDeleted) {
        imageData.current = null;
        tempImg.current = null;
        setIsDeleted(false);
      }
    }, [isDeleted]);

    const getTrainingName = (name) => {
      trainingList?.map((element) => {
        if (
          element?.attributes["training-name"]?.toLowerCase() ===
          name?.trim()?.toLowerCase()
        ) {
          setNameErr(true);
        }
        return null;
      });
    };

    const findFormErrors = () => {
      const training_name = fieldName.training_name;
      const training_description = fieldName.training_description;
      const training_link = fieldName.training_link;
      const trainer_list = trainerData;

      const newErrors = {};

      if (!training_name || training_name === "")
        newErrors.training_name = "Required*";
      if (!training_description || training_description === "")
        newErrors.training_description = "Required*";
      if (training_description.length > 999)
        newErrors.training_description = "Description too long";
      if (!training_link || training_link === null)
        newErrors.training_link = "Required*";
      if (!trainer_list || trainer_list.length === 0)
        newErrors.trainer_list = "Required*";

      return newErrors;
    };

    const handleChange = (e) => {
      setFieldValue({
        ...fieldName,
        [e.target.name]: e.target.value,
      });
    };

    const handleAppChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "application",
          name: item.name,
          attributes: {
            "app-id": item.attributes["app-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setAppCons(finalValue);
    };

    const handleToolsChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "tools",
          name: item.name,
          attributes: {
            "tool-id": item.attributes["tool-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setToolCons(finalValue);
    };

    const handleProcessChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "process",
          name: item.name,
          attributes: {
            "process-id": item.attributes["process-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setProcessCons(finalValue);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = findFormErrors();
      if (Object.keys(newErrors).length > 0 || nameErr === true) {
        setErrors(newErrors);
      } else {
        if (tempImg.current !== null && !imageData.current) {
          sendImageUrl.current = trainingData?.attributes["training-image"];
        } else if (imageData.current) {
          const base64 = await getBase64(imageData.current);
          base64Img.current = base64.split(",")?.pop();
          const imageHash = hash({ file: imageData.current, timestamp: now() });
          const extension = imageData.current.type?.split("/")?.pop();
          sendImageUrl.current = `/images/trainings/${imageHash}.${extension}`;
        } else {
          base64Img.current = null;
          sendImageUrl.current = null;
        }

        let tempVal = {
          data: {
            type: "training",
            attributes: {
              "training-description": fieldName.training_description,
              "training-image": sendImageUrl.current,
              image: base64Img.current,
              "training-link": {
                link:
                  link.includes("https:") || link.includes("http:")
                    ? link
                    : `https://${link}`,
              },
              "training-name": fieldName.training_name,
              "training-type": null,
              "parent-training-id": null,
            },
            relationships: {
              trainers: { data: trainerData },

              tools: {
                data: toolCons,
              },

              apps: {
                data: appCons,
              },

              process: {
                data: processCons,
              },
            },
          },
        };

        const url = `${getHostUrl()}training/${trainingData?.id}`;
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        };
        try {
          setLoading(true);
          // eslint-disable-next-line
          const res = await patchData(url, tempVal, headers);
          await setLoading(false);
          setLevel("table");
          if (res.status === 200) {
          }
        } catch (error) {
          console.error(error);
        }
      }

      return false;
    };

    return (
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Name</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Training Name"
                value={fieldName.training_name}
                name="training_name"
                onChange={(e) => {
                  // eslint-disable-next-line
                  setNameErr(false);
                  getTrainingName(e.target.value);
                  handleChange(e);
                }}
                isInvalid={errors.training_name ? true : false}
              />
            </div>
            {errors.training_name ? (
              <p className="m-0 text-danger">Required*</p>
            ) : nameErr === true ? (
              <p className="m-0 text-danger">Name already exists</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Link</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Training Link"
                value={fieldName.training_link}
                name="training_link"
                onChange={(e) => {
                  setLink(e.target.value);
                  handleChange(e);
                }}
                isInvalid={!!errors.training_link}
              />
            </div>
            {errors.training_link ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Training Description</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                as="textarea"
                rows={7}
                placeholder="Enter Training Description"
                value={fieldName.training_description}
                name="training_description"
                onChange={(e) =>
                  // eslint-disable-next-line
                  handleChange(e)
                }
                isInvalid={!!errors.training_description}
              />
            </div>
            {errors.training_description ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              {errors.training_description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Training Image</Form.Label>
            <ImageUpload
              editImage={trainingData?.attributes["training-image"]}
              className="main-container"
              setIsDeleted={setIsDeleted}
              isAppImage={true}
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label className="font-weight-bold d-flex justify-content-between">
              <p className="my-auto">Select Trainers</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                disabled
                type="input"
                placeholder="Select..."
                className="text-field"
                // value="Value"
                value={
                  trainerData?.length === 0
                    ? ""
                    : trainerData?.length > 0 && trainerData?.length <= 3
                    ? `${trainerData && trainerData?.map((item) => item.name)}`
                    : trainerData?.length > 3
                    ? `${
                        trainerData &&
                        trainerData?.slice(0, 3)?.map((item) => item.name)
                      } + ${trainerData?.length - 3} more...`
                    : null
                }
                // value={processOwner}
              />
            </div>
            {errors.trainer_list ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} className="mt-2" controlId="formGridPassword">
            <Form.Label>Applications Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Applications..."
                options={
                  appOptions &&
                  appOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "application",
                      name: item.name,
                      attributes: {
                        "app-id": item.attributes["app-id"].toString(),
                      },
                      id: item.id ? item.id.toString() : index - index * 2,
                      //   is_owners: false,
                    }))
                }
                onChange={handleAppChange}
                values={appCons}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Tools Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Tools..."
                options={
                  toolOptions &&
                  toolOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "tools",
                      name: item.name,
                      attributes: {
                        "tool-id": item.attributes["tool-id"].toString(),
                      },
                      id: item.id ? item.id.toString() : index - index * 2,
                      //   is_owners: true,
                    }))
                }
                onChange={handleToolsChange}
                values={toolCons}
                className="dropdown-select"
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Processes Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                placeholder="Select Processes..."
                options={
                  processOptions &&
                  processOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "process",
                      name: item.name,
                      attributes: {
                        "process-id": item.attributes["process-id"],
                      },
                      id: item.id ? item.id.toString() : index - index * 2,
                      //   is_owners: false,
                    }))
                }
                onChange={handleProcessChange}
                values={processCons}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>
        <div className="w-100 d-flex justify-content-end">
          <Button
            className="common-cancel-btn mt-1 me-3"
            variant="primary"
            type="submit"
            onClick={() => {
              setLevel("table");
            }}
          >
            Cancel
          </Button>
          <Button
            id="common-favbutton"
            variant="primary"
            type="submit"
            onClick={async (e) => {
              await handleSubmit(e);
              // setLevel("table");
            }}
          >
            {loading ? "Saving Changes..." : "Save Changes"}
          </Button>
        </div>
        {showModal === true ? (
          <EmployeeModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            getMembers={trainerData}
            title="Select Trainers"
            setMember={setTrainerData}
            type="trainers"
            buttonTitle={"Add Trainers"}
            is_owners={true}
            role="no-role"
          />
        ) : null}
      </Form>
    );
  };

  const EditTrainingFolder = ({
    trainingList,
    users,
    apps,
    tools,
    process,
  }) => {
    const hash = require("object-hash");
    const imageData = useRef(null);
    const sendImageUrl = useRef(null);
    const base64Img = useRef(null);
    const [errors, setErrors] = useState({});
    const [isDeleted, setIsDeleted] = useState(false);
    const tempImg = useRef(trainingData?.attributes["training-image"]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [fieldName, setFieldValue] = useState({
      training_name: trainingData?.attributes["training-name"],
      training_link: trainingData?.attributes["training-link"].link,
      training_description: trainingData?.attributes["training-description"],
      training_image: trainingData?.attributes["training-image"],
      training_type: null,
      parent_training_id: null,
    });

    const tempTrainers =
      trainingData?.relationships.trainers.data.length > 0
        ? trainingData?.relationships.trainers.data
            .filter(
              (elem) =>
                included?.filter(
                  (trainer) =>
                    trainer.type === "trainers" &&
                    trainer.id.toString() === elem.id.toString()
                )[0]
            )
            .map((elem) => {
              let ownerData = included?.filter(
                (trainer) =>
                  trainer.type === "trainers" &&
                  trainer.id.toString() === elem.id.toString()
              )[0];
              return {
                type: "trainers",
                name: `${ownerData?.attributes.employee.data.attributes["preferred-name"]}`,
                attributes: {
                  "employee-id": ownerData?.attributes["employee-id"],
                  "email-id":
                    ownerData?.attributes.employee.data.attributes["email-id"],
                  "profile-picture":
                    ownerData?.attributes.employee.data.attributes[
                      "profile-picture"
                    ],
                },
                id: ownerData?.id,
              };
            })
        : [];

    const [trainerData, setTrainerData] = useState(tempTrainers);

    const tempTool =
      trainingData?.relationships.tools.data.length > 0
        ? trainingData?.relationships.tools.data.map((elem, index) => {
            let toolData = included?.filter(
              (tool) =>
                tool.type === "tools" &&
                tool.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "tools",
              name: toolData.attributes["tools-master"].data.attributes[
                "tool-title"
              ],
              attributes: {
                "tool-id": toolData.attributes["tool-id"].toString(),
              },
              id: toolData.id,
            };
          })
        : [];
    const toolOptions = tools?.map((item, el) =>
      tempTool?.filter(
        (tool) =>
          tool.type === "tools" &&
          tool.attributes["tool-id"].toString() === item.id.toString()
      )[0]
        ? tempTool?.filter(
            (tool) =>
              tool.type === "tools" &&
              tool.attributes["tool-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "tools",
            name: item.attributes["tool-title"],
            attributes: { "tool-id": item.id.toString() },
            id: null,
          }
    );
    const [toolCons, setToolCons] = useState(tempTool);

    const tempApp =
      trainingData?.relationships.apps.data.length > 0
        ? trainingData?.relationships.apps.data.map((elem, index) => {
            let appData = included?.filter(
              (app) =>
                app.type === "application" &&
                app.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "application",
              name: appData.attributes.apps.data.attributes["app-title"],
              attributes: {
                "app-id": appData.attributes["app-id"].toString(),
              },
              id: appData.id,
            };
          })
        : [];

    const appOptions = apps?.map((item) =>
      tempApp?.filter(
        (app) =>
          app.type === "application" &&
          app.attributes["app-id"].toString() === item.id.toString()
      )[0]
        ? tempApp?.filter(
            (app) =>
              app.type === "application" &&
              app.attributes["app-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "application",
            name: item.attributes["app-title"],
            attributes: {
              "app-id": item.id.toString(),
            },
            id: null,
          }
    );
    const [appCons, setAppCons] = useState(tempApp);

    const tempProcess =
      trainingData?.relationships.process.data.length > 0
        ? trainingData?.relationships.process.data.map((elem, index) => {
            let proData = included?.filter(
              (process) =>
                process.type === "process" &&
                process.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "process",
              name: proData.attributes["process-master"].data.attributes[
                "title"
              ],
              attributes: {
                "process-id": proData.attributes["process-id"].toString(),
              },
              id: proData.id,
            };
          })
        : [];
    const processOptions = process?.map((item, el) =>
      tempProcess?.filter(
        (process) =>
          process.type === "process" &&
          process.attributes["process-id"].toString() === item.id.toString()
      )[0]
        ? tempProcess?.filter(
            (process) =>
              process.type === "process" &&
              process.attributes["process-id"].toString() === item.id.toString()
          )[0]
        : {
            type: "process",
            name: item.attributes["title"],
            attributes: { "process-id": item.id.toString() },
            id: null,
          }
    );
    const [processCons, setProcessCons] = useState(tempProcess);

    const [folderLinks, setFolderLinks] = useState(
      trainingData?.attributes["training-link"].link
    );

    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
      if (isDeleted) {
        imageData.current = null;
        tempImg.current = null;
        setIsDeleted(false);
      }
    }, [isDeleted]);

    const getTrainingName = (name) => {
      trainingList?.map((element) => {
        if (
          element?.attributes["training-name"]?.toLowerCase() ===
          name?.trim()?.toLowerCase()
        ) {
          setNameErr(true);
        }
        return null;
      });
    };

    const findFormErrors = () => {
      const training_name = fieldName.training_name;
      const training_description = fieldName.training_description;
      const training_link = fieldName.training_link;
      const trainer_list = trainerData;

      const newErrors = {};

      if (!training_name || training_name === "")
        newErrors.training_name = "Required*";
      if (!training_description || training_description === "")
        newErrors.training_description = "Required*";
      if (training_description.length > 999)
        newErrors.training_description = "Description too long";
      if (!training_link || training_link === null)
        newErrors.training_link = "Required*";
      if (!trainer_list || trainer_list.length === 0)
        newErrors.trainer_list = "Required*";

      return newErrors;
    };

    const handleChange = (e) => {
      setFieldValue({
        ...fieldName,
        [e.target.name]: e.target.value,
      });
    };

    const handleAppChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "application",
          name: item.name,
          attributes: {
            "app-id": item.attributes["app-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setAppCons(finalValue);
    };

    const handleToolsChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "tools",
          name: item.name,
          attributes: {
            "tool-id": item.attributes["tool-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setToolCons(finalValue);
    };

    const handleProcessChange = (value) => {
      let finalValue = [];
      value?.map((item) => {
        let newElem = {
          type: "process",
          name: item.name,
          attributes: {
            "process-id": item.attributes["process-id"].toString(),
          },
          id: item.id <= 0 ? null : item.id.toString(),
        };
        finalValue.push(newElem);
        return null;
      });

      setProcessCons(finalValue);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = findFormErrors();
      if (tempImg.current !== null && !imageData.current) {
        sendImageUrl.current = trainingData?.attributes["training-image"];
      } else if (imageData.current) {
        const base64 = await getBase64(imageData.current);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageData.current, timestamp: now() });
        const extension = imageData.current.type?.split("/")?.pop();
        sendImageUrl.current = `/images/trainings/${imageHash}.${extension}`;
      } else {
        base64Img.current = null;
        sendImageUrl.current = null;
      }

      if (Object.keys(newErrors).length > 0 || nameErr === true) {
        setErrors(newErrors);
      } else {
        let tempVal = {
          data: {
            type: "training",
            attributes: {
              "training-description": fieldName.training_description,
              "training-image": sendImageUrl.current,
              image: base64Img.current,
              "training-link": { link: folderLinks },
              "training-name": fieldName.training_name,
              "training-type": null,
              "parent-training-id": null,
            },
            relationships: {
              trainers: { data: trainerData },

              tools: {
                data: toolCons,
              },
              apps: {
                data: appCons,
              },
              process: {
                data: processCons,
              },
            },
          },
        };

        const url = `${getHostUrl()}training/${trainingData?.id}`;
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        };
        try {
          setLoading(true);
          // eslint-disable-next-line
          const res = await patchData(url, tempVal, headers);
          await setLoading(false);
          setLevel("table");
          if (res.status === 200) {
          }
        } catch (error) {
          console.error(error);
        }
      }

      return false;
    };

    const FolderLinksModal = (props) => {
      const [formValues, setFormValues] = useState(folderLinks);

      let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      };

      let addFormFields = () => {
        setFormValues([...formValues, { name: "", link: "" }]);
      };

      let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
      };

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Links to Playlist
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Training Name</p>
                          {index === 0 ? null : (
                            <p
                              className="app-tertiary-btn"
                              onClick={() => {
                                removeFormFields(index);
                              }}
                            >
                              Remove
                            </p>
                          )}
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          className="form-text-field"
                          placeholder="Enter Training Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                          isInvalid={errors.training_name ? true : false}
                        />
                      </div>
                      {errors.training_name ? (
                        <p className="m-0 text-danger">Required*</p>
                      ) : null}
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Training Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          className="form-text-field"
                          placeholder="Enter Training Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      {errors.training_link ? (
                        <p className="m-0 text-danger">Required*</p>
                      ) : null}
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <div>
                <Button
                  className="app-primary-btn me-2"
                  onClick={() => {
                    addFormFields();
                  }}
                >
                  Add
                </Button>
              </div>
              <div>
                <Button
                  className="app-secondary-btn me-2"
                  onClick={props.onHide}
                >
                  Close
                </Button>
                <Button
                  className="app-primary-btn"
                  onClick={async () => {
                    let linksList = formValues?.map((elem) => {
                      return {
                        name: elem.name,
                        link:
                          elem.link.includes("https:") ||
                          elem.link.includes("http:")
                            ? elem.link
                            : `https://${elem.link}`,
                      };
                    });
                    await setFolderLinks(linksList);
                    props.onHide();
                  }}
                >
                  Add Links
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Training Name</Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  className="form-text-field"
                  placeholder="Enter Training Name"
                  value={fieldName.training_name}
                  name="training_name"
                  onChange={(e) => {
                    // eslint-disable-next-line
                    setNameErr(false);
                    getTrainingName(e.target.value);
                    handleChange(e);
                  }}
                  isInvalid={errors.training_name ? true : false}
                />
              </div>
              {errors.training_name ? (
                <p className="m-0 text-danger">Required*</p>
              ) : nameErr === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className="w-100">
                <span className="w-100 d-flex justify-content-between">
                  <p className="mb-0">Playlist Links</p>
                  <p
                    className="app-tertiary-btn"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add Links
                  </p>
                </span>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  disabled={true}
                  placeholder="Enter Playlist Links"
                  value={folderLinks && folderLinks.map((link) => link.name)}
                  isInvalid={!!errors.training_link}
                />
              </div>
              {errors.training_link ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Training Description</Form.Label>
              <Form.Control
                className="form-text-field"
                as="textarea"
                rows={7}
                placeholder="Enter Training Description"
                value={fieldName.training_description}
                name="training_description"
                onChange={(e) =>
                  // eslint-disable-next-line
                  handleChange(e)
                }
                isInvalid={!!errors.training_description}
              />
              {errors.training_description ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                {errors.training_description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Upload Training Image</Form.Label>
              <ImageUpload
                editImage={trainingData?.attributes["training-image"]}
                className="main-container"
                setIsDeleted={setIsDeleted}
                isAppImage={true}
                onDrop={(files) => {
                  imageData.current = files[0];
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label className="font-weight-bold d-flex justify-content-between">
                <p className="my-auto">Select Trainers</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder="Select..."
                  className="text-field"
                  // value="Value"
                  value={
                    trainerData?.length === 0
                      ? ""
                      : trainerData?.length > 0 && trainerData?.length <= 3
                      ? `${
                          trainerData && trainerData?.map((item) => item.name)
                        }`
                      : trainerData?.length > 3
                      ? `${
                          trainerData &&
                          trainerData?.slice(0, 3)?.map((item) => item.name)
                        } + ${trainerData?.length - 3} more...`
                      : null
                  }
                  // value={processOwner}
                />
              </div>
              {errors.trainer_list ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} className="mt-2" controlId="formGridPassword">
              <Form.Label>Applications Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Applications..."
                  options={
                    appOptions &&
                    appOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "application",
                        name: item.name,
                        attributes: {
                          "app-id": item.attributes["app-id"].toString(),
                        },
                        id: item.id ? item.id.toString() : index - index * 2,
                      }))
                  }
                  onChange={handleAppChange}
                  values={appCons}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Tools Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Tools..."
                  options={
                    toolOptions &&
                    toolOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "tools",
                        name: item.name,
                        attributes: {
                          "tool-id": item.attributes["tool-id"].toString(),
                        },
                        id: item.id ? item.id.toString() : index - index * 2,
                      }))
                  }
                  onChange={handleToolsChange}
                  values={toolCons}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Processes Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder="Select Processes..."
                  options={
                    processOptions &&
                    processOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "process",
                        name: item.name,
                        attributes: {
                          "process-id": item.attributes["process-id"],
                        },
                        id: item.id ? item.id.toString() : index - index * 2,
                      }))
                  }
                  onChange={handleProcessChange}
                  values={processCons}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>
          </Row>

          <div className="w-100 d-flex justify-content-end">
            <Button
              className="common-cancel-btn mt-1 me-3"
              variant="primary"
              type="submit"
              onClick={() => {
                setLevel("table");
              }}
            >
              Cancel
            </Button>
            <Button
              id="common-favbutton"
              variant="primary"
              type="submit"
              onClick={async (e) => {
                await handleSubmit(e);
                // setLevel("table");
              }}
            >
              {loading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </div>
        </Form>
        {showModal === true ? (
          <EmployeeModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            getMembers={trainerData}
            title="Select Trainers"
            setMember={setTrainerData}
            type="trainers"
            buttonTitle={"Add Trainers"}
            is_owners={true}
            role="no-role"
          />
        ) : null}
        {modalShow ? (
          <FolderLinksModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="add-app-form">
      <span className="w-100 d-flex justify-content-between">
        {flag === "string" ? (
          <h4 className="fw-bold mt-2">Edit Training Link</h4>
        ) : (
          <h4 className="fw-bold mt-2">Edit Training Playlist</h4>
        )}
      </span>

      <div className="mt-2">
        {flag === "string" ? (
          <EditTrainingLink
            trainingList={trainingList}
            users={users}
            apps={apps}
            tools={tools}
            process={process}
          />
        ) : (
          <EditTrainingFolder
            trainingList={trainingList}
            users={users}
            apps={apps}
            tools={tools}
            process={process}
          />
        )}
      </div>
    </div>
  );
};

export const DeleteTrainingModal = (props) => {
  const [loading, setLoading] = useState(false);

  const deleteTraining = async (id) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    try {
      setLoading(true);
      // eslint-disable-next-line
      const res = await deleteData(`${getHostUrl()}training/${id}`, headers);
      setLoading(false);
      props.onHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Training
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Name: {props.trainingData.attributes["training-name"]}</h5>
        <p>
          Are you sure you want to delete this training? <br />
          All the connections of this training within FIN GS would be lost.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="common-cancel-btn"
          // id="common-favbutton"
          onClick={props.onHide}
        >
          Cancel
        </Button>
        <Button
          id="common-favbutton"
          onClick={() => {
            deleteTraining(props.trainingData.id);
          }}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
