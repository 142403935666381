import React from "react";
import "../Footer/Footer.scss";
import Button from "react-bootstrap/Button";
import { footerLinks } from "../../config/footerLinks";
import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const twitterIcon = (
  <span>
    <FaTwitter />
  </span>
);
const linkedinIcon = (
  <span>
    <FaLinkedinIn />
  </span>
);
const facebookIcon = (
  <span>
    <FaFacebookF />
  </span>
);
const mailIcon = (
  <span>
    <MdEmail />
  </span>
);

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="footer-base">
        <div className="d-flex justify-content-between">
          <div className="footer-button">
            <Button href={footerLinks.contactForm} target="_blank">
              {mailIcon}&nbsp;&nbsp; Get in touch
            </Button>
          </div>
          <div className="justify-content-between social-icons">
            <a href={footerLinks.seTwitter} target="_blank" rel="noreferrer">
              {twitterIcon}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href={footerLinks.seLinkedin} target="_blank" rel="noreferrer">
              {linkedinIcon}
            </a>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href={footerLinks.seFacebook} target="_blank" rel="noreferrer">
              {facebookIcon}
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-between footer-linklist">
          <div>
            <a href={footerLinks.seGlobal} target="_blank" rel="noreferrer">
              siemens-energy.com Global Website
            </a>
          </div>
          <div className="justify-content-between">
            <a
              href={footerLinks.corporateInfo}
              target="_blank"
              rel="noreferrer"
            >
              Corporate Information
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href={footerLinks.privacyNotice}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Privacy Notice
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <a href={footerLinks.cookieNotice} target="_blank" rel="noreferrer">
              Cookie Notice
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href={footerLinks.termsOfUse} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <a
              href={footerLinks.encryptedInfo}
              target="_blank"
              rel="noreferrer"
            >
              Encrypted Communication
            </a>
          </div>
        </div>
        <div className="footer-spacer" />
        <div className="opacity-50 footer-copy">
          Siemens Energy is a trademark licensed by Siemens AG.
          <br />© Siemens Energy, 2020 - 2022
        </div>
      </div>
    </div>
  );
};

export default Footer;
