import { useState, useRef, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MultiFilesComp from "../CommonComponents/MultiFIlesComp";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-dropdown-select";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../assets/scss/common.scss";
import "./AddAndEditProcess.scss";

export default function AddAndEditProcess(props) {
  const navigate = useNavigate();
  const pageLevel = props?.setPageLevel;
  const checkPageLevel = props?.pageLevel;
  const updateData = props?.setFlag;
  // const processAll = props?.processData;
  const processAll = sessionStorage?.getItem("processAll")
    ? JSON.parse(sessionStorage?.getItem("processAll"))
    : props?.processData;
  const trainingAll = sessionStorage?.getItem("trainingAll")
    ? JSON.parse(sessionStorage?.getItem("trainingAll"))
    : props?.trainingData;
  const communityAll = sessionStorage?.getItem("communityAll")
    ? JSON.parse(sessionStorage?.getItem("communityAll"))
    : props?.communityData;
  const applicationAll = sessionStorage?.getItem("applicationAll")
    ? JSON.parse(sessionStorage?.getItem("applicationAll"))
    : props?.applicationData;

  const groupList =
    sessionStorage?.getItem("allProcessGroups") &&
    JSON.parse(sessionStorage?.getItem("allProcessGroups"));

  const [editData, setEditData] = useState(
    sessionStorage?.getItem("processEditData") &&
      JSON.parse(sessionStorage?.getItem("processEditData"))
  );

  const includedData = sessionStorage?.getItem("processIncluded")
    ? JSON.parse(sessionStorage?.getItem("processIncluded"))
    : props?.processIncludedData;

  const formDataString = sessionStorage.getItem("processPayload");
  const formDataSet = JSON.parse(formDataString || "{}");

  const template64 = useRef(
    Object.keys(formDataSet).length !== 0
      ? formDataSet?.data && formDataSet?.data.attributes.template
      : editData !== null
      ? editData?.attributes.template
      : null
  );

  useEffect(() => {
    setEditData(props.editData);
    // eslint-disable-next-line
  }, [props.editId]);

  const [nameErr, setNameErr] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeputyModal, setShowDeputyModal] = useState(false);
  // eslint-disable-next-line
  const [orgQuery, setOrgQuery] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ownerCheck, setOwnerCheck] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [folderLinks, setFolderLinks] = useState(
    Object.keys(formDataSet).length !== 0 &&
      formDataSet?.data &&
      formDataSet?.data.attributes["reference-urls"]
      ? formDataSet?.data && formDataSet?.data.attributes["reference-urls"].data
      : editData && editData?.attributes["reference-urls"]
      ? editData?.attributes["reference-urls"].data
      : []
  );
  const [orgOptions, setOrgOptions] = useState([]);

  const FolderLinksModal = (props) => {
    const [formValues, setFormValues] = useState(folderLinks);

    let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    };

    let addFormFields = () => {
      setFormValues([...formValues, { name: "", link: "" }]);
    };

    let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Process References
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="trainings-links-modal">
          {formValues.length > 0 ? (
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Name</p>

                          <p
                            className="app-tertiary-btn"
                            onClick={() => {
                              removeFormFields(index);
                            }}
                          >
                            Remove
                          </p>
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Reference Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Reference Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          ) : (
            <p className="mb-0 fs-5">Add links to view them here.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <div>
              <Button
                className="app-primary-btn me-2"
                onClick={() => {
                  addFormFields();
                }}
              >
                Add
              </Button>
            </div>
            <div>
              <Button className="app-secondary-btn me-2" onClick={props.onHide}>
                Close
              </Button>
              <Button
                className="app-primary-btn"
                onClick={async () => {
                  let linksList = formValues?.map((elem) => {
                    return {
                      name: elem.name,
                      link:
                        elem.link.includes("https:") ||
                        elem.link.includes("http:")
                          ? elem.link
                          : `https://${elem.link}`,
                    };
                  });
                  await setFolderLinks(linksList);
                  props.onHide();
                }}
              >
                Add Links
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const customContentRenderer = () => {
    const isDefaultGroup =
      groupData && groupData.length > 0 && groupData[0].name === "Default";

    if (isDefaultGroup) {
      setGroupData([]);
    }

    return (
      <>
        {groupData && groupData.length > 0 ? (
          <div
            className="d-flex flex-row custom-group"
            style={{
              backgroundColor: groupData[0].data.nodeColor,
              color: groupData[0].data.fontColor,
            }}
          >
            <p className="my-auto">{groupData[0].name}</p>
            <button
              className="group-custom-btn"
              onClick={() => {
                setGroupData([]);
              }}
              style={{
                color: groupData[0].data.fontColor,
              }}
            >
              x
            </button>
          </div>
        ) : (
          <div className="text-muted default-text">Select Process Group</div>
        )}
      </>
    );
  };

  const periodicity = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Quarterly" },
    { id: 3, name: "Annually" },
  ];

  let processConfig =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data
      ? formDataSet?.data.attributes["process-config"] &&
        formDataSet?.data.attributes["process-config"]
      : editData !== null
      ? editData?.attributes["process-config"] &&
        editData?.attributes["process-config"]
      : null;

  if (processConfig) {
    sessionStorage.setItem("processConfig", JSON.stringify(processConfig));
  }

  const [formData, setFormData] = useState({
    processName:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.title
        : editData !== null
        ? editData?.attributes.title
        : null,
    processDesc:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.desc
        : editData !== null
        ? editData?.attributes.desc
        : null,
    template_url:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.template_url
        : editData !== null
        ? editData?.attributes.template_url
        : null,

    handbookLink:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes["handbook_link_url"]
        : editData?.attributes["handbook-link-url"] !== undefined
        ? editData?.attributes["handbook-link-url"]
        : null,
    wikiLink:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes["wiki_link_url"]
        : editData?.attributes["wiki-link-url"] !== undefined
        ? editData?.attributes["wiki-link-url"]
        : null,
  });

  const [processFiles, setProcessFiles] = useState(
    Object.keys(formDataSet).length !== 0 &&
      formDataSet?.data.attributes?.["template-urls"]
      ? formDataSet?.data.attributes?.["template-urls"].data
      : editData && editData?.attributes?.["template-urls"]
      ? editData?.attributes?.["template-urls"].data?.map((elem) => {
          return { file_name: elem?.file_name, file_path: elem?.file_path };
        })
      : []
  );

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.processName || formData.processName === "") {
      errors.processName = "Required*";
    }

    if (!formData.processDesc || formData.processDesc === "") {
      errors.processDesc = "Required*";
    }
    setFormErrors(errors);
    return errors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOwnerChange = () => {
    if (processOwner.length > 0) {
      setOwnerCheck("");
    } else {
      setOwnerCheck("Required");
    }
  };

  const dataU = [
    { id: 1, name: "U - 20" },
    { id: 2, name: "U - 19" },
    { id: 3, name: "U - 18" },
    { id: 4, name: "U - 17" },
    { id: 5, name: "U - 16" },
    { id: 6, name: "U - 15" },
    { id: 7, name: "U - 14" },
    { id: 8, name: "U - 13" },
    { id: 9, name: "U - 12" },
    { id: 10, name: "U - 11" },
    { id: 11, name: "U - 10" },
    { id: 12, name: "U - 9" },
    { id: 13, name: "U - 8" },
    { id: 14, name: "U - 7" },
    { id: 15, name: "U - 6" },
    { id: 16, name: "U - 5" },
    { id: 17, name: "U - 4" },
    { id: 18, name: "U - 3" },
    { id: 19, name: "U - 2" },
    { id: 20, name: "U - 1" },
    { id: 21, name: "Ultimo" },
    { id: 22, name: "U + 1" },
    { id: 23, name: "U + 2" },
    { id: 24, name: "U + 3" },
    { id: 25, name: "U + 4" },
    { id: 26, name: "U + 5" },
    { id: 27, name: "U + 6" },
    { id: 28, name: "U + 7" },
    { id: 29, name: "U + 8" },
    { id: 30, name: "U + 9" },
    { id: 31, name: "U + 10" },
    { id: 32, name: "U + 11" },
    { id: 33, name: "U + 12" },
    { id: 34, name: "U + 13" },
    { id: 35, name: "U + 14" },
    { id: 36, name: "U + 15" },
    { id: 37, name: "U + 16" },
    { id: 38, name: "U + 17" },
    { id: 39, name: "U + 18" },
    { id: 40, name: "U + 19" },
    { id: 41, name: "U + 20" },
  ];

  const dateSelect = [
    { id: 1, name: "12:00 AM" },
    { id: 2, name: "12:30 AM" },
    { id: 3, name: "01:00 AM" },
    { id: 4, name: "01:30 AM" },
    { id: 5, name: "02:00 AM" },
    { id: 6, name: "02:30 AM" },
    { id: 7, name: "03:00 AM" },
    { id: 8, name: "03:30 AM" },
    { id: 9, name: "04:30 AM" },
    { id: 10, name: "05:00 AM" },
    { id: 11, name: "05:30 AM" },
    { id: 12, name: "06:00 AM" },
    { id: 13, name: "06:30 AM" },
    { id: 14, name: "07:00 AM" },
    { id: 15, name: "07:30 AM" },
    { id: 16, name: "08:00 AM" },
    { id: 17, name: "08:30 AM" },
    { id: 18, name: "09:00 AM" },
    { id: 19, name: "09:30 AM" },
    { id: 20, name: "10:00 AM" },
    { id: 21, name: "10:30 AM" },
    { id: 22, name: "11:00 AM" },
    { id: 23, name: "11:30 AM" },
    { id: 24, name: "12:00 PM" },
    { id: 25, name: "12:30 PM" },
    { id: 26, name: "01:00 PM" },
    { id: 27, name: "01:30 PM" },
    { id: 28, name: "02:00 PM" },
    { id: 29, name: "02:30 PM" },
    { id: 30, name: "03:00 PM" },
    { id: 31, name: "03:30 PM" },
    { id: 32, name: "04:30 PM" },
    { id: 33, name: "05:00 PM" },
    { id: 34, name: "05:30 PM" },
    { id: 35, name: "06:00 PM" },
    { id: 36, name: "06:30 PM" },
    { id: 37, name: "07:00 PM" },
    { id: 38, name: "07:30 PM" },
    { id: 39, name: "08:00 PM" },
    { id: 40, name: "08:30 PM" },
    { id: 41, name: "09:00 PM" },
    { id: 42, name: "09:30 PM" },
    { id: 43, name: "10:00 PM" },
    { id: 44, name: "10:30 PM" },
    { id: 45, name: "11:00 PM" },
    { id: 46, name: "11:30 PM" },
  ];

  const [groupData, setGroupData] = useState(
    Object.keys(formDataSet).length !== 0 && formDataSet?.data.attributes?.group
      ? groupList
          // eslint-disable-next-line
          ?.map((group) => {
            if (
              formDataSet?.data.attributes?.group?.toString() ===
              group?.id?.toString()
            ) {
              return {
                id: group?.id,
                name: group?.attributes?.title,
                data: {
                  nodeColor: group?.attributes?.["node-color"],
                  fontColor: group?.attributes?.["font-color"],
                },
              };
            }
          })
          .filter((item) => item !== null && item !== undefined)
      : editData && editData?.attributes?.group
      ? groupList
          // eslint-disable-next-line
          ?.map((group) => {
            if (
              editData?.attributes?.group?.toString() === group?.id?.toString()
            ) {
              return {
                id: group?.id,
                name: group?.attributes?.title,
                data: {
                  nodeColor: group?.attributes?.["node-color"],
                  fontColor: group?.attributes?.["font-color"],
                },
              };
            }
          })
          .filter((item) => item !== null && item !== undefined)
      : []
  );

  const tempOwners =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships?.owners?.length > 0
      ? formDataSet?.data.relationships?.owners?.filter(
          (owner) => owner?.attributes["is-owners"] === true
        )
      : editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === true &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];
            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": true,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },

              id: ownerData?.id,
            };
          })
      : [];

  const tempDeputy =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships?.owners?.length > 0
      ? formDataSet?.data.relationships?.owners?.filter(
          (owner) => owner?.attributes["is-owners"] === false
        )
      : editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === false &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )

          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "owners",
              "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              "email-id":
                ownerData?.attributes.employee.data.attributes["email-id"],
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": false,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
              },
              id: ownerData?.id,
            };
          })
      : [];

  const tempDepartment =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.orgs.length > 0
      ? formDataSet?.data?.relationships.orgs
      : editData && editData?.relationships.orgs.data?.length > 0
      ? editData?.relationships.orgs.data?.map((data) => {
          let deptData = includedData?.filter(
            (val) =>
              val?.type === "orgs" &&
              val?.id?.toString() === data?.id?.toString()
          )[0];
          return {
            type: "orgs",
            name: deptData?.attributes["org-master"].data.attributes[
              "org-title"
            ],
            attributes: {
              org_id: deptData?.attributes["org-id"]?.toString(),
            },
            id: deptData?.id?.toString(),
          };
        })
      : [];

  const tempTraining =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.trainings.length > 0
      ? formDataSet?.data?.relationships.trainings
      : editData?.relationships.trainings?.data.length > 0
      ? editData?.relationships.trainings.data?.map((tr) => {
          let trainingData = includedData?.filter(
            (val) =>
              val?.attributes["training-master"] &&
              val?.attributes["training-master"].data.type === "training" &&
              val?.id === tr?.id
          )[0];
          return {
            type: "trainings",
            name: trainingData?.attributes["training-master"].data.attributes[
              "training-name"
            ],
            attributes: {
              training_id: trainingData?.attributes["training-id"].toString(),
            },
            id: trainingData?.id?.toString(),
          };
        })
      : [];

  const trainingData =
    trainingAll &&
    trainingAll?.data?.map((item) =>
      tempTraining?.filter(
        (tr) =>
          tr?.type === "training" && tr?.id?.toString() === item?.id?.toString()
      )[0]
        ? tempTraining?.filter(
            (val) =>
              val?.type === "training" &&
              val?.id?.toString() === item?.id?.toString()
          )[0]
        : {
            type: "trainings",
            name: item?.attributes["training-name"],
            attributes: {
              training_id: item?.id?.toString(),
            },
            id: null,
          }
    );

  const tempCommunity =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.communities.length > 0
      ? formDataSet?.data?.relationships.communities
      : editData?.relationships.communities?.data.length > 0
      ? editData?.relationships.communities.data?.map((com) => {
          let communityData = includedData?.filter(
            (val) => val?.type === "community" && val?.id === com?.id
          )[0];
          return {
            type: "community",
            name: communityData?.attributes["process-communities"].data
              .attributes["community-name"],
            attributes: {
              community_id:
                communityData?.attributes["community-id"]?.toString(),
            },
            id: communityData?.id?.toString(),
          };
        })
      : [];

  const communityData = communityAll?.data.map((item) =>
    tempCommunity?.filter(
      (com) =>
        com?.type === "community" &&
        com?.id?.toString() === item?.id?.toString()
    )[0]
      ? tempCommunity?.filter(
          (val) =>
            val.type === "community" &&
            val?.id?.toString() === item?.id?.toString()
        )[0]
      : {
          type: "community",
          name: item?.attributes["community-name"],
          attributes: {
            community_id: item?.id?.toString(),
          },
          id: null,
        }
  );

  const tempApplication =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.apps.length > 0
      ? formDataSet?.data?.relationships.apps
      : editData?.relationships.apps.data?.length > 0
      ? editData?.relationships.apps.data?.map((app) => {
          let applicationData = includedData?.filter(
            (val) => val?.type === "application" && val?.id === app?.id
          )[0];
          return {
            type: "application",
            name: applicationData?.attributes["apps-process"].data.attributes[
              "app-title"
            ],
            attributes: {
              application_id:
                applicationData?.attributes["application-id"]?.toString(),
            },
            id: applicationData?.id?.toString(),
          };
        })
      : [];

  const applicationData =
    applicationAll &&
    applicationAll?.data?.map((item) =>
      tempApplication?.filter(
        (app) =>
          app?.type === "application" &&
          app.id?.toString() === item?.id?.toString()
      )[0]
        ? tempApplication?.filter(
            (val) =>
              val?.type === "application" &&
              val?.id?.toString() === item?.id?.toString()
          )[0]
        : {
            type: "application",
            name: item?.attributes["app-title"],
            attributes: {
              application_id: item?.id?.toString(),
            },
            id: null,
          }
    );

  const tempParentProcess =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships?.parentCons.length > 0
      ? formDataSet?.data?.relationships?.parentCons
      : editData?.relationships["parent-processes"].data?.length > 0
      ? editData?.relationships["parent-processes"].data
          .map((app) => {
            let processData = includedData?.find(
              (val) => val?.type === "hierarchy" && val?.id === app?.id
            );

            if (processData) {
              const parentId = processData?.attributes["parent-id"];

              return {
                type: "hierarchy",
                name: processData?.attributes?.["child-processes"].data
                  .attributes.title,
                attributes: {
                  parent_id: parentId,
                },
                id: processData.id,
              };
            }

            return null;
          })
          .filter((item) => item !== null)
      : [];

  const tempChildProcess =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships?.childCons.length > 0
      ? formDataSet?.data?.relationships?.childCons
      : editData?.relationships["child-processes"].data?.length > 0
      ? editData?.relationships["child-processes"].data
          .map((app) => {
            let processData = includedData?.find(
              (val) => val?.type === "hierarchy" && val?.id === app?.id
            );

            if (processData) {
              const childId = processData?.attributes["child-id"];

              return {
                type: "hierarchy",
                name: processData?.attributes["parent-processes"].data
                  .attributes.title,
                attributes: {
                  child_id: childId,
                },
                id: processData.id,
              };
            }

            return null;
          })
          .filter((item) => item !== null)
      : [];

  const parentOptions = processAll
    ?.map((item) => {
      if (editData) {
        const editDataId = editData.id.toString();

        if (item.id.toString() === editDataId) {
          return null;
        }

        const parentProcessIds =
          editData?.relationships?.["parent-processes"]?.data?.map(
            (p) => p.id
          ) || [];

        const isInEditData = parentProcessIds.includes(item.id.toString());
        return isInEditData
          ? tempParentProcess?.find(
              (app) =>
                app.type === "hierarchy" &&
                app.id?.toString() === item.id?.toString()
            ) || {
              type: "hierarchy",
              name: item?.attributes.title,
              attributes: {
                parent_id: item?.id?.toString(),
              },
              id: null,
            }
          : {
              type: "hierarchy",
              name: item?.attributes.title,
              attributes: {
                parent_id: item?.id?.toString(),
              },
              id: null,
            };
      } else {
        return {
          type: "hierarchy",
          name: item?.attributes.title,
          attributes: {
            parent_id: item?.id?.toString(),
          },
          id: null,
        };
      }
    })
    .filter((item) => item !== null);

  const childOptions = processAll
    ?.map((item) => {
      if (editData) {
        const editDataId = editData.id.toString();
        if (item.id.toString() === editDataId) {
          return null;
        }
        const childProcessIds =
          editData?.relationships?.["child-processes"]?.data?.map(
            (c) => c.id
          ) || [];
        const isInEditData = childProcessIds.includes(item.id.toString());
        return isInEditData
          ? tempParentProcess?.find(
              (app) =>
                app.type === "hierarchy" &&
                app.id?.toString() === item.id?.toString()
            ) || {
              type: "hierarchy",
              name: item?.attributes.title,
              attributes: {
                child_id: item?.id?.toString(),
              },
              id: null,
            }
          : {
              type: "hierarchy",
              name: item?.attributes.title,
              attributes: {
                child_id: item?.id?.toString(),
              },
              id: null,
            };
      } else {
        return {
          type: "hierarchy",
          name: item?.attributes.title,
          attributes: {
            child_id: item?.id?.toString(),
          },
          id: null,
        };
      }
    })
    .filter((item) => item !== null);

  // eslint-disable-next-line
  const tempDate =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data?.attributes
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = formDataSet?.data?.attributes["process_day"];
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = editData && editData?.attributes["process-day"];
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : "";

  // eslint-disable-next-line
  const tempTime =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data.attributes
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = formDataSet?.data?.attributes["process_time"];
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = editData && editData?.attributes["process-time"];
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : "";

  const tempEndDate =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.attributes?.end_day
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = formDataSet?.data?.attributes?.end_day;
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : editData && editData?.attributes["end-day"]
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = editData && editData?.attributes["end-day"];
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : "";

  // eslint-disable-next-line
  const tempEndTime =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data.attributes?.end_time
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = formDataSet?.data?.attributes?.end_time;
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : editData && editData?.attributes["end-time"]
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = editData && editData?.attributes["end-time"];
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : "";

  const tempPeriod =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data.attributes
      ? // eslint-disable-next-line
        periodicity.filter((period) => {
          let periodId =
            formDataSet &&
            formDataSet?.data?.attributes["periodicity_period"][0]?.id;
          if (periodId === period.id) {
            return {
              type: "period",
              id: period?.id,
              name: period?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        periodicity.filter((period) => {
          let periodId = editData && editData?.attributes["periodicity-period"];
          if (periodId === period.id) {
            return {
              type: "period",
              id: period?.id,
              name: period?.name,
            };
          }
        })
      : "";

  let processId =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data
      ? formDataSet?.data?.attributes["parent_process_id"]
      : editData
      ? editData?.attributes["parent-process-id"]
      : null;

  let processVal =
    processAll &&
    processAll?.filter(
      (data) => data?.id?.toString() === processId?.toString()
    )[0];

  const tempProcess = [
    {
      type: "process",
      name: processVal?.attributes.title,
      attributes: {
        process_id: processVal?.id?.toString(),
      },
      id: processVal?.id?.toString(),
    },
  ];

  const [processOwner, setProcessOwner] = useState(tempOwners);
  const [processDeputy, setProcessDeputy] = useState(tempDeputy);
  // eslint-disable-next-line
  const [process, setProcess] = useState(tempProcess);
  const [uData, setUData] = useState(tempDate);
  const [timePeriod, setTimePeriod] = useState(tempTime);
  const [uEndData, setUEndData] = useState(tempEndDate);
  const [timeEndPeriod, setTimeEndPeriod] = useState(tempEndTime);
  const [periodData, setPeriodData] = useState(tempPeriod);
  const [trainingValue, setTrainingValue] = useState(tempTraining);
  const [communityValue, setCommunityValue] = useState(tempCommunity);
  const [applicationValue, setApplicationValue] = useState(tempApplication);
  const [parentProCons, setParentCons] = useState(tempParentProcess);
  const [childProCons, setChildProCons] = useState(tempChildProcess);
  const [dept, setDept] = useState(tempDepartment);

  const handlePeriod = (value) => {
    setPeriodData(value);
  };

  const handleClear = () => {
    sessionStorage.removeItem("processPayload");
    sessionStorage.removeItem("processConfig");
    sessionStorage.removeItem("processFlag");
    sessionStorage.removeItem("processEditData");
    sessionStorage.removeItem("processIncluded");
    sessionStorage.removeItem("applicationAll");
    sessionStorage.removeItem("trainingAll");
    sessionStorage.removeItem("communityAll");
    sessionStorage.removeItem("orgAll");
    sessionStorage.removeItem("allProcessGroups");
  };

  const handleParentProcessCons = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "hierarchy",
        name: item?.name,
        attributes: {
          parent_id: item?.attributes["parent_id"]?.toString(),
        },
        id: item.id <= 0 ? null : item?.id?.toString(),
      };

      finalValue.push(newElem);

      return null;
    });
    setParentCons(finalValue);
  };

  const handleChildProcessCons = (value) => {
    const finalValue = [];

    value.map((item) => {
      let newElem = {
        type: "hierarchy",
        name: item?.name,
        attributes: {
          child_id: item?.attributes["child_id"]?.toString(),
        },
        id: item.id <= 0 ? null : item?.id?.toString(),
      };
      finalValue.push(newElem);

      return null;
    });
    setChildProCons(finalValue);
  };

  const handleTraining = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "trainings",
        name: item?.name,
        attributes: {
          training_id: item?.attributes["training_id"]?.toString(),
        },
        id: item.id > 0 ? item?.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setTrainingValue(finalValue);
  };

  const handleCommunity = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "community",
        name: item?.name,
        attributes: {
          community_id: item?.attributes["community_id"]?.toString(),
        },
        id: item.id > 0 ? item?.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setCommunityValue(finalValue);
  };

  const handleApplication = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "application",
        name: item?.name,
        attributes: {
          application_id: item?.attributes["application_id"]?.toString(),
        },
        id: item.id > 0 ? item.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setApplicationValue(finalValue);
  };

  const handleGroupChange = (value) => {
    setGroupData(value);
  };

  const handleUData = (value) => {
    setUData(value);
  };

  const handleTimeChange = (value) => {
    setTimePeriod(value);
  };

  const handleEndUData = (value) => {
    setUEndData(value);
  };

  const handleEndTimeChange = (value) => {
    setTimeEndPeriod(value);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeputyModal = () => {
    setShowDeputyModal(false);
  };

  const handleDeputyModal = () => {
    setShowDeputyModal(true);
  };

  const payLoad = {
    data: {
      type: "process",
      attributes: {
        title: formData?.processName,
        desc: formData?.processDesc,
        template: template64.current,
        template_url: formData?.template_url,
        "reference-urls":
          folderLinks?.length > 0 ? { data: folderLinks && folderLinks } : null,
        handbook_link_url:
          formData?.handbookLink !== undefined &&
          formData?.handbookLink?.length > 0
            ? formData?.handbookLink?.includes("https:") ||
              formData?.handbookLink?.includes("http:")
              ? formData?.handbookLink
              : `https://${formData?.handbookLink}`
            : null,

        wiki_link_url:
          formData?.wikiLink !== undefined && formData?.wikiLink?.length > 0
            ? formData?.wikiLink?.includes("https:") ||
              formData?.wikiLink?.includes("http:")
              ? formData?.wikiLink
              : `https://${formData?.wikiLink}`
            : null,
        parent_process_id: process
          ? process[0]?.attributes?.["process_id"]
          : "",
        periodicity_period: periodData ? periodData : "",
        process_day:
          uData !== undefined && uData.length > 0 ? uData[0].name : null,
        process_time:
          timePeriod !== undefined &&
          timePeriod?.length > 0 &&
          timePeriod[0]?.name,
        end_day:
          uEndData !== undefined && uEndData?.length > 0
            ? uEndData[0]?.name
            : null,
        end_time:
          timeEndPeriod !== undefined && timeEndPeriod?.length > 0
            ? timeEndPeriod[0]?.name
            : null,
        group: groupData?.length > 0 ? groupData?.[0]?.id : null,
        "template-urls":
          processFiles?.length > 0 ? { data: processFiles } : null,
      },
      relationships: {
        owners: processOwner.concat(processDeputy),
        communities: communityValue,
        apps: applicationValue,
        orgs: dept,
        trainings: trainingValue,
        parentCons: parentProCons,
        childCons: childProCons,
      },
      id: editData && editData?.id,
      pageLevel: pageLevel,
      updateData: updateData,
      // setLoading: setLoading,
    },
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setDescriptionError("Description limit exceeded: 1000 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleProcessPayLoad = async (payload) => {
    const formError = validateForm();
    if (
      Object.keys(formError).length > 0 ||
      descriptionError.length > 1000 ||
      processOwner?.length === 0 ||
      nameErr === true
    ) {
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      if (checkPageLevel === "addProcess") {
        updateData(true);
        sessionStorage.setItem("processPayload", JSON.stringify(payload));
        await pageLevel("addSteps");
      } else {
        updateData(true);
        setDescriptionError("");
        sessionStorage.setItem("processPayload", JSON.stringify(payload));
        await pageLevel("editSteps");
      }
    }
  };

  const handleOrgChange = (value) => {
    let finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "orgs",
        name: item?.name,
        attributes: {
          org_id: item?.attributes["org_id"]?.toString(),
        },
        id: item?.id <= 0 ? null : item?.id?.toString(),
      };

      finalValue.push(newElem);
      return null;
    });
    setDept(finalValue);
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgOptions(res.data.data);
  };

  return (
    <div className="add-and-edit-process">
      <div className="d-flex justify-content-start pt-2">
        <p className="main-title">{props.title}</p>
      </div>
      <div>
        <Form>
          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">Process Name</Form.Label>
              <div className="app-primary-input-field ">
                <Form.Control
                  required
                  type="text"
                  key="field1"
                  className="text-field"
                  value={formData?.processName}
                  placeholder={"Enter Process Name"}
                  name="processName"
                  onChange={(e) => (
                    // eslint-disable-next-line
                    setNameErr(false),
                    // getProcessName(e.target.value),
                    handleChange(e),
                    !!formErrors ? setFormErrors(formData?.processName) : ""
                  )}
                  isInvalid={!!formErrors?.processName}
                />
              </div>
              {formErrors?.processName ? (
                <p className="m-0 text-danger">Required*</p>
              ) : nameErr === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Process Departments
              </Form.Label>
              <div className="app-secondary-dropdown">
                <Typeahead
                  multiple={true}
                  selected={dept}
                  labelKey="name"
                  placeholder="Search for Org Code"
                  id="typeAhead"
                  clearButton
                  searchable
                  emptyLabel={
                    <div>
                      {orgQuery?.length < 1
                        ? "Keep typing..."
                        : orgQuery?.length >= 1
                        ? "Loading..."
                        : orgQuery?.length >= 1
                        ? "No matches found!"
                        : "Loading..."}
                    </div>
                  }
                  onChange={handleOrgChange}
                  onInputChange={(e) => {
                    handleOrgSearch(e);
                  }}
                  options={
                    orgOptions &&
                    orgOptions.map((org, index) => ({
                      type: "organization",
                      name: org.attributes["org-title"],
                      attributes: {
                        org_id: org?.id.toString(),
                      },

                      id: null,
                    }))
                  }
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="d-flex justify-content-between">
                <p className="mb-0">Process Owners</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={handleOpenModal}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  onChange={handleOwnerChange}
                  placeholder={" Select Process Owners"}
                  value={
                    processOwner?.length <= 3
                      ? processOwner?.map((owner) => owner.name).join(", ")
                      : processOwner
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${processOwner?.length - 3} more`
                  }
                />
              </div>
              {ownerCheck?.length > 0 && processOwner?.length === 0 && (
                <p className="m-0 text-danger">Required*</p>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="d-flex justify-content-between">
                <p className="mb-0">Process Deputies</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={handleDeputyModal}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder={"Select Process Deputies"}
                  value={
                    processDeputy?.length <= 3
                      ? processDeputy?.map((owner) => owner.name).join(", ")
                      : processDeputy
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${processDeputy?.length - 3} more`
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              className="mb-4  min-vh-50"
              controlId="validationCustom02"
            >
              <Form.Label className="font-weight-bold">
                Process Description
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  placeholder={"Enter Process Description"}
                  as="textarea"
                  name="processDesc"
                  key="field2"
                  rows={7}
                  onChange={(e) => (
                    // eslint-disable-next-line
                    handleChange(e),
                    handleDescriptionChange(e),
                    !!formErrors ? setFormErrors(formData?.processDesc) : ""
                  )}
                  value={formData?.processDesc}
                  isInvalid={!!formErrors?.processDesc}
                />
              </div>
              {formErrors?.processDesc ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-4 min-vh-50">
              <Form.Label className="font-weight-bold">
                Process Files(Uploads)
              </Form.Label>

              <MultiFilesComp
                processFiles={processFiles}
                setProcessFiles={setProcessFiles}
              />
            </Form.Group>
          </Row>

          <Row className="d-flex flex-row mb-4">
            <Form.Group as={Col}>
              <Form.Label className="w-100">
                <span className="w-100 d-flex justify-content-between">
                  <p className="mb-0">Process References(URLs)</p>
                  <p
                    className="app-tertiary-btn"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add New
                  </p>
                </span>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  disabled
                  value={folderLinks && folderLinks?.map((link) => link.name)}
                  placeholder="Process References"
                />
              </div>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="font-weight-bold">
                Process Periodicity
              </Form.Label>
              <div className="app-primary-dropdown">
                <Select
                  placeholder="Select Process Periodicity"
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  className="dropdown-select"
                  options={
                    periodicity &&
                    periodicity?.map((period) => ({
                      type: "period",
                      id: parseInt(period?.id),
                      name: period?.name,
                    }))
                  }
                  onChange={handlePeriod}
                  values={periodData?.length > 0 ? periodData : []}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="d-flex mb-4">
            <>
              <Form.Group as={Col}>
                <Form.Label> Start Date</Form.Label>
                <div className="app-primary-dropdown">
                  <Select
                    placeholder="Select Ultimo Date"
                    Clearable
                    searchable="true"
                    disabledLabel="disabled"
                    create="false"
                    labelField="name"
                    valueField="id"
                    color="#8A00E5"
                    keepSelectedInList={true}
                    className="dropdown-select"
                    options={
                      dataU &&
                      dataU.map((period) => ({
                        id: period?.id,
                        name: period?.name,
                      }))
                    }
                    onChange={handleUData}
                    values={uData?.length > 0 ? uData : []}
                  />
                </div>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label> Start Time</Form.Label>
                <div className="app-primary-dropdown">
                  <Select
                    placeholder="Select Time"
                    Clearable
                    searchable="true"
                    disabledLabel="disabled"
                    create="false"
                    labelField="name"
                    valueField="id"
                    color="#8A00E5"
                    keepSelectedInList={true}
                    className="dropdown-select"
                    options={dateSelect.map((date) => ({
                      name: date?.name,
                      id: date?.id,
                    }))}
                    onChange={handleTimeChange}
                    values={timePeriod.length > 0 ? timePeriod : []}
                  />
                </div>
              </Form.Group>
            </>

            <>
              <Form.Group as={Col}>
                <Form.Label> End Date</Form.Label>
                <div className="app-primary-dropdown">
                  <Select
                    placeholder="Select Ultimo Date"
                    Clearable
                    searchable="true"
                    disabledLabel="disabled"
                    create="false"
                    labelField="name"
                    valueField="id"
                    color="#8A00E5"
                    keepSelectedInList={true}
                    className="dropdown-select"
                    options={
                      dataU &&
                      dataU.map((period) => ({
                        id: period?.id,
                        name: period?.name,
                      }))
                    }
                    onChange={handleEndUData}
                    values={uEndData?.length > 0 ? uEndData : []}
                  />
                </div>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label> End Time</Form.Label>
                <div className="app-primary-dropdown">
                  <Select
                    placeholder="Select Time"
                    Clearable
                    searchable="true"
                    disabledLabel="disabled"
                    create="false"
                    labelField="name"
                    valueField="id"
                    color="#8A00E5"
                    keepSelectedInList={true}
                    className="dropdown-select"
                    options={dateSelect.map((date) => ({
                      name: date?.name,
                      id: date?.id,
                    }))}
                    onChange={handleEndTimeChange}
                    values={timeEndPeriod.length > 0 ? timeEndPeriod : []}
                  />
                </div>
              </Form.Group>
            </>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-4">
              <Form.Label className="font-weight-bold">Delivers to</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  placeholder={"Select Parent Proccesses"}
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="false"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  className="dropdown-select"
                  options={parentOptions
                    ?.sort((a, b) => a?.name.localeCompare(b?.name))
                    ?.map((process, index) => ({
                      type: "process",
                      name: process?.name,
                      attributes: {
                        parent_id: process?.attributes["parent_id"]?.toString(),
                      },
                      id: process?.id ? process?.id : index - index * 2,
                    }))}
                  onChange={handleParentProcessCons}
                  values={parentProCons}
                />
              </div>
            </Form.Group>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Recieves From
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Child Proccesses"}
                  multi={true}
                  options={childOptions
                    ?.sort((a, b) => a?.name.localeCompare(b?.name))
                    ?.map((process, index) => ({
                      type: "process",
                      name: process?.name,
                      attributes: {
                        child_id: process?.attributes["child_id"]?.toString(),
                      },
                      id: process?.id ? process?.id : index - index * 2,
                    }))}
                  values={childProCons}
                  onChange={handleChildProcessCons}
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Applications Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Applications"}
                  multi={true}
                  options={
                    applicationData &&
                    applicationData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((application, index) => ({
                        type: "application",
                        name: application.name,
                        attributes: {
                          application_id:
                            application.attributes[
                              "application_id"
                            ]?.toString(),
                        },
                        id: application?.id
                          ? application?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={applicationValue}
                  onChange={handleApplication}
                />
              </div>
            </Form.Group>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Community Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Communities"}
                  multi={true}
                  options={
                    communityData &&
                    communityData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((community, index) => ({
                        type: "community",
                        name: community?.name,
                        attributes: {
                          community_id:
                            community &&
                            community?.attributes["community_id"]?.toString(),
                        },
                        id: community?.id
                          ? community?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={communityValue}
                  onChange={handleCommunity}
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-4">
              <Form.Label className="font-weight-bold">
                Trainings Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Trainings"}
                  multi={true}
                  options={
                    trainingData &&
                    trainingData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((training, index) => ({
                        type: "trainings",
                        name: training?.name,
                        attributes: {
                          training_id:
                            training?.attributes["training_id"]?.toString(),
                        },
                        id: training?.id
                          ? training?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={trainingValue}
                  onChange={handleTraining}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="font-weight-bold">
                Process Group
              </Form.Label>

              <div className="app-primary-dropdown group-dropdown">
                <Select
                  searchBy="name"
                  contentRenderer={customContentRenderer}
                  placeholder="Select Process Group"
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="false"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  className="dropdown-select"
                  options={
                    groupList?.length > 0 &&
                    [...groupList]
                      .filter((grp) => !grp.attributes["is-default"])
                      .sort((a, b) => {
                        const titleA = a.attributes["title"].toLowerCase();
                        const titleB = b.attributes["title"].toLowerCase();
                        return titleA.localeCompare(titleB);
                      })
                      ?.map((grp) => ({
                        id: grp.id.toString(),
                        name: grp.attributes["title"],
                        data: {
                          nodeColor: grp?.attributes?.["node-color"],
                          fontColor: grp?.attributes?.["font-color"],
                        },
                        type: "process-groups",
                      }))
                  }
                  onChange={handleGroupChange}
                  values={groupData?.length > 0 ? groupData : []}
                />
              </div>
            </Form.Group>
          </Row>

          {modalShow ? (
            <FolderLinksModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          ) : null}

          {showModal === true ? (
            <EmployeeModal
              show={showModal}
              handleClose={handleCloseModal}
              type={"owners"}
              getMembers={processOwner}
              getExisting={processDeputy}
              title={"Add Owners To Process"}
              setMember={setProcessOwner}
              buttonTitle={"Add Owners"}
              role={"is-owners"}
              is_owners={true}
            />
          ) : (
            ""
          )}
          {showDeputyModal === true ? (
            <EmployeeModal
              show={showDeputyModal}
              type={"owners"}
              handleClose={handleCloseDeputyModal}
              getMembers={processDeputy}
              getExisting={processOwner}
              title={"Add Deputy To Process"}
              setMember={setProcessDeputy}
              buttonTitle={"Add Deputies"}
              role={"is-owners"}
              is_owners={false}
            />
          ) : (
            ""
          )}
        </Form>
        <div className="d-flex justify-content-end font-weight-bold pt-4">
          <Button
            className="app-secondary-btn"
            onClick={() => {
              if (
                sessionStorage.getItem("processFlag") &&
                sessionStorage.getItem("processFlag") === "true"
              ) {
                sessionStorage.removeItem("processPayload");
                sessionStorage.removeItem("processConfig");
                sessionStorage.removeItem("processFlag");
                sessionStorage.removeItem("processEditData");
                sessionStorage.removeItem("processIncluded");
                sessionStorage.removeItem("applicationAll");
                sessionStorage.removeItem("trainingAll");
                sessionStorage.removeItem("communityAll");
                sessionStorage.removeItem("orgAll");
                sessionStorage.removeItem("allProcessGroups");

                navigate("/landscape/process?process_id=0&view=cardView");
              } else {
                pageLevel("process");
                sessionStorage.removeItem("processPayload");
                sessionStorage.removeItem("processEditData");
                sessionStorage.removeItem("processIncluded");
                handleClear();
              }
            }}
          >
            Cancel
          </Button>

          <Button
            className="app-primary-btn ms-3"
            onClick={() => handleProcessPayLoad(payLoad)}
          >
            Next Step
          </Button>
        </div>
      </div>
    </div>
  );
}
