import { useState } from "react";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AddNewTopic } from "../../utils/topicsUtils";
import "../../assets/scss/common.scss";
import { updateTopics } from "../../utils/topicsUtils";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";

export default function AddTopic(props) {
  const [showModal, setShowModal] = useState(false);
  const [showDeputyModal, setShowDeputyModal] = useState(false);
  const editData = props.topicEditData;
  const checkPageLevel = props.pageLevel;
  const updateData = props.setFlag;
  const pageLevel = props.setPageLevel;
  const includedData = props.topicIncludedData;
  const initialTopicData = props.iniTopicData;
  // eslint-disable-next-line
  const [orgQuery, setOrgQuery] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [formData, setFormData] = useState({
    topicName: editData != null ? editData.attributes["topics-name"] : null,
    topicDesc:
      editData != null ? editData.attributes["topics-description"] : null,
  });
  const [ownerCheck, setOwnerCheck] = useState("");
  const [loading, setLoading] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);

  const tempOwner =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-deputy"] === false &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "employee-id": ownerData?.attributes["employee-id"],
                "is-deputy": false,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },

              id: ownerData?.id,
            };
          })
      : [];

  const tempDeputy =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-deputy"] === true &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "employee-id": ownerData?.attributes["employee-id"],
                "is-deputy": true,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },

              id: ownerData?.id,
            };
          })
      : [];

  const tempDepartment =
    editData && editData?.relationships.orgs.data?.length > 0
      ? editData?.relationships.orgs.data?.map((item) => {
          let deptData = includedData?.filter(
            (val) =>
              val.attributes["orgs-master"]?.data.type === "organization" &&
              val.id.toString() === item.id.toString()
          )[0];

          return {
            type: "organization",
            name: deptData?.attributes["orgs-master"].data.attributes[
              "org-title"
            ],
            attributes: {
              orgs_id: deptData?.attributes["orgs-id"].toString(),
            },
            id: deptData?.id.toString(),
          };
        })
      : [];

  const [topicOwner, setTopicOwner] = useState(tempOwner && tempOwner);
  const [topicDeputy, setTopicDeputy] = useState(tempDeputy);
  const [dept, setDept] = useState(tempDepartment);

  const handleOrgChange = (value) => {
    let finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "organization",
        name: item.name,
        attributes: {
          orgs_id: item.attributes["orgs_id"].toString(),
        },
        id: item.id <= 0 ? null : item.id.toString(),
      };

      finalValue.push(newElem);
      return null;
    });

    setDept(finalValue);
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgOptions(res.data.data);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setDescriptionError("Description limit exceeded: 1000 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleOwnerChange = () => {
    if (topicOwner.length > 0) {
      setOwnerCheck("");
    } else {
      setOwnerCheck("Required");
    }
  };

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.topicName || formData.topicName === "") {
      errors.topicName = "Required*";
    }

    if (!formData.topicDesc || formData.topicDesc === "") {
      errors.topicDesc = "Required*";
    }

    setFormErrors(errors);
    return errors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const payLoad = {
    data: {
      type: "topics",
      attributes: {
        topics_name: formData?.topicName,
        topics_description: formData?.topicDesc,
      },
      relationships: {
        orgs: dept,
        owners: topicOwner.concat(topicDeputy),
      },
      updateData: updateData,
      pageLevel: pageLevel,
      id: editData?.id,
      setLoading: setLoading,
      setTableLoad: props.setTableLoad,
    },
  };

  const handleAddTopic = async (payLoad) => {
    const formError = validateForm();

    if (
      Object.keys(formError).length > 0 ||
      topicOwner?.length === 0 ||
      descriptionError.length > 0 ||
      nameErr === true
    ) {
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      await AddNewTopic(payLoad);
    }
  };

  const handleUpdateTopic = async (payLoad) => {
    const formError = validateForm();
    if (
      Object.keys(formError).length > 0 ||
      descriptionError.length > 0 ||
      topicOwner?.length === 0 ||
      nameErr === true
    ) {
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      await updateTopics(payLoad);
    }
  };

  const getTopicName = (name) => {
    // eslint-disable-next-line
    initialTopicData?.map((element) => {
      if (
        element?.attributes["topics-name"]?.toLowerCase() ===
        name?.trim()?.toLowerCase()
      ) {
        setNameErr(true);
      }
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeputyModal = () => {
    setShowDeputyModal(false);
  };

  const handleDeputyModal = () => {
    setShowDeputyModal(true);
  };

  return (
    <div>
      <div className="d-flex justify-content-between pt-2">
        <p className="main-title">{props.title}</p>
      </div>
      <Form>
        <Row>
          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="font-weight-bold">Topic Name</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                type="text"
                placeholder={"Enter Topic Name"}
                value={formData.topicName}
                key="field1"
                name="topicName"
                onChange={(e) => (
                  // eslint-disable-next-line
                  setNameErr(false),
                  getTopicName(e.target.value),
                  handleChange(e),
                  !!formErrors ? setFormErrors(formData?.topicName) : ""
                )}
                isInvalid={!!formErrors?.topicName}
              />
            </div>
            {formErrors?.topicName ? (
              <p className="m-0 text-danger">Required*</p>
            ) : nameErr ? (
              <p className="m-0 text-danger">Name already exists</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className="mb-4">
            <Form.Label className="font-weight-bold">
              Topic Department
            </Form.Label>
            <div className="app-secondary-dropdown">
              <Typeahead
                multiple={true}
                selected={dept}
                labelKey="name"
                placeholder="Search for Org Code"
                id="typeAhead"
                clearButton
                emptyLabel={
                  <div>
                    {orgQuery?.length < 1
                      ? "Keep typing..."
                      : orgQuery?.length >= 1
                      ? "Loading..."
                      : orgQuery?.length >= 1
                      ? "No matches found!"
                      : "Loading..."}
                  </div>
                }
                onChange={handleOrgChange}
                onInputChange={(e) => {
                  handleOrgSearch(e);
                }}
                options={
                  orgOptions &&
                  orgOptions.map((org, index) => ({
                    type: "organization",
                    name: org.attributes["org-title"],
                    attributes: {
                      orgs_id: org?.id.toString(),
                    },

                    id: null,
                  }))
                }
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="d-flex flex-row">
          <Form.Group
            as={Col}
            className="mb-4 min-vh-50"
            controlId="validationCustom02"
          >
            <Form.Label className="font-weight-bold">
              Topic Description
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                placeholder="Enter Topic Description"
                as="textarea"
                rows={7}
                name="topicDesc"
                key="field2"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e),
                  handleDescriptionChange(e),
                  !!formErrors ? setFormErrors(formData?.topicDesc) : ""
                )}
                value={formData?.topicDesc}
                isInvalid={!!formErrors?.topicDesc}
              />
            </div>
            {formErrors?.topicDesc ? (
              <p className="m-0 text-danger">Required*</p>
            ) : null}
            <Form.Control.Feedback type="invalid">
              Required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="d-flex justify-content-between">
              <p className="mb-0">Topic Owners</p>
              <Button
                className="app-icon-primary-btn"
                onClick={handleOpenModal}
              >
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                type="input"
                onChange={handleOwnerChange}
                placeholder={" Select Topic Owners"}
                disabled
                value={
                  topicOwner?.length <= 3
                    ? topicOwner?.map((owner) => owner.name).join(", ")
                    : topicOwner
                        ?.slice(0, 3)
                        .map((owner) => owner.name)
                        .join(", ") + ` + ${topicOwner?.length - 3} more`
                }
              />
            </div>
            {ownerCheck && topicOwner?.length === 0 && (
              <p className="m-0 text-danger">Required*</p>
            )}

            <Form.Label className="d-flex justify-content-between mt-4">
              <p className="mb-0">Topic Deputies</p>
              <Button
                className="app-icon-primary-btn"
                onClick={handleDeputyModal}
              >
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                type="input"
                placeholder={"Select Topic Deputies"}
                disabled
                value={
                  topicDeputy?.length <= 3
                    ? topicDeputy?.map((owner) => owner.name).join(", ")
                    : topicDeputy
                        ?.slice(0, 3)
                        .map((owner) => owner.name)
                        .join(", ") + ` + ${topicDeputy?.length - 3} more`
                }
              />
            </div>
          </Form.Group>
        </Row>
        {showModal === true ? (
          <EmployeeModal
            show={showModal}
            handleClose={handleCloseModal}
            type={"owners"}
            getMembers={topicOwner}
            getExisting={topicDeputy}
            title={"Add Owners To Topic"}
            setMember={setTopicOwner}
            buttonTitle={"Add Owners"}
            role={"is-deputy"}
            is_owners={false}
          />
        ) : (
          ""
        )}
        {showDeputyModal === true ? (
          <EmployeeModal
            show={showDeputyModal}
            type={"owners"}
            handleClose={handleCloseDeputyModal}
            getMembers={topicDeputy}
            getExisting={topicOwner}
            title={"Add Deputy To Topic"}
            setMember={setTopicDeputy}
            buttonTitle={"Add Deputies"}
            role={"is-deputy"}
            is_owners={true}
          />
        ) : (
          ""
        )}
      </Form>

      <div className="d-flex justify-content-end font-weight-bold pt-6 topic-btn-div">
        <Button
          className="app-secondary-btn"
          onClick={() => {
            pageLevel("topic");
          }}
        >
          Cancel
        </Button>

        <Button
          className="app-primary-btn ms-3"
          onClick={() => {
            checkPageLevel === "editTopic"
              ? handleUpdateTopic(payLoad)
              : handleAddTopic(payLoad);
          }}
        >
          {checkPageLevel === "editTopic"
            ? loading === true
              ? "Updating Topic..."
              : "Update Topic"
            : loading === true
            ? "Adding Topic..."
            : "Add Topic"}
        </Button>
      </div>
    </div>
  );
}
