import { React, useState } from "react";
import "../../assets/scss/common.scss";

export default function CustomTooltip({ children, name, orgCode }) {
  const [visible, setVisible] = useState(false);

  const tooltipText = `${name}\n${orgCode}`;

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          style={{
            position: "absolute",
            top: "60%",
            left: "110%",
            transform: "translateY(-50%)",
            backgroundColor: "black",
            color: "white",
            padding: "0.5rem",
            borderRadius: "4px",
            whiteSpace: "pre-wrap",
            zIndex: 1000,
            minWidth: "200px",
            maxWidth: "800px",
            textAlign: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
}
