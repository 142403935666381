import React from "react";
import FavoritesTable from "../components/FavoritesTable/FavoritesTable";

const FavoritesPage = () => {
  return (
    <div>
      <FavoritesTable />
    </div>
  );
};

export default FavoritesPage;
