import { React, useState, useEffect } from "react";
import { Carousel, Stack } from "react-bootstrap";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import ConnectionsComponent from "./ConnectionsComponent";

export default function CarouselConnections(data) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (data && data?.data?.length > 0) {
      setActiveIndex(0);
    }
  }, [data]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="w-100">
      {data?.data?.length > 0 && (
        <Carousel
          nextIcon={nextArrow}
          prevIcon={prevArrow}
          interval={null}
          wrap={false}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {data?.data?.map((record, i) => (
            <Carousel.Item key={i}>
              <Stack
                direction="horizontal"
                className="h-100  align-items-center"
                gap={2}
              >
                {record.map((elem, j) => {
                  if (j < record.length - 1) {
                    return (
                      <>
                        <ConnectionsComponent data={elem} key={j} />
                        <div className="divider"></div>
                      </>
                    );
                  } else {
                    return <ConnectionsComponent data={elem} key={j} />;
                  }
                })}
              </Stack>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
}
