import axios from "axios";

const getHeaders = (tokenType = "idToken") => {
  return {
    Authorization: `Bearer ${sessionStorage.getItem(tokenType)}`,
  };
};

export const getData = async (endpoint, tokenType, headers) => {
  try {
    const options = {
      headers: {
        ...getHeaders(tokenType),
        ...headers,
        "Cache-Control": "no-cache",
      },
      url: endpoint,
    };

    return await axios.get(endpoint, options);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postData = async (endpoint, data, headers) => {
  try {
    const options = {
      headers: { ...getHeaders(), ...headers },
      url: endpoint,
    };

    return await axios.post(endpoint, data, options);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const patchData = async (endpoint, data, headers) => {
  try {
    const options = {
      headers: { ...getHeaders(), ...headers },
      url: endpoint,
    };

    return await axios.patch(endpoint, data, options);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteData = async (endpoint, headers) => {
  try {
    const options = {
      headers: { ...getHeaders(), ...headers },
      url: endpoint,
    };

    return await axios.delete(endpoint, options);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadFile = async (endpoint, data, headers) => {
  try {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getHeaders(),
        ...headers,
      },
      url: endpoint,
    };

    let uploadFormData = new FormData();
    uploadFormData.append("file", data.file);
    uploadFormData.append("path", data.path);

    return await axios.post(endpoint, uploadFormData, options);
  } catch (error) {
    throw error;
  }
};
