import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import "./AdminScreen.scss";

const AdminCards = (props) => {
  return (
    <Card className="card">
      <Card.Body className="card-body">
        <Link to={props.link} className="text-decoration-none card-title">
          <div>
            <Card.Title className="card-type align-self-end mt-1">
              <span>{props.cardType} </span>
            </Card.Title>
            <div className="d-flex justify-content-between flex-grow-1 mt-3">
              <Card.Subtitle className="align-self-end mb-3">
                {props.icon}
              </Card.Subtitle>

              <Card.Text className="card-data">{props.data}</Card.Text>
            </div>
          </div>
        </Link>
      </Card.Body>
    </Card>
  );
};
export default AdminCards;
