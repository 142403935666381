import React from "react";
import { useEffect, useState } from "react";
import { getBaseUrl, getHostUrl } from "../config/environment";
import { getData } from "../services/apiService";
import ToolsTreemap from "../components/ToolsComponents/ToolsTreemap";
import ToolSupport from "../components/ToolSupport/ToolSupport";
import Loader from "../components/Loader/Loader";

const ToolsPage = () => {
  let flag = window.location.href.includes("v2");
  const [layers, setLayers] = useState();
  const [data, setData] = useState(null);
  const [toolsData, setToolsData] = useState();

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getBaseUrl()}/configurations/toolSupport/toolsPage.json`
      );
      await setData(res.data);

      const tools = await getData(
        `${getHostUrl()}tools?include=apps,trainings,experts,communities,tool_info&use_pagination=false`
      );
      await setToolsData(tools.data);
      const layerData = await getData(
        `${getBaseUrl()}/configurations/layerLookup.json`
      );
      await setLayers(layerData.data.layers);
    })();
  }, []);
  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <h2 className="fw-bold">Tools</h2>
        </div>
        {toolsData && layers && data ? (
          <div className="mt-3">
            {flag ? (
              <ToolSupport source={data} />
            ) : (
              <ToolsTreemap data={{ tools: toolsData, layers: layers }} />
            )}
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolsPage;
