import React from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsTreemap from "highcharts/modules/treemap";
import HighChartsHeatmap from "highcharts/modules/heatmap";
import { ButtonGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import UiTreemap from "./UiTreemap";
import IntegrationTreemap from "./IntegrationTreemap";
import SourceTreemap from "./SourceTreemap";
import NotInUseTremap from "./NotInUseTremap";
import Loader from "../Loader/Loader";
import "./ToolsComp.scss";
HighchartsExporting(Highcharts);
HighchartsTreemap(Highcharts);
HighChartsHeatmap(Highcharts);

const ToolsTreemap = (props) => {
  const [params] = useSearchParams();
  const [key, setKey] = useState(
    params.get("layerId") ? params.get("layerId") : "1"
  );

  const [uiTools, setUiTools] = useState();
  const [uiDef, setUiDef] = useState();
  const [intTools, setIntTools] = useState();
  const [intDef, setIntDef] = useState();
  const [sourceTools, setSourceTools] = useState();
  const [sourceDef, setSourceDef] = useState();
  const [noUseTools, setNoUseTools] = useState();
  const [noUseDef, setNoUseDef] = useState();
  const toolsData = props.data.tools.data;
  const layers = props.data.layers;
  const included = props.data.tools.included;

  useEffect(() => {
    (async () => {
      const uiToolsList = [];
      const intToolsList = [];
      const sourceToolsList = [];
      const noUseToolsList = [];

      // eslint-disable-next-line
      toolsData?.map((elem) => {
        // eslint-disable-next-line
        if (elem.attributes.nodes.data.length == 0) {
          noUseToolsList.push(elem.id);
        }
        // eslint-disable-next-line
        elem.attributes.nodes.data.map((tool) => {
          // eslint-disable-next-line
          if (tool.attributes["layer-id"] == 1) {
            uiToolsList.push(elem.id);
            // eslint-disable-next-line
          } else if (tool.attributes["layer-id"] == 2) {
            intToolsList.push(elem.id);
          } else {
            sourceToolsList.push(elem.id);
          }
        });
      });

      const uiList = await getToolsData(uiToolsList);
      await setUiTools(uiList);
      const uiDefValue = mostFrequent(uiToolsList, uiToolsList?.length);
      await setUiDef(uiDefValue);

      const intList = await getToolsData(intToolsList);
      await setIntTools(intList);
      const intDefValue = mostFrequent(intToolsList, intToolsList?.length);
      await setIntDef(intDefValue);

      const sourceList = await getToolsData(sourceToolsList);
      await setSourceTools(sourceList);
      const sourceDefValue = mostFrequent(
        sourceToolsList,
        sourceToolsList?.length
      );
      await setSourceDef(sourceDefValue);

      const noUseList = await getToolsData(noUseToolsList);
      await setNoUseTools(noUseList);
      await setNoUseDef(noUseList[0].id);
    })();
    // eslint-disable-next-line
  }, [toolsData]);

  async function getToolsData(tools) {
    let uniqArr = [...new Set(tools)];
    let finalData = [];
    let count = {};
    tools?.forEach((e) => (count[e] ? count[e]++ : (count[e] = 1)));
    // eslint-disable-next-line
    uniqArr.map((elem, i) => {
      // eslint-disable-next-line
      let tool = toolsData.filter((tool) => tool.id == elem);
      let freq = count[elem];
      // let percent = ((freq / tools.length) * 100).toFixed(2);

      let newElem = {
        id: tool[0].id,
        name: tool[0].attributes["tool-name"],
        toolDetails: tool[0],
        value: parseFloat(freq),
        colorValue: parseFloat(freq),
      };

      finalData.push(newElem);
    });

    return finalData;
  }

  function mostFrequent(arr, n) {
    var hash = new Map();
    for (var i = 0; i < n; i++) {
      if (hash.has(arr[i])) hash.set(arr[i], hash.get(arr[i]) + 1);
      else hash.set(arr[i], 1);
    }

    var max_count = 0,
      res = -1;
    hash.forEach((value, key) => {
      if (max_count < value) {
        res = key;
        max_count = value;
      }
    });

    return res;
  }

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  return (
    <div className="d-flex flex-column tools-page">
      <div className="tabs-section border-bottom">
        {layers && key ? (
          <>
            <ButtonGroup className="me-2 ms-2" aria-label="First group">
              <Button
                style={{ color: "#8C7500" }}
                className={
                  key === "1" ? "tools-tab-btn uiTab" : "tools-tab-btn"
                }
                onClick={() => {
                  setKey("1");
                  addQueryParam("layerId", "1");
                }}
              >
                UI Tools
              </Button>
              <Button
                style={{ color: "#006C3B" }}
                className={
                  key === "2"
                    ? "tools-tab-btn intTab mx-2"
                    : "tools-tab-btn mx-2"
                }
                onClick={() => {
                  setKey("2");
                  addQueryParam("layerId", "2");
                }}
              >
                Integration Tools
              </Button>
              <Button
                style={{ color: "#00328C" }}
                className={
                  key === "3"
                    ? "tools-tab-btn sourceTab mx-2"
                    : "tools-tab-btn mx-2"
                }
                onClick={() => {
                  setKey("3");
                  addQueryParam("layerId", "3");
                }}
              >
                Source Tools
              </Button>
              <Button
                style={{ color: "#9F9F9F" }}
                className={
                  key === "4" ? "tools-tab-btn noTab" : "tools-tab-btn"
                }
                onClick={() => {
                  setKey("4");
                  addQueryParam("layerId", "4");
                }}
              >
                Not In Focus
              </Button>
            </ButtonGroup>
            <div>
              {key === "1" ? (
                <UiTreemap
                  data={{ tools: uiTools, defValue: uiDef, included: included }}
                />
              ) : key === "2" ? (
                <IntegrationTreemap
                  data={{
                    tools: intTools,
                    defValue: intDef,
                    included: included,
                  }}
                />
              ) : key === "3" ? (
                <SourceTreemap
                  data={{
                    tools: sourceTools,
                    defValue: sourceDef,
                    included: included,
                  }}
                />
              ) : (
                <NotInUseTremap
                  data={{
                    tools: noUseTools,
                    defValue: noUseDef,
                    included: included,
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div style={{ maxWidth: "100%", height: "35rem" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolsTreemap;
