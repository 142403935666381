import ProcessView from "../components/ProcessAdminComponent/ProcessUserPage/ProcessView";

export default function ProcessPage() {
  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <h2 className="fw-bold">Processes </h2>
        </div>
        <ProcessView />
      </div>
    </div>
  );
}
