import "../FavoritesTable/FavoritesTable.scss";
import React, { useState, useEffect, useRef } from "react";
import { getBaseUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { chunk, isEqual } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImageSvgs from "../ImageSvgs";
import {
  FavAddModal,
  EditFavModal,
  DeleteFavModal,
  SubTableAddModal,
  NewFavAddForm,
  NewFolderAddForm,
  NewSubFavAddForm,
} from "../../utils/favoriteUtils";
import {
  nextArrow,
  editIcon,
  deleteIcon,
  linkIcon,
  folderIcon,
  dragIcon,
} from "../../utils/iconUtils";
import { useClient } from "jsonapi-react";
import { Nav, Button, ButtonGroup } from "react-bootstrap";
import ConfirmationBox from "./ConfirmationBox";

const FolderTable = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [stockData, setStockData] = useState();
  const [newStockData, setNewStockData] = useState();
  const [tableCaption, setTableCaption] = useState("");
  const [tableLevel, setTableLevel] = useState("baseLevel");
  const [playerData, setPlayerData] = useState(null);
  const [subFolder, setSubFolder] = useState(null);
  const [saveButton, setSaveButton] = useState(false);
  const saveButtonn = useRef(false);
  const [subSaveButton, setSubSaveButton] = useState(false);
  const [folderFav, setFolderFav] = useState(null);
  const [parentFolder, setParentFolder] = useState(null);
  const subTableData = useRef([]);
  const subTable = useRef(null);
  const [folderLinkData, setFolderLinkData] = useState(null);
  const orderId = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formType, setFormType] = useState(1);
  const [subForm, setSubForm] = useState(false);
  const [active, setActive] = useState("1");

  const client = useClient();

  const tableData = props.data;

  const [tableDrag, setTableDrag] = useState(tableData);

  orderId.current = props.data !== undefined ? props.data.length : null;

  useEffect(() => {
    const param = `${getBaseUrl()}/configurations/home/stock-images.json`;
    async function getResponse() {
      const res = await getData(param);
      setStockData(chunk(res.data.images, 6));
      setNewStockData(chunk(res.data.images, 8));
      await setTableDrag(tableData);
    }
    getResponse();

    // eslint-disable-next-line
  }, [tableData]);

  useEffect(() => {
    let counter = tableData?.length;
    tableData?.forEach((favorite) => {
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });
  });

  const handleCallback = (childData) => {
    setIsFormOpen(childData);
  };

  const handleSubCallback = (childData) => {
    setSubForm(childData);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem("flagCheck") === "true") {
      setIsModalOpen(true);
    } else {
      setTableLevel("baseLevel");
      setCaption();
    }
  };

  const onOkClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    setTableLevel("baseLevel");
    setCaption();
    setSubSaveButton(false);
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", true);
  };

  const ChangeOrder = async (folderList) => {
    let counter = folderList.length;
    setLoading(true);

    folderList.forEach((favorite) => {
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });

    setLoading(false);
    setSaveButton(false);
    sessionStorage.setItem("flagCheck", false);
  };

  const onSaveClick = async () => {
    setLoading(true);
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    setTableLevel("baseLevel");
    setCaption();
    setSubSaveButton(false);
  };
  const SubChangeOrder = async (folderList) => {
    let counter = folderList.length;

    setLoading(true);

    folderList.forEach((favorite) => {
      setLoading(true);
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });
    setLoading(false);
    setSubSaveButton(false);
  };

  const handleDragEnd = (results) => {
    saveButtonn.current = true;
    let tempUser = [...tableDrag];

    let [selectedRow] = tempUser.splice(results.source.index, 1);
    tempUser.splice(results.destination.index, 0, selectedRow);

    if (JSON.stringify(tableData) === JSON.stringify(tempUser)) {
      sessionStorage.setItem("flagCheck", false);
      setSaveButton(false);
    } else {
      sessionStorage.setItem("flagCheck", true);
      setSaveButton(true);
    }
    sessionStorage.setItem("updatedList", JSON.stringify(tempUser));

    setTableDrag(tempUser);
  };

  function SubFolderComp(props) {
    const [editModal, setEditModal] = useState("");
    const [editModalShowComp, setEditModalShowComp] = useState(false);
    setFolderFav(props.folderData.favorites);

    let folderDataArgs = props.folderData;

    const handleSubDragEnd = (results) => {
      let tempUser = [...folderFav];
      let folderName = props.folderData["favorite-name"];
      let counter = tempUser.length;
      let [selectedRow] = tempUser.splice(results.source.index, 1);
      tempUser.splice(results.destination.index, 0, selectedRow);

      if (
        JSON.stringify(props.folderData.favorites) === JSON.stringify(tempUser)
      ) {
        sessionStorage.setItem("flagCheck", false);
        setSubSaveButton(false);
      } else {
        sessionStorage.setItem("flagCheck", true);
        setSubSaveButton(true);
      }

      setLoading(true);

      tempUser.forEach((favorite) => {
        setLoading(true);
        if (favorite.order !== counter) {
          // eslint-disable-next-line
          const resData = client.mutate(["user-favorites", favorite.id], {
            is_folder: favorite["is-folder"],
            order: counter,
          });
        }
        counter--;
      });
      sessionStorage.setItem("updatedList", JSON.stringify(tempUser));

      setFolderFav(tempUser);
      setParentFolder(folderName);
    };

    const tableBody = (folderData) => {
      setFolderLinkData(folderData);
      subTableData.current = (
        <div className="empty-folder">
          <p>Empty folder. Add links to view them here.</p>
        </div>
      );

      let tableHeaders = (
        <>
          <caption
            style={{ color: "#8A00E5" }}
            className="d-flex justify-content-between"
          >
            <div className="my-auto">
              <Nav.Link className="base-btn" onClick={handleClick}>
                Your favorites
              </Nav.Link>
              {nextArrow}
              {tableCaption}
            </div>
            {tableLevel === "baseLevel" ? (
              <>
                <Button
                  className="app-primary-btn"
                  onClick={() => setAddModalShow(true)}
                >
                  Add New +
                </Button>
              </>
            ) : (
              <div>
                {subSaveButton === true ? (
                  <Button
                    className="app-secondary-btn me-2"
                    onClick={() => {
                      SubChangeOrder(folderFav);
                      sessionStorage.setItem("flagCheck", false);
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  className="app-primary-btn"
                  onClick={() => {
                    setSubForm(true);
                  }}
                >
                  Add Link
                </Button>
              </div>
            )}
          </caption>
          <thead>
            <tr>
              <th>{dragIcon}</th>
              <th>Name</th>
              <th>Link</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
        </>
      );

      if (folderData.favorites.length > 0) {
        subTableData.current = folderData.favorites;

        if (folderFav && parentFolder === folderData["favorite-name"]) {
          subTableData.current = folderFav
            .sort((a, b) => parseFloat(b.order) - parseFloat(a.order))
            .map((favorite, index) => (
              <Draggable
                key={favorite["id"]}
                draggableId={favorite["id"]}
                index={index}
              >
                {(provided) => (
                  <tr
                    className="my-table"
                    key={favorite.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <td {...provided.dragHandleProps}>
                      <p className="my-2">{dragIcon}</p>
                    </td>
                    <td className="ellipsis">
                      <span>
                        {favorite["favorite-image"] === null ? (
                          <img
                            className="fav-table-img"
                            src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                            alt="fav-img"
                          />
                        ) : (
                          <img
                            className="fav-table-img"
                            src={`${getBaseUrl()}${favorite["favorite-image"]}`}
                            alt="fav-img"
                          />
                        )}
                        &nbsp;&nbsp;
                        {linkIcon}
                        &nbsp;
                        {favorite["favorite-name"]}
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={favorite["favorite-redirect-url"]}
                    >
                      <span className="my-1">
                        <ImageSvgs
                          className="img m-2 copy-icon"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              favorite["favorite-redirect-url"]
                            )
                          }
                          name="copy"
                        />
                        <a
                          className="mx-1"
                          href={favorite["favorite-redirect-url"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {favorite["favorite-redirect-url"]}
                        </a>
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={favorite["description"]}
                    >
                      {favorite["description"] === "" ? (
                        <span>-</span>
                      ) : (
                        <span>{favorite["description"]}</span>
                      )}
                    </td>
                    <td>
                      <button
                        className="table-btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        onClick={() => {
                          setEditModal(favorite);
                          setEditModalShowComp(true);
                        }}
                      >
                        {editIcon}
                      </button>
                      <button
                        className="table-btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove Link"
                        onClick={() => {
                          setDeleteModalShow(true);
                          setDeleteModal(favorite);
                        }}
                      >
                        {" "}
                        {deleteIcon}
                      </button>
                    </td>
                  </tr>
                )}
              </Draggable>
            ));
        } else {
          subTableData.current = props.folderData.favorites
            .sort((a, b) => parseFloat(b.order) - parseFloat(a.order))
            .map((favorite, index) => (
              <Draggable
                key={favorite["id"]}
                draggableId={favorite["id"]}
                index={index}
              >
                {(provided) => (
                  <tr
                    className="my-table"
                    key={favorite.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <td {...provided.dragHandleProps}>
                      <p className="my-2">{dragIcon}</p>
                    </td>
                    <td className="ellipsis">
                      <span>
                        {favorite["favorite-image"] === null ? (
                          <img
                            className="fav-table-img"
                            src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                            alt="fav-img"
                          />
                        ) : (
                          <img
                            className="fav-table-img"
                            src={`${getBaseUrl()}${favorite["favorite-image"]}`}
                            alt="fav-img"
                          />
                        )}
                        &nbsp;&nbsp;
                        {linkIcon}
                        &nbsp;
                        {favorite["favorite-name"]}
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={favorite["favorite-redirect-url"]}
                    >
                      <span span className="mt-2">
                        <ImageSvgs
                          className="img m-2 copy-icon"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              favorite["favorite-redirect-url"]
                            )
                          }
                          name="copy"
                        />
                        <a
                          className="mx-1"
                          href={favorite["favorite-redirect-url"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {favorite["favorite-redirect-url"]}
                        </a>
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={favorite["description"]}
                    >
                      {favorite["description"] === "" ? (
                        <span className="mt-2">-</span>
                      ) : (
                        <span className="mt-2">{favorite["description"]}</span>
                      )}
                    </td>
                    <td>
                      <button
                        className="table-btn mt-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        onClick={() => {
                          setEditModal(favorite);
                          setEditModalShowComp(true);
                        }}
                      >
                        {editIcon}
                      </button>
                      <button
                        className="table-btn mt-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove Link"
                        onClick={() => {
                          setDeleteModalShow(true);
                          setDeleteModal(favorite);
                        }}
                      >
                        {" "}
                        {deleteIcon}
                      </button>
                    </td>
                  </tr>
                )}
              </Draggable>
            ));
        }
      }

      return (
        <DragDropContext onDragEnd={(results) => handleSubDragEnd(results)}>
          {subForm ? (
            <>
              <div style={{ fontSize: "20px", color: "#8A00E5" }}>
                <button
                  className="form-fav-btn my-1"
                  onClick={() => {
                    setSubForm(false);
                    setTableLevel("baseLevel");
                    setCaption();
                  }}
                >
                  {`Your favorites`}
                </button>
                {nextArrow}
                <button
                  className="form-fav-btn my-1"
                  onClick={() => {
                    setSubForm(false);
                  }}
                >
                  {subFolder}
                </button>
                {nextArrow}Add link
              </div>
              <NewSubFavAddForm
                data={{
                  stockData: newStockData,
                  favData: folderLinkData,
                }}
                parentCallback={handleSubCallback}
              />{" "}
            </>
          ) : (
            <>
              <table className="my-table">
                {tableHeaders}

                <Droppable droppableId="tbody">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {subTableData.current}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </>
          )}
        </DragDropContext>
      );
    };

    return (
      <div>
        {tableBody(folderDataArgs)}
        {modalShow === true ? (
          <SubTableAddModal
            data={{
              stockData: stockData,
              favData: folderLinkData,
            }}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : (
          ""
        )}
        {editModalShowComp === true ? (
          <EditFavModal
            data={{ editModal, stockData, tableData }}
            show={editModalShowComp}
            onHide={() => setEditModalShowComp(false)}
          />
        ) : (
          ""
        )}
        {deleteModalShow === true ? (
          <DeleteFavModal
            data={deleteModal}
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
          />
        ) : (
          ""
        )}
        <ConfirmationBox
          showDialog={isModalOpen}
          cancelNavigation={onCancelClick}
          confirmNavigation={onOkClick}
          saveNavigation={onSaveClick}
        />
      </div>
    );
  }

  const setCaption = (name) => {
    if (name) {
      setTableCaption(name);
    } else {
      setTableCaption("");
    }
  };

  if (tableLevel === "folderLevel") {
    let oldFavData = tableData.find((name) => {
      if (name["favorite-name"] === subFolder) {
        return name;
      }
      return null;
    });
    if (isEqual(oldFavData, playerData)) {
      return <SubFolderComp folderData={playerData} />;
    } else {
      return <SubFolderComp folderData={oldFavData} />;
    }
  }

  const getRows = (player, index) => {
    let rows = [];

    const firstRow = (
      <Draggable key={player["id"]} draggableId={player["id"]} index={index}>
        {(provided) => (
          <tr ref={provided.innerRef} {...provided.draggableProps}>
            <td {...provided.dragHandleProps}>
              <p className="my-2">{dragIcon}</p>
            </td>
            {player["is-folder"] ? (
              <td
                className="ellipsis"
                data-toggle="tooltip"
                data-placement="top"
                title="Open folder"
                onClick={() => {
                  setTableLevel("folderLevel");
                  setCaption(player["favorite-name"]);
                  setSubFolder(player["favorite-name"]);
                  setPlayerData(player);
                  setFolderLinkData(player);
                  setSaveButton(false);
                }}
              >
                <span className="d-flex">
                  {player["favorite-image"] === null ? (
                    <img
                      className="mb-2 fav-table-img"
                      src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                      alt="fav-img"
                    />
                  ) : (
                    <img
                      className="fav-table-img"
                      src={`${getBaseUrl()}${player["favorite-image"]}`}
                      alt="fav-img"
                    />
                  )}
                  <p className="folder-name my-2 mx-2">
                    {folderIcon}
                    &nbsp;
                    {player["favorite-name"]}
                  </p>
                </span>
              </td>
            ) : (
              <td className="ellipsis d-flex ">
                <span className="d-flex">
                  {player["favorite-image"] === null ? (
                    <img
                      className="mb-2 fav-table-img"
                      src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                      alt="fav-img"
                    />
                  ) : (
                    <img
                      className="fav-table-img"
                      src={`${getBaseUrl()}${player["favorite-image"]}`}
                      alt="fav-img"
                    />
                  )}
                  <p className="my-2 mx-2">
                    {linkIcon}
                    &nbsp;
                    {player["favorite-name"]}
                  </p>
                </span>
              </td>
            )}
            {player["is-folder"] ? (
              <td>
                <p className="my-2">&nbsp;-</p>
              </td>
            ) : (
              <td
                className="ellipsis"
                data-toggle="tooltip"
                data-placement="top"
                title={player["favorite-redirect-url"]}
              >
                <span className="my-2">
                  &nbsp;
                  <ImageSvgs
                    className="img m-2 copy-icon"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        player["favorite-redirect-url"]
                      )
                    }
                    name="copy"
                  />
                  &nbsp;
                  <a
                    href={player["favorite-redirect-url"]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {player["favorite-redirect-url"]}
                  </a>
                </span>
              </td>
            )}
            <td
              className="ellipsis"
              data-toggle="tooltip"
              data-placement="top"
              title={player["description"]}
            >
              {player["description"] === "" ? (
                <span className="my-2">-</span>
              ) : (
                <span className="my-2">{player["description"]}</span>
              )}
            </td>
            <td>
              <button
                className="table-btn my-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                onClick={() => {
                  setEditModalShow(true);
                  setEditModal(player);
                }}
              >
                {editIcon}
              </button>
              <button
                className="table-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => {
                  setDeleteModalShow(true);
                  setDeleteModal(player);
                }}
              >
                {deleteIcon}
              </button>
            </td>
          </tr>
        )}
      </Draggable>
    );

    rows.push(firstRow);

    return rows;
  };

  const getPlayerTable = (players) => {
    const playerRows = players.map((player, index) => {
      return getRows(player, index);
    });

    return (
      <>
        <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
          {isFormOpen ? (
            <>
              <div style={{ fontSize: "20px", color: "#8A00E5" }}>
                <button
                  className="form-fav-btn my-1"
                  onClick={() => {
                    setIsFormOpen(false);
                    setTableLevel("baseLevel");
                  }}
                >
                  {`Your favorites`}
                </button>
                {nextArrow}Add new
              </div>

              <ButtonGroup className="my-2" aria-label="First group">
                <h5 className="my-1">Choose:</h5>
                &nbsp;&nbsp;
                <Button
                  className={
                    active === "1"
                      ? "form-select-btn form-select-activeBtn"
                      : "form-select-btn"
                  }
                  onClick={() => {
                    setActive("1");
                    setFormType(1);
                  }}
                >
                  Link
                </Button>
                <Button
                  className={
                    active === "2"
                      ? "form-select-btn form-select-activeBtn"
                      : "form-select-btn"
                  }
                  onClick={() => {
                    setActive("2");
                    setFormType(2);
                  }}
                >
                  Folder
                </Button>
              </ButtonGroup>
              {formType === 1 ? (
                <NewFavAddForm
                  data={{
                    stockData: newStockData,
                    favData: tableData,
                    orderNumber: orderId.current,
                  }}
                  parentCallback={handleCallback}
                />
              ) : (
                <NewFolderAddForm
                  data={{
                    stockData: newStockData,
                    favData: tableData,
                    orderNumber: orderId.current,
                  }}
                  parentCallback={handleCallback}
                />
              )}
            </>
          ) : (
            <table className="my-table">
              <caption
                style={{ color: "#8A00E5" }}
                className="d-flex justify-content-between"
              >
                <div className="my-auto">
                  <Nav.Link
                    className="base-btn"
                    onClick={() => {
                      setTableLevel("baseLevel");
                      setCaption();
                    }}
                  >
                    Your favorites
                  </Nav.Link>

                  {nextArrow}
                  {tableCaption}
                </div>
                {tableLevel === "baseLevel" ? (
                  <div>
                    {saveButton === true ? (
                      <button
                        className="app-secondary-btn me-2"
                        onClick={() => {
                          ChangeOrder(tableDrag);
                        }}
                      >
                        {loading ? "Saving..." : "Save"}
                      </button>
                    ) : (
                      ""
                    )}
                    <Button
                      className="app-primary-btn"
                      onClick={() => {
                        setIsFormOpen(true);
                      }}
                    >
                      Add New +
                    </Button>
                  </div>
                ) : (
                  <>
                    {nextArrow}
                    <Button
                      className="app-primary-btn"
                      onClick={() => setModalShow(true)}
                    >
                      Add Link
                    </Button>
                  </>
                )}
              </caption>
              <>
                <thead>
                  <tr>
                    <th>{dragIcon}</th>
                    <th>Name</th>
                    <th>Link</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <Droppable droppableId="tbody">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {tableLevel === "baseLevel" ? (
                        <>{playerRows}</>
                      ) : (
                        <>{subTable.current}</>
                      )}
                      {addModalShow === true ? (
                        <FavAddModal
                          data={{
                            stockData: stockData,
                            favData: tableData,
                            orderNumber: orderId.current,
                          }}
                          show={addModalShow}
                          onHide={() => setAddModalShow(false)}
                        />
                      ) : (
                        ""
                      )}
                      {modalShow === true ? (
                        <SubTableAddModal
                          data={{
                            stockData: stockData,
                            favData: folderLinkData,
                          }}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      ) : (
                        ""
                      )}
                      {deleteModalShow === true ? (
                        <DeleteFavModal
                          data={deleteModal}
                          show={deleteModalShow}
                          onHide={() => setDeleteModalShow(false)}
                        />
                      ) : (
                        ""
                      )}
                      {editModalShow === true ? (
                        <EditFavModal
                          data={{ editModal, stockData, tableData }}
                          show={editModalShow}
                          onHide={() => setEditModalShow(false)}
                        />
                      ) : (
                        ""
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </>
            </table>
          )}
        </DragDropContext>
      </>
    );
  };

  return <div>{getPlayerTable(tableDrag)}</div>;
};

export default FolderTable;
