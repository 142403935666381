import React from "react";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";

import "../CommonComponents/CustomizableHomePage.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function FavPlaceholder(props) {
  const deleteCard = props.deleteCard;
  const setWidth = props.setFavFeed;
  const activeBtn = props.favFeed;
  const handleHalfWidth = props.handleHalfWidth;
  const handleFullWidth = props.handleFullWidth;
  const widthData = props.data;
  // const deleteAction = props.enableDelete;

  return (
    <div className="d-flex flex-row fav-placeholder-div">
      <div
        className="d-flex flex-column "
        style={{ width: "-webkit-fill-available" }}
      >
        <div className="placeholder-title pt-2">{props.title}</div>

        <Container className="pt-3 ps-0 component-container ">
          <Row className="component-layout">
            <Col xs={2} md={2} xl={2} className="me-1">
              <div className="first-div mb-2 "></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
            <Col xs={2} md={2} xl={2} className="me-1">
              <div className="first-div mb-2"></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
            <Col xs={2} md={2} xl={2} className="me-1">
              <div className="first-div mb-2"></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
            <Col xs={2} md={2} xl={2} className="me-1">
              <div className="first-div mb-2"></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
            <Col xs={2} md={2} xl={2} className="me-1">
              <div className="first-div mb-2"></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
            <Col xs={2} md={2} xl={2}>
              <div className="first-div mb-2"></div>
              <div className="middle-div mb-2"></div>
              <div className="last-div"></div>
            </Col>
          </Row>
        </Container>
        <div className="d-flex flex-row">
          {activeBtn === true || widthData?.w === 1 ? (
            <Button
              className="common-save-btn"
              onClick={() => {
                setWidth(false);
                handleFullWidth();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              L
            </Button>
          ) : (
            <Button
              className="common-save-btn"
              onClick={() => {
                setWidth(true);
                handleHalfWidth();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              S
            </Button>
          )}
        </div>
      </div>
      <div className="delete-div d-flex">
        <Button
          className="widget-delete-btn"
          onClick={() => deleteCard()}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ImageSvgs name="widgets_delete" />
        </Button>
      </div>
    </div>
  );
}
