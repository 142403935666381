import React from "react";
import { useEffect, useState } from "react";
import { getBaseUrl } from "../config/environment";
import { getData } from "../services/apiService";
import BusinessControlling from "../components/OrgChart/OrgChartComp";
import IconExpansionTreeView from "../components/OrgChart/OrganizationUserScreen";

const OrganizationPage = () => {
  let flag = window.location.href.includes("v2");
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getBaseUrl()}/configurations/organizationMap/organizationPage.json`
      );
      await setData(res.data);
    })();
  }, []);

  return (
    <>
      <div className="main-internal-page mx-auto mt-4">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <h2 className="fw-bold">Organizations</h2>
        </div>

        <div className="mt-3">
          {flag ? (
            <BusinessControlling source={data} />
          ) : (
            <IconExpansionTreeView />
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationPage;
