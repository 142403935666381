import React, { useState } from "react";
import InfoComp from "./InfoComp";
import FlowWithProvider from "./Diagram";
import { ButtonGroup, Button } from "react-bootstrap";
import { getBaseUrl } from "../../config/environment";
import ImageSvgs from "../ImageSvgs";

const AppSubComp = ({ data, included }) => {
  const [key, setKey] = useState("1");

  return (
    <div className="d-flex flex-column">
      <div className="tabs-section border-bottom">
        <ButtonGroup className="me-2" aria-label="First group">
          <Button
            className={key === "1" ? "tools-tab-btn actTab" : "tools-tab-btn"}
            onClick={() => {
              setKey("1");
            }}
          >
            Tool Map
          </Button>
          <Button
            className={
              key === "2" ? "tools-tab-btn actTab mx-2" : "tools-tab-btn mx-2"
            }
            onClick={() => {
              setKey("2");
            }}
          >
            Access Links & Connections
          </Button>
          <Button
            className={
              key === "3" ? "tools-tab-btn actTab mx-2" : "tools-tab-btn mx-2"
            }
            onClick={() => {
              setKey("3");
            }}
          >
            Preview
          </Button>
        </ButtonGroup>
      </div>
      <div>
        {key === "1" ? (
          <>
            <FlowWithProvider appdata={data} />
          </>
        ) : key === "2" ? (
          <InfoComp appdata={data} included={included} />
        ) : key === "3" ? (
          <div>
            {data?.attributes["app-preview-image"] ? (
              <img
                className="app-preview-image"
                src={`${getBaseUrl()}${data?.attributes["app-preview-image"]}`}
                alt="app"
              />
            ) : (
              <div
                className="d-flex justify-content-center border mt-3 "
                style={{ height: "485px" }}
              >
                <div className="my-auto">
                  <ImageSvgs name="no_preview_img" />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AppSubComp;
