import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationBox = ({
  showDialog,
  confirmNavigation,
  cancelNavigation,
  saveNavigation,
}) => {
  return (
    <Modal show={showDialog}>
      <Modal.Header>
        <Modal.Title>Warning!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>
          The sequence of your favorites has been changed. Do you want to save
          these changes?
        </b>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="app-primary-btn"
          onClick={saveNavigation}
          data-toggle="tooltip"
          data-placement="top"
          title="Save changes and navigate away"
        >
          Save
        </Button>
        <Button
          className="app-secondary-btn"
          onClick={confirmNavigation}
          data-toggle="tooltip"
          data-placement="top"
          title="Discard changes and navigate away"
        >
          Discard
        </Button>
        <Button
          className="app-secondary-btn"
          onClick={cancelNavigation}
          data-toggle="tooltip"
          data-placement="top"
          title="Cancel navigation"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationBox;
