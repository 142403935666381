import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Modal, Button, Stack, Carousel } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import ExpertsComponent from "./ExpertsComponent";
import { Link } from "react-router-dom";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { chunk } from "lodash";

const ConnectionsComponent = (props) => {
  const url = new URL(window.location.href);
  const appId = url.searchParams.get("appId");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const data = props?.data;

  const [layerId, setLayerId] = useState(null);
  useEffect(() => {
    (async () => {
      if (appId) {
        setLayerId(
          data?.attributes.nodes.data.length > 0
            ? data?.attributes.nodes.data.filter(
                (app) =>
                  app.type === "nodes" &&
                  app.attributes["application-id"].toString() ===
                    url.searchParams.get("appId").toString()
              )[0].attributes["layer-id"]
            : "4"
        );
      } else {
        setLayerId(
          data?.attributes.nodes?.data.length > 0
            ? data?.attributes.nodes.data[0].attributes["layer-id"].toString()
            : "4"
        );
      }
    })();
    // eslint-disable-next-line
  }, []);

  function TopicsModal(props) {
    const [topicData, setTopicData] = useState();

    useEffect(() => {
      (async () => {
        if (data.type === "topics") {
          const topic = await getData(
            `${getHostUrl()}topics/${data.id}?include=owners`
          );

          setTopicData(
            chunk(
              topic.data.included
                .sort((a, b) =>
                  a.attributes.employee.data.attributes[
                    "preferred-name"
                  ].localeCompare(
                    b.attributes.employee.data.attributes["preferred-name"]
                  )
                )
                .sort(
                  (a, b) =>
                    Number(a.attributes["is-deputy"]) -
                    Number(b.attributes["is-deputy"])
                ),
              4
            )
          );
        }
      })();
    }, []);

    return (
      <div className="topics-modal" style={{ backgroundColor: "red" }}>
        <Modal
          {...props}
          size="lg"
          dialogClassName="topics-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-2 ms-2">
              <b>Description: </b>
              {description}
            </p>
            {topicData ? (
              <div className="experts-section mt-3 ms-2">
                <p className="fw-bold">Owners & Deputies of {title} are:-</p>
                <div className="d-flex flex-row justify-content-between">
                  {topicData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {topicData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100 align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ExpertsComponent
                                      key={j}
                                      data={elem.attributes.employee.data}
                                      owner={!elem.attributes["is-deputy"]}
                                    />
                                    <div className="expert-divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ExpertsComponent
                                    key={j}
                                    data={elem.attributes.employee.data}
                                    owner={!elem.attributes["is-deputy"]}
                                  />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <div style={{ maxWidth: "100%", height: "5rem" }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button id="common-favbutton" onClick={props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  const title =
    data?.type === "application"
      ? data.attributes["app-title"]
      : data?.type === "community"
      ? data.attributes["community-name"]
      : data?.type === "training"
      ? data.attributes["training-name"]
      : data?.type === "tool"
      ? data.attributes["tool-name"]
      : data?.type === "process"
      ? data.attributes["title"]
      : data?.type === "topics"
      ? data.attributes["topics-name"]
      : data?.type === "organization"
      ? data.attributes["org-title"]
      : "Title";

  const description =
    data?.type === "application"
      ? data.attributes["app-description"]
      : data?.type === "community"
      ? data.attributes["community-desc"]
      : data?.type === "training"
      ? data.attributes["training-description"]
      : data?.type === "tool"
      ? data.attributes["tool-description"]
      : data?.type === "process"
      ? data.attributes["desc"]
      : data?.type === "topics"
      ? data.attributes["topics-description"]
      : data.type === "organization"
      ? data.attributes?.description
      : "Description";

  const link =
    data?.type === "application"
      ? `/landscape/applications?orgId=${data?.attributes["app-org"]?.appOrg[0].id}&appId=${data?.id}`
      : data?.type === "community"
      ? `/landscape/communities?page=communities${data.id}`
      : data?.type === "training"
      ? `/landscape/trainings?id=${data.id}`
      : data?.type === "tool"
      ? `/landscape/tools?layerId=${layerId && layerId}&toolId=${data.id}`
      : data?.type === "process"
      ? `/landscape/process?process_id=${data.id}&view=cardView`
      : data?.type === "organization"
      ? `/landscape/organization?id=${data.id}`
      : "";

  return (
    <>
      {data.type === "topics" ? (
        <div
          className="connection-comp"
          onClick={() =>
            data.type === "topics" ? setShowModal(true) : navigate(link)
          }
        >
          <h6 data-toggle="tooltip" data-placement="top" title={title}>
            {title}
          </h6>
          <p>{description}</p>
        </div>
      ) : (
        <div className="connection-comp">
          <Link
            // className="app-table-name"
            style={{ textDecoration: "none" }}
            to={link}
            state={{ prevPath: window.location.href }}
          >
            <h6 data-toggle="tooltip" data-placement="top" title={title}>
              {title}
            </h6>
            <p>{description}</p>
          </Link>
        </div>
      )}
      {showModal ? (
        <TopicsModal show={showModal} onHide={() => setShowModal(false)} />
      ) : null}
    </>
  );
};

export default ConnectionsComponent;
