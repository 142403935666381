import React from "react";
import { Handle, Position } from "@xyflow/react";
import "./CustomNode.scss";
const CustomNode = ({ data, isConnectable }) => {
  return (
    <div className="node-box custom-node">
      <div className="node-up">
        <div className="nameText" title={data.name}>
          {data.name}
        </div>
      </div>
      <div className="node-down">
        <p className="processDescText m-0" title={data.description}>
          {data.description}
        </p>
      </div>

      <Handle
        type="target"
        position={Position.Top}
        id="e"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="f"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Right}
        id="g"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="h"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Top}
        id="b"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="c"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="d"
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default CustomNode;
