import { useState, useEffect } from "react";
import "../assets/scss/common.scss";
import { getHostUrl } from "../config/environment";
import { getData } from "../services/apiService";
import CommunityTable from "../components/CommunitiesAdminPages/CommunityTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageSvgs from "../components/ImageSvgs";
import Loader from "../components/Loader/Loader";
import "../components/CommunitiesAdminPages/CommunitiesAdminScreen.scss";

export default function ManageCommunities() {
  const [communityData, setCommunityData] = useState(null);
  const [getRes, setGetRes] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const [paramValue] = useSearchParams();
  // eslint-disable-next-line
  const [communityPageLevel, setCommunityPageLevel] = useState(
    paramValue ? paramValue.get("page") : "communities"
  );
  const navigate = useNavigate();

  useEffect(() => {
    const param = `${getHostUrl()}community?include=heads,apps,&use_pagination=false`;

    async function getResponse() {
      const adRes = await getData(
        `${getHostUrl()}user/check/${
          JSON.parse(sessionStorage.getItem("userData")).id
        }`
      );
      await setIsAdmin(adRes.data);
      if (adRes.data) {
        try {
          const res = await getData(param);

          if (res) {
            setGetRes(true);
            setCommunityData(res);
          }
          // setIsAdmin(true);
        } catch (err) {
          if (err) {
            setGetRes(false);
            if (err.response.status === 401) {
              setGetRes(true);
              // setIsAdmin(false);
            }
          }
        }
      }
    }
    getResponse();
  }, []);

  return (
    <div className="main-page-margin">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <div className="main-manage">
              <>
                {getRes ? (
                  <div>
                    {
                      <CommunityTable
                        communityData={communityData && communityData}
                        pageValue={communityPageLevel && communityPageLevel}
                      />
                    }
                  </div>
                ) : (
                  <div style={{ maxWidth: "100%", height: "100vh" }}>
                    <Loader />
                  </div>
                )}
              </>
            </div>
          ) : (
            <div
              className="non-admin d-flex justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="my-auto">
                <span className="mx-auto d-flex justify-content-center">
                  <ImageSvgs name="non_admin" />
                </span>
                <h1 className="text-danger d-flex justify-content-center">
                  Unauthorized user!
                </h1>
                <h4 className="mx-auto d-flex justify-content-center">
                  You do not have admin access rights.
                </h4>
                <button
                  className="d-flex mx-auto"
                  id="common-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}
