import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import negArrow from "../../assets/images/negArrow.svg";
import posArrow from "../../assets/images/posArrow.svg";
import stocksArrow from "../../assets/images/stocksArrow.svg";
import "./MainStocks.scss";

const InfoComp = ({ setCurrentOrg, orgData }) => {
  const lastData =
    orgData?.data[0].data.attributes["last-day-records"].data?.attributes;
  const percentData = (
    <span
      className={
        lastData?.percentage > 0
          ? "d-flex justify-content-center pos-percent"
          : "d-flex justify-content-center neg-percent"
      }
    >
      <p className="mb-0">{lastData?.percentage}%</p>
      <img
        className="my-auto ms-1"
        src={lastData?.percentage > 0 ? posArrow : negArrow}
        alt="stocksArrow"
      />
    </span>
  );
  const [activeOrg, setActiveOrg] = useState("2");
  const title = (
    <span className="fs-4">
      {activeOrg === "1"
        ? "Siemens AG"
        : activeOrg === "2"
        ? "Siemens Energy"
        : "Siemens Healthineers"}
      <img className="ms-2 mb-1 my-auto" src={stocksArrow} alt="stocksArrow" />
    </span>
  );

  return (
    <div className="d-flex flex-column py-3 px-4" style={{ height: "26rem" }}>
      <div className="d-flex w-100 h-25 justify-content-between border-bottom">
        <span className="mb-2">
          <DropdownButton className="stocks-dropbtn" title={title}>
            <Dropdown.Item
              onClick={() => {
                setActiveOrg("1");
                setCurrentOrg("1");
              }}
            >
              Siemens AG
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setActiveOrg("2");
                setCurrentOrg("2");
              }}
            >
              Siemens Energy
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setActiveOrg("3");
                setCurrentOrg("3");
              }}
            >
              Siemens Healthineers
            </Dropdown.Item>
          </DropdownButton>
        </span>
        <div className="d-flex flex-column me-1 mb-2">
          <p
            className="mb-0"
            style={{ fontSize: "20px", fontWeight: "bold", color: "#8A00E5" }}
          >
            EUR {lastData?.close}
          </p>
          <>{percentData}</>
        </div>
      </div>{" "}
      <div className="w-100 h-75 my-3">
        <Row xs={1} md={3} className="g-2">
          <Col>
            <div
              className="d-flex flex-column my-2  text-start"
              style={{ height: "3.5rem", borderRight: "2px solid #dee2e6" }}
            >
              <span className="text-muted">Previous Close</span>
              <span className="fw-bold fs-5">EUR {lastData?.close}</span>
            </div>
          </Col>
          <Col>
            {" "}
            <div
              className="d-flex flex-column my-2  text-center"
              style={{ height: "3.5rem", borderRight: "2px solid #dee2e6" }}
            >
              <span className="text-muted">High</span>
              <span className="fw-bold fs-5">EUR {lastData?.high}</span>
            </div>
          </Col>
          <Col>
            {" "}
            <div
              className="d-flex flex-column my-2 text-end"
              style={{ height: "3.5rem" }}
            >
              <span className="text-muted">Volume</span>
              <span className="fw-bold fs-5">{lastData?.volume}</span>
            </div>
          </Col>
          <Col>
            {" "}
            <div
              className="d-flex flex-column my-2  text-start"
              style={{ height: "3.5rem", borderRight: "2px solid #dee2e6" }}
            >
              <span className="text-muted">Open</span>
              <span className="fw-bold fs-5">EUR {lastData?.open}</span>
            </div>
          </Col>
          <Col>
            {" "}
            <div
              className="d-flex flex-column my-2  text-center"
              style={{ height: "3.5rem", borderRight: "2px solid #dee2e6" }}
            >
              <span className="text-muted">Low</span>
              <span className="fw-bold fs-5">EUR {lastData?.low}</span>
            </div>
          </Col>
          <Col>
            {" "}
            <div
              className="d-flex flex-column my-2  text-end"
              style={{ height: "3.5rem" }}
            >
              <span className="text-muted">Dividends</span>
              <span className="fw-bold fs-5">{lastData?.dividends}</span>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex flex-column my-2 text-start"
              style={{ height: "3.5rem", borderRight: "2px solid #dee2e6" }}
            >
              <span className="text-muted">Last Updated</span>
              <span className="fw-bold fs-5">
                {new Date(lastData?.schedule).toLocaleDateString("en-GB")}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InfoComp;
