import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CommunityChannelModal = (props) => {
  const setCommunityLinks = props?.setCommunityLinks;
  const communityLinks = props.communityLinks;
  const setChannelLinkRes = props.setChannelLinkRes;

  const [formValues, setFormValues] = useState(communityLinks);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setCommunityLinks(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "", link: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Community Channel Links
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="trainings-links-modal">
        {formValues?.length > 0 ? (
          <Form>
            {formValues?.map((elem, index) => {
              return (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label className="w-100">
                      <span className="w-100 d-flex justify-content-between">
                        <p className="mb-0">
                          {index + 1}.Community Channel Name
                        </p>

                        <p
                          className="app-tertiary-btn"
                          onClick={() => {
                            removeFormFields(index);
                          }}
                        >
                          Remove
                        </p>
                      </span>
                    </Form.Label>
                    <div className="app-primary-input-field">
                      <Form.Control
                        placeholder="Enter Platform Name"
                        name="name"
                        value={elem.name}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      Required*
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Link</Form.Label>
                    <div className="app-primary-input-field">
                      <Form.Control
                        required
                        placeholder="Enter Platform Link"
                        name="link"
                        value={elem.link}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Required*
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              );
            })}
          </Form>
        ) : (
          <p className="mb-0 fs-5">Add Community channels here.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            <Button
              className="app-primary-btn"
              onClick={() => {
                addFormFields();
              }}
            >
              Add
            </Button>
          </div>
          <div>
            <Button className="app-secondary-btn me-2" onClick={props.onHide}>
              Close
            </Button>
            <Button
              className="app-primary-btn"
              onClick={async () => {
                let linksList = formValues?.map((elem) => {
                  return {
                    name: elem.name,
                    link:
                      elem.link.includes("https:") ||
                      elem.link.includes("http:")
                        ? elem.link
                        : `https://${elem.link}`,
                  };
                });
                await setCommunityLinks(linksList);
                setChannelLinkRes(linksList);
                props.onHide();
              }}
            >
              Add Links
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CommunityChannelModal;
