import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResponsivePagination from "react-responsive-pagination";
import { getData } from "../../services/apiService";
import Avatar from "react-avatar";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Loader from "../Loader/Loader";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import ImageSvgs from "../ImageSvgs";
import { useNavigate } from "react-router";

export const EmployeeShowModal = (props) => {
  const communityId = props.communityData?.data?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [empQuery, setEmpQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState("");
  const [employeeData, setEmployeeData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [organData, setOrganData] = useState();
  const [totalPages, setTotalPages] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [finalOrgQuery, setFinalOrgQuery] = useState([]);
  const [copy, setCopy] = useState(false);
  const [copyMail, setCopyMail] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();

  const employeeValues =
    employeeData &&
    employeeData?.data.map((emp) => ({
      id: emp.id.toString(),
      label: emp.attributes["f-name"] + " " + emp.attributes["l-name"],
      email: emp.attributes["email-id"],
      image: emp.attributes["profile-picture"],
      employeeId: emp.attributes["employee-id"],
    }));

  const orgValues = organData?.data?.map((org) => ({
    type: org?.type,
    id: org?.id,
    name: org?.attributes["org-title"],
  }));

  async function getResponse() {
    setShowLoader(true);
    let employeeData;

    if (finalOrgQuery.length === 0) {
      if (empQuery.length === 0) {
        employeeData = `${getHostUrl()}community/${communityId}/members?&page=${currentPage}&per_page=${itemsPerPage}`;
      } else {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&search=${empQuery}&community_id=${communityId}&page=${currentPage}&per_page=${itemsPerPage}`;
      }
    } else {
      let id = finalOrgQuery[0].id;
      if (empQuery?.length === 0 && finalOrgQuery?.length > 0) {
        // eslint-disable-next-line

        employeeData = `${getHostUrl()}employee/search?&page=${currentPage}&community_id=${communityId}&per_page=${itemsPerPage}&org_id=${id}`;
      } else {
        // eslint-disable-next-line
        employeeData = `${getHostUrl()}employee/search?&search=${empQuery}&community_id=${communityId}&page=${currentPage}&per_page=${itemsPerPage}&org_id=${id}`;
      }
    }

    const empData = await getData(employeeData);
    setShowLoader(false);
    let pageCount = empData.data.meta["page-count"];
    let perPage = empData.data.meta["per-page"];
    try {
      if (empData && empQuery.length > 0 && orgQuery?.length > 0) {
        await setEmployeeData(empData.data);
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      } else {
        await setEmployeeData(empData.data);
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      }
    } catch (error) {
      if (error) {
        setEmployeeData([]);
      }
    }
  }

  async function getOrgResponse() {
    const orgData = `${getHostUrl()}organization/search?search=${orgQuery}&community_id=${communityId}&per_page=30`;
    const org = await getData(orgData);

    try {
      if (orgData) {
        await setOrganData(org.data);
      }
    } catch (error) {
      if (error) {
        setOrganData([]);
      }
    }
  }

  async function getOrgEmployeeResponse(value) {
    const empDataOnOrg = `${getHostUrl()}employee/search?org_id=${
      value[0].id
    }&community_id=${communityId}&per_page=30`;
    const empData = await getData(empDataOnOrg);

    try {
      if (empData && value.length > 0) {
        let pageCount = empData.data.meta["page-count"];
        let perPage = empData.data.meta["per-page"];
        setTotalPages(pageCount);
        setItemsPerPage(perPage);
      }
    } catch (error) {
      if (error) {
        setEmployeeData([]);
      }
    }
  }

  useEffect(() => {
    getOrgResponse();
    // eslint-disable-next-line
  }, [orgQuery]);

  useEffect(() => {
    getResponse();
    setCurrentPage(currentPage);
    setTotalPages(totalPages);
    // eslint-disable-next-line
  }, [empQuery, totalPages, currentPage]);

  useEffect(() => {
    getResponse();
    // eslint-disable-next-line
  }, [finalOrgQuery]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleInputChange = (e) => {
    const targetValue = e.target.value;
    if (targetValue) {
      setCurrentPage(1);
      setEmpQuery(targetValue.toLowerCase());
    } else {
      setEmpQuery("");
      setEmployeeData(employeeData);
    }
  };

  const handleOrgChange = (value) => {
    let newValue = value;

    if (value.length) {
      setEmpQuery("");
      setFinalOrgQuery(newValue);
      getOrgEmployeeResponse(newValue);
    } else {
      setFinalOrgQuery(newValue);
    }
  };
  const paginatedItems = employeeValues;

  const renderCheckboxes = (items) => {
    if (items) {
      return (
        <Container className="px-0 pe-0">
          <Row>
            {items &&
              items.map((item) => {
                return (
                  <Col
                    xs="3"
                    xl="3"
                    className="mb-3 employee-name"
                    key={item.id}
                  >
                    <div key={item.id} className="p-1">
                      <span className="d-flex flex-row">
                        <span className="px-1 pt-2 d-flex flex-row">
                          {item.image ? (
                            <img
                              className="my-auto"
                              src={`${getBaseUrl()}${item.image}`}
                              style={{
                                height: "2rem",
                                width: "2rem",
                                border: "1.5px solid black",
                                borderRadius: "7rem",
                                objectFit: "contain",
                              }}
                              alt="User Img"
                            />
                          ) : (
                            <Avatar
                              name={item.label}
                              size="32"
                              className="me-1"
                              round={true}
                            />
                          )}
                        </span>
                        <div className="px-0 d-flex flex-column custom-name">
                          <p className="pr-2 pt-2 mb-0 me-3 custom-name-font">
                            {item.label}
                          </p>
                        </div>

                        <div className="pt-2">
                          <span className="d-flex justify-content-between">
                            <span>
                              <button
                                className=" bg-transparent border-0"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={!copy ? "Copy Email" : "Copied"}
                                disabled={copy}
                                onClick={() => {
                                  navigator.clipboard.writeText(item?.email);
                                  setCopy(true);
                                  setId(item.id);
                                  setTimeout(() => {
                                    setCopy(false);
                                    setId(null);
                                  }, 300);
                                }}
                              >
                                {id !== item?.id ? (
                                  <>
                                    <ImageSvgs name="expertCopy" />
                                  </>
                                ) : (
                                  <>
                                    <ImageSvgs name="tickMark" />
                                  </>
                                )}
                              </button>
                            </span>
                            <span>
                              <button
                                className="ms-2 bg-transparent border-0"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={!copyMail ? "Mail to" : "Mail to:"}
                                disabled={copyMail}
                                onClick={() => {
                                  navigator.clipboard.writeText(item?.email);
                                  setCopyMail(true);
                                }}
                              >
                                {item?.id && (
                                  <>
                                    <a href={`mailto:${item?.email}`}>
                                      <ImageSvgs name="expertMail" />
                                    </a>
                                  </>
                                )}
                              </button>
                            </span>

                            <span>
                              <button
                                className="bg-transparent border-0"
                                onClick={() => {
                                  navigate(
                                    `/employee_relation?id=${item?.employeeId}`
                                  );
                                }}
                              >
                                <ImageSvgs name="relationIcon" />
                              </button>
                            </span>
                          </span>
                        </div>
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      );
    } else {
      return <h1> No Match Found! </h1>;
    }
  };

  const setClose = props?.handleClose;

  return (
    <>
      <Modal
        show={props.show}
        onHide={setClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="employee-show-modal my-auto"
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <>
            <div className="d-flex pb-3 ">
              <div className="app-primary-input-field d-flex w-100">
                <div className="w-75">
                  <input
                    className="w-75"
                    type="search"
                    value={empQuery}
                    placeholder="Search by name or email"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="app-primary-dropdown"
                  style={{ width: "16.5rem" }}
                >
                  <Typeahead
                    className="ms-4 w-25"
                    labelKey="name"
                    placeholder="Search by Org Code"
                    id="typeAhead"
                    clearButton
                    onChange={handleOrgChange}
                    emptyLabel={
                      <div>
                        {orgQuery?.length < 1
                          ? "Keep typing..."
                          : orgQuery?.length >= 1 && showLoader
                          ? "Loading..."
                          : orgQuery?.length >= 1
                          ? "No matches found!"
                          : "Loading..."}
                      </div>
                    }
                    onInputChange={(e) => {
                      const newInput = e;
                      setOrgQuery(newInput.toUpperCase());
                      // handleOrgQueryChange(newInput);
                    }}
                    options={orgValues && orgValues}
                  />
                </div>
              </div>
            </div>
            {!showLoader ? (
              <div className="pb-3 employee-div pt-3">
                {renderCheckboxes(paginatedItems)}
              </div>
            ) : (
              <div style={{ maxWidth: "100%", height: "30rem" }}>
                <Loader />
              </div>
            )}
            <div className="d-flex">
              <div className="w-100 mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={handlePageChange}
                  extraClassName="pagination-component"
                />
              </div>
              <Button
                onClick={() => {
                  // eslint-disable-next-line
                  props.handleClose();
                }}
                className="app-secondary-btn mt-2"
              >
                Close
              </Button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
