import { useState, useEffect } from "react";
import { getHostUrl } from "../../config/environment";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { chunk } from "lodash";
import { getData } from "../../services/apiService";
import ExpertsComponent from "../CommonComponents/ExpertsComponent";
import { Carousel, Stack } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { nextArrow, prevArrow } from "../../utils/iconUtils";

export default function TopicsModal(props) {
  // eslint-disable-next-line
  const [topicData, setTopicData] = useState();
  const data = props?.data;

  useEffect(() => {
    (async () => {
      if (data.type === "topics") {
        const topic = await getData(
          `${getHostUrl()}topics/${data.id}?include=owners`
        );

        setTopicData(
          chunk(
            topic.data.included
              .sort((a, b) =>
                a.attributes.employee.data.attributes[
                  "preferred-name"
                ].localeCompare(
                  b.attributes.employee.data.attributes["preferred-name"]
                )
              )
              .sort(
                (a, b) =>
                  Number(a.attributes["is-deputy"]) -
                  Number(b.attributes["is-deputy"])
              ),
            4
          )
        );
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="topics-modal">
      <Modal
        {...props}
        size="lg"
        dialogClassName="topics-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {data.attributes["topics-name"]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2 ms-2">
            <b>Description: </b>
            {data.attributes["topics-description"]}
          </p>
          {topicData ? (
            <div className="experts-section mt-3 ms-2">
              <p className="fw-bold">
                Owners & Deputies of {data.attributes["topics-name"]} are:-
              </p>
              <div className="d-flex flex-row justify-content-between">
                {topicData?.length > 0 ? (
                  <Carousel
                    nextIcon={nextArrow}
                    prevIcon={prevArrow}
                    interval={null}
                    wrap={false}
                    indicators={false}
                  >
                    {topicData?.map((record, i) => (
                      <Carousel.Item key={i}>
                        <Stack
                          direction="horizontal"
                          className="h-100 align-items-center"
                          gap={2}
                        >
                          {record.map((elem, j) => {
                            if (j < record.length - 1) {
                              return (
                                <>
                                  <ExpertsComponent
                                    key={j}
                                    data={elem.attributes.employee.data}
                                    owner={!elem.attributes["is-deputy"]}
                                  />
                                  <div className="expert-divider"></div>
                                </>
                              );
                            } else {
                              return (
                                <ExpertsComponent
                                  key={j}
                                  data={elem.attributes.employee.data}
                                  owner={!elem.attributes["is-deputy"]}
                                />
                              );
                            }
                          })}
                        </Stack>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <div style={{ maxWidth: "100%", height: "5rem" }}>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Modal.Body>{" "}
        <Modal.Footer>
          <Button id="common-favbutton" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
