import Select from "react-dropdown-select";
// import "../ManageAdminPage/ManageTools.scss";

export const MultipleSelectDropdown = (props) => {
  return (
    <Select
      placeholder={props.placeholder ? props.placeholder : "Select..."}
      disabled={props.disabled}
      multi={props.multi}
      Clearable
      options={props.options}
      disabledLabel="disabled"
      create={false}
      labelField="name"
      valueField="name"
      onChange={props.onChange}
      color="#8A00E5"
      keepSelectedInList={true}
      values={props.values}
      className="dropdown-select"
      searchable={true}
      backspaceDelete={true}
      searchBy="name"
    />
  );
};
