import { Button, Modal, Row, Col, Form, Carousel } from "react-bootstrap";
import { now } from "lodash";
import { Upload } from "../components/ImageDragDrop/ImageDragDrop";
import { UserContext } from "../contexts/UserContext";
import { getBaseUrl } from "../config/environment";
import { useRef, useContext, useState } from "react";
import { useClient } from "jsonapi-react";
import { nextArrow, prevArrow } from "./iconUtils";
import validate from "../schemas/validations";

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export const NewFavAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const stockImages = props.data.stockData;
  const fileData = props.data.favData;
  const imageData = useRef(null);
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const linkUrl = useRef(null);

  imageData.current = null;
  stockUrl.current = null;
  const recordId = useRef(null);
  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    favorite_redirect_url: "",
    description: "",
    favorite_image: stockUrl.current,
  });
  const [errors, setErrors] = useState({});

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const RecordId = () => {
    const folderId = document.getElementById("folderselect").value;
    recordId.current = folderId;
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, "link");
    setErrors(errorObj);

    if (fieldName.favorite_redirect_url.includes("https://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else if (fieldName.favorite_redirect_url.includes("http://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else {
      linkUrl.current = `https://${fieldName.favorite_redirect_url}`;
    }

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();

        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: false,
            favorite_redirect_url: linkUrl.current,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: recordId.current,
            order: props.data.orderNumber + 1,
          },
        },
      };

      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);
        props.parentCallback(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="validationCustom01">
        <Form.Label>
          Link Name<sup>*</sup>
        </Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            required
            type="text"
            placeholder="Enter link name"
            value={fieldName.favorite_name}
            name="favorite_name"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.favorite_name ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.favorite_name}
          </label>
        ) : (
          ""
        )}

        <Form.Control.Feedback>Enter Name</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>
          Link URL<sup>*</sup>
        </Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter link URL"
            value={fieldName.favorite_redirect_url}
            name="favorite_redirect_url"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.favorite_redirect_url ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.favorite_redirect_url}
          </label>
        ) : (
          ""
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Short Description</Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter a short description"
            value={fieldName.description}
            name="description"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.description ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.description}
          </label>
        ) : (
          ""
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Choose Image</Form.Label>

        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div className="new-stock-carousel">
            <Carousel nextIcon={nextArrow} prevIcon={prevArrow} interval={null}>
              {stockImages?.map((record, i) => (
                <Carousel.Item key={i}>
                  <Row xs={1} md={4} className="g-1">
                    {stockImages[i].map((record, id) => (
                      <Col key={id}>
                        <img
                          className="new-stock-img"
                          tabIndex={1}
                          src={getBaseUrl().concat(record["img-url"])}
                          alt="stock_image"
                          onClick={() => handleStockImage(record)}
                        ></img>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <p className="mt-auto mb-auto fw-bold">OR</p>
          <div className="upload-img-container">
            <Upload
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </div>
        </div>
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Choose Folder</Form.Label>
          <div className="app-primary-dropdown">
            <Form.Select
              defaultValue="Choose..."
              name="folderselect"
              id="folderselect"
              onChange={RecordId}
            >
              <option value="null">Choose...</option>
              {fileData.map((record, j) => {
                return record["is-folder"] === true ? (
                  <option value={record.id} key={j}>
                    {record["favorite-name"]}
                  </option>
                ) : (
                  ""
                );
              })}
            </Form.Select>
          </div>
        </Form.Group>
      </Row>
      <div className="w-100 justify-content-end">
        <Button
          className="app-primary-btn float-end"
          onClick={() => handleSubmit(imageData.current)}
        >
          {loading ? "Adding Favorite..." : "Add Favorite"}
        </Button>

        <br />
        {Object.keys(errors).length > 0 ? (
          <label className="text-danger">
            Please check all the validations!
          </label>
        ) : (
          ""
        )}
      </div>
    </Form>
  );
};

export const NewFolderAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const stockImages = props.data.stockData;
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const imageData = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const [errors, setErrors] = useState({});

  imageData.current = null;

  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    description: "",
    favorite_image: null,
  });

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, false, "folder");
    setErrors(errorObj);

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();
        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: true,
            favorite_redirect_url: fieldName.favorite_redirect_url,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: null,
            order: props.data.orderNumber + 1,
          },
        },
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);
        props.parentCallback(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };
  return (
    <Form>
      <Form.Group as={Col} className="mb-3">
        <Form.Label>
          Folder Name<sup>*</sup>
        </Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter folder name"
            value={fieldName.favorite_name}
            name="favorite_name"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.favorite_name ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.favorite_name}
          </label>
        ) : (
          ""
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Short Description</Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter a short description"
            value={fieldName.description}
            name="description"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.description ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.description}
          </label>
        ) : (
          ""
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Choose Image</Form.Label>

        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div className="new-stock-carousel">
            <Carousel nextIcon={nextArrow} prevIcon={prevArrow} interval={null}>
              {stockImages?.map((record, i) => (
                <Carousel.Item key={i}>
                  <Row xs={1} md={4} className="g-1">
                    {stockImages[i].map((record, id) => (
                      <Col key={id}>
                        <img
                          className="new-stock-img"
                          tabIndex={1}
                          src={getBaseUrl().concat(record["img-url"])}
                          alt="stock_image"
                          onClick={() => handleStockImage(record)}
                        ></img>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <p className="mt-auto mb-auto fw-bold">OR</p>
          <div className="upload-img-container">
            <Upload
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </div>
        </div>
      </Form.Group>

      <div className="w-100 justify-content-end">
        <Button
          className="app-primary-btn float-end"
          onClick={() => {
            handleSubmit(imageData.current);
          }}
        >
          {loading ? "Adding Favorite..." : "Add Favorite"}
        </Button>

        <br />
        {Object.keys(errors).length > 0 ? (
          <label className="text-danger">
            Please check all the validations!
          </label>
        ) : (
          ""
        )}
      </div>
    </Form>
  );
};

export const NewSubFavAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const stockImages = props.data.stockData;
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const imageData = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const linkUrl = useRef(null);
  const [errors, setErrors] = useState({});

  imageData.current = null;

  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    description: "",
    favorite_image: null,
  });

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, "link");
    setErrors(errorObj);

    if (fieldName.favorite_redirect_url.includes("https://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else if (fieldName.favorite_redirect_url.includes("http://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else {
      linkUrl.current = `https://${fieldName.favorite_redirect_url}`;
    }

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();
        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: false,
            favorite_redirect_url: linkUrl.current,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: props.data.favData.id,
            order: props.data.favData.favorites.length + 1,
          },
        },
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);

        props.parentCallback(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };
  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>
          Link Name<sup>*</sup>
        </Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            required
            type="text"
            placeholder="Enter link name"
            value={fieldName.favorite_name}
            name="favorite_name"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.favorite_name ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.favorite_name}
          </label>
        ) : (
          ""
        )}

        <Form.Control.Feedback>Enter Name</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>
          Link URL<sup>*</sup>
        </Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter link URL"
            value={fieldName.favorite_redirect_url}
            name="favorite_redirect_url"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.favorite_redirect_url ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.favorite_redirect_url}
          </label>
        ) : (
          ""
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Short Description</Form.Label>
        <div className="app-primary-input-field">
          <Form.Control
            type="text"
            placeholder="Enter a short description"
            value={fieldName.description}
            name="description"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.description ? (
          <label className="text-danger">
            <sup>*</sup>
            {errors.description}
          </label>
        ) : (
          ""
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Choose Image</Form.Label>

        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div className="new-stock-carousel">
            <Carousel nextIcon={nextArrow} prevIcon={prevArrow} interval={null}>
              {stockImages?.map((record, i) => (
                <Carousel.Item key={i}>
                  <Row xs={1} md={4} className="g-1">
                    {stockImages[i].map((record, id) => (
                      <Col key={id}>
                        <img
                          className="new-stock-img"
                          tabIndex={1}
                          src={getBaseUrl().concat(record["img-url"])}
                          alt="stock_image"
                          onClick={() => handleStockImage(record)}
                        ></img>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <p className="mt-auto mb-auto fw-bold">OR</p>
          <div className="upload-img-container">
            <Upload
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </div>
        </div>
      </Form.Group>
      <div className="w-100 justify-content-end">
        <Button
          className="app-primary-btn float-end"
          onClick={() => handleSubmit(imageData.current)}
        >
          {loading ? "Adding Favorite..." : "Add Favorite"}
        </Button>

        <br />
        {Object.keys(errors).length > 0 ? (
          <label className="text-danger">
            Please check all the validations!
          </label>
        ) : (
          ""
        )}
      </div>
    </Form>
  );
};

export const LinkForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const stockImages = data.stockData;
  const fileData = data.favData;
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const imageData = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const linkUrl = useRef(null);

  imageData.current = null;
  stockUrl.current = null;
  const recordId = useRef(null);
  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    favorite_redirect_url: "",
    description: "",
    favorite_image: stockUrl.current,
  });
  const [errors, setErrors] = useState({});

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const RecordId = () => {
    const folderId = document.getElementById("folderselect").value;
    recordId.current = folderId;
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, "link");
    setErrors(errorObj);

    if (fieldName.favorite_redirect_url.includes("https://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else if (fieldName.favorite_redirect_url.includes("http://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else {
      linkUrl.current = `https://${fieldName.favorite_redirect_url}`;
    }

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();

        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: false,
            favorite_redirect_url: linkUrl.current,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: recordId.current,
            order: data.orderNumber,
          },
        },
      };

      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);
        data.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="validationCustom01">
          <Form.Label column sm={2}>
            Link Name:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-input-field">
              <Form.Control
                required
                type="text"
                placeholder="Enter link name"
                value={fieldName.favorite_name}
                name="favorite_name"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.favorite_name ? (
              <label className="text-danger">
                <sup>*</sup>
                {errors.favorite_name}
              </label>
            ) : (
              ""
            )}

            <Form.Control.Feedback>Enter Name</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Link URL:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-input-field">
              <Form.Control
                type="text"
                placeholder="Enter link URL"
                value={fieldName.favorite_redirect_url}
                name="favorite_redirect_url"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.favorite_redirect_url ? (
              <label className="text-danger">
                <sup>*</sup>
                {errors.favorite_redirect_url}
              </label>
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Description:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-input-field">
              <Form.Control
                type="text"
                placeholder="Enter a short description"
                value={fieldName.description}
                name="description"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.description ? (
              <label className="text-danger">
                <sup>*</sup>
                {errors.description}
              </label>
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Link Image:
          </Form.Label>
          <Col xs={7}>
            <div className="stock-img-block">
              <p>Choose from images below...</p>
              <div className="stock-carousel">
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                >
                  {stockImages.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Row xs={1} md={3} className="g-1">
                        {stockImages[i].map((record, id) => (
                          <Col key={id}>
                            <img
                              className="stock-img"
                              tabIndex={1}
                              src={getBaseUrl().concat(record["img-url"])}
                              alt="stock_image"
                              onClick={() => handleStockImage(record)}
                            ></img>
                          </Col>
                        ))}
                      </Row>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
            <strong>OR</strong>
            <Upload
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Folder Name:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-dropdown">
              <Form.Select
                defaultValue="Choose..."
                name="folderselect"
                id="folderselect"
                onChange={RecordId}
              >
                <option value="null">Choose...</option>
                {fileData.map((record, j) => {
                  return record["is-folder"] === true ? (
                    <option value={record.id} key={j}>
                      {record["favorite-name"]}
                    </option>
                  ) : (
                    ""
                  );
                })}
              </Form.Select>
            </div>
          </Col>
        </Form.Group>
      </Form>
      <Modal.Footer>
        {Object.keys(errors).length > 0 ? (
          <label className="text-danger">
            Please check all the validations!
          </label>
        ) : (
          ""
        )}
        <Button
          className="app-primary-btn"
          type="submit"
          onClick={() => handleSubmit(imageData.current)}
        >
          {loading ? "Adding..." : "Add"}
        </Button>
      </Modal.Footer>
    </>
  );
};

export const FolderForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const stockImages = data.stockData;
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const imageData = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const [errors, setErrors] = useState({});

  imageData.current = null;

  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    description: "",
    favorite_image: null,
  });

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, false, "folder");
    setErrors(errorObj);

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();
        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: true,
            favorite_redirect_url: fieldName.favorite_redirect_url,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: null,
            order: data.orderNumber,
          },
        },
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);
        data.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };
  return (
    <>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Folder Name:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-input-field">
              <Form.Control
                type="text"
                placeholder="Enter folder name"
                value={fieldName.favorite_name}
                name="favorite_name"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.favorite_name ? (
              <label className="text-danger">
                <sup>*</sup>
                {errors.favorite_name}
              </label>
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Description:
          </Form.Label>
          <Col xs={7}>
            <div className="app-primary-input-field">
              <Form.Control
                type="text"
                placeholder="Enter a short description"
                value={fieldName.description}
                name="description"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.description ? (
              <label className="text-danger">
                <sup>*</sup>
                {errors.description}
              </label>
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Folder Image:
          </Form.Label>
          <Col xs={7}>
            <p>Choose from images below...</p>
            <div className="stock-carousel">
              <Carousel
                nextIcon={nextArrow}
                prevIcon={prevArrow}
                interval={null}
              >
                {stockImages.map((record, i) => (
                  <Carousel.Item key={i}>
                    <Row xs={1} md={3} className="g-1">
                      {stockImages[i].map((record, id) => (
                        <Col key={id}>
                          <img
                            className="stock-img"
                            tabIndex={1}
                            src={getBaseUrl().concat(record["img-url"])}
                            alt="stock_image"
                            onClick={() => handleStockImage(record)}
                          ></img>
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <strong>OR</strong>
            <Upload
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Col>
        </Form.Group>
      </Form>
      <Modal.Footer>
        {Object.keys(errors).length > 0 ? (
          <label className="text-danger">
            Please check all the validations!
          </label>
        ) : (
          ""
        )}
        <Button
          className="app-primary-btn"
          type="submit"
          onClick={() => {
            handleSubmit(imageData.current);
          }}
        >
          {loading ? "Adding..." : "Add"}
        </Button>
      </Modal.Footer>
    </>
  );
};

export const FavAddModal = (props) => {
  const [radioType, setRadioType] = useState("link");
  const stockImages = props.data.stockData;
  const fileData = props.data.favData;

  const handleChange = (event) => {
    setRadioType(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Link/Folder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h7 className="mb-2">Choose from below :</h7>
        <div className="d-flex mb-3" id="app-primary-radio">
          <Form.Check
            type="radio"
            id="link"
            value="link"
            label="Link"
            checked={radioType === "link"}
            onChange={handleChange}
          />

          <Form.Check
            className="ms-3"
            label="Folder"
            type="radio"
            id="folder"
            value="folder"
            checked={radioType === "folder"}
            onChange={handleChange}
          />
        </div>

        {radioType === "link" ? (
          <>
            <LinkForm
              data={{
                stockData: stockImages,
                favData: fileData,
                orderNumber: props.data.orderNumber + 1,
                onHide: props.onHide,
              }}
            />
          </>
        ) : (
          <>
            <FolderForm
              data={{
                stockData: stockImages,
                orderNumber: props.data.orderNumber + 1,
                onHide: props.onHide,
              }}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const SubTableAddModal = (props) => {
  const [loading, setLoading] = useState(false);
  const stockImages = props.data.stockData;
  const stockUrl = useRef(null);
  const sendImageUrl = useRef(null);
  const imageData = useRef(null);
  const base64Img = useRef(null);
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  const client = useClient();
  const linkUrl = useRef(null);
  const [errors, setErrors] = useState({});

  imageData.current = null;

  const [fieldName, setFieldValue] = useState({
    favorite_name: "",
    description: "",
    favorite_image: null,
  });

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (imageFile) => {
    let errorObj = validate(fieldName, "link");
    setErrors(errorObj);

    if (fieldName.favorite_redirect_url.includes("https://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else if (fieldName.favorite_redirect_url.includes("http://")) {
      linkUrl.current = fieldName.favorite_redirect_url;
    } else {
      linkUrl.current = `https://${fieldName.favorite_redirect_url}`;
    }

    if (Object.keys(errorObj).length === 0) {
      if (imageData.current !== null) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();
        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = null;
        base64Img.current = null;
      } else {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: false,
            favorite_redirect_url: linkUrl.current,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: props.data.favData.id,
            order: props.data.favData.favorites.length + 1,
          },
        },
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites"],
          finalData.data.attributes
        );
        setLoading(false);
        props.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add link{nextArrow}
          {props.data.favData["favorite-name"]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="validationCustom01"
            >
              <Form.Label column sm={2}>
                Link Name:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter link name"
                    value={fieldName.favorite_name}
                    name="favorite_name"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.favorite_name ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.favorite_name}
                  </label>
                ) : (
                  ""
                )}

                <Form.Control.Feedback>Enter Name</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Link URL:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter link URL"
                    value={fieldName.favorite_redirect_url}
                    name="favorite_redirect_url"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.favorite_redirect_url ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.favorite_redirect_url}
                  </label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter a short description"
                    value={fieldName.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.description ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.description}
                  </label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Link Image:
              </Form.Label>
              <Col xs={7}>
                <div className="stock-img-block">
                  <p>Choose from images below...</p>
                  <div className="stock-carousel">
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                    >
                      {stockImages.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Row xs={1} md={3} className="g-1">
                            {stockImages[i].map((record, id) => (
                              <Col key={id}>
                                <img
                                  className="stock-img"
                                  tabIndex={1}
                                  src={getBaseUrl().concat(record["img-url"])}
                                  alt="stock_image"
                                  onClick={() => handleStockImage(record)}
                                ></img>
                              </Col>
                            ))}
                          </Row>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
                <strong>OR</strong>
                <Upload
                  onDrop={(files) => {
                    imageData.current = files[0];
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
          <Modal.Footer>
            {Object.keys(errors).length > 0 ? (
              <label className="text-danger">
                Please check all the validations!
              </label>
            ) : (
              ""
            )}
            <Button
              className="app-primary-btn"
              type="submit"
              onClick={() => handleSubmit(imageData.current)}
            >
              {loading ? "Adding..." : "Add"}
            </Button>
          </Modal.Footer>
        </>
      </Modal.Body>
    </Modal>
  );
};

export const EditFavModal = (props) => {
  const [loading, setLoading] = useState(false);
  const recordData = props.data.editModal;
  const stockImages = props.data.stockData;
  const fileData = props.data.tableData;
  const imageData = useRef(null);
  const stockUrl = useRef(null);
  const linkUrl = useRef(null);
  const base64Img = useRef(recordData["image"]);
  const sendImageUrl = useRef(recordData["favorite-image"]);
  const client = useClient();
  const { user } = useContext(UserContext);
  const hash = require("object-hash");
  imageData.current = null;
  const recordId = useRef(recordData["parent-id"]);
  const orderNum = useRef(recordData.order);
  const isFolder = recordData["is-folder"];
  const favType = useRef(null);

  const [fieldName, setFieldValue] = useState({
    favorite_name: recordData["favorite-name"],
    favorite_redirect_url: recordData["favorite-redirect-url"],
    description: recordData["description"],
    favorite_image: stockUrl.current,
  });
  const [errors, setError] = useState(false);

  const handleStockImage = (props) => {
    stockUrl.current = props["img-url"];
  };

  const RecordId = () => {
    const tempId = document.getElementById("folderselect").value;
    if (tempId === "keep") {
      recordId.current = recordData["parent-id"];
      orderNum.current = recordData.order;
    } else if (tempId === "base") {
      recordId.current = null;
      orderNum.current = fileData?.length + 1;
    } else {
      recordId.current = tempId;
      orderNum.current =
        fileData?.filter((elem) => elem.id.toString() === tempId.toString())[0]
          .favorites.length + 1;
    }
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (imageFile) => {
    if (isFolder) {
      favType.current = "folder";
    } else {
      favType.current = "link";
    }

    let errObj = validate(fieldName, favType.current);
    setError(errObj);

    if (!isFolder) {
      if (fieldName.favorite_redirect_url.includes("https://")) {
        linkUrl.current = fieldName.favorite_redirect_url;
      } else if (fieldName.favorite_redirect_url.includes("http://")) {
        linkUrl.current = fieldName.favorite_redirect_url;
      } else {
        linkUrl.current = `https://${fieldName.favorite_redirect_url}`;
      }
    }

    if (Object.keys(errObj).length === 0) {
      if (imageData.current !== null && imageData.current !== undefined) {
        const base64 = await getBase64(imageFile);
        base64Img.current = base64.split(",")?.pop();
        const imageHash = hash({ file: imageFile, timestamp: now() });
        const extension = imageFile.type?.split("/")?.pop();
        sendImageUrl.current = `/images/home/favorites/${user.id}/${imageHash}.${extension}`;
      } else if (stockUrl.current === null && imageData.current === null) {
        sendImageUrl.current = recordData["favorite-image"];
        base64Img.current = null;
      } else if (stockUrl.current !== null) {
        sendImageUrl.current = stockUrl.current;
        base64Img.current = null;
      }
      const finalData = {
        data: {
          type: "user-favorite",
          attributes: {
            favorite_name: fieldName.favorite_name,
            favorite_image: sendImageUrl.current,
            is_folder: isFolder,
            favorite_redirect_url: linkUrl.current,
            image: base64Img.current,
            description: fieldName.description,
            is_external: false,
            parent_id: recordId.current,
            order: orderNum.current,
          },
        },
      };
      // eslint-disable-next-line

      try {
        setLoading(true);
        // eslint-disable-next-line
        const resData = await client.mutate(
          ["user-favorites", recordData["id"]],
          finalData.data.attributes
        );
        setLoading(false);
        props.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        {recordData["is-folder"] === true ? (
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Folder
          </Modal.Title>
        ) : (
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Link
          </Modal.Title>
        )}
      </Modal.Header>
      {recordData["is-folder"] === true ? (
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Folder Name:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter folder name"
                    value={fieldName.favorite_name}
                    name="favorite_name"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.favorite_name ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.favorite_name}
                  </label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter a short description"
                    value={fieldName.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Folder Image:
              </Form.Label>
              <Col xs={7}>
                <p>Choose from images below...</p>
                <div className="stock-carousel">
                  <Carousel
                    nextIcon={nextArrow}
                    prevIcon={prevArrow}
                    interval={null}
                  >
                    {stockImages.map((record, i) => (
                      <Carousel.Item key={i}>
                        <Row xs={1} md={3} className="g-1">
                          {stockImages[i].map((record, id) => (
                            <Col key={id}>
                              <img
                                className="stock-img"
                                tabIndex={1}
                                src={getBaseUrl().concat(record["img-url"])}
                                alt="stock_image"
                                onClick={() => handleStockImage(record)}
                              ></img>
                            </Col>
                          ))}
                        </Row>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <strong>OR</strong>
                <Upload
                  onDrop={(files) => {
                    imageData.current = files[0];
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
          <Modal.Footer>
            {Object.keys(errors).length > 0 ? (
              <label className="text-danger">
                Please fill all the required fields!
              </label>
            ) : (
              ""
            )}
            <Button
              className="app-primary-btn"
              type="submit"
              onClick={() => handleSubmit(imageData.current)}
            >
              {loading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Link Name:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter link name"
                    value={fieldName.favorite_name}
                    name="favorite_name"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.favorite_name ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.favorite_name}
                  </label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Link URL:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter link URL"
                    value={fieldName.favorite_redirect_url}
                    name="favorite_redirect_url"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.favorite_redirect_url ? (
                  <label className="text-danger">
                    <sup>*</sup>
                    {errors.favorite_redirect_url}
                  </label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-input-field">
                  <Form.Control
                    type="text"
                    placeholder="Enter a short description"
                    value={fieldName.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Link Image:
              </Form.Label>
              <Col xs={7}>
                <p>Choose from images below...</p>
                <div className="stock-carousel">
                  <Carousel
                    nextIcon={nextArrow}
                    prevIcon={prevArrow}
                    interval={null}
                  >
                    {stockImages.map((record, i) => (
                      <Carousel.Item key={i}>
                        <Row xs={1} md={3} className="g-1">
                          {stockImages[i].map((record, id) => (
                            <Col key={id}>
                              <img
                                className="stock-img"
                                tabIndex={1}
                                src={getBaseUrl().concat(record["img-url"])}
                                alt="stock_image"
                                onClick={() => handleStockImage(record)}
                              ></img>
                            </Col>
                          ))}
                        </Row>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <strong>OR</strong>
                <Upload
                  onDrop={(files) => {
                    imageData.current = files[0];
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Move To:
              </Form.Label>
              <Col xs={7}>
                <div className="app-primary-dropdown">
                  <Form.Select
                    defaultValue="-"
                    name="folderselect"
                    id="folderselect"
                    onChange={RecordId}
                  >
                    {recordData["parent-id"] === null ? null : (
                      <option value="keep">-</option>
                    )}

                    {recordData["parent-id"] === null ? null : (
                      <option value="base">Out of this folder</option>
                    )}

                    {recordData["parent-id"] === null ? (
                      <option value="base">-</option>
                    ) : null}
                    {fileData?.map((record, j) => {
                      return record["is-folder"] === true &&
                        record.id.toString() !==
                          recordData["parent-id"]?.toString() ? (
                        <option value={record.id} key={j}>
                          {record["favorite-name"]}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </Form.Select>
                </div>
              </Col>
            </Form.Group>
          </Form>
          <Modal.Footer>
            {Object.keys(errors).length > 0 ? (
              <label className="text-danger">
                Please fill all the required fields!
              </label>
            ) : (
              ""
            )}
            <Button
              className="app-primary-btn"
              type="submit"
              onClick={() => handleSubmit(imageData.current)}
            >
              {loading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
};

export const DeleteFavModal = (props) => {
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const DeleteFavorite = async (id) => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const resData = await client.delete(["user-favorites", id]);
      setLoading(false);
      props.onHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {props.data["is-folder"] === true ? (
          <Modal.Title id="contained-modal-title-vcenter">
            Deleting Folder
          </Modal.Title>
        ) : (
          <Modal.Title id="contained-modal-title-vcenter">
            Deleting Link
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {props.data["is-folder"] === true ? (
          <p>Are you sure you want to delete this folder?</p>
        ) : (
          <p>Are you sure you want to delete this link?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="app-primary-btn"
          onClick={() => {
            DeleteFavorite(props.data.id);
          }}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
        <Button className="app-secondary-btn" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
