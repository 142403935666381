import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import pendingIcon from "../../assets/images/pendingIcon.svg";
import inProgressIcon from "../../assets/images/inProgressIcon.svg";
import completedIcon from "../../assets/images/completedIcon.svg";
import overdueIcon from "../../assets/images/overdueIcon.svg";
import "./TrainingsDashboards.scss";

const GraphComp = ({ mainData, metaData }) => {
  const chartRef = useRef(null);

  const statusList = [
    [
      { id: 1, name: "Pending" },
      {
        id: 2,
        name: "In Progress",
      },
    ],
    [
      { id: 3, name: "Completed" },
      { id: 4, name: "Overdue" },
    ],
  ];

  const options = {
    chart: {
      height: "382px",
      renderTo: "container",
      type: "pie",
      events: {
        render: function () {
          const chart = this,
            width = chart.plotWidth,
            height = chart.plotHeight,
            title = chart.title;

          const centerX = width / 2,
            centerY = height / 2;

          if (title) {
            title.attr({
              x: centerX + 10,
              y: centerY + 67,
              "text-anchor": "middle",
            });
          }
        },
      },
    },

    title: {
      text: `<div style="text-align: center; font-weigth: bold;">
      <div style="font-size: 20px; font-weight: 700; color: black; vertical align">Total Trainings:</div>
      <div style="font-size: 20px; font-weight: 700; color: #8a00e5;">${metaData?.total}</div>
    </div>`,
      useHtml: true,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        colors:
          metaData?.total.toString() === "0"
            ? ["#d6d6d6"]
            : ["#3A21A1", "#FFCE4F", "#2FB600", "#F02A2A"],
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: metaData?.total.toString() === "0" ? false : true,
      formatter: function () {
        return "<b>" + this.point.name + "</b>: " + this.y;
      },
    },

    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Browsers",
        data:
          metaData?.total.toString() === "0"
            ? ["Total", 1]
            : [
                ["Pending", metaData?.pending],
                ["In Progress", metaData?.inprogress],
                ["Completed", metaData?.completed],
                ["Overdue", metaData?.overdue],
              ],
        size: "100%",
        innerSize: "70%",
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div
      className="d-flex trainings-graph-container"
      style={{ height: "24rem" }}
    >
      <div className="w-50">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
      <div className="w-50 p-3" style={{ height: "24rem" }}>
        {statusList?.map((record) => {
          return (
            <div className="d-flex w-100 h-50">
              {record.map((elem) => {
                return (
                  <div
                    className={
                      elem?.id === 1
                        ? "w-50 my-2 mx-2 d-flex flex-column pending-card"
                        : elem?.id === 2
                        ? "w-50 my-2 mx-2 d-flex flex-column inprogress-card"
                        : elem?.id === 3
                        ? "w-50 my-2 mx-2 d-flex flex-column completed-card"
                        : "w-50 my-2 mx-2 d-flex flex-column overdue-card"
                    }
                    data-toggle="tooltip"
                    data-placement="top"
                    title={`${
                      elem?.id === 1
                        ? "Pending: "
                        : elem?.id === 2
                        ? "In Progress: "
                        : elem?.id === 3
                        ? "Completed: "
                        : "Overdue: "
                    }${
                      elem?.id === 1
                        ? metaData?.pending
                        : elem?.id === 2
                        ? metaData?.inprogress
                        : elem?.id === 3
                        ? metaData?.completed
                        : metaData?.overdue
                    }`}
                  >
                    <div className="d-flex justify-content-between mx-3 mt-2 mb-2 ">
                      <p
                        className={
                          elem?.id === 1
                            ? "fw-bold mb-0 pending-text"
                            : elem?.id === 2
                            ? "fw-bold mb-0 inprogress-text"
                            : elem?.id === 3
                            ? "fw-bold mb-0 completed-text"
                            : "fw-bold mb-0 overdue-text"
                        }
                      >
                        {elem?.id === 1
                          ? "Pending"
                          : elem?.id === 2
                          ? "In Progress"
                          : elem?.id === 3
                          ? "Completed"
                          : "Overdue"}
                      </p>
                      <div>
                        <img
                          src={
                            elem?.id === 1
                              ? pendingIcon
                              : elem?.id === 2
                              ? inProgressIcon
                              : elem?.id === 3
                              ? completedIcon
                              : overdueIcon
                          }
                          alt="Status Icon"
                        />
                      </div>
                    </div>
                    <div className="mx-3">
                      <p
                        className={
                          elem?.id === 1
                            ? "fw-bold mb-0 pending-number"
                            : elem?.id === 2
                            ? "fw-bold mb-0 inprogress-number"
                            : elem?.id === 3
                            ? "fw-bold mb-0 completed-number"
                            : "fw-bold mb-0 overdue-number"
                        }
                      >
                        {elem?.id === 1
                          ? metaData?.pending
                          : elem?.id === 2
                          ? metaData?.inprogress
                          : elem?.id === 3
                          ? metaData?.completed
                          : metaData?.overdue}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GraphComp;
