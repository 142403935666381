import React, { Component } from "react";
import { getData } from "../../services/apiService";
import OrgChart from "../OrgChart/index";
import OrgTable from "../OrgTable/index";
import { Spinner } from "react-bootstrap";
import { getBaseUrl } from "../../config/environment";

export default class BusinessControlling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableinfo: null,
      orgInfo: null,
      oriDataSet: [],
      oriNodesSet: [],
      dataSet: [],
      nodesSet: [],
      nextLevelSet: [],
      oldDataSet: [],
      orgName: "",
      chartHeight: 360,
      activeNode: null,
      aID: null,
      sections: null,
      propsData: null,
      pointClicked: false,
      nodata: true,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getOrgData();
  }

  async getOrgData() {
    try {
      this.setState({ orgName: this.props.source.name });

      const orgMap = await getData(
        `${getBaseUrl()}${this.props.source["source-url"]}`
      );

      this.setState({ orgInfo: orgMap?.data });
      this.setState({ sections: this.props.source });

      const orgData = orgMap?.data?.orgRelationData;
      const orgPeople = orgMap?.data?.people;

      let nextSet = orgData
        ?.map((el, i) => {
          return {
            id: el.orgId,
            isNextLevelPresent: el.isNextLevelPresent,
            currentLevel: el.currentLevel,
            isExpandable: el.isExpandable,
          };
        })
        .filter(Boolean);
      this.setState({ nextLevelSet: nextSet });

      let nSet = orgData
        ?.map((el, i) => {
          function objectFindByKey(array, key, value) {
            return array.find((elem) => elem[key] === value);
          }
          let result_obj = objectFindByKey(
            orgPeople,
            "personId",
            el.orgHeadPerson
          );
          return {
            id: el.orgId,
            name: el.orgCode,
            title: el.orgTitle,
            description: result_obj?.personName,
          };
        })
        .filter(Boolean);

      this.setState({ nodesSet: nSet, oriNodesSet: nSet });

      let dSet = orgData
        ?.map((value, idx) => {
          function objectFindByKey(array, key, value) {
            return array.find((elem) => elem[key] === value);
          }
          let result_obj = objectFindByKey(
            orgData,
            "orgId",
            value.previousLevelOrgID
          );
          return (
            (value.previousLevelOrgID || value.previousLevelOrgID === 0) &&
            value.currentLevel < 2 && [result_obj?.orgId, value?.orgId]
          );
        })
        .filter((value) => value || value === 0);
      this.setState({ dataSet: dSet, oriDataSet: dSet });
      if (dSet.length > 0 && nSet.length > 0) {
        this.setState({ nodata: false });
      }
    } catch (err) {
      console.error(err);
    }
  }

  setTabData = (eId, eName, eLevel) => {
    let { orgInfo, oriDataSet, nodesSet, nextLevelSet, oldDataSet } =
      this.state;
    let { orgRelationData, people, organizationDetails, application } = orgInfo;

    let tInfo = [],
      objdata;
    orgRelationData?.forEach((el, i) => {
      if (el.isClickable && eId === el.orgId) {
        organizationDetails?.forEach((item) => {
          let peoArr = people
            ?.map((ob, i) => {
              return (
                item?.ownerDetails.includes(ob.personId) && {
                  name: ob.personName,
                  email: ob.emailId,
                }
              );
            })
            .filter(Boolean);

          let appArr = application
            ?.map((ob, i) => {
              return (
                item?.applicationDetails.includes(ob.appId) && {
                  name: ob.applicationName,
                }
              );
            })
            .filter(Boolean);

          objdata = {
            topic: item.topic,
            desc: item.shortDesc,
            people: peoArr,
            app: appArr,
          };
          if (el.orgId === item.orgId) {
            tInfo.push(objdata);
          }
        });
      }
    });
    this.setState({
      tableinfo: tInfo,
      activeNode: eName,
      aID: eId,
      pointClicked: true,
    });

    let nSet = orgRelationData
      ?.map((el, i) => {
        function objectFindByKey(array, key, value) {
          return array.find((elem) => elem[key] === value);
        }
        let result_obj = objectFindByKey(people, "personId", el.orgHeadPerson);
        return (
          el.currentLevel > 2 &&
          el.previousLevelOrgID === eId && {
            id: el.orgId,
            name: el.orgCode,
            title: el.orgTitle,
            description: result_obj.personName,
            level: el.currentLevel,
          }
        );
      })
      .filter(Boolean);
    this.setState({ nodesSet: [...nodesSet, ...nSet] });

    const nest = (items, orgId = eId, link = "previousLevelOrgID") =>
      items
        .filter((item) => item[link] === orgId)
        .map((item) => ({ ...item, children: nest(items, item.orgId) }));
    let nes = nest(orgRelationData);

    function nextNodes(data) {
      let response = [];
      if (Array.isArray(data)) {
        for (let o of data) {
          response.push([o.previousLevelOrgID, o.orgId]);
          if (o.hasOwnProperty("children") && o.children.length > 0) {
            let child = nextNodes(o.children);
            response = response.concat(child);
          }
        }
      } else {
        response.push([data.previousLevelOrgID, data.orgId]);
        if (data.hasOwnProperty("children") && data.children.length > 0) {
          let child = nextNodes(data.children);
          response = response.concat(child);
        }
      }
      return response;
    }
    let res = nextNodes(nes);

    let dSet = orgRelationData
      ?.map((value, idx) => {
        function objectFindByKey(array, key, value) {
          return array.find((elem) => elem[key] === value);
        }
        let result_obj = objectFindByKey(
          orgRelationData,
          "orgId",
          value.previousLevelOrgID
        );
        return (
          value.previousLevelOrgID === eId && [result_obj?.orgId, value?.orgId]
        );
      })
      .filter((value) => value || value === 0);

    if (eLevel > 1) {
      this.setState({ dataSet: [...oriDataSet, ...oldDataSet] });
    } else {
      this.setState({
        dataSet: [...oriDataSet, ...dSet],
        oldDataSet: [...dSet, ...res],
      });
    }

    nextLevelSet?.forEach((el) => {
      if (
        el.id === eId &&
        (eLevel === 0 || (eLevel === 1 && !el.isNextLevelPresent))
      ) {
        this.setState({ chartHeight: 360 });
      } else if (
        el.id === eId &&
        (eLevel === 1 ||
          eLevel === 0 ||
          (eLevel === 2 && !el.isNextLevelPresent))
      ) {
        this.setState({ chartHeight: 540 });
      } else if (
        el.id === eId &&
        eLevel === 2 &&
        el.isNextLevelPresent &&
        el.isExpandable
      ) {
        this.setState({ chartHeight: 720 });
      } else if (
        el.id === eId &&
        eLevel === 3 &&
        el.isNextLevelPresent &&
        el.isExpandable
      ) {
        this.setState({ chartHeight: 900 });
      } else if (
        el.id === eId &&
        eLevel === 4 &&
        el.isNextLevelPresent &&
        el.isExpandable
      ) {
        this.setState({ chartHeight: 1080 });
      }
    });
  };

  render() {
    let {
      orgInfo,
      dataSet,
      nodesSet,
      orgName,
      tableinfo,
      activeNode,
      aID,
      pointClicked,
      nodata,
    } = this.state;

    return nodata ? (
      <div className="text-center">
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
      </div>
    ) : (
      <div className="main-container">
        <OrgChart
          setTabData={this.setTabData}
          dataSet={dataSet}
          nodesSet={nodesSet}
          orgInfo={orgInfo}
          orgName={orgName}
          chartHeight={this.state.chartHeight}
          selID={aID}
        />

        <div className="tableDiv">
          {activeNode && (
            <div className="titleDiv">
              <h3 className="title tabTitle">{activeNode}</h3>
            </div>
          )}
          <OrgTable
            data={tableinfo}
            refProp={this.myRef}
            pointClicked={pointClicked}
          />
        </div>
      </div>
    );
  }
}
