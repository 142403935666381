import React from "react";
import { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";

Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

const StocksGraph = ({ orgData }) => {
  const finalData = orgData?.data[0].data.attributes["historical-data"].data
    ?.filter((elem) => {
      return elem.attributes.close !== "None";
    })
    .map((elem) => {
      return [
        new Date(elem.attributes.schedule).getTime(),
        Number(elem.attributes.close),
      ];
    });
  const chartRef = useRef({});

  const [btn, setBtn] = useState(3);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const chart = chartRef.current?.chart;
      if (chart) {
        chart.reflow();
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const options = {
    chart: {
      height: "380px",
    },
    rangeSelector: {
      enabled: true,
      selected: btn,
      allButtonsEnabled: true,
      inputEnabled: false,
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1W",
          events: {
            click: function () {
              setBtn(0);
            },
          },
        },
        {
          type: "month",
          count: 1,
          text: "1M",
          events: {
            click: function () {
              setBtn(1);
            },
          },
        },
        {
          type: "month",
          count: 6,
          text: "6M",
          events: {
            click: function () {
              setBtn(2);
            },
          },
        },
        {
          type: "year",
          count: 1,
          text: "1Y",
          events: {
            click: function () {
              setBtn(3);
            },
          },
        },
        {
          type: "ytd",
          text: "YTD",
          events: {
            click: function () {
              setBtn(4);
            },
          },
        },
      ],
    },

    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    title: {
      text:
        orgData?.id === "1"
          ? "Siemens AG"
          : orgData?.id === "2"
          ? "Siemens Energy"
          : "Siemens Healthineers",
    },

    yAxis: { opposite: false },

    series: [
      {
        name:
          orgData?.id === "1"
            ? "Siemens AG"
            : orgData?.id === "2"
            ? "Siemens Energy"
            : "Siemens Healthineers",
        data: finalData,
        type: "area",
        threshold: null,
        tooltip: {
          valueDecimals: 2,
        },

        color: "#8A00E5",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#8A00E5"],
            [1, Highcharts.color("#8A00E5").setOpacity(0).get("rgba")],
          ],
        },
      },
    ],
  };

  return (
    <div style={{ height: "26rem" }}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
};

export default StocksGraph;
