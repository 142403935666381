import React, { useEffect } from "react";
import ImageSvgs from "../ImageSvgs";
import "./style.scss";

export default function Details({ data, ownerInfo }) {
  useEffect(() => {}, []);

  return (
    <div className="details">
      <p className="titLabel">
        Application name:{" "}
        <span className="titName capitalize">{data?.appTitle}</span>
        {data?.appLink && (
          <span
            className="img"
            data-toggle="tooltip"
            data-placement="top"
            title="Redirect"
          >
            <a href={data?.appLink} target="_blank" rel="noreferrer">
              <ImageSvgs name="redirect" />
            </a>
          </span>
        )}
      </p>
      <p className="titdesc" title={data?.appDesc}>
        {data?.appDesc}
      </p>

      <div className="app-info">
        <div className="secDiv owner">
          <p className="secName name">Owner / Deputy :</p>
          <p className="secName val flex-col">
            {ownerInfo?.map((el, key) => (
              <span className="addInfo" key={key}>
                <span className="secName trunc txt" title={el?.name}>
                  {el?.name}
                </span>
                <span
                  className="img"
                  onClick={() => navigator.clipboard.writeText(el?.email)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Copy email"
                >
                  <ImageSvgs name="copy" />
                </span>
                <span
                  className="img"
                  onClick={() => navigator.clipboard.writeText(el?.email)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Mail to"
                >
                  <a href={`mailto:${el.email}`}>
                    <ImageSvgs name="mail" />
                  </a>
                </span>
              </span>
            ))}
          </p>
        </div>
        <div className="secDiv access">
          <p className="secName name">Access Authorization :</p>
          <p className="secName val">
            <span className="secName trunc txt" title={data?.accessInfo}>
              {data?.accessInfo}
            </span>
            {data?.accessLink && (
              <>
                <span
                  className="img"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      data?.accessLink.replace("mailto:", "")
                    )
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Copy link"
                >
                  <ImageSvgs name="copy" />
                </span>

                <span
                  className="img"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Redirect"
                >
                  <a href={data?.accessLink} target="_blank" rel="noreferrer">
                    <ImageSvgs name="redirect" />
                  </a>
                </span>
              </>
            )}
          </p>
        </div>
        <div className="secDiv further">
          <p className="secName name">Further information :</p>
          <p className="secName val flex-col">
            {data?.additionalInfo?.map((el, key) => (
              <span className="addInfo" key={key}>
                <span className="secName trunc txt" title={el?.infoTitle}>
                  {el?.infoTitle}
                </span>
                <span
                  className="img"
                  onClick={() => navigator.clipboard.writeText(el?.infoURL)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Copy link"
                >
                  <ImageSvgs name="copy" />
                </span>
                <span
                  className="img"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Redirect"
                >
                  <a href={el.infoURL} target="_blank" rel="noreferrer">
                    <ImageSvgs name="redirect" />
                  </a>
                </span>
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}
