import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { postData } from "../../services/apiService";
import { getWernerUrl } from "../../config/environment";

function DislikeModal(props) {
  const [userText, setUserText] = useState("");
  const [loading, setLoading] = useState(false);

  const { feedbackList, setFeedbackList, feedbackData } = props;

  return (
    <Modal
      className="werner-feedback-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Submit feedback
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row justify-content-between w-100">
          <h6>
            {props?.feedbackType === "positive"
              ? "What did you like?"
              : "What did you dislike?*"}
          </h6>
          <p
            className={
              userText?.length <= 250 ? "text-muted mb-0" : "text-danger mb-0"
            }>
            {userText?.length}/250
          </p>
        </div>
        <div>
          <Form noValidate className="tool-form">
            <Row>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <div className="app-primary-input-field">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter your feedback..."
                    value={userText}
                    name="app_desc"
                    onChange={(e) => {
                      setUserText(e.target.value);
                    }}
                  />
                </div>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onHide();
          }}
          className="app-secondary-btn">
          Cancel
        </Button>
        <Button
          className="app-primary-btn"
          onClick={async () => {
            await setLoading(true);
            let tempFeedbackList = feedbackList && feedbackList;
            let finalPayload = {
              data: {
                type: "feedback",
                attributes: {
                  feedback_date: new Date(),
                  user_id: JSON.parse(sessionStorage.getItem("userData"))
                    .userPrincipalName,
                  question: feedbackData?.question,
                  answer: feedbackData?.answer,
                  feedback_type: "0",
                  feedback: userText,
                },
              },
            };

            let headers = {
              Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
            };
            // eslint-disable-next-line
            const res = await postData(
              `${getWernerUrl()}feedback`,
              finalPayload,
              headers
            );
            if (res.data) {
              tempFeedbackList.push({
                id: feedbackData?.id,
                type: "negative",
              });
              const updatedList = tempFeedbackList;
              sessionStorage.setItem(
                "feedbackList",
                JSON.stringify({
                  data: tempFeedbackList,
                })
              );

              setFeedbackList(updatedList);
              await setLoading(false);
              props.onHide();
            }
          }}
          disabled={
            userText?.length <= 0 || userText?.length > 250 || loading
              ? true
              : false
          }>
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DislikeModal;
