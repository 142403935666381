import { React, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Link } from "react-router-dom";

const DashboardExpandModal = (props) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <div style={{ flex: "1" }}>
            <Modal.Title>{props?.activeLink?.name}</Modal.Title>
          </div>

          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "0.5rem",
              cursor: "pointer",
            }}
          >
            <Link
              href={props?.activeLink?.link}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(props?.activeLink?.link, "_blank");
              }}
            >
              <ImageSvgs name="redirect_btn" />
            </Link>
          </div>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={props?.activeLink?.link}
            name="sac"
            width="100%"
            height={windowHeight - 200 + "px"}
            title="SAC Dashoboard"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardExpandModal;
