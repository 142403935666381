import React from "react";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import "./TrainingsDashboards.scss";

const TableComp = ({ mainData }) => {
  return (
    <div className="trainings-table-container h-100">
      {mainData?.length.toString() === "0" ? (
        <div className="d-flex flex-column w-100" style={{ marginTop: "6rem" }}>
          <div className="mx-auto">
            <ImageSvgs name="no_trainings" />
          </div>
          <p className="mb-0 mx-auto fw-bold">
            There are no trainings assigned to you
          </p>
        </div>
      ) : (
        <Table>
          <thead className="mb-2">
            <tr>
              <th>Training Name</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody className="mt-2 custom-scrollbar">
            {mainData &&
              mainData?.map((elem) => {
                return (
                  <tr>
                    <td>
                      <a
                        className="selp-links"
                        href={elem.attributes.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {elem.attributes.title}
                      </a>
                    </td>
                    <td className="d-flex justify-content-center">
                      <span
                        className={
                          elem.attributes.status
                            .toLowerCase()
                            .includes("progress")
                            ? "p-1 inprogress-tab"
                            : elem.attributes.status
                                .toLowerCase()
                                .includes("pending")
                            ? "p-1 pending-tab"
                            : elem.attributes.status
                                .toLowerCase()
                                .includes("completed")
                            ? "p-1 completed-tab"
                            : "p-1- overdue-tab"
                        }
                      >
                        {elem.attributes.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TableComp;
