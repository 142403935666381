import React from "react";
import "../LandscapeCanvas/SplineCanvas.scss";
import Spline from "@splinetool/react-spline";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import LandscapeLoader from "../LandscapeLoader";

const SplineCanvas = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [loading]);

  function onMouseDown(e) {
    if (e.target.id === "9e5fdd7c-5a29-488c-a015-cb404cac63ea") {
      window.open("/landscape/trainings?id=0", "_blank");
      // window.open("/landscape/v2/trainings", "_blank");
    } else if (e.target.id === "92af2276-042b-4d30-bb05-b6b83812ba94") {
      window.open("/landscape/tools?layerId=1&toolId=0", "_blank");
      // window.open("/landscape/v2/tools", "_blank");
    } else if (e.target.id === "2b594cde-8f6b-4e56-8062-5dc8110d3516") {
      window.open("/landscape/organization?id=0", "_blank");
      // window.open("/landscape/v2/organization", "_blank");
    } else if (e.target.id === "9c1930cb-b970-4766-95f2-f3c770cca899") {
      // window.open("/landscape/v2/applications", "_blank");
      window.open("/landscape/applications?orgId=0&appId=0", "_blank");
    } else if (e.target.id === "263fd0f8-50b7-42ea-a504-964016837a21") {
      window.open("/landscape/communities?page=0", "_blank");
    } else if (e.target.id === "fa4c5c59-d917-4d63-826f-3f9b18474274") {
      window.open("/landscape/process?process_id=0&view=cardView", "_blank");
    }
  }

  return (
    <div>
      {loading ? (
        <div>
          <LandscapeLoader />
        </div>
      ) : null}
      <motion.div
        animate={{ x: !loading ? 0 : -8000 }}
        transition={{ type: "none", stiffness: 100 }}
        className="d-flex flex-column spline-comp"
      >
        <Spline
          scene="https://prod.spline.design/AxWXMbJ7V9NF2JlI/scene.splinecode"
          // scene="/untitled.spline"
          onMouseDown={onMouseDown}
        />
        <p className="position-absolute fw-bold scroll-text px-3 py-1 mt-1 me-1 rounded">
          Scroll to Zoom in + / Zoom out -
        </p>
      </motion.div>
    </div>
  );
};

export default SplineCanvas;
