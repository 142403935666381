import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import "./GenericCard.scss";

const Header = ({ header, overlayTriggers, headerIcon, isHover }) => (
  <div className="header-section pt-2 d-flex">
    <div className={classNames("header-left flex-grow-1", { isHover })}>
      {overlayTriggers.header ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-header`}>{overlayTriggers.header}</Tooltip>
          }>
          <div className="header-content">{header}</div>
        </OverlayTrigger>
      ) : (
        <div className="header-content">{header}</div>
      )}
    </div>
    <div className="header-right">
      {headerIcon && (
        <div className="header-icon">
          <img src={headerIcon} alt="icon" />
        </div>
      )}
    </div>
  </div>
);

const Body = ({
  description,
  overlayTriggers,
  links,
  onLinkClick,
  linkSectionMoreButton,
}) => (
  <>
    <div className="sub-heading">
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.href}
          className={link.className}
          onClick={(e) => {
            e.stopPropagation();
            if (onLinkClick) onLinkClick(link, e);
          }}>
          {link.text}
        </Link>
      ))}{" "}
      {linkSectionMoreButton}
    </div>
    <div className="description-section">
      {overlayTriggers.description ? (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-title`}>{description}</Tooltip>}>
          <div className="process-desc">{description}</div>
        </OverlayTrigger>
      ) : (
        <div className="process-desc">{description}</div>
      )}
    </div>
  </>
);

const Footer = ({ footer }) => (
  <div className="d-flex flex-row justify-content-between pb-2">
    <span>{footer}</span>
  </div>
);

const Image = ({ src, alt, isHover }) => (
  <div className={classNames("image-section", { isHover })}>
    <img src={src} alt={alt} className="card-image" />
  </div>
);

const GenericCard = ({
  id,
  isActive,
  header,
  description,
  links = [],
  linkSectionMoreButton,
  onClick,
  onLinkClick,
  customClass = "",
  overlayTriggers = [],
  footer,
  imageSrc,
  imageAlt,
  headerIcon,
  isHover,
}) => {
  return (
    <div
      key={id}
      className={classNames("card generic-card", customClass, {
        highlighted: isActive,
        "not-highlighted": !isActive,
      })}
      onClick={(e) => {
        onClick(e);
      }}>
      {imageSrc && <Image src={imageSrc} alt={imageAlt} isHover={isHover} />}
      <div className="ps-2 pe-2">
        {header && (
          <Header
            header={header}
            overlayTriggers={overlayTriggers}
            headerIcon={headerIcon}
            isHover={isHover}
          />
        )}
        {description && (
          <Body
            description={description}
            overlayTriggers={overlayTriggers}
            links={links}
            onLinkClick={onLinkClick}
            linkSectionMoreButton={linkSectionMoreButton}
          />
        )}

        {footer && <Footer footer={footer} />}
      </div>
    </div>
  );
};

GenericCard.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  header: PropTypes.node,
  description: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  linkSectionMoreButton: PropTypes.node,
  onClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  customClass: PropTypes.string,
  overlayTriggers: PropTypes.shape({
    header: PropTypes.node,
    description: PropTypes.node,
  }),
  footer: PropTypes.node,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  headerIcon: PropTypes.string,
  isHover: PropTypes.bool,
};

GenericCard.defaultProps = {
  isActive: false,
  description: null,
  links: [],
  linkSectionMoreButton: null,
  onClick: () => {},
  onLinkClick: () => {},
  customClass: "",
  overlayTriggers: {},
  footer: null,
  imageSrc: null,
  imageAlt: "Card image",
  headerIcon: null,
  isHover: false,
};

export default GenericCard;
