import React, { useEffect, useState } from "react";
import { postData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { cardArrow } from "../../utils/iconUtils";
import { getBaseUrl, getYammerUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { Card, Stack, Carousel, Nav } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { sessionExpiredMsg } from "../../config/Constants";
import { chunk } from "lodash";
import Loader from "../Loader/Loader";

const YammerComp = ({ props, fullSize }) => {
  const { instance } = useMsal();
  const _ = require("lodash");
  const base64 = require("base-64");
  const [community, setCommunity] = useState([]);
  const [communityData, setCommunityData] = useState([]);

  useEffect(() => {
    (async () => {
      const root = document.documentElement;
      root?.style.setProperty("--card-width", fullSize ? "25%" : "50%");

      const yammerHeaders = {
        yammerToken: `${sessionStorage.getItem("accessToken")}`,
      };
      const yammerUrl = `${getHostUrl()}${props["source-url"]}`;
      const resp = await getData(yammerUrl, "idToken", yammerHeaders);
      setCommunity(resp.data[0].group_memberships);

      const comList = resp.data[0].group_memberships?.map((elem) => {
        if (resp.data[0].group_memberships.length === 1) {
          return { limit: 20, id: elem.id };
        } else if (
          resp.data[0].group_memberships.length >= 1 &&
          resp.data[0].group_memberships.length <= 2
        ) {
          return { limit: 10, id: elem.id };
        } else if (
          resp.data[0].group_memberships.length > 2 &&
          resp.data[0].group_memberships.length <= 5
        ) {
          if (elem.id === 3781246977) {
            return { limit: 10, id: elem.id };
          } else {
            return { limit: 5, id: elem.id };
          }
        } else {
          return { limit: 4, id: elem.id };
        }
      });

      const body = {
        communityid: comList,
      };

      const headers = {
        yammerToken: `${sessionStorage.getItem("accessToken")}`,
      };

      const url = `${getHostUrl()}yammer/community_message`;

      try {
        const res = await postData(url, body, headers);
        res.data.map(async (elem) => {
          await setCommunityData((communityData) =>
            communityData.concat(elem.messages)
          );
        });
      } catch (error) {
        if (error.response.status === 422) {
          // eslint-disable-next-line no-restricted-globals
          if (confirm(sessionExpiredMsg)) {
            instance.logoutRedirect();
          }
        }
      }
    })();

    // eslint-disable-next-line
  }, []);

  const orderData = _.orderBy(communityData, "created_at", "desc");
  const sortedData = chunk(orderData.slice(0, 20), fullSize ? 4 : 2);

  return (
    <div className="w-100">
      <div className="newscomp mt-3 mb-3">
        <div className="news-header d-flex flex-column  my-2">
          <div>
            <h4 className="sec-title fw-bold">{props.title}</h4>
          </div>
          {props["display-show-all"] ? (
            <div>
              <a
                className="app-tertiary-btn"
                href="https://web.yammer.com"
                target="_blank"
                rel="noreferrer"
              >
                {props["show-all-text"]}
              </a>
            </div>
          ) : null}
        </div>

        <div className="newscomp-carousel banner-carousel" id="news-carousel">
          {communityData.length <= 0 ? (
            <div style={{ maxWidth: "100%", height: "24rem" }}>
              <Loader />
            </div>
          ) : (
            <>
              <Carousel interval={null} wrap={false} controls={false}>
                {sortedData.map((record, i) => {
                  return (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100 align-items-center"
                        gap={2}
                      >
                        {record.map((comMsg, i) => {
                          const date = comMsg.created_at.split(" ");
                          const comData = [];
                          // eslint-disable-next-line
                          community.map((elem) => {
                            if (elem.id === comMsg.group_id) {
                              comData.push(elem);
                            }
                          });
                          return (
                            <Card key={i}>
                              <Nav.Link
                                href={`${getYammerUrl()}threads/${base64.encode(
                                  JSON.stringify({
                                    _type: "Thread",
                                    id: `${comMsg["thread_id"]}`,
                                  })
                                )}`}
                                target="_blank"
                              >
                                <div className="img-hover-zoom">
                                  <Card.Img
                                    variant="bottom"
                                    src={
                                      comData[0].mugshot_url
                                        ? `${comData[0].mugshot_url.replace(
                                            "&size=48x48",
                                            ""
                                          )}`
                                        : `${getBaseUrl()}/images/default-images/default-fav.jpg`
                                    }
                                    alt={comData[0].full_name}
                                  />
                                </div>
                                <Card.Body>
                                  <Card.Title className="mb-2 fw-bold fs-5">
                                    {comData[0].full_name}
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    Date: {date[0]}
                                  </Card.Subtitle>

                                  <Card.Text className="yammer-card-text">
                                    {comMsg.body.rich.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )}
                                  </Card.Text>
                                  <div className="yammer-card-arrow card-arrow">
                                    {cardArrow}
                                  </div>
                                  <footer className="blockquote-footer">
                                    {comMsg.sender_id ? (
                                      <div className="card-author">
                                        by {comMsg.sender_id}
                                      </div>
                                    ) : (
                                      <div className="card-author">
                                        by Community Admin
                                      </div>
                                    )}
                                  </footer>
                                </Card.Body>
                              </Nav.Link>
                            </Card>
                          );
                        })}
                      </Stack>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default YammerComp;
