import { Modal, Button, Form } from "react-bootstrap";
import { postData, patchData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { useState, useRef, useEffect } from "react";
import { getData } from "../services/apiService";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const UserConfirmationModal = (props) => {
  const [loading, setLoading] = useState(false);
  const finalData = useRef(null);

  const handleSubmit = async () => {
    finalData.current = {
      data: {
        type: "user",
        attributes: {
          is_admin: !props.data.attributes["is-admin"],
        },
      },
    };

    try {
      setLoading(true);
      // eslint-disable-next-line
      const resData = await patchData(
        `${getHostUrl()}user/${props.data.id}`,
        finalData.current
      );
      setLoading(false);
      props.onHide();
      if (resData) {
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Name:</b> {props.data.attributes["first-name"]}&nbsp;
          {props.data.attributes["last-name"]}
        </p>
        <p>
          <b>Email Id:</b> {props.data.attributes["unique-name"]}
        </p>
        <p>
          {props.data.attributes["is-admin"] ? (
            <>
              Are you sure you want to <b>remove</b>{" "}
              {props.data.attributes["first-name"]}&nbsp;
              {props.data.attributes["last-name"]} as admin?
            </>
          ) : (
            <>
              Are you sure you want to <b>set</b>{" "}
              {props.data.attributes["first-name"]}&nbsp;
              {props.data.attributes["last-name"]} as admin and give the admin
              rights?
            </>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="form-button" onClick={props.onHide}>
          Close
        </Button>
        {props.data.attributes["is-admin"] ? (
          <Button
            className="form-button"
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? "Removing..." : "Remove as admin"}
          </Button>
        ) : (
          <Button
            className="form-button"
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? "Saving..." : "Set as admin"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const AddUserModal = (props) => {
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [loading, setLoading] = useState(false);
  const [loadingOpt, setLoadingOpt] = useState(false);
  const [query, setQuery] = useState("");
  const [empOptions, setEmpOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [added, setAdded] = useState(props.added);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleQueryChange = async (text) => {
    await setLoadingOpt(true);
    if (text.length.toString() === "0" || text.length < 4) {
      setEmpOptions([]);
    } else if (text.length >= 4) {
      const param = `${getHostUrl()}employee/search?search=${text}&group=SE&per_page=40`;
      const res = await getData(param);

      res.data.data.map(async (elem) => {
        setEmpOptions((empOptions) => [...empOptions, { data: elem }]);
      });
      await setLoadingOpt(false);
    }
  };

  const handleSubmit = async () => {
    setAdded("null");
    if (value) {
      try {
        setLoading(true);
        // eslint-disable-next-line
        const res = await postData(`${getHostUrl()}user`, value && value);
        setAdded("true");
        setLoading(false);
        setValue(null);
        setTimeLeft(3);
        props.setIsLoad(!props.isLoad);
        setTimeout(() => {
          props.onHide();
          setAdded("null");
          setEmpOptions([]);
        }, 2950);
        // props.onHide();
      } catch (error) {
        if (error.response.status.toString() === "403") {
          setTimeLeft(3);
          setAdded("false");
          setTimeout(() => {
            props.onHide();
            setAdded("null");
            setEmpOptions([]);
          }, 2950);
        }
        setLoading(false);
        setValue(null);
      }
    }
  };

  return (
    <div className="add-admin-user-modal">
      <Modal
        {...props}
        className="add-admin-user-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <p className=" mb-1">Select User</p>
            <div className="app-primary-dropdown">
              <Typeahead
                labelKey={(option) => `${option.name} - ${option.email}`}
                filterBy={["name", "email"]}
                placeholder="Search by name or email..."
                id="typeAhead"
                highlightOnlyResult={true}
                clearButton={true}
                onChange={async (selected) => {
                  if (selected.length > 0) {
                    await setValue({
                      data: {
                        type: "user",
                        attributes: {
                          name: selected[0].name,
                          is_admin: true,
                          unique_name: selected[0].email,
                          employee_id: selected[0].id,
                        },
                      },
                    });
                  } else {
                    await setValue(null);
                    await setAdded("null");
                  }
                }}
                emptyLabel={
                  <div>
                    {query?.length < 4
                      ? "Keep typing..."
                      : query?.length >= 4 && loadingOpt
                      ? "Loading..."
                      : query?.length >= 4 &&
                        empOptions?.length.toString() === "0"
                      ? "No matches found!"
                      : "Loading..."}
                  </div>
                }
                onInputChange={(text) => {
                  setQuery(text);
                  handleQueryChange(text);
                }}
                options={
                  query?.length < 4
                    ? []
                    : empOptions &&
                      [
                        ...new Map(
                          empOptions?.map((item) => [item.data["id"], item])
                        ).values(),
                      ].map((item) => ({
                        type: "employee",
                        name: `${item.data.attributes["f-name"]} ${item.data.attributes["l-name"]}`,
                        email: `${item.data.attributes["email-id"]}`,
                        attributes: item.data.attributes,
                        id: item.data.id,
                      }))
                }
              />
            </div>
            <Form.Check
              className="mt-2"
              defaultChecked
              disabled
              type="checkbox"
              label="Set as Admin"
              value={true}
              name="is_admin"
            />
            <p
              className={
                added === "true"
                  ? "text-success mb-1 fw-bold"
                  : "text-danger mb-1 fw-bold"
              }
            >
              {added === "true"
                ? `User has been set as admin. Closing in ${
                    timeLeft ? `${timeLeft}...` : ""
                  }`
                : added === "false"
                ? `User is already set as admin. Closing in ${
                    timeLeft ? `${timeLeft}...` : ""
                  }`
                : ""}
            </p>
          </div>

          <Modal.Footer>
            <Button
              disabled={value ? false : true}
              className="app-primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? "Adding User..." : "Add Admin User"}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};
