export const footerLinks = {
  contactForm: "https://www.siemens-energy.com/global/en/general/contact.html",
  seTwitter: "https://twitter.com/siemens_energy",
  seLinkedin: "https://de.linkedin.com/showcase/siemens-energy",
  seFacebook: "https://www.facebook.com/SiemensEnergy",
  seGlobal: "https://www.siemens-energy.com/global/en.html",
  corporateInfo:
    "https://www.siemens-energy.com/global/en/general/corporate-information.html",
  privacyNotice:
    "https://www.siemens-energy.com/global/en/general/privacy-notice.html",
  cookieNotice:
    "https://www.siemens-energy.com/global/en/general/cookie-notice.html",
  termsOfUse:
    "https://www.siemens-energy.com/global/en/general/terms-of-use.html",
  encryptedInfo:
    "https://www.siemens-energy.com/global/en/general/digital-id.html",
};
