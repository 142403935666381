import React, { useState, useEffect } from "react";
import GenericCard from "./../../Cards/GenericCard";
import ImageSvgs from "./../../ImageSvgs";
// import defaultImg from "../../../assets/default_img.png"; // Keeping this code for future reference
import CustomTooltip from "../../CommonComponents/CustomTooltip";
import Avatar from "react-avatar";
import { getBaseUrl } from "../../../config/environment";
import ProcessDetailsPage from "./ProcessDetailPage";
import { useLocation } from "react-router-dom";
import "./ProcessRender.scss";

const ProcessRender = ({
  processDetails,
  activePro,
  getOrgTitles,
  handleProcessData,
  navigate,
  activeBtn,
  handleEmployeeModalClick,
  handleOrgModalClick,
  included,
  groupQuery,
  orgQuery,
  allLoading,
  setDeleteFlag,
}) => {
  const location = useLocation();
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processId = parseInt(queryParams.get("process_id"), 10);

    if (processId && processId !== 0) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
    // eslint-disable-next-line
  }, [location]);

  const handleProcessClick = (processId) => {
    sessionStorage.removeItem("processPayload");
    sessionStorage.removeItem("processEditData");
    sessionStorage.removeItem("updateId");
    sessionStorage.removeItem("processFlag");
    sessionStorage.removeItem("processIncluded");
    handleProcessData(processId);
    const queryParams = new URLSearchParams({
      process_id: processId,
      view: activeBtn,
    });

    let selectedProcessFilter = {};

    if (orgQuery?.length > 0) {
      selectedProcessFilter.orgQuery = orgQuery;
    }

    if (groupQuery?.length > 0) {
      selectedProcessFilter.groupQuery = groupQuery;
    }

    if (Object.keys(selectedProcessFilter).length > 0) {
      const encodedFilter = encodeURIComponent(
        JSON.stringify(selectedProcessFilter)
      );
      queryParams.append("selectedProcessFilter", encodedFilter);
    }

    navigate(`/landscape/process?${queryParams.toString()}`, {
      state: { prevPath: window.location.href },
    });
    setShowDetail(true);
  };

  const handleClose = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("process_id", 0);
    navigate(`/landscape/process?${queryParams.toString()}`, {
      state: { prevPath: window.location.href },
    });
    setShowDetail(false);
  };

  return (
    <>
      <div className="grid-container">
        {processDetails?.length > 0 ? (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5 row-cols-xxl-7">
            {processDetails.map((process) => {
              const isActive =
                process.id?.toString() === activePro?.id?.toString();
              const orgIds = process.relationships.orgs.data.map(
                (org) => org.id
              );
              const orgTitles = getOrgTitles(orgIds);

              const links = [
                {
                  href: `/landscape/organization?id=${orgTitles[0]?.id}`,
                  text: orgTitles.length > 0 ? orgTitles[0]?.title : "N/A",
                  className: "link-item",
                },
              ];

              const linkSectionMoreButton = (
                <button
                  className="more-user-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOrgModalClick(process);
                  }}
                >
                  {process.relationships.orgs.data.length > 1 ? (
                    `+ ${process.relationships.orgs.data.length - 1} more`
                  ) : (
                    <b></b>
                  )}
                </button>
              );

              const renderOwners = () => {
                const owners = process?.relationships?.owners?.data || [];
                return (
                  <>
                    {owners.slice(0, 3).map((elem, index) => {
                      const owner = included?.find(
                        (item) =>
                          item.type === "owners" &&
                          item.id.toString() === elem.id.toString()
                      );

                      const employee =
                        owner?.attributes?.employee?.data?.attributes;
                      const employeeId = owner?.attributes?.["employee-id"];
                      const profilePicture = employee?.["profile-picture"];
                      const preferredName = employee?.["preferred-name"];
                      const orgTitle =
                        employee?.["employee-org-master"]?.data?.attributes[
                          "org-title"
                        ];

                      const handleProfileClick = (e) => {
                        e.stopPropagation();
                        navigate(`/employee_relation?id=${employeeId}`);
                      };

                      return (
                        <span
                          key={index}
                          className="me-1 owner"
                          onClick={handleProfileClick}
                        >
                          {profilePicture ? (
                            <CustomTooltip
                              data={owner}
                              name={preferredName}
                              orgCode={orgTitle}
                            >
                              <img
                                className="my-auto owner-img"
                                src={`${getBaseUrl()}${profilePicture}`}
                                alt="User Img"
                              />
                            </CustomTooltip>
                          ) : (
                            <CustomTooltip
                              data={owner}
                              name={preferredName}
                              orgCode={orgTitle}
                            >
                              <Avatar
                                name={preferredName}
                                size="30"
                                round={true}
                              />
                            </CustomTooltip>
                          )}
                        </span>
                      );
                    })}
                    {owners.length > 3 && (
                      <button
                        className="more-user-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEmployeeModalClick(process);
                        }}
                      >
                        + {owners.length - 3} more
                      </button>
                    )}
                  </>
                );
              };
              // Keeping this code for future reference
              // const imageSrc =
              //   "https://mugshot0eu-1.assets-yammer.com/mugshot/images/xZdzS4DwkXZJvPz7sZK4tlcRSX2bPZh9?P1=1728995875&P2=104&P3=1&P4=dvKlUG6iJ6w_XWXzwiG6EAtmmWBIaaAyClS0XPxMZw6qPrQUws62LaSW6qtwrG0mnmWH4EaywBc5mgwRQx9PT4U3LGP894puC8e3BCm9wMLbI2xLOvogO7mcUEUWYKWgYm50K-7GjGuiOchFApBg9uoTanSlJPX_iozMM0pFriPXXtvvy6MfVmB39Qa4ZsAb8idsq5xMkVzPDgrXRspbcXyFx-pb59_Env6rwJLeXMlUc1qwhjvMHbAJVGXlWsZWCUTF8DZmM-5y7wnDTBldCdalTFR44qH_ShvgfgFd0REdLe8KMg5pxOg4wxVh6_C_6Pjftn9prIFzbQ5ZrJYabA";
              // const imageAlt = "placeholder";

              return (
                <div className="col" key={process.id}>
                  <GenericCard
                    id={process.id.toString()}
                    isActive={isActive}
                    header={process.attributes.title}
                    description={process.attributes.desc}
                    links={links}
                    linkSectionMoreButton={linkSectionMoreButton}
                    onClick={() => handleProcessClick(process.id)}
                    overlayTriggers={{
                      header: process.attributes.title,
                      description: process.attributes.desc,
                    }}
                    customClass="process-cards"
                    footer={renderOwners()}
                    // imageSrc={imageSrc}
                    // imageAlt={imageAlt}
                    // isHover={true}
                    // headerIcon={defaultImg} // Keeping this code for future reference
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex justify-content-center no-data-check">
            <ImageSvgs name="no_data" />
          </div>
        )}
      </div>
      {showDetail && (
        <ProcessDetailsPage
          hide={handleClose}
          show={true}
          activeData={true}
          activeBtn={true}
          deleted={false}
          setIsDeleted={true}
          allLoading={allLoading}
          setDeleteFlag={setDeleteFlag}
        />
      )}
    </>
  );
};

export default ProcessRender;
