import React, { useState, useEffect, useRef } from "react";
import "../ImageDragDrop/ImageDragDrop.scss";
import { imageIcon } from "../../utils/iconUtils";

function removeItems(arr, item) {
  for (var i = 0; i < item; i++) {
    arr.pop();
  }
}

function useFiles({ initialState = [], maxFiles }) {
  const [state, setstate] = useState(initialState);
  function withBlobs(files) {
    const destructured = [...files];
    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeItems(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        if (file.type.includes("image")) {
          file.preview = URL.createObjectURL(file);
          return file;
        }
        return null;
      })
      .filter((elem) => elem !== null);

    setstate(blobs);
  }
  return [state, withBlobs];
}

function Upload({ onDrop, maxFiles = 1 }) {
  const [over, setover] = useState(false);
  const [files, setfiles] = useFiles({ maxFiles });
  const validTypes = ["image/jpg", "image/jpeg", "image/png"];
  const $input = useRef(null);
  useEffect(() => {
    if (onDrop) {
      onDrop(files);
    }
    // eslint-disable-next-line
  }, [files]);
  return (
    <>
      <div
        onClick={() => {
          $input.current.click();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.persist();
          setfiles(e.dataTransfer.files);
          setover(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setover(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setover(false);
        }}
        className={over ? "upload-container over" : "upload-container"}
      >
        <p>{imageIcon} Upload or drop an image here(less than 2MB)</p>
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          ref={$input}
          onChange={(e) => {
            setfiles(e.target.files);
          }}
          multiple={maxFiles > 1}
        />
      </div>
      <div className="blob-container">
        {files.map((file) => (
          <>
            {file.size <= 2000000 && validTypes.includes(file["type"]) ? (
              <>
                <p>Image Preview:</p>
                <img
                  key={file.name + "file"}
                  src={file.preview}
                  alt="your file"
                />
              </>
            ) : (
              <p className="text-danger">
                File size is too large/Invalid image type.
              </p>
            )}
          </>
        ))}
      </div>
    </>
  );
}

export { Upload };
