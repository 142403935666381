import CustomNode from "./CustomNode";
import { useCallback, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useNodesState,
  useEdgesState,
  Controls,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "../AppAdminComponents/AppStyles.scss";
import { addProcess, updateProcessData } from "../../utils/processUtil";
import { useNavigate } from "react-router-dom";

const nodeTypes = { textUpdater: CustomNode };

const AddProcessSteps = ({
  setPageLevel,
  setFlag,
  pageLevel,
  setTableLoad,
}) => {
  const navigate = useNavigate();
  const processData = JSON.parse(sessionStorage.getItem("processPayload"));

  const processConfig = sessionStorage.getItem("processConfig")
    ? JSON.parse(sessionStorage.getItem("processConfig"))
    : null;
  let id = processConfig ? processConfig?.nodes.length + 1 : 1;

  const getId = () => `${id++}`;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const sanitizeNodesAndEdges = (nodes, edges) => {
    const clonedNodes = nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        label: undefined,
      },
    }));

    return { nodes: clonedNodes, edges: edges };
  };

  const renderLabel = (nodeData) => (
    <CustomNode
      data={{
        name: nodeData.name,
        description: nodeData.description,
      }}
      isConnectable={true}
    />
  );

  useEffect(() => {
    (async () => {
      if (processConfig) {
        setNodes([]);
        processConfig?.nodes?.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,

            data: {
              name: elem.data.name,
              description: elem.data.description,
              type: "textUpdater",
              label: (
                <CustomNode
                  data={{
                    name: elem.data.name,
                    description: elem.data.description,
                  }}
                  isConnectable={true}
                />
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });

        setEdges(processConfig?.edges);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const addNode = useCallback((props) => {
    const id = getId();
    const newNode = {
      id,
      position: { x: 0, y: 0 },

      data: {
        name: props.name,
        description: props.desc,
        type: "textUpdater",
        label: (
          <CustomNode
            data={{
              name: props.name,
              description: props.desc,
            }}
            isConnectable={true}
          />
        ),
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setName("");
    setDesc("");
    // eslint-disable-next-line
  }, []);

  const onConnect = useCallback(
    (connection) => {
      const styledConnection = {
        ...connection,
        type: "step",
        markerEnd: {
          type: "arrowclosed",
          width: 15,
          height: 15,
          color: "#8A00E5",
        },
        style: {
          strokeWidth: 1,
          stroke: "#8A00E5",
        },
      };

      setEdges((eds) => addEdge(styledConnection, eds));
    },
    [setEdges]
  );

  useEffect(() => {
    if (selectedNode) {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === selectedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                description: selectedNode.data.description,
              },
            };
          }
          return node;
        })
      );
    }
  }, [selectedNode, setNodes]);

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
    setName(node.data.name);
    setDesc(node.data.description);
  };

  const onSave = () => {
    if (selectedNode) {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === selectedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                name: name,
                description: desc,
              },
            };
          }
          return node;
        })
      );
    } else {
      addNode({
        name: name && name,
        desc: desc && desc,
      });
    }
    clearFields();
    sanitizeNodesAndEdges(nodes, edges);
  };

  const onDeleteNode = () => {
    if (selectedNode) {
      setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
      setEdges((eds) =>
        eds.filter(
          (edge) =>
            edge.source !== selectedNode.id && edge.target !== selectedNode.id
        )
      );
      clearFields();
      sanitizeNodesAndEdges(nodes, edges);
    }
  };

  const clearFields = () => {
    setName("");
    setDesc("");
    setSelectedNode(null);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="mb-3">
          <h4 className="fw-bold">
            {processData && processData?.data?.attributes?.title}
          </h4>
          <h6>{processData && processData?.data?.attributes?.desc}</h6>
        </div>
        <div className="layers-section d-flex w-100">
          <div className="list-section w-25 me-3 border">
            <span className="select-tool mx-auto">
              <div className="d-flex justify-content-between align-items-center">
                <p className="fw-bold mt-3 mb-2 fs-5">Add Process Steps</p>
                <button
                  type="button"
                  className="app-tertiary-btn ms-3 mt-1 btn btn-primary"
                  onClick={clearFields}
                >
                  X
                </button>
              </div>
              <div className="app-primary-input-field mt-2 mx-auto">
                <Form className="d-flex flex-column border-0 p-0">
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      <b>Enter Title:</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Add Title"
                      value={name}
                      name="tool_description"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      <b>Enter Description:</b>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Add Description"
                      value={desc}
                      name="tool_description"
                      rows={4}
                      onChange={(e) => {
                        setDesc(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  className="app-primary-btn me-2"
                  disabled={!selectedNode}
                  onClick={onDeleteNode}
                >
                  Delete
                </Button>
                <Button
                  className="app-primary-btn"
                  disabled={name?.length > 0 ? false : true}
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
            </span>
          </div>
          <div className="react-flow-section w-75 border">
            <div className="flow-canvas">
              <ReactFlow
                // nodes={nodes}
                nodes={nodes.map((node) => ({
                  ...node,
                  data: {
                    ...node.data,
                    label: renderLabel(node.data),
                  },
                }))}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                nodesDraggable={true}
                draggable={false}
                deleteKeyCode={["Delete", "Backspace"]}
                onNodeClick={onNodeClick}
                onNodesDelete={clearFields}
              >
                <Controls />
              </ReactFlow>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 mt-3">
          <Button
            className="app-secondary-btn my-auto me-2"
            onClick={() => {
              sessionStorage.setItem(
                "processConfig",
                JSON.stringify({
                  nodes: nodes && nodes,
                  edges: edges && edges,
                })
              );
              pageLevel === "addSteps"
                ? setPageLevel("addProcess")
                : setPageLevel("editProcess");
            }}
          >
            Cancel
          </Button>
          <Button
            className="app-primary-btn"
            onClick={() => {
              setLoading(true);
              if (pageLevel === "addSteps") {
                addProcess({
                  processData,
                  processConfig:
                    nodes?.length > 0 || edges?.length > 0
                      ? {
                          nodes: nodes && nodes,
                          edges: edges && edges,
                        }
                      : null,
                  setPageLevel: setPageLevel,
                  setFlag: setFlag,
                  setLoading: setLoading,
                  setTableLoad: setTableLoad,
                  navigate: navigate,
                });
              } else {
                updateProcessData({
                  processData,
                  processConfig:
                    nodes?.length > 0 || edges?.length > 0
                      ? {
                          nodes: nodes && nodes,
                          edges: edges && edges,
                        }
                      : null,
                  setPageLevel: setPageLevel,
                  setFlag: setFlag,
                  setLoading: setLoading,
                  navigate: navigate,
                });
              }
            }}
          >
            {pageLevel === "addSteps" && !loading
              ? "Create Process"
              : pageLevel === "addSteps" && loading
              ? "Creating Process..."
              : pageLevel === "editSteps" && !loading
              ? "Update Process"
              : pageLevel === "editSteps" && loading
              ? "Updating Process..."
              : "Create Process"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddProcessSteps;
