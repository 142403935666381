import React from "react";
import Details from "./Details";
import AppSubComp from "./AppSubComp";
import { useState, useEffect } from "react";
import Loader from "../Loader/Loader";

const MainAppScreen = (props) => {
  const sortedList = props.source.appData.data.sort((a, b) =>
    a.attributes["app-title"].localeCompare(b.attributes["app-title"])
  );
  const [appOrg, setAppOrg] = useState(1);
  const [check, setCheck] = useState(true);
  const [selectedApp, setSelectedApp] = useState(null);
  const [activeAppID, setActiveAppId] = useState(null);
  const [filterAppList, setFilterAppList] = useState([]);

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      if (
        url.searchParams.get("orgId") === "0" &&
        url.searchParams.get("appId") === "0"
      ) {
        handleOrgChange(1);
      } else {
        let orgId = url.searchParams.get("orgId");
        let appId = url.searchParams.get("appId");
        let newList = [];
        sortedList?.forEach((elem) => {
          if (
            elem.attributes["app-org"].appOrg.filter(
              (org) => org.id.toString() === orgId.toString()
            ).length > 0
          ) {
            newList.push(elem);
          }
        });
        let app = sortedList?.filter(
          (elem) => elem.id.toString() === appId.toString()
        )[0];
        setFilterAppList(newList);
        setAppOrg(orgId);
        setActiveAppId(appId);
        // setPrevId(appId);
        setSelectedApp(app);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const getFilteredList = (idx) => {
    setFilterAppList([]);
    let newList = [];
    sortedList.forEach((elem) => {
      if (
        elem.attributes["app-org"].appOrg.filter(
          (org) => org.id.toString() === idx.toString()
        ).length > 0
      ) {
        newList.push(elem);
      }
    });
    return newList;
  };

  const handleAppChange = async (idx) => {
    setSelectedApp(null);
    if (activeAppID.toString() !== idx.toString()) {
      let app = sortedList?.filter(
        (elem) => elem.id.toString() === idx.toString()
      )[0];

      await setCheck(false);
      addQueryParam("appId", idx);
      await setActiveAppId(idx);
      await setSelectedApp(app);
      await setCheck(true);
    }
  };

  const handleOrgChange = async (idx) => {
    setSelectedApp(null);
    await setCheck(false);
    let newList = await getFilteredList(idx);
    let defVal = newList[0];

    addQueryParam("orgId", idx);
    addQueryParam("appId", defVal.id);

    await setFilterAppList(newList);
    await setAppOrg(idx);
    await setActiveAppId(defVal.id);
    await setSelectedApp(defVal);
    await setCheck(true);
  };

  const handleSearchFilter = (name) => {
    const newList = sortedList?.filter((item) =>
      item.attributes["app-title"].toLowerCase().includes(name.toLowerCase())
    );
    setFilterAppList(newList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="appMap-page">
      {filterAppList ? (
        <div className="mainDiv">
          <div className="leftSide">
            <h5 className="appHead">Application list:-</h5>
            <p
              style={{
                marginLeft: "20px",
                marginBottom: "0px",
                marginTop: "0px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Select Corporate Function:
            </p>
            <select
              value={appOrg}
              onChange={(e) => {
                setAppOrg(e.target.value);
                handleOrgChange(e.target.value);
              }}
              style={{
                borderColor: "#cfcfcf",
                lineHeight: "24px",
                padding: "7px 0 7px 15px",
                margin: "5px 20px 6px",
                width: "85%",
              }}
            >
              {props.source.orgData.map((elem, id) => {
                return (
                  <option value={elem.id} key={id}>
                    {elem.name}
                  </option>
                );
              })}
            </select>

            <form className="search-app" onSubmit={(e) => handleSubmit(e)}>
              <div
                className="app-primary-input-field"
                style={{ width: "16rem" }}
              >
                <input
                  type="search"
                  placeholder="Search Application..."
                  onChange={(e) => handleSearchFilter(e.target.value)}
                />
              </div>
            </form>
            <div className="appList" style={{ marginTop: "10px" }}>
              {filterAppList?.map((el, key) => {
                return (
                  <div
                    className={`${
                      activeAppID.toString() === el.id.toString()
                        ? "border-bottom appTag act"
                        : "border-bottom appTag"
                    }`}
                    key={el.id}
                    onClick={() => {
                      if (activeAppID.toString() !== el.id.toString()) {
                        handleAppChange(el.id);
                      }
                    }}
                  >
                    <span className="capitalize" style={{ fontSize: "15px" }}>
                      {el.attributes["app-title"]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rightSide d-flex flex-column">
            <div className="titDiv">
              <div className="innerDiv">
                {selectedApp ? (
                  <Details
                    data={selectedApp && selectedApp}
                    included={props.source.appData.included}
                  />
                ) : null}
              </div>
            </div>
            <div className="section">
              <div className="innerSec">
                {check && selectedApp && (
                  <AppSubComp
                    data={selectedApp}
                    included={props.source.appData.included}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", height: "35rem" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default MainAppScreen;
