import React, { Component } from "react";
import Details from "./V2Details";
import Diagram from "./V2Diagram";
// import Details from "./Details";
// import Diagram from "./Diagram";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";

import Loader from "../Loader/Loader";

import "./style.scss";

export default class ApplicationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appOrg: "SE FIN GS",
      toolData: null,
      appList: [],
      mapData: [],
      selectedApp: null,
      activeAppID: null,
      peopleData: [],
      filterAppList: [],
      searchValue: "",
      uniqueList: [],
    };
    this.setAppOrg = this.setAppOrg.bind(this);
  }

  componentDidMount() {
    this.setMapData();
  }

  setMapData = async () => {
    const param = `${getBaseUrl()}${this.props.source["source-url"]}`;
    const res = await getData(param);
    const orgList = res.data.appDetails.map((elem) => elem.appOrg);
    const uniqueList = orgList.filter((q, idx) => orgList.indexOf(q) === idx);
    const sortedList = res.data.appDetails.sort((a, b) =>
      a.appTitle.localeCompare(b.appTitle)
    );

    let tInfo = [],
      objdata,
      peoArr;
    res?.data?.appNodes[0].nodes.forEach((el, i) => {
      res?.data?.nodeDetails.forEach((ob, i) => {
        objdata = {
          id: el.node,
          name: ob.nodeName,
          desc: el.nodeDesc,
          layer: el.layer,
          parent: el.parentNode,
        };
        if (el.node === ob.nodeID) {
          tInfo.push(objdata);
        }
      });
    });

    peoArr = res?.data?.people
      ?.map((ob, i) => {
        return (
          res?.data?.appDetails[0]?.ownerDetails.includes(ob.personId) && {
            id: ob.personId,
            name: ob.personName,
            email: ob.emailId,
          }
        );
      })
      .filter(Boolean);

    this.setState({
      toolData: res?.data,
      appList: res?.data?.appDetails,
      filterAppList: sortedList,
      selectedApp: res?.data?.appDetails[0],
      activeAppID: res?.data?.appDetails[0].appId,
      peopleData: peoArr,
      mapData: tInfo,
      uniqueList: uniqueList,
    });
  };

  setAppOrg = (e) => {
    this.setState({
      appOrg: e.target.value,
    });
  };

  setActiveApp = (idx) => {
    let { toolData } = this.state;
    this.setState({ activeAppID: idx });
    let selObj = {},
      peoArr;

    selObj = toolData.appDetails.find((o) => o.appId === idx);

    peoArr = toolData?.people
      ?.map((ob, i) => {
        return (
          selObj?.ownerDetails.includes(ob.personId) && {
            id: ob.personId,
            name: ob.personName,
            email: ob.emailId,
          }
        );
      })
      .filter(Boolean);

    let tInfo = [],
      objdata;
    toolData?.appNodes.forEach((el, i) => {
      el.appId === idx &&
        el?.nodes.forEach((el, i) => {
          toolData?.nodeDetails.forEach((ob, i) => {
            objdata = {
              id: el.node,
              name: ob.nodeName,
              desc: el.nodeDesc,
              layer: el.layer,
              parent: el.parentNode,
            };
            if (el.node === ob.nodeID) {
              tInfo.push(objdata);
            }
          });
        });
    });
    this.setState({
      selectedApp: selObj,
      peopleData: peoArr,
      mapData: tInfo,
    });
  };

  handleSearchFilter = (e) => {
    let { appList } = this.state;
    const newList = appList.filter((item) =>
      item.appTitle.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ searchValue: e.target.value, filterAppList: newList });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    let {
      selectedApp,
      activeAppID,
      peopleData,
      searchValue,
      filterAppList,
      mapData,
      appList,
      appOrg,
      uniqueList,
    } = this.state;

    return (
      <div className="appMap-page">
        {appList?.length > 0 ? (
          <div className="mainDiv">
            <div className="leftSide">
              <h5 className="appHead">Application list:-</h5>
              <p
                style={{
                  marginLeft: "20px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Choose org:
              </p>
              <select
                value={this.state.appOrg}
                onChange={this.setAppOrg}
                style={{
                  borderColor: "#cfcfcf",
                  lineHeight: "24px",
                  padding: "7px 0 7px 15px",
                  margin: "5px 20px 12px",
                  width: "85%",
                }}
              >
                {uniqueList.map((elem, id) => {
                  return (
                    <option value={elem} key={id}>
                      {elem}
                    </option>
                  );
                })}
              </select>

              <form
                className="search-app"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <input
                  className="search-app"
                  type="search"
                  placeholder="Search application name"
                  value={searchValue}
                  onChange={(e) => this.handleSearchFilter(e)}
                />
              </form>
              <div className="appList">
                {filterAppList?.map((el, key) => {
                  if (el.appOrg === appOrg) {
                    return (
                      <div
                        className={`${
                          activeAppID === el.appId
                            ? "appTag act border-bottom"
                            : "appTag border-bottom"
                        }`}
                        key={el.appId}
                        onClick={() => this.setActiveApp(el.appId)}
                      >
                        <span className="capitalize">{el.appTitle}</span>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div className="rightSide">
              <div className="titDiv">
                <div className="innerDiv">
                  <Details data={selectedApp} ownerInfo={peopleData} />
                </div>
              </div>
              <div className="section">
                <div className="innerSec">
                  <Diagram data={mapData} appId={activeAppID} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}
