import React from "react";
import { useQuery, useClient } from "jsonapi-react";
import { useMsal } from "@azure/msal-react";
import "../FavoritesTable/FavoritesTable.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import FolderTable from "../CustomTable/FolderTable";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import {
  signatureInvaliddMsg,
  sessionExpiredMsg,
} from "../../config/Constants";
import { prevArrow } from "../../utils/iconUtils";
import { Nav } from "react-bootstrap";
import ConfirmationBox from "../CustomTable/ConfirmationBox";
import { useState } from "react";

const GetFavoritesData = () => {
  const { instance } = useMsal();
  const { data, message } = useQuery([
    "user-favorites",
    { use_pagination: false, include: ["favorites"] },
  ]);

  if (message && message === signatureInvaliddMsg) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(sessionExpiredMsg)) {
      instance.logoutRedirect();
    }
  }
  return data;
};

const FavoritesTable = () => {
  const navigate = useNavigate();
  const client = useClient();
  const data = GetFavoritesData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState();

  const handleClick = (event) => {
    event.preventDefault();

    if (sessionStorage.getItem("flagCheck") === "true") {
      setIsModalOpen(true);
    } else {
      navigate("/");
    }
    setUrl("/");
  };

  const onOkClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate(url);
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", true);
  };

  const onSaveClick = async () => {
    const folderList = JSON.parse(sessionStorage.getItem("updatedList"));

    let counter = folderList.length;

    folderList.forEach((favorite) => {
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });

    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate("/");
  };

  if (!data) {
    return (
      <div style={{ maxWidth: "100%", height: "100vh" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="main">
        <div className="a">
          <Nav.Link onClick={handleClick}>{prevArrow}Favorites</Nav.Link>
        </div>
      </div>

      <div className="table-inner">
        <FolderTable data={data} />
      </div>
      <ConfirmationBox
        showDialog={isModalOpen}
        cancelNavigation={onCancelClick}
        confirmNavigation={onOkClick}
        saveNavigation={onSaveClick}
      />
    </div>
  );
};

export default FavoritesTable;
