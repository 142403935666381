import React from "react";
import { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import worldMap from "./mapData.json";
import { Tooltip } from "react-tooltip";
import moment from "moment-timezone";
// import "./MapComp.scss";

const MapComp = ({ empKey, empList, locationList }) => {
  var tzlookup = require("tz-lookup");
  const [markersMap, setMarkersMap] = useState(null);
  const [employeesList, setEmployeesList] = useState(null);

  useEffect(() => {
    (async () => {
      let tempList = empList?.map((elem) => {
        return {
          type: "employee",
          attributes: {
            "email-id": elem.attributes["email-id"],
            processes: elem.attributes.processes,
            "preferred-name": elem.attributes["preferred-name"],
            "l-name": elem.attributes["l-name"],
            "profile-picture": elem.attributes["profile-picture"],
            location: elem.attributes.location,
            city: locationList?.filter(
              (mark) => mark?.locn_abbr === elem.attributes.location
            )[0]
              ? locationList?.filter(
                  (mark) => mark?.locn_abbr === elem.attributes.location
                )[0]?.location
              : "",
            "f-name": elem.attributes["f-name"],
            topics: elem.attributes.topics,
          },
          id: elem.id,
        };
      });

      setEmployeesList(tempList);

      if (empKey === "0") {
        let uniqueLocations = [
          ...new Map(
            tempList?.map((item) => [
              item.attributes.location,
              item.attributes.location,
            ])
          ).values(),
        ];

        let locationMap = locationList?.filter((elem) => {
          if (uniqueLocations?.includes(elem.locn_abbr)) {
            return elem;
          }
          return null;
        });

        setMarkersMap(locationMap);
      } else {
        let locationMap = locationList?.filter(
          (elem) => elem?.locn_abbr === empKey?.attributes.location
        )[0];
        if (locationMap) {
          setMarkersMap([locationMap]);
        } else {
          setMarkersMap([]);
        }
      }
    })();
    // eslint-disable-next-line
  }, [empKey]);

  return (
    <div className="location-map">
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 100 }}
        // height={400}
        // width={800}
      >
        <ZoomableGroup center={[0, 0]} zoom={1}>
          <Geographies geography={worldMap}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: { fill: "#D6D6DA", outline: "none" },
                    hover: { fill: "#D6D6DA", outline: "none" },
                    pressed: { fill: "#D6D6DA", outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
          {markersMap?.map(({ name, coordinates, locn_abbr, markerOffset }) => (
            <>
              <Marker key={name} coordinates={coordinates}>
                <circle
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={locn_abbr}
                  r={3}
                  fill="#8a00e5"
                  stroke="#fff"
                  strokeWidth={0.5}
                  style={{ cursor: "pointer" }}
                />
              </Marker>
            </>
          ))}
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip
        id="my-tooltip"
        render={({ content, activeAnchor }) => {
          let tempList = [];
          let tempCords = locationList?.filter(
            (elem) => elem?.locn_abbr && elem?.locn_abbr === content
          )[0]?.coordinates;
          const timezone = tzlookup(
            tempCords ? tempCords[1] : 0,
            tempCords ? tempCords[0] : 0
          );
          const localTime = moment.tz(timezone && timezone).format("HH:mm:ss");

          if (empKey && empKey === "0") {
            let tempLoc = locationList?.filter(
              (elem) => elem?.locn_abbr && elem?.locn_abbr === content
            )[0]?.location;
            employeesList?.map((elem) => {
              if (
                elem?.attributes?.city &&
                elem?.attributes?.city === tempLoc
              ) {
                tempList.push(elem);
              }
              return null;
            });
          } else {
            employeesList?.map((elem) => {
              if (
                elem?.attributes?.location &&
                elem?.attributes?.location === content
              ) {
                tempList.push(elem);
              }
              return null;
            });
          }

          return (
            <div className="d-flex flex-column">
              <span className="mb-2 d-flex">
                <p className="mb-0 fw-bold me-2">Country:</p>
                <p className="mb-0">
                  {locationList?.filter(
                    (elem) => elem?.locn_abbr === content
                  )[0]
                    ? locationList?.filter(
                        (elem) => elem?.locn_abbr === content
                      )[0].country
                    : ""}
                </p>
              </span>
              <span className="mb-2 d-flex">
                <p className="mb-0 fw-bold me-2">Location:</p>
                <p className="mb-0">
                  {locationList?.filter(
                    (elem) => elem?.locn_abbr === content
                  )[0]
                    ? locationList?.filter(
                        (elem) => elem?.locn_abbr === content
                      )[0]?.location
                    : ""}

                  {empKey === "0" ? "" : ` (${content})`}
                </p>
              </span>
              <span className="mb-2 d-flex">
                <p className="mb-0 fw-bold me-2">Time:</p>
                <p className="mb-0">{localTime ? localTime : "-"}</p>
              </span>

              <span className="d-flex flex-column">
                <p className="mb-0 fw-bold">People:</p>
                {tempList?.map((elem) => {
                  return (
                    <p className="mb-0">
                      {elem?.attributes?.["preferred-name"]}
                    </p>
                  );
                })}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MapComp;
