import React from "react";
import { useState, useEffect, useRef } from "react";
import Loader from "../Loader/Loader";
import { chunk } from "lodash";
import ImageSvgs from "../ImageSvgs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsTreemap from "highcharts/modules/treemap";
import HighChartsHeatmap from "highcharts/modules/heatmap";
import { getBaseUrl } from "../../config/environment";
import noData from "../../assets/images/noData.svg";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import CarouselConnections from "../CommonComponents/CarouselConnections";
import CarouselExpertComponent from "../CommonComponents/CarouselExpertComponent";
HighchartsExporting(Highcharts);
HighchartsTreemap(Highcharts);
HighChartsHeatmap(Highcharts);

const UiTreemap = (props) => {
  const toolsList = props.data.tools;
  const defValue = props?.data.defValue;
  const included = props?.data.included;
  const [mapData, setMapData] = useState();
  const [toolDetails, setToolDetails] = useState();
  const [toolRelations, setToolRelations] = useState();
  const [copy, setCopy] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const widthRef = useRef(null);

  useEffect(() => {
    (async () => {
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    })();

    // eslint-disable-next-line
  }, [toolDetails]);

  useEffect(() => {
    (async () => {
      setMapData(toolsList);
      const url = new URL(window.location.href);
      const isFound = toolsList.some((elem) => {
        if (elem.id === url.searchParams.get("toolId")) {
          return true;
        }
        return false;
      });
      // eslint-disable-next-line
      if (url.searchParams.get("toolId") == 0 || !isFound) {
        addQueryParam("toolId", defValue);
        findTool(defValue);
      } else {
        findTool(url.searchParams.get("toolId"));
      }
    })();
    // eslint-disable-next-line
  }, [toolsList, defValue]);

  async function findTool(id) {
    let tool = toolsList?.filter((tool) => tool.id === id)[0];
    let relations = tool["toolDetails"]?.relationships;

    let tempInfo =
      relations?.["tool-info"].data.length > 0
        ? relations?.["tool-info"].data.map((elem) => {
            return included?.filter(
              (info) =>
                info.type === "tool-additional-info" &&
                info.id.toString() === elem.id.toString()
            )[0];
          })
        : [];

    let expertRelations =
      relations?.experts.data.length > 0
        ? relations.experts.data.map((elem) => {
            return included?.filter(
              // eslint-disable-next-line
              (expert) =>
                expert.type === "experts" &&
                expert.id.toString() === elem.id.toString()
            )[0].attributes.employee.data;
          })
        : [];
    let appRelations =
      relations?.apps.data.length > 0
        ? relations.apps.data.map((elem) => {
            return included?.filter(
              // eslint-disable-next-line
              (app) => app.type === "application" && app.id == elem.id
            )[0];
          })
        : [];
    let commRelations =
      relations?.communities.data.length > 0
        ? relations.communities.data.map((elem) => {
            return included?.filter(
              // eslint-disable-next-line
              (comm) => comm.type === "community" && comm.id == elem.id
            )[0].attributes.communities.data;
          })
        : [];
    let trainRelations =
      relations?.trainings.data.length > 0
        ? relations.trainings.data.map((elem) => {
            return included?.filter(
              (training) =>
                // eslint-disable-next-line
                training.type === "training" && training.id == elem.id
            )[0].attributes["training-master"].data;
          })
        : [];

    let finalData = {
      toolInfo: tempInfo,
      experts: chunk(
        expertRelations.sort((a, b) =>
          a.attributes["preferred-name"].localeCompare(
            b.attributes["preferred-name"]
          )
        ),
        4
      ),
      applications:
        appRelations.length > 0
          ? chunk(
              appRelations.sort((a, b) =>
                a.attributes["app-title"].localeCompare(
                  b.attributes["app-title"]
                )
              ),
              5
            )
          : [],
      communities:
        commRelations.length > 0
          ? chunk(
              commRelations.sort((a, b) =>
                a.attributes["community-name"].localeCompare(
                  b.attributes["community-name"]
                )
              ),
              5
            )
          : [],
      trainings:
        trainRelations.length > 0
          ? chunk(
              trainRelations.sort((a, b) =>
                a.attributes["training-name"].localeCompare(
                  b.attributes["training-name"]
                )
              ),
              5
            )
          : [],
    };
    await setToolDetails(tool);
    await setToolRelations(finalData);
  }

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const options = {
    colorAxis: {
      minColor: "#d9d1ac",
      maxColor: "#8C7500",
      visible: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat:
        "<b>Name: </b>{point.name}<br><b>Application(s): </b>{point.value}",
    },
    plotOptions: {
      series: {
        cursor: "pointer",

        allowPointSelect: true,
        events: {
          click: async function (event) {
            await findTool(event.point.node.id);
            addQueryParam("toolId", event.point.node.id);
            setCopy(false);
          },
        },
      },
    },
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        dataLabels: [
          {
            format: "{point.name} ({point.value})",
          },
        ],
        data: mapData,
      },
    ],
  };

  return (
    <div className="d-flex flex-column">
      {mapData ? (
        <>
          <div className="d-flex flex-row w-100 pb-2 border-bottom">
            <div className="highchart-comp">
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className="d-flex flex-column justify-content-between ms-2 page-right-section">
              <div>
                <div className="d-flex mb-2 mt-2">
                  {/* eslint-disable-next-line  */}
                  {toolDetails?.toolDetails.attributes["tool-image"] != null ? (
                    <img
                      className="tool-image"
                      src={`${getBaseUrl()}${
                        toolDetails?.toolDetails.attributes["tool-image"]
                      }`}
                      alt="tool"
                    />
                  ) : (
                    <ImageSvgs className="mt-2" name="uiLayer" />
                  )}
                  <h4 className="mt-2 ms-2 fw-bold">{toolDetails?.name}</h4>
                  <button
                    className="ms-2 bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={!copy ? "Copy tool link" : "Copied"}
                    disabled={copy}
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      setCopy(true);
                      setTimeout(() => {
                        setCopy(false);
                      }, 1500);
                    }}
                  >
                    {!copy ? (
                      <>
                        <ImageSvgs name="expertCopy" />
                      </>
                    ) : (
                      <>
                        <ImageSvgs name="tickMark" />
                      </>
                    )}
                  </button>
                </div>

                <div className="tool-description ms-2">
                  <p className="mb-0 limited-space ms-0" ref={widthRef}>
                    <b>Description: </b>
                    <div style={{ whiteSpace: "pre-line" }}>
                      {toolDetails?.toolDetails.attributes[
                        "tool-description"
                      ]?.replace(/\\n/g, "\n")}
                    </div>
                  </p>
                  <p
                    className="mt-0 mb-0 read-more-btn"
                    style={{ cursor: "pointer", color: "#8a00e5" }}
                    onClick={() => {
                      setShowInfoModal(true);
                    }}
                  >
                    {isOverflowing ? "Read more" : null}
                  </p>
                </div>
              </div>
              <div>
                <div className="ms-2">
                  {toolRelations?.toolInfo.length > 0 ? (
                    <b className="me-2">Further Information:</b>
                  ) : (
                    <b>
                      Further Information:<b className="text-muted ms-2">N/A</b>
                    </b>
                  )}
                  <div className="d-flex flex-column">
                    {toolRelations?.toolInfo.length > 0 ? (
                      <>
                        {chunk(toolRelations?.toolInfo, 3).map((record) => {
                          return (
                            <div className="d-flex mb-2">
                              {record.map((elem) => {
                                return (
                                  <div
                                    style={{ width: "33%" }}
                                    className="d-flex me-2"
                                  >
                                    <span
                                      onClick={() => {
                                        // setId(data.id);
                                        navigator.clipboard.writeText(
                                          elem.attributes["info-URL"]
                                        );
                                      }}
                                      className="me-1 h5"
                                      style={{
                                        color: "#8A00E5",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <ImageSvgs name="expertCopy" />
                                    </span>
                                    <a
                                      href={elem.attributes["info-URL"]}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="trainings-link my-auto"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {elem.attributes["info-title"]}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <h6 className="ms-2 mb-2 fw-bold">
                  Power users of {toolDetails?.name} :-
                </h6>
                <div className="d-flex flex-row justify-content-between">
                  {toolRelations?.experts.length > 0 ? (
                    <CarouselExpertComponent data={toolRelations?.experts} />
                  ) : (
                    <div className="ms-2 mb-3 d-flex flex-row border justify-content-center w-100 expert-no-data">
                      <img src={noData} alt="No data available" />
                      <h5 className="my-auto fw-bold">No data available</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <>
              <h6>
                <b>Connections in FIN GS :-</b>
              </h6>
              <div className="p-3 page-connections-section">
                <div className="mt-2">
                  {toolRelations?.applications.length > 0 ? (
                    <h6 className="fw-bold ms-4 d-flex">Applications:</h6>
                  ) : (
                    <h6 className="fw-bold ms-4 d-flex">
                      Applications:<p className="mb-0 ms-2 text-muted">N/A</p>
                    </h6>
                  )}
                  <div className="d-flex flex-row justify-content-center">
                    {toolRelations?.applications.length > 0 ? (
                      <CarouselConnections data={toolRelations?.applications} />
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  {toolRelations?.communities.length > 0 ? (
                    <h6 className="fw-bold ms-4 d-flex">Communities:</h6>
                  ) : (
                    <h6 className="fw-bold ms-4 d-flex">
                      Communities:<p className="mb-0 ms-2 text-muted">N/A</p>
                    </h6>
                  )}

                  <div className="d-flex flex-row justify-content-center">
                    {toolRelations?.communities.length > 0 ? (
                      <CarouselConnections data={toolRelations?.communities} />
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  {toolRelations?.trainings.length > 0 ? (
                    <h6 className="fw-bold ms-4 d-flex">Trainings:</h6>
                  ) : (
                    <h6 className="fw-bold ms-4 d-flex">
                      Trainings:<p className="mb-0 ms-2 text-muted">N/A</p>
                    </h6>
                  )}
                  <div className="d-flex flex-row justify-content-center">
                    {toolRelations?.trainings.length > 0 ? (
                      <CarouselConnections data={toolRelations?.trainings} />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
            {showInfoModal ? (
              <MyVerticallyCenteredModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                data={toolDetails?.toolDetails}
              />
            ) : null}
          </div>
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "35rem" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default UiTreemap;
