import { useState, useEffect } from "react";
import "./CommunitiesAdminScreen.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ImageUpload } from "../AdminComponents/ImageUpload";
import { addCommunity } from "../../utils/communitiesUtil";
import { UpdateCommunityData } from "../../utils/communitiesUtil";
import Select from "react-dropdown-select";
import { addYammerCommunity } from "../../utils/communitiesUtil";
import { addYammerCom } from "../../utils/communitiesUtil";
import { updateYammerCom } from "../../utils/communitiesUtil";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";
import "./CommunitiesAdminScreen.scss";
import "../../assets/scss/common.scss";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import CommunityChannelModal from "./CommunityChannelModal";

export default function AddNewCommunity(props) {
  const applicationDataAll = props?.["applicationData"];
  const communityDataAll = props?.["communityDataValue"];
  const toolsAll = props?.["toolsData"];
  const processDataAll = props?.["processData"];
  const communityPageLevel = props?.["pageLevel"];
  const editCommunityData = props?.["editCommunityData"];
  const communityTagsData = props?.communityTags;
  const [showModal, setShowModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [ownerCheck, setOwnerCheck] = useState("");
  const [communityImage, setCommunityImage] = useState(null);
  const [editCommunityImage, setEditCommunityImage] = useState(
    editCommunityData?.attributes["community-image"]
  );
  const [formErrors, setFormErrors] = useState({});
  const [check, setCheckValue] = useState(
    editCommunityData
      ? editCommunityData?.attributes["community-type"].toString()
      : "1"
  );
  const [disableField, setDisableField] = useState(false);
  const [res, setRes] = useState(false);
  const [yammerCommunityData, setYammerCommunityData] = useState();
  const yammerData = yammerCommunityData && yammerCommunityData[0];
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showCategoryModal, setCategoryModal] = useState(false);
  // eslint-disable-next-line
  const [communityTypeJson, setCommunityTypeJson] = useState({
    community_type: [
      {
        id: 1,
        name: "Application",
        type: "application",
      },
      {
        id: 2,
        name: "Process",
        type: "process",
      },
      {
        id: 3,
        name: "Tools",
        type: "tools",
      },
      {
        id: 4,
        name: "Miscellaneous",
        type: "miscellaneous",
      },
    ],
  });

  const includedValue = props?.includedData;
  const [formData, setFormData] = useState({
    communityType: editCommunityData
      ? editCommunityData?.attributes["community-type"]
      : check,
    yammerLink: editCommunityData
      ? editCommunityData?.attributes["community-link"]
      : null,
    communityLink:
      editCommunityData != null
        ? editCommunityData?.["attributes"]["community-link"]
        : null,
    communityName:
      editCommunityData != null
        ? editCommunityData?.["attributes"]["community-name"]
        : null,
    communityParticipants: "",
    communityDesc:
      editCommunityData != null
        ? editCommunityData?.["attributes"]["community-desc"]
        : null,
  });
  const [channelLinkRes, setChannelLinkRes] = useState(
    editCommunityData?.relationships["channel-info"]?.data?.length > 0
      ? editCommunityData?.relationships?.["channel-info"]?.data?.map(
          (elem) => {
            let tempData = includedValue?.filter(
              (info) =>
                info.type === "community-channel-info" &&
                info.id.toString() === elem.id.toString()
            )[0];
            return {
              name: tempData?.attributes["channel-title"],
              link: tempData?.attributes["channel-URL"],
              id: tempData?.id.toString(),
            };
          }
        )
      : []
  );

  const [communityLinks, setCommunityLinks] = useState(
    editCommunityData?.relationships["channel-info"]?.data?.length > 0
      ? editCommunityData?.relationships["channel-info"]?.data?.map((elem) => {
          let tempData = includedValue?.filter(
            (info) =>
              info.type === "community-channel-info" &&
              info.id.toString() === elem.id.toString()
          )[0];
          return {
            name: tempData?.attributes["channel-title"],
            link: tempData?.attributes["channel-URL"],
            id: tempData?.id.toString(),
          };
        })
      : []
  );

  const [existedName, setExistedName] = useState(false);
  const [existedEmail, checkExistedEmail] = useState(false);
  const upadteCheck =
    editCommunityData?.attributes["community-type"]?.toString();

  const [descriptionError, setDescriptionError] = useState("");
  const [communityLinkError, setCommunityLinkError] = useState("");

  const relationshipData = editCommunityData?.relationships;

  useEffect(() => {
    if (isDeleted) {
      setCommunityImage(null);
      setEditCommunityImage(null);
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const tempHeads =
    relationshipData && relationshipData?.heads?.data?.length > 0
      ? relationshipData &&
        relationshipData?.heads?.data
          ?.filter(
            (elem) =>
              includedValue?.filter(
                (owner) =>
                  owner.type === "heads" &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedValue?.filter(
              (owner) =>
                owner.type === "heads" &&
                owner.attributes["is-head"] === true &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "heads",
              name: ownerData?.attributes?.employee?.data?.attributes[
                "preferred-name"
              ],
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-head": true,
                "email-id":
                  ownerData?.attributes?.employee?.data?.attributes["email-id"],
                "profile-picture":
                  ownerData?.attributes?.employee?.data?.attributes[
                    "profile-picture"
                  ],
              },
              owner_flag: true,
              id: ownerData?.id,
            };
          })
      : [];

  const [communityOwners, setCommunityOwners] = useState(tempHeads);

  const tempMembers =
    relationshipData && relationshipData?.members?.data?.length > 0
      ? relationshipData &&
        relationshipData?.members?.data
          ?.filter(
            (elem) =>
              includedValue?.filter(
                (owner) =>
                  owner.type === "members" &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedValue?.filter(
              (owner) =>
                owner.type === "members" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            const isFound = tempHeads?.some((element) => {
              if (
                element.attributes["employee-id"] ===
                ownerData?.attributes["employee-id"]
              ) {
                return true;
              }
              return false;
            });

            return {
              type: "members",
              name: ownerData?.attributes?.employee?.data?.attributes[
                "preferred-name"
              ],
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-head": false,
                "email-id":
                  ownerData?.attributes?.employee?.data?.attributes["email-id"],
                "profile-picture":
                  ownerData?.attributes?.employee?.data?.attributes[
                    "profile-picture"
                  ],
              },
              owner_flag: isFound,
              id: ownerData?.id,
            };
          })
      : [];

  const toolsDataMap =
    relationshipData?.tools?.data?.length > 0
      ? relationshipData?.tools.data?.map((tools, index) => {
          let toolData = includedValue.filter(
            (tool) =>
              tool?.type === "tools" &&
              tool?.id?.toString() === tools?.id.toString()
          )[0];

          return {
            type: "tools",
            name: toolData?.attributes.tools.data?.attributes["tool-name"],
            attributes: {
              "tool-id": toolData?.attributes["tool-id"],
            },
            id: toolData?.id,
          };
        })
      : [];

  const toolOptions =
    toolsAll &&
    toolsAll?.data?.map((item) =>
      toolsDataMap?.filter(
        (tool) =>
          tool?.type === "tools" &&
          tool?.attributes["tool-id"].toString() === item?.id?.toString()
      )[0]
        ? toolsDataMap?.filter(
            (tool) =>
              tool?.type === "tools" &&
              tool?.attributes["tool-id"].toString() === item?.id?.toString()
          )[0]
        : {
            type: "tools",
            name: item?.attributes["tool-name"],
            attributes: {
              "tool-id": item?.id?.toString(),
            },
            id: null,
          }
    );

  const [commTools, setCommTools] = useState(toolsDataMap);

  const appDataMap =
    relationshipData?.apps?.data.length > 0
      ? relationshipData?.apps?.data?.map((apps, index) => {
          let appData = includedValue.filter(
            (app) =>
              app?.type === "application" &&
              app?.id?.toString() === apps?.id?.toString()
          )[0];

          return {
            type: "application",
            name: appData?.attributes["app-master"].data.attributes[
              "app-title"
            ],
            attributes: {
              "app-id": appData?.attributes["app-id"]?.toString(),
            },
            id: appData?.id?.toString(),
          };
        })
      : [];

  const appOptions =
    applicationDataAll &&
    applicationDataAll?.data?.map((item) =>
      appDataMap?.filter(
        (app) =>
          app?.type === "application" &&
          app?.attributes["app-id"].toString() === item?.id?.toString()
      )[0]
        ? appDataMap?.filter(
            (val) =>
              val?.type === "application" &&
              val?.attributes["app-id"]?.toString() === item?.id?.toString()
          )[0]
        : {
            type: "application",
            name: item?.attributes["app-title"],
            attributes: {
              "app-id": item?.id?.toString(),
            },
            id: null,
          }
    );

  const [commApps, setcommApps] = useState(appDataMap);

  const processDataMap =
    relationshipData?.["communities-process"]?.data.length > 0
      ? relationshipData?.["communities-process"]?.data?.map(
          (process, index) => {
            let processData = includedValue?.filter(
              (val) =>
                val?.type === "process" &&
                val?.id.toString() === process?.id.toString()
            )[0];
            return {
              type: "process",
              name: processData?.attributes.process?.data.attributes.title,
              attributes: {
                "process-id": processData?.attributes["process-id"],
              },
              id: process?.id?.toString(),
            };
          }
        )
      : [];

  const processOptions =
    processDataAll &&
    processDataAll?.data?.map((item) =>
      processDataMap?.filter(
        (process) =>
          process?.type === "process" &&
          process?.attributes["process-id"].toString() === item?.id?.toString()
      )[0]
        ? processDataMap?.filter(
            (val) =>
              val?.type === "process" &&
              val?.attributes["process-id"].toString() === item?.id?.toString()
          )[0]
        : {
            type: "process",
            name: item?.attributes.title,
            attributes: {
              "process-id": item?.id?.toString(),
            },
            id: null,
          }
    );

  const [commProcess, setCommProcess] = useState(processDataMap);

  // eslint-disable-next-line
  const tempCommunityTags = communityTagsData?.communityTag?.filter((value) => {
    let tagValue = editCommunityData?.attributes?.tags;
    if (tagValue?.toString() === value?.name?.toString()) {
      return {
        id: value?.id,
        name: value?.name,
      };
    }
  });

  // eslint-disable-next-line
  const [communityConnections, setCommunityConnections] = useState([]);
  const [communityMembers, setCommunityMembers] = useState(tempMembers);
  const [communityTag, setCommunityTag] = useState(tempCommunityTags);

  useEffect(() => {
    setCommunityOwners((member) => {
      return [
        ...member.filter((item) =>
          communityMembers.find(
            (member) =>
              member.attributes["employee-id"] ===
              item.attributes["employee-id"]
          )
        ),
      ];
    });
  }, [communityMembers]);

  useEffect(() => {
    let tempMembers = communityMembers?.map((item) => {
      let tempData = communityOwners?.filter(
        (emp) =>
          emp.attributes["employee-id"].toString() ===
          item.attributes["employee-id"].toString()
      )[0];

      if (
        tempData &&
        communityMembers?.filter(
          (emp) =>
            tempData.attributes["employee-id"].toString() ===
            emp.attributes["employee-id"].toString()
        )[0].owner_flag === false
      ) {
        return {
          type: "members",
          name: item?.name,
          attributes: {
            "employee-id": item?.attributes["employee-id"],
            "email-id": item?.attributes["email-id"],
            "profile-picture": item?.attributes?.["profile-picture"],
          },
          id: item?.id,
          owner_flag: true,
        };
      } else return item;
    });

    let finalList = [];
    // eslint-disable-next-line
    tempMembers.filter((employee) => {
      const isFound = communityOwners?.some((element) => {
        if (
          element.attributes["employee-id"] ===
          employee.attributes["employee-id"]
        ) {
          return true;
        }

        return false;
      });

      if (employee.owner_flag === true && isFound === false) {
      } else {
        finalList.push(employee);
      }
    });

    setCommunityMembers((member) => {
      const newAddMembers = communityOwners
        .filter((item) => {
          return (
            member.find(
              (owner) =>
                owner.attributes["employee-id"] ===
                item.attributes["employee-id"]
            ) === undefined
          );
        })
        .map((item) => ({
          type: "members",
          name: item?.attributes["preferred-name"],
          attributes: {
            "employee-id": item?.attributes["employee-id"],
            "email-id": item?.attributes["email-id"],
            "profile-picture": item?.attributes?.["profile-picture"],
          },
          id: item?.id,
          owner_flag: true,
        }));

      return [...finalList, ...newAddMembers];
    });
    // eslint-disable-next-line
  }, [communityOwners]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOwnerChange = () => {
    if (communityOwners?.length > 0) {
      setOwnerCheck("");
    } else {
      setOwnerCheck("Required");
    }
  };

  const handleToolChange = (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "tools",
        name: item.name,
        attributes: {
          "tool-id": item.attributes["tool-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setCommTools(finalValue);
  };

  const handleAppChange = (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "application",
        name: item.name,
        attributes: {
          "app-id": item.attributes["app-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setcommApps(finalValue);
  };

  const handleProcessChange = (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "process",
        name: item.name,
        attributes: {
          "process-id": item.attributes["process-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setCommProcess(finalValue);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setDescriptionError("Description limit exceeded: 1000 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseMemberModal = () => {
    setShowMemberModal(false);
  };

  const handleOpenMemberModal = () => {
    setShowMemberModal(true);
  };

  const handleCommunityTag = (value) => {
    setCommunityTag(value);
  };

  const updatedChannelLinks =
    channelLinkRes?.length > 0
      ? channelLinkRes?.map((val) => {
          return {
            type: "community-channel-info",
            attributes: {
              "channel-title": val?.name,
              "channel-URL": val?.link,
              "channel-type": val?.link?.includes("teams.microsoft.com")
                ? "Teams"
                : val?.link?.includes("sharepoint.com")
                ? "Share point"
                : val?.link?.includes("web.yammer.com")
                ? "Viva Engage"
                : "Others",
            },
            id: null,
          };
        })
      : [];

  const addNewCommunityPayload = {
    data: {
      type: "community",
      attributes: {
        community_desc: formData?.communityDesc
          ? formData?.communityDesc
          : null,

        community_type:
          formData?.communityCategory != null
            ? formData?.communityCategory
            : null,
        community_link:
          (formData?.communityLink !== "" &&
            formData?.communityLink?.includes("https:")) ||
          formData?.communityLink?.includes("http:")
            ? formData?.communityLink
            : `https://${formData?.communityLink}`,
        // community_image: communityImage !== null ? communityImage : null,
        community_image:
          !editCommunityImage && !communityImage
            ? null
            : editCommunityImage && !communityImage
            ? editCommunityImage
            : communityImage,
        community_name:
          formData?.communityName !== null ? formData?.communityName : null,
        tags: communityTag?.length > 0 ? communityTag[0]?.name : "",
      },
      relationships: {
        heads: communityOwners,
        members: communityMembers,
        apps: commApps && commApps,

        communities_process: commProcess && commProcess,

        tools: commTools && commTools,

        communityChannel: updatedChannelLinks,
      },
    },
    updateData: props.setUpdateData,
    pageLevel: props.setPageLevel,
    setLoading: setLoading,
  };

  const yammerCommunityPayLoad = {
    link: formData?.yammerLink,
    setRes: setRes,
    setYammerCommunityData: setYammerCommunityData,
  };

  const yammerPayLoad = {
    data: {
      type: "community",
      attributes: {
        community_desc: null,
        community_type: parseInt(check),

        "community-link":
          (formData?.yammerLink != null &&
            formData?.yammerLink?.includes("https:")) ||
          formData?.yammerLink?.includes("http:")
            ? formData?.yammerLink
            : `https://${formData?.yammerLink}`,
        community_image: null,
        community_name: null,
        tags: communityTag?.length > 0 ? communityTag[0]?.name : "",
      },
      relationships: {
        heads: { data: [] },
        members: { data: [] },
        apps: { data: commApps && commApps },

        communities_process: { data: commProcess && commProcess },

        tools: { data: commTools && commTools },
        "channel-info": { data: updatedChannelLinks },
      },
    },
    updateData: props.setUpdateData,
    pageLevel: props.setPageLevel,
    setLoading: setLoading,
  };

  const yammerUpdatePayLoad = {
    payloadData: {
      data: {
        type: "community",
        attributes: {
          community_type: parseInt(check),
          "community-link": editCommunityData?.attributes["community-link"],
          tags: communityTag?.length > 0 ? communityTag[0]?.name : "",
        },
        relationships: {
          apps: { data: commApps && commApps },

          communities_process: { data: commProcess && commProcess },

          tools: { data: commTools && commTools },
          "channel-info": { data: updatedChannelLinks },
        },
      },
    },
    id: editCommunityData && editCommunityData?.["id"],
    updateData: props.setUpdateData,
    pageLevel: props.setPageLevel,
    setLoading: setLoading,
  };

  const handleYammerUpdate = (payload) => {
    updateYammerCom(payload);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData?.communityName || formData?.communityName === null) {
      errors.communityName = "Required*";
    }

    if (!formData?.communityDesc || formData?.communityDesc === null) {
      errors.communityDesc = "Required*";
    }

    if (!formData?.communityLink || formData?.communityLink === null) {
      errors.communityLink = "Required*";
    }

    setFormErrors(errors);
    return errors;
  };

  const handleAddCommunity = async (payLoad) => {
    const formError = validateForm();

    if (
      Object.keys(formError).length > 0 ||
      communityOwners?.length === 0 ||
      descriptionError?.length > 0 ||
      existedName === true
    ) {
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      addCommunity(payLoad);
      setOwnerCheck("");
      setDescriptionError("");
    }
  };

  const handleUpdateCommunity = async () => {
    const updateDataValue = {
      payload: addNewCommunityPayload,
      updateData: props.setUpdateData,
      pageLevel: props.setPageLevel,
      id: editCommunityData && editCommunityData["id"],
      includedData: includedValue,
      setLoading: setLoading,
    };

    const formError = validateForm();

    if (
      Object.keys(formError).length > 0 ||
      communityOwners?.length === 0 ||
      descriptionError.length > 0 ||
      existedName === true
    ) {
      setLoading(false);
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      UpdateCommunityData(updateDataValue);
      setOwnerCheck("");
      setDescriptionError("");
    }
  };

  const handleCheckChange = (event) => {
    if (event.target.value === "0") {
      setDisableField(true);
      setCheckValue("0");
      setFormData(null);
    } else {
      setCheckValue("1");
      setDisableField(false);
    }
  };

  const getCommunityName = (name) => {
    let isAlreadyPresent = false;
    // eslint-disable-next-line
    communityDataAll?.forEach((comName) => {
      if (
        comName?.attributes["community-name"]?.toLowerCase() ===
        name?.trim().toLowerCase()
      ) {
        isAlreadyPresent = true;
      }
    });
    setExistedName(isAlreadyPresent);
  };

  const getCommunityEmail = (email) => {
    // eslint-disable-next-line
    let isAlreadyPresent = false;
    communityDataAll?.forEach((mail) => {
      if (check === "1") {
        if (
          mail?.attributes["community-link"]?.slice(8)?.toLowerCase() ===
          email?.trim()?.toLowerCase()
        ) {
          isAlreadyPresent = true;
        }
      } else {
        if (
          mail?.attributes["community-link"]?.toLowerCase() ===
          email?.trim()?.toLowerCase()
        ) {
          isAlreadyPresent = true;
        }
      }
    });
    checkExistedEmail(isAlreadyPresent);
  };

  const handleYammerAdd = async (payload) => {
    await addYammerCommunity(payload);
    if (res === true) {
      setCommunityLinkError("");
      setTimeout(setRes(false), 5000);
    } else {
      setCommunityLinkError("Enter Valid Yammer link");
    }
  };

  const handleAddYammerCommunity = async (payload) => {
    if (existedEmail === true) {
      checkExistedEmail(true);
    } else {
      setLoading(true);
      await addYammerCom(payload);
      props.setUpdateData(true);
    }
  };

  const handleNewUpdateCommunity = () => {
    if (upadteCheck === "0") {
      setLoading(true);
      handleYammerUpdate(yammerUpdatePayLoad);
    } else {
      setLoading(true);
      handleUpdateCommunity();
    }
  };

  return (
    <div>
      <div>
        <p className="main-title">{props?.communityPageName}</p>
      </div>
      <div>
        <Form className="community-form">
          <Row>
            <Form.Group className="d-flex pt-2 pe-2">
              <Form.Label> Choose Your Community Type:</Form.Label>
              <div id="app-primary-radio">
                <Form.Check
                  type="radio"
                  id="0"
                  disabled={editCommunityData ? true : false}
                  className="ms-2 me-3"
                  label="Viva Engage"
                  value="0"
                  checked={check === "0"}
                  onChange={handleCheckChange}
                />
              </div>

              <div id="app-primary-radio">
                <Form.Check
                  type="radio"
                  id="1"
                  disabled={editCommunityData ? true : false}
                  label="Others"
                  value="1"
                  onChange={handleCheckChange}
                  checked={check === "1"}
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-4 pt-2">
              {check && check === "0" ? (
                <>
                  <Form.Label>Viva Engage Link</Form.Label>

                  <span className="d-flex">
                    <div
                      className="app-primary-input-field"
                      style={{ width: "42rem" }}
                    >
                      <Form.Control
                        key="field1"
                        name="yammerLink"
                        type="email"
                        defaultValue={formData?.yammerLink}
                        onChange={(e) => (
                          // eslint-disable-next-line
                          handleChange(e),
                          getCommunityEmail(e.target.value),
                          !!formErrors
                            ? setFormErrors(formData?.yammerLink)
                            : "",
                          setTimeout(setRes(false), 1000)
                        )}
                        placeholder={"Enter Viva Engage Link"}
                      />
                    </div>
                    <Button
                      className="app-tertiary-btn"
                      disabled={
                        editCommunityData ||
                        res === true ||
                        editCommunityData?.attributes[
                          "community-type"
                        ].toString() === "0"
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleYammerAdd(yammerCommunityPayLoad);
                      }}
                    >
                      {res === true ||
                      editCommunityData?.attributes[
                        "community-type"
                      ].toString() === "0"
                        ? "Verified"
                        : "Verify"}
                    </Button>
                  </span>
                  <p className="ms-2 p-0 fs-7 mb-0 text-danger">
                    {res === false && formData?.yammerLink?.length > 0
                      ? communityLinkError
                      : ""}
                  </p>
                  <p className="ms-2 p-0 fs-7 mb-0 text-danger">
                    {existedEmail && formData?.yammerLink?.length > 0
                      ? "Link already exists*"
                      : null}
                  </p>
                </>
              ) : (
                <>
                  <Form.Label>Link</Form.Label>
                  <div className="app-primary-input-field">
                    <Form.Control
                      key="field0"
                      name="communityLink"
                      type="email"
                      defaultValue={formData?.communityLink}
                      onChange={(e) => (
                        // eslint-disable-next-line
                        getCommunityEmail(e.target.value),
                        handleChange(e),
                        !!formErrors
                          ? setFormErrors(formData?.communityLink)
                          : ""
                      )}
                      placeholder={"Enter Community Link"}
                      className=" text-field"
                      isInvalid={!!formErrors?.communityLink}
                    />
                  </div>
                  {formErrors?.communityLink ? (
                    <p className="m-0 text-danger">Required*</p>
                  ) : null}
                  <Form.Control.Feedback type="invalid">
                    Required*
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>

            <Form.Group as={Col} className="mb-4 pt-2">
              <Form.Label>Community Name</Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  type="text"
                  key="field1"
                  name="communityName"
                  disabled={disableField || check === "0"}
                  onChange={(e) => (
                    // eslint-disable-next-line
                    handleChange(e),
                    // setExistedName(false),
                    getCommunityName(e.target.value),
                    !!formErrors ? setFormErrors(formData?.communityName) : ""
                  )}
                  value={check === "0" ? "" : formData?.communityName}
                  placeholder={
                    disableField === true && formData?.yammerLink != null
                      ? yammerData?.group?.["full_name"]
                      : "Enter Community Name"
                  }
                  className={
                    disableField || check === "0"
                      ? "text-field-disable"
                      : "text-field "
                  }
                  isInvalid={!!formErrors?.communityName}
                />
              </div>
              {formErrors?.communityName ? (
                <p className="m-0 text-danger">Required*</p>
              ) : existedName === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-4 pt-2">
              <Form.Label className="d-flex justify-content-between">
                Community Owners
                {disableField || check === "0" ? (
                  ""
                ) : (
                  <Button
                    className="app-icon-primary-btn"
                    disabled={disableField || check === "0" ? true : false}
                    onClick={handleOpenModal}
                  >
                    +
                  </Button>
                )}
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  type="input"
                  className={
                    disableField || check === "0"
                      ? "text-field-disable"
                      : "text-field  disabled-field"
                  }
                  disabled
                  onChange={handleOwnerChange}
                  placeholder={"Select Community Owners"}
                  value={
                    check === "0"
                      ? ""
                      : communityOwners?.length <= 3
                      ? communityOwners?.map((owner) => owner.name).join(", ")
                      : communityOwners

                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${communityOwners?.length - 3} more`
                  }
                />
              </div>
              {ownerCheck && communityOwners?.length === 0 ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} className="mb-4 pt-2">
              <Form.Label className="d-flex justify-content-between">
                <span>Community Members</span>
                {disableField || check === "0" ? (
                  " "
                ) : (
                  <Button
                    className="app-icon-primary-btn"
                    disabled={disableField || check === "0" ? true : false}
                    onClick={handleOpenMemberModal}
                  >
                    +
                  </Button>
                )}
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder={" Select Community Members"}
                  className={
                    disableField || check === "0"
                      ? "text-field-disable"
                      : "text-field  disabled-field"
                  }
                  value={
                    check === "0"
                      ? ""
                      : communityMembers?.length <= 3
                      ? communityMembers?.map((owner) => owner.name).join(", ")
                      : communityMembers
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") +
                        ` + ${communityMembers?.length - 3} more`
                  }
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-4 pt-2">
              <Form.Label>Community Description</Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  rows={7}
                  key="field2"
                  disabled={disableField || check === "0"}
                  name="communityDesc"
                  onChange={(e) => (
                    // eslint-disable-next-line
                    handleDescriptionChange(e),
                    handleChange(e),
                    !!formErrors ? setFormErrors(formData?.communityDesc) : null
                  )}
                  as="textarea"
                  value={check === "0" ? "" : formData?.communityDesc}
                  placeholder={
                    disableField === true
                      ? yammerData?.group?.description
                      : "Enter Community Description"
                  }
                  className={
                    disableField || check === "0"
                      ? "text-field-disable "
                      : "text-field form-text-field"
                  }
                  isInvalid={!!formErrors?.communityDesc}
                />
              </div>
              {formErrors?.communityDesc ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-4 pt-2">
              <Form.Label>Upload Community Image</Form.Label>

              <ImageUpload
                className={
                  disableField || check === "0"
                    ? "image-disable"
                    : " main-container"
                }
                editImage={
                  editCommunityData?.attributes["community-image"]?.length > 0
                    ? editCommunityData?.attributes["community-image"]
                    : null
                }
                setIsDeleted={setIsDeleted}
                isAppImage={true}
                onDrop={(files) => {
                  setCommunityImage(files[0]);
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} className="pt-2">
              <Form.Label>Applications Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder="Select..."
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  multi={true}
                  className="dropdown-select dropdown-menu "
                  options={
                    appOptions &&
                    appOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "application",
                        name: item.name,
                        attributes: {
                          "app-id": item.attributes["app-id"].toString(),
                        },
                        id: item.id
                          ? item.id.toString()
                          : (index - index * 2).toString(),
                      }))
                  }
                  values={commApps}
                  onChange={handleAppChange}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} className="mt-2">
              <Form.Label>Processes Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder="Select..."
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  multi={true}
                  className="dropdown-select dropdown-menu"
                  options={
                    processOptions &&
                    processOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "process",
                        name: item.name,
                        attributes: {
                          "process-id":
                            item.attributes["process-id"].toString(),
                        },
                        id: item.id
                          ? item.id.toString()
                          : (index - index * 2).toString(),
                      }))
                  }
                  values={commProcess}
                  onChange={handleProcessChange}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label>Tools Connections</Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder="Select..."
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  multi={true}
                  className="dropdown-select dropdown-menu "
                  options={
                    toolOptions &&
                    toolOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => ({
                        type: "tool",
                        name: item.name,
                        attributes: {
                          "tool-id": item.attributes["tool-id"].toString(),
                        },
                        id: item.id
                          ? item.id.toString()
                          : (index - index * 2).toString(),
                      }))
                  }
                  values={commTools}
                  onChange={handleToolChange}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} style={{ zIndex: "1" }}>
              <Form.Label>Select Community Tag</Form.Label>
              <div className="app-primary-dropdown">
                <Select
                  placeholder="Select Community Tag"
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  values={tempCommunityTags}
                  options={communityTagsData?.communityTag?.map((tags) => ({
                    name: tags.name,
                    id: tags.id,
                  }))}
                  onChange={handleCommunityTag}
                  className="dropdown-select dropdown-menu "
                />
              </div>
            </Form.Group>
          </Row>

          <Row style={{ width: "100%" }}>
            <Form.Group as={Col}>
              <Form.Label className="w-50">
                <span className="d-flex justify-content-between">
                  <p className="mb-0">Community Channel</p>

                  <p
                    className="app-tertiary-btn"
                    onClick={() => {
                      setCategoryModal(true);
                    }}
                  >
                    Add New
                  </p>
                </span>
              </Form.Label>
              <div className="app-primary-input-field w-50">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder={"Community Channel"}
                  value={channelLinkRes?.map((link) => link.name)}
                />
              </div>
            </Form.Group>
          </Row>
          <Row className="padding-btm">
            <Form.Group
              as={Col}
              className="mb-4 pt-4 d-flex justify-content-end "
            >
              <span className="px-2">
                <Button
                  className="app-secondary-btn"
                  onClick={() => props.navigateFun("communities")}
                >
                  Cancel
                </Button>
              </span>
              {communityPageLevel === "addCommunities" ? (
                <Button
                  className="app-primary-btn"
                  onClick={() => {
                    check === "0"
                      ? handleAddYammerCommunity(yammerPayLoad)
                      : handleAddCommunity(addNewCommunityPayload);
                  }}
                >
                  {loading === true ? "Adding Community..." : "Add Community"}
                </Button>
              ) : (
                <Button
                  className="app-primary-btn"
                  onClick={() => {
                    handleNewUpdateCommunity();
                  }}
                >
                  {loading === true
                    ? "Updating Community..."
                    : "Update Community"}
                </Button>
              )}
            </Form.Group>
          </Row>

          {showModal === true ? (
            <EmployeeModal
              show={showModal}
              handleClose={handleCloseModal}
              type={"heads"}
              getMembers={communityOwners}
              title={"Add Owners To Community"}
              setMember={setCommunityOwners}
              buttonTitle={"Add Owners"}
              role={"is-head"}
              is_owners={true}
            />
          ) : (
            ""
          )}
          {showCategoryModal === true ? (
            <CommunityChannelModal
              show={showCategoryModal}
              onHide={() => setCategoryModal(false)}
              communityLinks={communityLinks}
              setCommunityLinks={setCommunityLinks}
              channelLinkRes={channelLinkRes}
              setChannelLinkRes={setChannelLinkRes}
            />
          ) : (
            ""
          )}
          {showMemberModal === true ? (
            <EmployeeModal
              show={showMemberModal}
              type={"members"}
              handleClose={handleCloseMemberModal}
              getMembers={communityMembers}
              title={"Add Members To Community"}
              setMember={setCommunityMembers}
              buttonTitle={"Add Members"}
              role={"is-head"}
              is_owners={false}
            />
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
}
