import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "react-bootstrap";
import { now } from "lodash";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      console.log("error");
      reject(error);
    };
  });
};

const MAX_FILE_SIZE_MB = 4;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_TOTAL_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_FILES = 3;

const MultiFilesComp = ({ processFiles, setProcessFiles }) => {
  const [error, setError] = useState(null);

  const hash = require("object-hash");

  const onDrop = useCallback(
    (acceptedFiles) => {
      setError(null);

      if (processFiles.length + acceptedFiles.length > MAX_FILES) {
        setError(`You can only upload up to ${MAX_FILES} files.`);
        return;
      }

      let totalSize = processFiles.reduce((acc, file) => acc + file.size, 0);
      const validFiles = [];
      const invalidFiles = [];
      const oversizedFiles = [];

      acceptedFiles.forEach(async (file) => {
        if (file.size > MAX_FILE_SIZE) {
          invalidFiles.push(file.name);
        } else if (totalSize + file.size > MAX_TOTAL_SIZE) {
          oversizedFiles.push(file.name);
        } else {
          validFiles.push(file);
          totalSize += file.size;
        }
      });

      if (invalidFiles.length > 0) {
        setError(
          `Max 3 files and 4 MB in total. These files are too large: ${invalidFiles.join(
            ", "
          )}`
        );
      }

      if (oversizedFiles.length > 0) {
        setError(
          `Max 3 files and 4 MB in total. These files are too large: ${oversizedFiles.join(
            ", "
          )}`
        );
      }

      validFiles.forEach(async (file) => {
        const base64 = await getBase64(file);
        const tempPreview = base64.split(",")?.pop();
        const imageHash = hash({ file, timestamp: now() });
        const extension = file.name.substring(file.name.lastIndexOf("."));
        const url = `/files/process-template-files/${imageHash}${extension}`;
        setProcessFiles((processFiles) => [
          ...processFiles,
          {
            file_name: file.name,
            file_preview: tempPreview,
            file_path: url,
            size: file.size,
          },
        ]);
      });
    },
    // eslint-disable-next-line
    [processFiles, setProcessFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: MAX_FILES,
  });

  const removeFile = (fileName) => {
    setProcessFiles(
      processFiles?.filter((file) => file.file_name !== fileName)
    );
  };

  return (
    <div
      className="p-1"
      style={{
        minHeight: "9.4rem",
        border: "1.5px solid #969696",
        borderRadius: "4px",
      }}
    >
      <div
        className="d-flex w-100"
        {...getRootProps()}
        style={isDragActive ? dragStyle : dropzoneStyle}
      >
        <input
          disabled={processFiles?.length === MAX_FILES ? true : false}
          {...getInputProps()}
        />

        <p className="my-auto">
          {isDragActive
            ? "Drop the files here ..."
            : `Click here or drag & drop to select files... (Up to ${MAX_FILES} files, total size up to 4MB)`}
        </p>
      </div>
      {error && (
        <p className="error-message" style={{ color: "red" }}>
          {error}
        </p>
      )}
      <div className="mt-1">
        {processFiles?.length > 0 ? (
          <>
            {processFiles?.map((file, index) => (
              <div key={index}>
                {index + 1}
                {". "}
                {file.file_name}
                {" - "}
                <Button
                  className="app-tertiary-btn p-0 fw-normal"
                  onClick={() => removeFile(file.file_name)}
                >
                  Remove
                </Button>
              </div>
            ))}
          </>
        ) : (
          <p className="mb-0 text-muted">No files selected.</p>
        )}
      </div>
    </div>
  );
};

const dropzoneStyle = {
  height: "50px",
  width: "100%",
  border: "1.5px dashed #969696",
  outline: "0px",
  borderRadius: "4px",
  fontSize: "14px",
  lineHeight: "22px",
  paddingLeft: "8px",
  position: "relative",
  outlineOffset: "initial",
  cursor: "pointer",
};

const dragStyle = {
  height: "50px",
  width: "100%",
  border: "1.5px dashed #969696",
  outline: "0px",
  borderRadius: "4px",
  fontSize: "14px",
  lineHeight: "22px",
  paddingLeft: "8px",
  position: "relative",
  outlineOffset: "initial",
  cursor: "pointer",
  backgroundColor: "#e9ecef",
};

export default MultiFilesComp;
