import { postData } from "../services/apiService";
import { getHostUrl, getBaseUrl } from "../config/environment";
import { patchData } from "../services/apiService";
import { deleteData } from "../services/apiService";
import { useState } from "react";
import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import ImageSvgs from "../components/ImageSvgs";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";

export const TopicsDeleteModal = (props) => {
  const deleteTopics = async () => {
    props.setDeleteFlag(true);
    const deleteTopicId = props.topicdata.id;
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    // eslint-disable-next-line
    const res = await deleteData(
      `${getHostUrl()}topics/${deleteTopicId}`,
      headers
    );
    if (res.status === 204) {
      props.onHide();
      props.setDeleteFlag(false);
      props.updatedata(true);
      props.setTableLoad(true);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Topic
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="pt-1 text-capitalize">
          <strong>
            Topic Name : {props?.topicdata?.attributes["topics-name"]}{" "}
          </strong>
          <br></br>
        </span>
        <span className="pt-3">
          <span className="d-flex flex-column">
            <span>Are you sure you want to delete this Topic?</span>
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="common-favbutton"
          onClick={() => {
            deleteTopics();
          }}
        >
          {props.deleteFlag ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddNewTopic = async (props) => {
  const relationships = props.data.relationships;
  const setLoading = props?.data.setLoading;
  const finalTopicData = {
    data: {
      type: "topics",
      attributes: {
        topics_name: props?.data.attributes.topics_name,
        topics_description: props?.data.attributes.topics_description,
      },
      relationships: {
        orgs: {
          data: relationships?.orgs ? relationships.orgs : [],
        },
        owners: {
          data: relationships?.owners ? relationships.owners : [],
        },
      },
    },
  };

  const url = `${getHostUrl()}topics`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };
  try {
    // eslint-disable-next-line
    const toolRes = await postData(url, finalTopicData, headers);
    if (toolRes.status === 200) {
      props.data.updateData(true);
      props.data.pageLevel("topic");
      setLoading(false);
      props.data.setTableLoad(true);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateTopics = async (props) => {
  const relationships = props.data.relationships;
  const attributes = props.data.attributes;
  const updateId = props.data.id;
  const setLoading = props?.data.setLoading;
  const updateData = {
    data: {
      type: "topics",
      attributes: {
        topics_name: attributes["topics_name"],
        topics_description: attributes["topics_description"],
      },
      relationships: {
        orgs: {
          data: relationships.orgs ? relationships.orgs : [],
        },
        owners: {
          data: relationships.owners ? relationships.owners : [],
        },
      },
    },
  };

  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };

  try {
    const res = await patchData(
      `${getHostUrl()}topics/${updateId}`,
      updateData,
      headers
    );
    // eslint-disable-next-line
    if (res.status === 200) {
      props.data.pageLevel("topic");
      setLoading(false);
      props.data.setTableLoad(true);
    }
  } catch (error) {
    console.log(error);
  }
};

export const UserModal = (props) => {
  const [copy, setCopy] = useState(false);
  const [copyMail, setCopyMail] = useState(false);
  const [id, setId] = useState(null);
  const listData = props.listdata;
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="user-modal-title"
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}
        <ListGroup>
          {listData.map((value) => {
            let imageUrl =
              value.attributes.employee.data.attributes["profile-picture"];
            return (
              <ListGroupItem className="user-modal-user-list">
                <span className="d-flex justify-content-between">
                  <span>
                    <span>
                      {imageUrl ? (
                        <img
                          className="my-auto"
                          src={`${getBaseUrl()}${imageUrl}`}
                          style={{
                            height: "2rem",
                            width: "2rem",
                            border: "1.5px solid black",
                            borderRadius: "7rem",
                            objectFit: "contain",
                          }}
                          alt="User Img"
                        />
                      ) : (
                        <Avatar
                          name={
                            value.attributes.employee.data.attributes[
                              "preferred-name"
                            ]
                          }
                          size="32"
                          round={true}
                        />
                      )}
                    </span>
                    <span className="px-2 user-name">
                      {" "}
                      {
                        value.attributes.employee.data.attributes[
                          "preferred-name"
                        ]
                      }{" "}
                    </span>
                  </span>
                  <span className="d-flex justify-content-between">
                    <span>
                      <button
                        className=" bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copy ? "Copy email" : "Copied"}
                        disabled={copy}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopy(true);
                          setId(value.id);
                          setTimeout(() => {
                            setCopy(false);
                            setId(null);
                          }, 1500);
                        }}
                      >
                        {id !== value.id ? (
                          <>
                            <ImageSvgs name="expertCopy" />
                          </>
                        ) : (
                          <>
                            <ImageSvgs name="tickMark" />
                          </>
                        )}
                      </button>
                    </span>
                    <span>
                      <button
                        className="ms-2 bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copyMail ? "Mail to" : "Mail to:"}
                        disabled={copyMail}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopyMail(true);
                        }}
                      >
                        {value.id && (
                          <>
                            <a
                              href={`mailto:${value.attributes.employee.data.attributes["email-id"]}`}
                            >
                              <ImageSvgs name="expertMail" />
                            </a>
                          </>
                        )}
                      </button>
                    </span>

                    <span>
                      <button
                        className="bg-transparent border-0"
                        onClick={() => {
                          navigate(
                            `/employee_relation?id=${value.attributes["employee-id"]}`
                          );
                        }}
                      >
                        <ImageSvgs name="relationIcon" />
                      </button>
                    </span>
                  </span>
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
