import React from "react";
import { useEffect } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import CustomNode from "./CustomNode";
import "./ProcessSteps.scss";

const nodeTypes = { textUpdater: CustomNode };

const ProcessSteps = (processConfig) => {
  const stepsData = processConfig.processConfig.attributes["process-config"];
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    (async () => {
      if (stepsData) {
        setNodes([]);
        stepsData?.nodes?.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,

            data: {
              name: elem.data.name,
              description: elem.data.description,
              type: "textUpdater",
              label: (
                <CustomNode
                  data={{
                    name: elem.data.name,
                    description: elem.data.description,
                  }}
                  isConnectable={true}
                />
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });

        setEdges(stepsData?.edges);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="layoutflow d-flex w-100">
      <div className="react-flow-section w-100 border">
        <div className="flow-canvas">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView
            nodesDraggable={true}
            draggable={false}
          >
            <Controls />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
