import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../assets/scss/common.scss";
import { HandleToolSubmit, updateTool } from "../../utils/toolsUtils";
import "./ManageTools.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ImageUpload } from "../AdminComponents/ImageUpload";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";
import { Modal } from "react-bootstrap";

const AddNewTool = ({
  navigateToPage,
  includedData,
  powerUsers,
  employeeData,
  communityData,
  trainingData,
  data,
  setIncluded,
  setFlag,
  ...props
}) => {
  const iniToolData = props["iniToolData"];
  const [toolName, setToolName] = useState(
    data != null ? data["attributes"]["tool-name"] : null
  );
  const [toolDesc, setToolDesc] = useState(
    data != null ? data["attributes"]["tool-description"] : null
  );
  const [ownerCheck, setOwnerCheck] = useState("");
  // eslint-disable-next-line
  const [powerUsersVal, setPowerUsersVal] = useState([]);
  // eslint-disable-next-line
  const [trainingVal, setTrainingVal] = useState([]);

  const [toolImage, setToolImage] = useState(null);

  const [editImageData, setEditImageData] = useState(
    data?.attributes["tool-image"]
  );

  const [isDeleted, setIsDeleted] = useState(false);

  const url = new URL(window.location.href);
  const pageValue = url.searchParams.get("page");
  const [nameErr, setNameErr] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [descriptionCheck, setDescriptionCheck] = useState("");
  const [loading, setLoading] = useState(false);

  const [folderLinks, setFolderLinks] = useState(
    data && data?.relationships["tool-info"].data.length > 0
      ? data?.relationships["tool-info"].data.map((elem) => {
          let tempData = includedData?.filter(
            (info) =>
              info.type === "tool-additional-info" &&
              info.id.toString() === elem.id.toString()
          )[0];
          return {
            name: tempData.attributes["info-title"],
            link: tempData.attributes["info-URL"],
            id: tempData.id.toString(),
          };
        })
      : []
  );

  useEffect(() => {
    (async () => {
      if (data && data["relationships"]["experts"]["data"].length > 0) {
        // eslint-disable-next-line
        const totalPowerUsers = [];
        // eslint-disable-next-line
        data["relationships"]["experts"]["data"]?.map((elem) => {
          // eslint-disable-next-line
          const filteredPowerUsers = includedData
            .filter((empId) => {
              if (empId.type === "experts") {
                if (elem.id === empId.id) {
                  return true;
                }
              }
              return false;
            })
            .map((empId) => ({
              name:
                empId.attributes.employee != null &&
                empId.attributes.employee.data.attributes["preferred-name"],
              id: empId.attributes["employee-id"],
            }));
          totalPowerUsers.push(...filteredPowerUsers);
        });
        setPowerUsersVal(totalPowerUsers);
      }
    })();

    (async () => {
      if (data && data["relationships"]["trainings"]["data"]?.length > 0) {
        // eslint-disable-next-line
        const totalTrainings = [];
        // eslint-disable-next-line
        data["relationships"]["trainings"]["data"]?.map((elem) => {
          const filteredTrainings = includedData
            .filter((TrId) => {
              // eslint-disable-next-line
              if (TrId.type === "training") {
                if (elem.id === TrId.id) {
                  return true;
                }
              }
              return false;
            })
            .map((id) => ({
              name: id.attributes["training-master"].data.attributes[
                "training-name"
              ],
              id: id.attributes["training-id"],
            }));
          totalTrainings.push(...filteredTrainings);
        });
        setTrainingVal(totalTrainings);
      }
    })();

    (async () => {
      if (data && data["relationships"]["communities"]["data"].length > 0) {
        // eslint-disable-next-line
        const totalCommunity = [];
        // eslint-disable-next-line
        data["relationships"]["communities"]["data"]?.map((elem) => {
          // eslint-disable-next-line
          const filteredCommunity = includedData.filter((comId) => {
            // eslint-disable-next-line
            if (comId.type === "community") {
              if (elem.id === comId.id) {
                return true;
              }
            }
            return false;
          });
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDeleted) {
      setToolImage(null);
      setEditImageData(null);
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const FolderLinksModal = (props) => {
    const [formValues, setFormValues] = useState(folderLinks);

    let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    };

    let addFormFields = () => {
      setFormValues([...formValues, { name: "", link: "", id: null }]);
    };

    let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Further Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="trainings-links-modal">
          {formValues.length > 0 ? (
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Platform Name</p>

                          <p
                            className="app-tertiary-btn"
                            onClick={() => {
                              removeFormFields(index);
                            }}
                          >
                            Remove
                          </p>
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                          //   isInvalid={!!errors.app_name}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          ) : (
            <p className="mb-0 fs-5">Add links to view them here.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <div>
              <Button
                className="app-primary-btn me-2"
                onClick={() => {
                  addFormFields();
                }}
              >
                Add
              </Button>
            </div>
            <div>
              <Button className="app-secondary-btn me-2" onClick={props.onHide}>
                Close
              </Button>
              <Button
                className="app-primary-btn"
                onClick={async () => {
                  let linksList = formValues?.map((elem) => {
                    return {
                      name: elem.name,
                      link:
                        elem.link.includes("https:") ||
                        elem.link.includes("http:")
                          ? elem.link
                          : `https://${elem.link}`,
                      id: elem.id,
                    };
                  });
                  await setFolderLinks(linksList);
                  props.onHide();
                }}
              >
                Add Links
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const relationships = data && data.relationships;
  const tempOwners =
    relationships && relationships?.experts.data.length > 0
      ? relationships &&
        relationships?.experts.data
          ?.filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "experts" &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "experts" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "experts",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },
              id: ownerData?.id,
            };
          })
      : [];

  const [newExperts, setNewExperts] = useState(tempOwners);
  const trainingDataMap =
    relationships?.trainings.data.length > 0
      ? relationships?.trainings.data.map((training, index) => {
          let trainingData = includedData.filter(
            (tr) => tr.type === "training" && tr.id === training.id
          )[0];
          return {
            type: "training",
            name: trainingData?.attributes["training-master"].data.attributes[
              "training-name"
            ],
            attributes: {
              training_id: trainingData.attributes["training-id"].toString(),
            },
            id: trainingData?.id,
          };
        })
      : [];

  const trainingOptions =
    trainingData &&
    trainingData?.map((item) =>
      trainingDataMap?.filter(
        (training) =>
          training.type === "training" &&
          training.attributes["training_id"].toString() === item.id.toString()
      )[0]
        ? trainingDataMap?.filter(
            (tr) =>
              tr.type === "training" &&
              tr.attributes["training_id"].toString() === item.id.toString()
          )[0]
        : {
            type: "training",
            name: item.attributes["training-name"],
            attributes: {
              training_id: item.id.toString(),
            },
            id: null,
          }
    );

  const [newTraining, setNewTraining] = useState(trainingDataMap);

  const handleTrainingData = (value) => {
    let finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "training",
        name: item.name,
        attributes: {
          training_id: item.attributes["training_id"],
        },
        id: item.id <= 0 ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });
    setNewTraining(finalValue);
  };

  const communityDataMap =
    relationships?.communities.data.length > 0
      ? relationships?.communities.data.map((community, index) => {
          let communityDataVal = includedData?.filter(
            (com) =>
              com.type === "community" &&
              com.id.toString() === community.id.toString()
          )[0];
          return {
            type: "community",
            name: communityDataVal?.attributes.communities.data.attributes[
              "community-name"
            ],
            attributes: {
              community_id: communityDataVal?.attributes["community-id"],
            },
            id: communityDataVal?.id,
          };
        })
      : [];

  const communityOptions =
    communityData &&
    communityData?.map((item) =>
      communityDataMap?.filter(
        (com) =>
          com.type === "community" && com.id.toString() === item.id.toString()
      )[0]
        ? communityDataMap?.filter(
            (comm) =>
              comm.type === "community" &&
              comm.id.toString() === item.id.toString()
          )[0]
        : {
            type: "community",
            name: item?.attributes["community-name"],
            attributes: {
              community_id: item.id.toString(),
            },
            id: null,
          }
    );
  const [newCommunity, setNewCommunity] = useState(communityDataMap);
  const handleCommunityData = (value) => {
    let finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "community",
        name: item.name,
        attributes: {
          community_id: item.attributes["community_id"],
        },
        id: item.id <= 0 ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });
    setNewCommunity(finalValue);
  };

  const handleOwnerChange = () => {
    if (newExperts?.length > 0) {
      setOwnerCheck("");
    } else {
      setOwnerCheck("Required");
    }
  };

  const findFormErrors = () => {
    const tool_name = toolName;
    const tool_desc = toolDesc;
    const newErrors = {};
    if (!tool_name || tool_name === "") newErrors.tool_name = "Required*";
    if (!tool_desc || tool_desc === "") newErrors.tool_desc = "Required*";
    return newErrors;
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setDescriptionCheck("Description limit exceeded: 1000 characters");
    } else {
      setDescriptionCheck("");
    }
  };

  const payLoad = {
    data: {
      type: "tool",
      attributes: {
        tool_image:
          !editImageData && !toolImage
            ? null
            : editImageData && !toolImage
            ? editImageData
            : toolImage,
        tool_description: toolDesc != null && toolDesc,
        tool_title: toolName != null && toolName,
        tool_name: toolName != null && toolName,
        experts: newExperts,
        communities: newCommunity,
        trainings: newTraining,
        additionalInfo:
          folderLinks.length > 0
            ? folderLinks.map((elem) => {
                return {
                  type: "tool-additional-info",
                  attributes: {
                    "info-title": elem.name,
                    "info-URL": elem.link,
                  },
                  id: elem.id,
                };
              })
            : [],
      },
    },
    updateData: setFlag,
    navigateFun: navigateToPage,
    setLoading: setLoading,
  };

  const handleSubmit = async (payLoad) => {
    const newErrors = findFormErrors();
    if (
      Object.keys(newErrors).length > 0 ||
      newExperts?.length === 0 ||
      descriptionCheck?.length > 0 ||
      nameErr === true
    ) {
      setErrors(newErrors);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      await HandleToolSubmit(payLoad);
    }
  };

  const handleUpdate = async () => {
    const dataUtil = {
      payLoad: payLoad,
      id: data && data["id"].toString(),
      updateData: setFlag,
      navigateFun: navigateToPage,
    };
    // eslint-disable-next-line
    const newErrors = findFormErrors();
    if (
      Object.keys(newErrors).length > 0 ||
      newExperts?.length === 0 ||
      descriptionCheck?.length > 0
    ) {
      setErrors(newErrors);
      setOwnerCheck("Required");
    } else {
      setLoading(true);
      await updateTool(dataUtil);
    }
  };

  const cancelEvent = () => {
    navigateToPage("tool");
  };

  const getToolName = (name) => {
    // eslint-disable-next-line
    iniToolData.map((element) => {
      if (
        element.attributes["tool-name"]?.toLowerCase() ===
        name?.trim()?.toLowerCase()
      ) {
        setNameErr(true);
      }
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <p className="main-title">{props.title}</p>
        <Form noValidate className="tool-form">
          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <span className="d-flex">
                <Form.Label className="font-weight-bold mb-3">
                  Tool Name
                </Form.Label>
              </span>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Tool Name"
                  defaultValue={data && data?.attributes["tool-name"]}
                  onChange={(e) => (
                    // eslint-disable-next-line
                    setNameErr(false),
                    getToolName(e.target.value),
                    // eslint-disable-next-line
                    setToolName(e.target.value),
                    !!errors ? setErrors(toolName) : ""
                  )}
                  className="form-text-field"
                  isInvalid={!!errors?.tool_name}
                />
              </div>
              {errors?.tool_name ? (
                <p className="m-0 text-danger">Required*</p>
              ) : nameErr === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                <p className="mb-0">Power Users</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={handleOpenModal}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder={"Select Power Users"}
                  className="text-field disabled-field"
                  onChange={handleOwnerChange}
                  value={
                    newExperts?.length <= 3
                      ? newExperts?.map((owner) => owner.name).join(", ")
                      : newExperts
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${newExperts?.length - 3} more`
                  }
                />
              </div>
              {ownerCheck && newExperts?.length === 0 && (
                <p className="m-0 text-danger">Required*</p>
              )}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-4 min-vh-50"
              controlId="validationCustom02"
            >
              <Form.Label className="font-weight-bold">
                Tool Description
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  as="textarea"
                  rows={7}
                  placeholder="Enter Tool Description"
                  defaultValue={data && data?.attributes["tool-description"]}
                  // eslint-disable-next-line
                  onChange={(e) => (
                    // eslint-disable-next-line
                    handleDescriptionChange(e),
                    setToolDesc(e.target.value),
                    !!errors ? setErrors(toolDesc) : ""
                  )}
                  className="form-text-field"
                  isInvalid={!!errors?.tool_desc}
                />
              </div>
              {errors?.tool_desc ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4 min-vh-50"
              controlId="formGridEmail"
            >
              <Form.Label className="font-weight-bold">
                Upload Tool Image
              </Form.Label>
              <ImageUpload
                editImage={editImageData && editImageData}
                className="main-container"
                setIsDeleted={setIsDeleted}
                isAppImage={true}
                onDrop={(files) => {
                  setToolImage(files[0]);
                }}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-2">
              <Form.Label className="font-weight-bold">
                Trainings Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  options={trainingOptions
                    ?.sort((a, b) => a?.name.localeCompare(b?.name))
                    ?.map((training, index) => ({
                      type: "training",
                      name: training.name,
                      attributes: {
                        training_id:
                          training && training.attributes["training_id"],
                      },
                      id: training.id
                        ? training?.id?.toString()
                        : index - index * 2,
                    }))}
                  onChange={handleTrainingData}
                  values={newTraining}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-2"
              controlId="validationCustom05"
            >
              <Form.Label className="font-weight-bold">
                Community Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  multi={true}
                  options={
                    communityOptions &&
                    communityOptions
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((community, index) => ({
                        type: "community",
                        name: community.name,
                        attributes: {
                          community_id:
                            community && community.attributes["community_id"],
                        },
                        id: community.id
                          ? community.id.toString()
                          : index - index * 2,
                      }))
                  }
                  onChange={handleCommunityData}
                  values={newCommunity}
                  className="dropdown-select"
                />
              </div>
            </Form.Group>
          </Row>
          <Row className="mb-3" style={{ width: "50.5%" }}>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className="w-100">
                <span className="w-100 d-flex justify-content-between mt-4">
                  <p className="mb-0">Further Information</p>
                  <p
                    className="app-tertiary-btn mb-0"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Add New
                  </p>
                </span>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  disabled
                  value={folderLinks && folderLinks.map((link) => link.name)}
                  placeholder="Further Information"
                />
              </div>
            </Form.Group>
          </Row>
          {showModal === true ? (
            <EmployeeModal
              show={showModal}
              handleClose={handleCloseModal}
              type={"experts"}
              getMembers={newExperts}
              title={"Add Expert To Tools"}
              setMember={setNewExperts}
              buttonTitle={"Add Experts"}
              is_owners={true}
              role={"no-role"}
            />
          ) : (
            ""
          )}
        </Form>
        {modalShow ? (
          <FolderLinksModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : null}
        <div className="d-flex justify-content-end font-weight-bold">
          <Button
            className="app-secondary-btn ms-3 my-auto"
            onClick={() => cancelEvent()}
          >
            {" "}
            Cancel
          </Button>

          {pageValue === "addTool" ? (
            <Button
              // id="common-favbutton"
              className="app-primary-btn ms-3"
              onClick={() => {
                handleSubmit(payLoad);
              }}
            >
              {" "}
              {loading === true ? "Creating Tool..." : "Create Tool"}{" "}
            </Button>
          ) : (
            <Button
              // id="common-favbutton"
              className="app-primary-btn ms-3"
              onClick={() => {
                handleUpdate();
                setFlag(true);
              }}
            >
              {" "}
              {loading === true ? "Updating Tool..." : "Update Tool"}{" "}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddNewTool;
