import validator from "validator";

export default function validateForm(values, type) {
  let errors = {};

  if (type === "link") {
    if (!values.favorite_name) {
      errors.favorite_name = "Name is required";
    }

    if (!values.favorite_redirect_url || values.favorite_redirect_url === "") {
      errors.favorite_redirect_url = "Redirect URL is required";
    }

    if (
      values.favorite_redirect_url &&
      !validator.isURL(values.favorite_redirect_url)
    ) {
      errors.favorite_redirect_url = "Invalid URL";
    }

    if (values.description.length > 250) {
      errors.description = "Description limit exceeded: 250 characters";
    }
  } else {
    if (!values.favorite_name) {
      errors.favorite_name = "Name is required";
    }
    if (values.description.length > 250) {
      errors.description = "Description limit exceeded: 250 characters";
    }
  }

  return errors;
}
