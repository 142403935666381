import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { patchData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import "./ProcessGroupModal.scss";

const GroupModal = (props) => {
  const defaultNodeColor = "#8a00e5";
  const defaultFontColor = "#FFFFFF";
  const [formValues, setFormValues] = useState([]);
  // eslint-disable-next-line
  const [fontColor, setFontColor] = useState(defaultFontColor);
  // eslint-disable-next-line
  const [nodeColor, setNodeColor] = useState(defaultNodeColor);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchGroupList = async () => {
      setLoading(true);
      try {
        const groupLink = `${getHostUrl()}process/groups`;
        const res = await getData(groupLink);
        if (res.status === 200) {
          const formattedValues = res.data.data.map((group) => ({
            type: "process-groups",
            attributes: {
              title: group.attributes.title,
              "node-color": group.attributes["node-color"] || defaultNodeColor,
              "font-color": group.attributes["font-color"] || defaultFontColor,
            },
            id: group.id,
            isDefault: group.attributes["is-default"],
          }));
          const sortedValues = [...formattedValues].sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1;
            if (!a.isDefault && b.isDefault) return 1;
            return 0;
          });
          setFormValues(sortedValues);
        }
      } catch (error) {
        console.error("Error fetching group list", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupList();
  }, []);

  const handleChange = (i, e) => {
    const { name, value } = e.target;

    if (formValues[i].isDefault && name === "title") {
      return;
    }

    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[i].attributes[name] = value;
      return newFormValues;
    });
  };

  const addFormFields = () => {
    setFormValues((prevValues) => [
      ...prevValues,
      {
        type: "process-groups",
        attributes: {
          title: "",
          "node-color": nodeColor,
          "font-color": fontColor,
        },
        id: "",
        isDefault: false,
      },
    ]);
  };

  const removeFormFields = (i) => {
    setFormValues((prevValues) => {
      const newFormValues = prevValues.filter((_, index) => index !== i);
      return newFormValues;
    });
  };

  const handleNodeColorChange = (index, color) => {
    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[index].attributes["node-color"] = color.hex;
      return newFormValues;
    });
  };

  const handleFontColorChange = (index, color) => {
    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[index].attributes["font-color"] = color.hex;
      return newFormValues;
    });
  };

  const handleSave = async () => {
    setLoad(true);

    const updatedGroupList = formValues.map((elem) => ({
      type: elem.type,
      attributes: {
        title: elem.attributes.title,
        "node-color": elem.attributes["node-color"],
        "font-color": elem.attributes["font-color"],
      },
      id: elem.id,
    }));
    try {
      const res = await patchData(`${getHostUrl()}process/groups`, {
        data: updatedGroupList,
      });
      if (res.status === 200) {
        setLoad(false);
        props.onHide();
        props.setUpadateGroup(true);
      }
    } catch (error) {
      console.error("Error saving data", error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="group-modal-container"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Manage Process Groups
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="trainings-links-modal modal-body">
        {loading ? (
          <div>
            <Loader className="custom-loader" />
          </div>
        ) : (
          <Form>
            {formValues.map((elem, index) => (
              <Row className="mb-3 " key={index}>
                <Form.Group as={Col} controlId={`formGroupName${index}`}>
                  <Form.Label className="w-100 p-0">
                    <span className="w-100 d-flex justify-content-between">
                      <p className="mb-0">Group Name</p>
                      {!elem.isDefault && (
                        <p
                          className="app-tertiary-btn"
                          onClick={() => removeFormFields(index)}
                        >
                          Remove
                        </p>
                      )}
                    </span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Group Name"
                    name="title"
                    value={elem.attributes.title}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required*
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId={`formGroupNodeColor${index}`}>
                  <Form.Label>Group Node Color (HEX)</Form.Label>
                  <div className="border d-flex justify-content-between input-fields ">
                    <div>{elem.attributes["node-color"]}</div>
                    <input
                      type="color"
                      className="input-field"
                      value={elem.attributes["node-color"]}
                      onChange={(e) =>
                        handleNodeColorChange(index, { hex: e.target.value })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} controlId={`formGroupFontColor${index}`}>
                  <Form.Label>Group Font Color (HEX)</Form.Label>
                  <div className="border d-flex justify-content-between input-fields">
                    <div className="mt-0 mb-0">
                      {elem.attributes["font-color"]}
                    </div>
                    <input
                      type="color"
                      value={elem.attributes["font-color"]}
                      onChange={(e) =>
                        handleFontColorChange(index, { hex: e.target.value })
                      }
                    />
                  </div>
                </Form.Group>
              </Row>
            ))}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            <Button className="app-primary-btn me-2" onClick={addFormFields}>
              Add
            </Button>
          </div>
          <div>
            <Button className="app-secondary-btn me-2" onClick={props.onHide}>
              Close
            </Button>
            <Button className="app-primary-btn" onClick={() => handleSave()}>
              {load ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupModal;
