import React from "react";
import MainScreen from "../components/AdminScreen/MainScreen";

const UserManagement = () => {
  return (
    <>
      <MainScreen />
    </>
  );
};

export default UserManagement;
