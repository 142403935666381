import React from "react";
import { useEffect, useState } from "react";
import { Carousel, Stack, Button } from "react-bootstrap";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import ImageSvgs from "../ImageSvgs";
import ExpertsComponent from "../CommonComponents/ExpertsComponent";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  ReactFlowProvider,
} from "@xyflow/react";
import { getHostUrl } from "../../config/environment";
import { postData } from "../../services/apiService";
import noData from "../../assets/images/noData.svg";
import { chunk } from "lodash";

const PreviewFlow = ({ setLevel, included, users }) => {
  const [loading, setLoading] = useState(false);
  const appDetails = JSON.parse(sessionStorage.getItem("appDetails"));
  const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
  const appNodes =
    appConfig?.nodes?.length > 0 &&
    appConfig?.nodes.map((node) => {
      return {
        type: "nodes",
        attributes: {
          "layer-id": node.data.layerId.id.toString(),
          "tool-id": node.data.toolDetails.id.toString(),
          "node-description": node.data.description,
        },
        id: null,
      };
    });

  const ownerDetails = chunk(appDetails?.owners, 5);

  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // eslint-disable-next-line
  const [edges, setEdges, onEdgesChange] = useEdgesState(appConfig.edges);

  useEffect(() => {
    (async () => {
      appConfig?.nodes?.length > 0 &&
        appConfig?.nodes.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,
            targetPosition: "left",
            sourcePosition: "right",
            data: {
              label: (
                <div className="node-box">
                  <div
                    className="node-up"
                    style={{
                      backgroundColor:
                        elem.data.layerId.id === 1
                          ? "#8C7500"
                          : elem.data.layerId.id === 2
                          ? "#006C3B"
                          : "#00328C",
                      borderRadius: "3px",
                    }}
                  >
                    <div className="layerText">{elem.data.layerId.name}</div>
                    <div className="nameText" title="name">
                      {elem.data.toolDetails.attributes["tool-name"]}
                    </div>
                  </div>
                  <div className="node-down">
                    <div className="descText" title="desc">
                      {elem.data.description}
                    </div>
                  </div>
                </div>
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    let finalPayload = {
      data: {
        type: "application",
        attributes: {
          "app-config": appConfig && appConfig,
          "access-link": appDetails?.access_link,
          "app-link": appDetails?.app_link,
          "app-org": { appOrg: appDetails?.app_org },
          "app-title": appDetails?.app_title,
          "app-description": appDetails?.app_description,
          "access-info": appDetails?.access_link,
          image: appDetails?.image,
          "preview-image": appDetails?.preview_image,
          "app-image": appDetails?.app_image,
          "app-preview-image": appDetails?.app_preview_image,
        },

        relationships: {
          communities: {
            data: JSON.parse(sessionStorage.getItem("appComs")),
          },

          owners: {
            data: appDetails?.owners,
          },

          "app-nodes": { data: appNodes },

          "app-info": {
            data: appDetails.app_info,
          },

          trainings: {
            data: JSON.parse(sessionStorage.getItem("appTrainings")),
          },

          process: {
            data: JSON.parse(sessionStorage.getItem("appProcess")),
          },
        },
      },
    };

    const url = `${getHostUrl()}application`;
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    try {
      setLoading(true);
      // eslint-disable-next-line
      const resData = await postData(url, finalPayload, headers);
      setLevel("table");
      sessionStorage.removeItem("appDetails");
      sessionStorage.removeItem("appConfig");
      sessionStorage.removeItem("appOwners");
      sessionStorage.removeItem("appDeputy");
      sessionStorage.removeItem("appNodes");
      sessionStorage.removeItem("appComs");
      sessionStorage.removeItem("appTrainings");
      sessionStorage.removeItem("appProcess");
      setLoading(false);
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="app-preview">
      <div className="preview-title d-flex flex-row justify-content-between mb-3">
        <span className="my-auto">
          <h4 className="fw-bold">Preview Details</h4>
        </span>
        <span>
          <ImageSvgs name="progress_3" />
        </span>
      </div>
      <div className="preview-app-details border-bottom pb-3">
        <div>
          <div className="d-flex flex-row">
            <span>
              <ImageSvgs name="app_icon" />
            </span>
            <span className="my-auto ms-2">
              <h5 className="m-0 fw-bold">{appDetails?.app_title}</h5>
            </span>
          </div>
          <span>
            <h6 className="mt-2"> {appDetails?.app_description}</h6>
          </span>
          <span className="d-flex flex-row">
            <p className="fw-bold me-2 mb-1">Application Link:</p>
            <p className="preview-link mb-1">
              <a href={appDetails?.app_link} target="_blank" rel="noreferrer">
                {appDetails?.app_link}
              </a>
            </p>
          </span>
          <span className="d-flex flex-row">
            <p className="fw-bold me-2 mb-1">Access Authorization:</p>
            <p className="preview-link mb-1">
              {appDetails?.access_link.length > 0 ? (
                <a
                  href={appDetails?.access_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {appDetails?.access_link}
                </a>
              ) : (
                <b>-</b>
              )}
            </p>
          </span>
          <div>
            <span className="d-flex flex-row">
              <p className="fw-bold me-2 mb-1">Further Information:</p>
              <p className="preview-link mb-1">
                {appDetails?.app_info.length > 0 ? (
                  <>
                    {" "}
                    {appDetails.app_info.map((elem) => {
                      return (
                        <>
                          <a
                            href={elem.attributes["info-URL"]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {elem.attributes["info-title"]}
                          </a>
                          <br />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <b>-</b>
                )}
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="preview-emp experts-section border-bottom pt-3 pb-3">
        <h6 className="fw-bold mb-2">Owner Details</h6>{" "}
        <div className="d-flex flex-row justify-content-between">
          {ownerDetails?.length > 0 ? (
            <Carousel
              nextIcon={nextArrow}
              prevIcon={prevArrow}
              interval={null}
              wrap={false}
              indicators={false}
            >
              {ownerDetails?.map((record, i) => (
                <Carousel.Item key={i}>
                  <Stack
                    direction="horizontal"
                    className="h-100 align-items-center"
                    gap={2}
                  >
                    {record.map((elem, j) => {
                      if (j < record.length - 1) {
                        return (
                          <>
                            <ExpertsComponent
                              key={j}
                              data={elem}
                              owner={elem.attributes["is-owners"]}
                            />
                            <div className="expert-divider"></div>
                          </>
                        );
                      } else {
                        return (
                          <ExpertsComponent
                            key={j}
                            data={elem}
                            owner={elem.attributes["is-owners"]}
                          />
                        );
                      }
                    })}
                  </Stack>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="ms-2 mb-3 d-flex flex-row border justify-content-center w-100 expert-no-data">
              <img src={noData} alt="No data available" />
              <h5 className="my-auto fw-bold">No data available</h5>
            </div>
          )}
        </div>
      </div>
      <div className="preview-map pt-3">
        <h6 className="fw-bold mb-3">Preview Connections</h6>{" "}
        {nodes && edges ? (
          <div className="preview-flow">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              deleteKeyCode=""
              draggable={false}
              nodesDraggable={false}
              fitView
              preventScrolling={true}
              zoomOnScroll={false}
              panOnDrag={false}
              //onScrollCapture={false}
              panOnScroll={false}
            >
              <Controls />
            </ReactFlow>
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-end w-100 mt-3">
        <Button
          className="app-secondary-btn my-auto me-2"
          onClick={() => {
            setLevel("addLayer");
          }}
        >
          Cancel
        </Button>
        <Button
          className="app-primary-btn"
          onClick={async () => {
            await handleSubmit();
          }}
        >
          {loading ? "Adding Application..." : "Add Application"}
        </Button>
      </div>
    </div>
  );
};

function PreviewPage(props) {
  return (
    <ReactFlowProvider>
      <PreviewFlow {...props} />
    </ReactFlowProvider>
  );
}

export default PreviewPage;
