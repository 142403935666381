import "../TrainingComp/TrainingComp.scss";
import React from "react";
import { useState, useEffect } from "react";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";
import { Nav } from "react-bootstrap";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { folderIcon, linkIcon } from "../../utils/iconUtils";

const TrainingComp = (props) => {
  const [trainingData, setTrainingData] = useState(null);
  const [folderTrainingData, setFolderTrainingData] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const param = `${getBaseUrl()}${props.source["source-url"]}`;
    async function getResponse() {
      // eslint-disable-next-line
      const res = await getData(param);
      setTrainingData(res.data);
    }
    getResponse();
    // eslint-disable-next-line
  }, []);

  function FolderLinksModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="folder-modal"
          >
            {"Links :"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.data.length === 0 ? (
            <p className="empty-msg">
              Empty folder. Add links to view them here.
            </p>
          ) : (
            <Row xs={1} md={3} className="g-4">
              {props.data.map((record, id) => (
                <Col key={id}>
                  <div className="sub-training-wrapper">
                    <Card
                      data-toggle="tooltip"
                      data-placement="top"
                      title={record.name}
                      key={id}
                    >
                      <Nav.Link
                        href={record["redirect-url"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="img-hover-zoom">
                          {record.image === null ? (
                            <Card.Img
                              variant="bottom"
                              src={`${getBaseUrl()}/images/default-images/default-trainings.jpg`}
                            />
                          ) : (
                            <Card.Img
                              variant="bottom"
                              src={`${getBaseUrl()}${record.image}`}
                            />
                          )}
                        </div>
                        <Card.Body>
                          <Card.Title>
                            {linkIcon}
                            &nbsp;{record.name}
                          </Card.Title>
                          <Card.Subtitle>{record.description}</Card.Subtitle>
                        </Card.Body>
                      </Nav.Link>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  if (!trainingData)
    return (
      <div className="text-center">
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
        &nbsp;
        <Spinner animation="grow" variant="dark" />
      </div>
    );

  return (
    <div className="training-wrapper">
      <div className="training-carousel" id="train-carousel">
        {trainingData.length === 0 ? (
          <p className="empty-msg">
            Empty folder. Add links to view them here.
          </p>
        ) : (
          <Row xs={1} md={4} className="g-4">
            {trainingData.map((record, id) => (
              <Col key={id}>
                <Card
                  data-toggle="tooltip"
                  data-placement="top"
                  title={record.name}
                  key={id}
                >
                  {record["is-folder"] ? (
                    <div
                      onClick={() => {
                        setModalShow(true);
                        setFolderTrainingData(record.trainings);
                      }}
                    >
                      <div className="img-hover-zoom">
                        {record.image === null ? (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}/images/default-images/default-trainings.jpg`}
                          />
                        ) : (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}${record.image}`}
                          />
                        )}
                      </div>
                      <Card.Body>
                        <Card.Title>
                          {folderIcon}
                          &nbsp;{record.name}
                        </Card.Title>
                        <Card.Subtitle>{record.description}</Card.Subtitle>
                      </Card.Body>
                    </div>
                  ) : (
                    <Nav.Link
                      href={record["redirect-url"]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="img-hover-zoom">
                        {record.image === null ? (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}/images/default-images/default-trainings.jpg`}
                          />
                        ) : (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}${record.image}`}
                          />
                        )}
                      </div>
                      <Card.Body>
                        <Card.Title>
                          {linkIcon}
                          &nbsp;{record.name}
                        </Card.Title>
                        <Card.Subtitle>{record.description}</Card.Subtitle>
                      </Card.Body>
                    </Nav.Link>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
      {modalShow === true ? (
        <div id="fav-modal">
          <FolderLinksModal
            data={folderTrainingData}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TrainingComp;
